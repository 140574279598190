import _ from 'lodash';
import { showPartialString, isNumberInput } from '../utils/utils';
import {
  PMT_WIZARD_FAILED_VALIDATION,
  RESET_WIZARD,
  UPDATE_PMT_WIZARD_TOP_NAV,
  UPDATE_PMT_WIZARD_SERVICES
} from '../actions/pmtWizardServices';
import {
  SOLAR_MODE_NO,
  SOLAR_MODE_EXISTING,
  SOLAR_MODE_NEW,
} from '../actions/pmtWizardGeneratorServices';

const STR_THRESHOLD = 12;

const INITIAL_STATE = {
  steps: [
    {
      id: 1,
      name: "customer",
      route: "app.analysis.solution.wizard.customer",
      className: "analysis-vert-menu-customer", title: "CUSTOMER", descr: "Enter Client Information",
      metadata: {
        created: false,
        visited: true,
        attempted: false,
        createMode: false,
        cloneMode: false,
        needToCreate: false,
        optional: false,
        disableTopNav: false,
        valid: false
      },
      requiredStatus: {
        customerName: true,
        naics: true
      },
      formData: {
      },
      setSelected() {
        this.isSelected = false;
        if (!_.isUndefined(this.formData) && !_.isUndefined(this.formData.existingCustomer)) {
          this.isSelected = true;
          this.originalValue = this.formData.existingCustomer.customerName;
          this.selectedValue = showPartialString(this.formData.existingCustomer.customerName, STR_THRESHOLD);
        }
      },
      validate(doCreate = false) {
        const { createMode } = this.metadata;
        this.metadata.valid = true;
        this.metadata.needToCreate = false;

        this.metadata.attempted = true;
        if (!createMode) {
          if (_.isEmpty(this.formData.existingCustomer)) {
            this.metadata.valid = false;
          }
          if (this.metadata.canViewAnalysisFeatures && _.isUndefined(this.formData.developerId)) {
            this.requiredStatus.developerId = false;
            this.metadata.valid = false;
          }
        } else if (createMode && (_.isEmpty(this.formData) || _.isUndefined(this.formData.customer))) {
          this.requiredStatus.customerName = this.requiredStatus.naics = this.metadata.valid = false;
        } else if (createMode) {
          this.requiredStatus.customerName = this.metadata.valid = !_.isEmpty(this.formData.customer.customerName);
          this.requiredStatus.naics = this.metadata.valid = !_.isEmpty(this.formData.customer.naics) || !_.isUndefined(this.formData.customer.naics);

          if (doCreate && this.requiredStatus.customerName && this.requiredStatus.naics) {
            this.metadata.needToCreate = false;
          }
        }

        return this.metadata.valid && !this.metadata.needToCreate;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.formData = {};
      },
      reset() {
        this.metadata = {
          created: false,
          visited: true,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          needToCreate: false,
          optional: false,
          valid: false,
        };
        this.formData = {
          developerId: this.formData ? this.formData.developerId : null
        };
      }
    },
    {
      id: 2,
      name: "services",
      route: "app.analysis.solution.wizard.service",
      className: "analysis-vert-menu-service", title: "SERVICE", descr: "Enter Facility Meter Information",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        needToCreate: false,
        optional: false,
        disableTopNav: false,
        valid: false,
      },
      formData: {
        service: {
          isSameAsAddress: true
        }
      },
      setSelected() {
        this.isSelected = false;
        if (!_.isUndefined(this.formData) && !_.isUndefined(this.formData.existingService)) {
          this.isSelected = true;
          this.originalValue = this.formData.existingService.serviceName;
          this.selectedValue = showPartialString(this.formData.existingService.serviceName, STR_THRESHOLD);
        }
      },
      postValidate() {
        if (this.formData.service.isSameAsAddress) {
          this.formData.service.siteName = this.formData.service.address;
        }
      },
      validate(doCreate = false) {
        const { createMode } = this.metadata;
        this.metadata.valid = true;
        this.metadata.needToCreate = false;

        this.metadata.attempted = true;
        if (!createMode && _.isNull(this.formData.existingService.serviceUUID)) {
          this.metadata.valid = false;
        } else if (createMode) {
          const { selectedUtility, selectedTariff, selectedBillingCalendar, said, meterNumber, address, city, state, zipcode, siteName, isSameAsAddress } = this.formData.service;
          // console.error("PmtWizardServices: validate(): selected utility = ");
          // console.info(selectedUtility);
          // console.error("PmtWizardServices: validate(): selected tariff = ");
          // console.info(selectedTariff);
          // console.error("PmtWizardServices: validate(): selected billing calendar = ");
          // console.info(selectedBillingCalendar);
          // console.error("PmtWizardServices: validate(): SAID = " + said);
          // console.error("PmtWizardServices: validate(): meter # = " + meterNumber);
          // console.error("PmtWizardServices: validate(): address = " + address);
          // console.error("PmtWizardServices: validate(): city = " + city);
          // console.error("PmtWizardServices: validate(): state = " + state);
          // console.error("PmtWizardServices: validate(): zipcode = " + zipcode);
          if (_.isUndefined(selectedUtility.utilityId) || _.isNull(selectedUtility.utilityId)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(selectedTariff.tariffId) || _.isNull(selectedTariff.tariffId)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(selectedBillingCalendar.calendarId) || _.isNull(selectedBillingCalendar.calendarId)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(said) || _.isNull(said)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(meterNumber) || _.isNull(meterNumber)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(address) || _.isNull(address)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(city) || _.isNull(city)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(state) || _.isNull(state)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(zipcode) || _.isNull(zipcode)) {
            this.metadata.valid = false;
          } else if (_.isEmpty(siteName) && !isSameAsAddress) {
            this.metadata.valid = false;
          }

          if (this.metadata.valid && _.isNull(this.formData.existingService.serviceUUID)) {
            this.metadata.needToCreate = true;
          }

          if (doCreate && this.metadata.valid) {
            this.metadata.needToCreate = false;
          }
        }

        this.postValidate();
        return this.metadata.valid && !this.metadata.needToCreate;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {
          service: {
            isSameAsAddress: true
          }
        };
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          needToCreate: false,
          optional: false,
          valid: false,
        };
        this.formData = {
          service: {
            isSameAsAddress: true
          }
        }
      }
    },
    {
      id: 3,
      name: "services-add-meter",
      route: "app.analysis.solution.wizard.add-meter",
      className: "analysis-vert-menu-data", title: "DATA", descr: "Upload Facility Meter Interval Data",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        optional: false,
        disableTopNav: false,
        valid: false
      },
      formData: {
        meter: {
          dataType: "NET_LOAD",
          intervalDuration: "900",
          intervalLabel: true,
          units: "kW",
          isService: true
        }
      },
      setSelected() {
        this.isSelected = false;
        this.originalValue = "";
        this.selectedValue = "";
      },
      validate() {
        this.metadata.valid = true;
        this.metadata.attempted = true;

        if (_.isUndefined(this.formData.meter.uploadFile)) {
          this.metadata.valid = false;
        }

        return this.metadata.valid;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {
          meter: {
            dataType: "NET_LOAD",
            intervalDuration: "900",
            intervalLabel: true,
            units: "kW",
            isService: true
          }
        };
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          optional: false,
          valid: false,
        };
        this.formData = {
          meter: {
            dataType: "NET_LOAD",
            intervalDuration: "900",
            intervalLabel: true,
            units: "kW",
            isService: true
          }
        };
      }
    },
    {
      id: 4,
      name: "generators",
      route: "app.analysis.solution.wizard.generators",
      className: "wiz-solar-generation", title: "SOLAR", descr: "Enter Solar Generator Details",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        needToCreate: false,
        solarMode: 1,
        optional: false,
        valid: false,
        disableTopNav: false,
        validate: true,
      },
      formData: {},
      setSelected() {
        this.isSelected = false;
        if (this.metadata.solarMode === SOLAR_MODE_NO) {
          this.isSelected = false;
          this.originalValue = "";
          this.selectedValue = "";
        } else {
          if (!_.isUndefined(this.formData) && !_.isUndefined(this.formData.existingGenerator)) {
            this.isSelected = true;
            this.originalValue = this.formData.existingGenerator.generatorName;
            this.selectedValue = showPartialString(this.formData.existingGenerator.generatorName, STR_THRESHOLD);
          }
        }
      },
      validate(doCreate = false) {
        const { solarMode } = this.metadata;
        this.metadata.valid = true;
        this.metadata.solarEpc = true;
        this.metadata.solarOpex = true;
        this.metadata.solarOpexEscalation = true;
        this.metadata.solarAnnualDegradation = true;
        this.metadata.solarInverterReplacementYear = true;
        this.metadata.solarInverterReplacementCost = true;
        this.metadata.solarCapex = true;
        this.metadata.solarUpfrontIncentive = true;
        this.metadata.solarAnnualIncentive = true;
        this.metadata.solarAnnualIncentiveEscalation = true;
        this.metadata.solarIncentiveDuration = true;
        this.metadata.needToCreate = false;

        this.metadata.attempted = true;
        if (solarMode === SOLAR_MODE_EXISTING) {
          const {
            solarAnnualDegradation, solarInverterReplacementYear, solarInverterReplacementCost,
            solarEpc, solarOpex, solarOpexEscalation, solarTariff,
            solarCapex, solarUpfrontIncentive, solarAnnualIncentive, solarAnnualIncentiveEscalation,
            solarIncentiveDuration
          } = this.formData.generator;
          // console.error("In Solar: validate(): EDIT mode -- form data = ");
          // console.error(this.formData.generator);
          if (_.isNull(this.formData.existingGenerator.generatorUUID)) {
            this.metadata.valid = false;
          } else if (_.isUndefined(solarTariff) || _.isNull(solarTariff.tariffId)) {
            this.metadata.valid = false;
          }

          if (this.metadata.validate) {
            if (_.isUndefined(solarEpc) || (!_.isUndefined(solarEpc) && solarEpc < 0)) {
              this.metadata.valid = false;
              this.metadata.solarEpc = false;
            }
            if (_.isUndefined(solarOpex) || (!_.isUndefined(solarOpex) && solarOpex < 0)) {
              this.metadata.valid = false;
              this.metadata.solarOpex = false;
            }
            if (_.isUndefined(solarOpexEscalation) || (!_.isUndefined(solarOpexEscalation) && (solarOpexEscalation < -100 || solarOpexEscalation > 100))) {
              this.metadata.valid = false;
              this.metadata.solarOpexEscalation = false;
            }
            if (_.isUndefined(solarAnnualDegradation) || (!_.isUndefined(solarAnnualDegradation) && (solarAnnualDegradation < 0))) {
              this.metadata.valid = false;
              this.metadata.solarAnnualDegradation = false;
            }
            if (_.isUndefined(solarInverterReplacementYear) || (!_.isUndefined(solarInverterReplacementYear) && solarInverterReplacementYear < 0)) {
              this.metadata.valid = false;
              this.metadata.solarInverterReplacementYear = false;
            }
            if (_.isUndefined(solarInverterReplacementCost) || (!_.isUndefined(solarInverterReplacementCost) && solarInverterReplacementCost < 0)) {
              this.metadata.valid = false;
              this.metadata.solarInverterReplacementCost = false;
            }
            if (_.isUndefined(solarCapex) || (!_.isUndefined(solarCapex) && solarCapex < 0)) {
              this.metadata.valid = false;
              this.metadata.solarCapex = false;
            }
            if (_.isUndefined(solarUpfrontIncentive) || (!_.isUndefined(solarUpfrontIncentive) && solarUpfrontIncentive < 0)) {
              this.metadata.valid = false;
              this.metadata.solarUpfrontIncentive = false;
            }
            if (_.isUndefined(solarAnnualIncentive) || (!_.isUndefined(solarAnnualIncentive) && solarAnnualIncentive < 0)) {
              this.metadata.valid = false;
              this.metadata.solarAnnualIncentive = false;
            }
            if (_.isUndefined(solarAnnualIncentiveEscalation) || (!_.isUndefined(solarAnnualIncentiveEscalation) && (solarAnnualIncentiveEscalation < -100 || solarAnnualIncentiveEscalation > 100))) {
              this.metadata.valid = false;
              this.metadata.solarAnnualIncentiveEscalation = false;
            }
            if (_.isUndefined(solarIncentiveDuration) || (!_.isUndefined(solarIncentiveDuration) && solarIncentiveDuration < 0)) {
              this.metadata.valid = false;
              this.metadata.solarIncentiveDuration = false;
            }
          }
        } else if (solarMode === SOLAR_MODE_NEW) {
          const {
            manufacturer, inverterACCapacity, inverterDCCapacity,
            solarAnnualDegradation, solarInverterReplacementYear, solarInverterReplacementCost,
            solarEpc, solarOpex, solarOpexEscalation, solarTariff,
            solarCapex, solarUpfrontIncentive, solarAnnualIncentive, solarAnnualIncentiveEscalation,
            solarIncentiveDuration
          } = this.formData.generator;
          // console.error("In Solar: validate(): CREATE mode -- form data = ");
          // console.error(this.formData.generator);
          if (_.isUndefined(manufacturer) || _.isNull(manufacturer)) {
            this.metadata.valid = false;
          } else if ((_.isUndefined(inverterACCapacity) || _.isNull(inverterACCapacity) || !isNumberInput(inverterACCapacity)) &&
            (_.isUndefined(inverterDCCapacity) || _.isNull(inverterDCCapacity) || !isNumberInput(inverterDCCapacity))) {
            this.metadata.valid = false;
          } else if (_.isUndefined(solarTariff) || _.isNull(solarTariff.tariffId)) {
            this.metadata.valid = false;
          }

          if (this.metadata.validate) {
            if (_.isUndefined(solarEpc) || (!_.isUndefined(solarEpc) && solarEpc < 0) || !isNumberInput(solarEpc)) {
              this.metadata.valid = false;
              this.metadata.solarEpc = false;
            }
            if (_.isUndefined(solarOpex) || (!_.isUndefined(solarOpex) && solarOpex < 0) || !isNumberInput(solarOpex)) {
              this.metadata.valid = false;
              this.metadata.solarOpex = false;
            }
            if (_.isUndefined(solarOpexEscalation) || (!_.isUndefined(solarOpexEscalation) && (solarOpexEscalation < -100 || solarOpexEscalation > 100)) || !isNumberInput(solarOpexEscalation)) {
              this.metadata.valid = false;
              this.metadata.solarOpexEscalation = false;
            }
            if (_.isUndefined(solarAnnualDegradation) || (!_.isUndefined(solarAnnualDegradation) && (solarAnnualDegradation < 0)) || !isNumberInput(solarAnnualDegradation)) {
              this.metadata.valid = false;
              this.metadata.solarAnnualDegradation = false;
            }
            if (_.isUndefined(solarInverterReplacementYear) || (!_.isUndefined(solarInverterReplacementYear) && solarInverterReplacementYear < 0) || !isNumberInput(solarInverterReplacementYear)) {
              this.metadata.valid = false;
              this.metadata.solarInverterReplacementYear = false;
            }
            if (_.isUndefined(solarInverterReplacementCost) || (!_.isUndefined(solarInverterReplacementCost) && solarInverterReplacementCost < 0) || !isNumberInput(solarInverterReplacementCost)) {
              this.metadata.valid = false;
              this.metadata.solarInverterReplacementCost = false;
            }
            if (_.isUndefined(solarCapex) || (!_.isUndefined(solarCapex) && solarCapex < 0) || !isNumberInput(solarCapex)) {
              this.metadata.valid = false;
              this.metadata.solarCapex = false;
            }
            if (_.isUndefined(solarUpfrontIncentive) || (!_.isUndefined(solarUpfrontIncentive) && solarUpfrontIncentive < 0) || !isNumberInput(solarUpfrontIncentive)) {
              this.metadata.valid = false;
              this.metadata.solarUpfrontIncentive = false;
            }
            if (_.isUndefined(solarAnnualIncentive) || (!_.isUndefined(solarAnnualIncentive) && solarAnnualIncentive < 0) || !isNumberInput(solarAnnualIncentive)) {
              this.metadata.valid = false;
              this.metadata.solarAnnualIncentive = false;
            }
            if (_.isUndefined(solarAnnualIncentiveEscalation) || (!_.isUndefined(solarAnnualIncentiveEscalation) && (solarAnnualIncentiveEscalation < -100 || solarAnnualIncentiveEscalation > 100)) || !isNumberInput(solarAnnualIncentiveEscalation)) {
              this.metadata.valid = false;
              this.metadata.solarAnnualIncentiveEscalation = false;
            }
            if (_.isUndefined(solarIncentiveDuration) || (!_.isUndefined(solarIncentiveDuration) && solarIncentiveDuration < 0) || !isNumberInput(solarIncentiveDuration)) {
              this.metadata.valid = false;
              this.metadata.solarIncentiveDuration = false;
            }
          }

          if (this.metadata.valid && _.isNull(this.formData.existingGenerator.generatorUUID)) {
            this.metadata.needToCreate = true;
          }

          if (doCreate && this.metadata.valid) {
            this.metadata.needToCreate = false;
          }
        }

        // console.error(`In Solar: validate(): is valid? (${this.metadata.valid})`);
        return this.metadata.valid && !this.metadata.needToCreate;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {};
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          needToCreate: false,
          solarMode: 1,
          optional: false,
          valid: false,
          validate: true,
        };
        this.formData = {};
      }
    },
    {
      id: 5,
      name: "generators-add-meter",
      route: "app.analysis.solution.wizard.add-meter",
      className: "analysis-vert-menu-data", title: "DATA", descr: "Upload Solar Generator Interval Data",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        optional: false,
        disableTopNav: false,
        valid: false
      },
      formData: {
        meter: {
          dataType: "NET_LOAD",
          intervalDuration: "900",
          intervalLabel: true,
          units: "kW",
          isService: false
        }
      },
      setSelected() {
        this.isSelected = false;
        this.originalValue = "";
        this.selectedValue = "";
      },
      validate() {
        this.metadata.valid = true;
        this.metadata.attempted = true;

        // if (!_.isUndefined(this.formData.meter.uploadFile)) {
        //   this.metadata.valid = false;
        // }

        return this.metadata.valid;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {
          meter: {
            dataType: "NET_LOAD",
            intervalDuration: "900",
            intervalLabel: true,
            units: "kW",
            isService: false
          }
        };
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          optional: false,
          valid: false
        };
        this.formData = {
          meter: {
            dataType: "NET_LOAD",
            intervalDuration: "900",
            intervalLabel: true,
            units: "kW",
            isService: false
          }
        };
      }
    },
    {
      id: 6,
      name: "storage",
      route: "app.analysis.solution.wizard.storage",
      className: "wiz-selector-storage", title: "STORAGE", descr: "Configure Energy Storage System",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        optional: false,
        valid: false,
        disableTopNav: false,
        validate: true,
      },
      formData: {
      },
      setSelected() {
        this.isSelected = false;
        this.originalValue = "";
        this.selectedValue = "";
        // console.error("PmtWizardServices: setSelected(): STORAGE: this.formData = ", this.formData);
        //
        // if (!_.isUndefined(this.formData) && !_.isUndefined(this.formData.storageManufacturer)) {
        //   this.isSelected = true;
        //   this.selectedValue = showPartialString(this.formData.storageManufacturer.manufacturerName, STR_THRESHOLD);
        // }
      },
      validate() {
        this.metadata.valid = true;
        this.metadata.attempted = true;
        this.metadata.storageSgipRate = true;
        this.metadata.storageOtherUpfrontIncentive = true;
        this.metadata.storageOtherAnnualIncentive = true;
        this.metadata.storageOtherIncentiveEscalation = true;
        this.metadata.storageOtherIncentiveDuration = true;

        const {
          freightCost, storageTariff, selectedNonExportMode,
          storageSgipRate, storageOtherUpfrontIncentive, storageOtherAnnualIncentive,
          storageOtherIncentiveEscalation, storageOtherIncentiveDuration
        } = this.formData;

        // if (_.isUndefined(storageManufacturer) || _.isNull(storageManufacturer.manufacturerId)) {
        //   this.metadata.valid = false;
        // }

        if (_.isUndefined(freightCost) || _.isNull(freightCost.featureId)) {
          this.metadata.valid = false;
        } else if (_.isUndefined(storageTariff) || _.isNull(storageTariff.tariffId)) {
          this.metadata.valid = false;
        } else if (_.isUndefined(selectedNonExportMode) || _.isNull(selectedNonExportMode.id)) {
          this.metadata.valid = false;
        }

        if (this.metadata.validate) {
          if (_.isUndefined(storageSgipRate) || (!_.isUndefined(storageSgipRate) && storageSgipRate < 0) || !isNumberInput(storageSgipRate)) {
            this.metadata.valid = false;
            this.metadata.storageSgipRate = false;
          }
          if (_.isUndefined(storageOtherUpfrontIncentive) || (!_.isUndefined(storageOtherUpfrontIncentive) && storageOtherUpfrontIncentive < 0) || !isNumberInput(storageOtherUpfrontIncentive)) {
            this.metadata.valid = false;
            this.metadata.storageOtherUpfrontIncentive = false;
          }
          if (_.isUndefined(storageOtherAnnualIncentive) || (!_.isUndefined(storageOtherAnnualIncentive) && storageOtherAnnualIncentive < 0) || !isNumberInput(storageOtherAnnualIncentive)) {
            this.metadata.valid = false;
            this.metadata.storageOtherAnnualIncentive = false;
          }
          if (_.isUndefined(storageOtherIncentiveEscalation) || (!_.isUndefined(storageOtherIncentiveEscalation) && (storageOtherIncentiveEscalation < -100 || storageOtherIncentiveEscalation > 100)) || !isNumberInput(storageOtherIncentiveEscalation)) {
            this.metadata.valid = false;
            this.metadata.storageOtherIncentiveEscalation = false;
          }
          if (_.isUndefined(storageOtherIncentiveDuration) || (!_.isUndefined(storageOtherIncentiveDuration) && storageOtherIncentiveDuration < 0) || !isNumberInput(storageOtherIncentiveDuration)) {
            this.metadata.valid = false;
            this.metadata.storageOtherIncentiveDuration = false;
          }
        }

        return this.metadata.valid;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {};
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          optional: false,
          valid: false,
          validate: true,
        };
        this.formData = {
        };
      }
    },
    {
      id: 7,
      name: "project",
      route: "app.analysis.solution.wizard.project",
      className: "wiz-selector-project", title: "PROJECT", descr: "Enter Project-Specific Configurations",
      metadata: {
        created: false,
        visited: false,
        attempted: false,
        createMode: false,
        cloneMode: false,
        optional: false,
        valid: false,
        disableTopNav: false,
        validate: true,
      },
      formData: {
      },
      setSelected() {
        this.isSelected = false;
      },
      validate() {
        this.metadata.valid = true;
        this.metadata.attempted = true;
        this.metadata.codDate = true;
        this.metadata.combinedTaxRate = true;
        this.metadata.discountRate = true;
        this.metadata.salesTax = true;
        this.metadata.guaranteeDemandThreshold = true;
        this.metadata.fixedEscalationY1 = true;
        this.metadata.fixedEscalationY11 = true;
        this.metadata.energyEscalationY1 = true;
        this.metadata.energyEscalationY11 = true;
        this.metadata.demandEscalationY1 = true;
        this.metadata.demandEscalationY11 = true;

        const {
          combinedTaxRate, depreciationSchedule, discountRate, guaranteeDemandThreshold, codDate,
          performanceGuarantee, projectLifetime, projectName, salesTax,
          fixedEscalationY1, fixedEscalationY11, energyEscalationY1, energyEscalationY11,
          demandEscalationY1, demandEscalationY11,
        } = this.formData.project;

        if (_.isUndefined(this.formData.project) || (!_.isUndefined(this.formData.project) && projectName.length < 1)) {
          this.metadata.valid = false;
        }
        if (_.isUndefined(codDate) || _.isNull(codDate)) {
          this.metadata.valid = false;
          this.metadata.codDate = false;
        }

        if (this.metadata.validate) {
          if (_.isUndefined(projectLifetime)) {
            this.metadata.valid = false;
          }
          if (_.isUndefined(salesTax) || (!_.isUndefined(salesTax) && (salesTax <= 0 || salesTax >= 100)) || !isNumberInput(salesTax)) {
            this.metadata.valid = false;
            this.metadata.salesTax = false;
          }
          if (_.isUndefined(depreciationSchedule)) {
            this.metadata.valid = false;
          }
          if (_.isUndefined(performanceGuarantee)) {
            this.metadata.valid = false;
          }
          if (_.isUndefined(combinedTaxRate) || (!_.isUndefined(combinedTaxRate) && (combinedTaxRate <= 0 || combinedTaxRate >= 100)) || !isNumberInput(combinedTaxRate)) {
            this.metadata.valid = false;
            this.metadata.combinedTaxRate = false;
          }
          if (_.isUndefined(discountRate) || (!_.isUndefined(discountRate) && (discountRate <= 0 || discountRate >= 100)) || !isNumberInput(discountRate)) {
            this.metadata.valid = false;
            this.metadata.discountRate = false;
          }
          if (_.isUndefined(guaranteeDemandThreshold) || (!_.isUndefined(guaranteeDemandThreshold) && guaranteeDemandThreshold < 0) || !isNumberInput(guaranteeDemandThreshold)) {
            this.metadata.valid = false;
            this.metadata.guaranteeDemandThreshold = false;
          }
          if (_.isUndefined(fixedEscalationY1) || (!_.isUndefined(fixedEscalationY1) && (fixedEscalationY1 < -100 || fixedEscalationY1 > 100)) || !isNumberInput(fixedEscalationY1)) {
            this.metadata.valid = false;
            this.metadata.fixedEscalationY1 = false;
          }
          if (_.isUndefined(fixedEscalationY11) || (!_.isUndefined(fixedEscalationY11) && (fixedEscalationY11 < -100 || fixedEscalationY11 > 100)) || !isNumberInput(fixedEscalationY11)) {
            this.metadata.valid = false;
            this.metadata.fixedEscalationY11 = false;
          }
          if (_.isUndefined(energyEscalationY1) || (!_.isUndefined(energyEscalationY1) && (energyEscalationY1 < -100 || energyEscalationY1 > 100)) || !isNumberInput(energyEscalationY1)) {
            this.metadata.valid = false;
            this.metadata.energyEscalationY1 = false;
          }
          if (_.isUndefined(energyEscalationY11) || (!_.isUndefined(energyEscalationY11) && (energyEscalationY11 < -100 || energyEscalationY11 > 100)) || !isNumberInput(energyEscalationY11)) {
            this.metadata.valid = false;
            this.metadata.energyEscalationY11 = false;
          }
          if (_.isUndefined(demandEscalationY1) || (!_.isUndefined(demandEscalationY1) && (demandEscalationY1 < -100 || demandEscalationY1 > 100)) || !isNumberInput(demandEscalationY1)) {
            this.metadata.valid = false;
            this.metadata.demandEscalationY1 = false;
          }
          if (_.isUndefined(demandEscalationY11) || (!_.isUndefined(demandEscalationY11) && (demandEscalationY11 < -100 || demandEscalationY11 > 100)) || !isNumberInput(demandEscalationY11)) {
            this.metadata.valid = false;
            this.metadata.demandEscalationY11 = false;
          }
        }

        return this.metadata.valid;
      },
      setClone() {
        this.metadata.cloneMode = true;
        this.metadata.visited = true;
      },
      unsetClone() {
        this.metadata.cloneMode = false;
        this.metadata.disableTopNav = false;
        this.metadata.visited = false;
        this.formData = {};
      },
      reset() {
        this.metadata = {
          created: false,
          visited: false,
          attempted: false,
          createMode: false,
          cloneMode: this.metadata.cloneMode,
          disableTopNav: this.metadata.disableTopNav,
          optional: false,
          valid: false,
          validate: true,
        };
        this.formData = {};
      }
    },
  ],
  currentId: 1,
  failedValidation: false,
  isFirst: true,
  isLast: false,
  nextId: 2
};

export default function updatePmtWizard(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case PMT_WIZARD_FAILED_VALIDATION:
      return Object.assign({}, state, payload, { isCloseWizard: false, isCloseCreateNewWizard: false, reset: false });
    case RESET_WIZARD:
      return Object.assign({}, payload, { isCloseWizard: false, isCloseCreateNewWizard: false, reset: true });
    case UPDATE_PMT_WIZARD_TOP_NAV:
      return Object.assign({}, state, payload, { failedValidation: false, isCloseWizard: false, isCloseCreateNewWizard: false, reset: false });
    case UPDATE_PMT_WIZARD_SERVICES:
      return Object.assign({}, state, payload, { reset: false });
    default:
      return Object.assign({}, state, { isCloseWizard: false, isCloseCreateNewWizard: false, reset: false });
  }
}
