import { VIEW_HEALTH_CYCLE } from '../actions/healthCycleServices';

const INITIAL_STATE = {
  createEvent: false
};

export default function healthCycleServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case VIEW_HEALTH_CYCLE:
      return Object.assign({}, state, payload);
    default:
      // console.error("In reducer.scheduleServices: (2) payload = ");
      // console.error(payload);
      // console.error(state);
      return Object.assign({}, state, { createEvent: false } );
  }
}
