import { HEADER_VPP_IDS } from './cachedObjectServices';
import { default as gsLog } from '../service/loggingService';
import VppApi from "../service/vppApi";
import CachedObjectServicesActions from '../actions/cachedObjectServices';
import LoggedInUserCheckInService from "../actions/loggedInUserCheckInService";
import Rx from "rx";
import _ from 'underscore';

export const VPP_LIST = "VPP_LIST";
export const VPP_SEARCH = "VPP_SEARCH";
export const VPP_SET_SELECTED_VPP = "VPP_SET_SELECTED_VPP";
export const VPP_SET_SELECTED_PROJECT = "VPP_SET_SELECTED_PROJECT";

export const VppSelectorServicesActions = () => {
  const rx = Rx;
  const vppApi = new VppApi();
  const cachedObjectServicesActions = CachedObjectServicesActions();
  const loggedInUserCheckInService = LoggedInUserCheckInService();

  const search = (searchTerm) => {
    return (dispatch, getState) => {
      return dispatch({
        type: VPP_SEARCH,
        payload: {
          data: []
        }
      })
    };
  };

  const setSelectedVpp = (selectedVpp) => {

    return (dispatch, getState) => {
      gsLog.debug("In VppSelectorServicesActions: setSelectedVpp(): selected VPP = ");
      gsLog.debug(selectedVpp);

      const { projects, vpps } = getState().vppSelectorServices.vppListResults;
      const { selectedProject, allPmtProjects } = getState().vppSelectorServices;
      const { vppRevenueStreams, vppApps, aggregratedProjects } = getRevenueConfigProjectBySelectedVpp(vpps, projects, allPmtProjects, selectedVpp);

      dispatch(cachedObjectServicesActions.setSelectedVpp(selectedVpp));

      let project = null;

      if (selectedProject && aggregratedProjects && aggregratedProjects.length > 0) {
        project = aggregratedProjects.find(item => {
          return item.projectUuid === selectedProject.projectUuid;
        });

        if (!project) {
          project = aggregratedProjects[0];
        }
      } else if (aggregratedProjects && aggregratedProjects.length > 0) {
        project = aggregratedProjects[0];
      }

      return dispatch({
        type: VPP_SET_SELECTED_VPP,
        payload: {
          selectedVpp,
          vppRevenueStreams,
          vppApps,
          aggregratedProjects,
          selectedProject: project
        }
      })
    }
  }

  const getRevenueConfigProjectBySelectedVpp = (vpps, projects, allPmtProjects, selectedVpp) => {
    let vppRevenueStreams = [],
      vppApps = [],
      aggregratedProjects = [];

    gsLog.debug("In VppSelectorServicesActions: getRevenueConfigProjectBySelectedVpp(): selected VPP = ");
    gsLog.debug(selectedVpp);
    if (selectedVpp) {
      const vpp = vpps.filter((item) => item.vppId === selectedVpp.vppId);

      gsLog.debug("In VppSelectorServicesActions: getRevenueConfigProjectBySelectedVpp(): found VPP = ");
      gsLog.debug(vpp);

      if (vpp && vpp.length > 0) {
        vppRevenueStreams = [...vpp[0].vppRevenueStreams];
        const applications = vpp[0].applications;
        gsLog.debug("In VppSelectorServicesActions: getRevenueConfigProjectBySelectedVpp(): applications = ");
        gsLog.debug(applications);

        if (applications) {
          vppApps = Object.keys(applications).reduce((acc, key) => {
            gsLog.debug("In VppSelectorServicesActions: getRevenueConfigProjectBySelectedVpp(): key = ");
            gsLog.debug(key);
            if (applications[key]) {
              applications[key].map((item) => {
                acc.push({ application: key, version: item.version });
              });
            }
            return acc;
          }, []);
        }

        const aggregatedProjectUuids = vpp[0].aggregatedProjectUuids;

        aggregratedProjects = aggregatedProjectUuids.reduce((acc, projectUuid) => {
          //PMT return projectUUID, pipeline API return projectUuid
          let project = allPmtProjects.find((project) => project.projectUUID === projectUuid);

          if (project) {
            project.projectUuid = projectUuid;
            acc.push(project);
          } else {
            project = projects.find((item) => item.projectUuid === projectUuid);

            if (project) {
              project.projectUUID = projectUuid;
              acc.push(project);
            }
          }

          return acc;
        }, []);
      }
    }

    gsLog.debug("In VppSelectorServicesActions: getRevenueConfigProjectBySelectedVpp(): applications = ");
    gsLog.debug(vppApps);

    return {
      vppRevenueStreams,
      vppApps,
      aggregratedProjects
    };
  };

  const getVppList = (isAllVpps = false) => {
    return (dispatch, getState) => {
      const vppList = getState().cachedObjectServices[HEADER_VPP_IDS];
      gsLog.debug("In VppSelectorServicesActions: getVppList(): cached vpp list = ");
      gsLog.debug(vppList);

      const observables = [
        vppApi.getVppList().catch((errors) => {
          gsLog.error("In VppSelectorServicesActions: getVppList(): error calling vpp list API = ");
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        }),
        vppApi.getAllPmtProjects().catch((errors) => {
          gsLog.error("In VppSelectorServicesActions: getVppList(): error calling project list API = ");
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        })
      ];

      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([vppListResults, projectResults]) => {
            if (vppListResults.apiError) {
              return dispatch({
                type: VPP_LIST,
                payload: {
                  selectedVpp: null,
                  vppListResults: null,
                  vppRevenueStreams: [],
                  vppApps: [],
                  aggregratedProjects: []
                }
              });
            } else {
              gsLog.debug("In VppSelectorServicesActions: getVppList(): vpp list API results = ");
              gsLog.debug(vppListResults);

              //Restricting API DATA for Vpps
              if (vppListResults.data) {
                vppListResults.data.vpps = loggedInUserCheckInService.setExcludingVpps(vppListResults);

                let selectedVpp = getState().cachedObjectServices.selectedVpp;

                if (selectedVpp) {
                  let vpp = vppListResults.data.vpps.find((item) => item.vppId === selectedVpp.vppId);

                  if (!_.isUndefined(vpp) && !_.isNull(vpp)) {
                    selectedVpp = vpp;
                  } else {
                    selectedVpp = vppListResults.data.vpps[0];
                  }
                } else if (!isAllVpps && !selectedVpp) {
                  selectedVpp = vppListResults.data.vpps[0];
                }

                const allPmtProjects = projectResults.apiError && projectResults.data && projectResults.data.content ? [] : projectResults.data.content;

                dispatch(cachedObjectServicesActions.setSelectedVpp(selectedVpp));

                const { vppRevenueStreams, vppApps, aggregratedProjects } = getRevenueConfigProjectBySelectedVpp(vppListResults.data.vpps, vppListResults.data.projects, allPmtProjects, selectedVpp);
                const { selectedProject } = getState().vppSelectorServices;

                let project = null;

                if (selectedProject && aggregratedProjects && aggregratedProjects.length > 0) {
                  project = aggregratedProjects.find(item => {
                    return item.projectUuid === selectedProject.projectUuid;
                  });

                  if (!project) {
                    project = aggregratedProjects[0];
                  }
                } else if (aggregratedProjects && aggregratedProjects.length > 0) {
                  project = aggregratedProjects[0];
                }

                return dispatch({
                  type: VPP_LIST,
                  payload: {
                    selectedVpp,
                    vppListResults: vppListResults.data,
                    allPmtProjects,
                    vppRevenueStreams,
                    vppApps,
                    aggregratedProjects,
                    selectedProject: project
                  }
                });
              }
            }
          },
          (errors) => {
            gsLog.error("In VppSelectorServicesActions: getVppList(): error in processing vpp list API results = ");
            gsLog.error(errors);
          },
          () => {
            gsLog.debug("In VppSelectorServicesActions: getVppList(): DONE with processing vpp list API results");
          }
        )
    };
  };

  return {
    getVppList,
    search,
    setSelectedVpp
  };
}

export default VppSelectorServicesActions;