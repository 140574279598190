import { labelFormatter } from '../utils/utils';
import { LOAD_GRAPH_TITLE, FIFTEEN_MIN_IN_MS, TELSTRA_GRAPH } from "../shared/constants";
import { default as gsLog } from '../service/loggingService';
import $ from "jquery";

//This convert the Angular Service , using singleton Class Patten. Will only one instnace cross the whole system, avoid the muti-instance
class BillingTimeSeriesChart {
    constructor() {
        if (BillingTimeSeriesChart.instance) {
            return BillingTimeSeriesChart.instance;
        }
        this.gsLog = gsLog;
        this.LOAD_GRAPH_TITLE = LOAD_GRAPH_TITLE;
        this.FIFTEEN_MIN_IN_MS = FIFTEEN_MIN_IN_MS;
        this.TELSTRA_GRAPH = TELSTRA_GRAPH;
        this.pdfGraph = {};
        this.webGraph = {};
        this.ONE_HOUR = 3600 * 1000;
        this.TWO_HOURS = 2 * this.ONE_HOUR;
        this.THREE_HOURS = 3 * this.ONE_HOUR;
        this.FOUR_HOURS = 4 * this.ONE_HOUR;
        this.ONE_DAY = 24 * this.ONE_HOUR;
        this.SEVEN_DAYS = 7 * 24 * this.ONE_HOUR;
        this.init();
        BillingTimeSeriesChart.instance = this;
    }

    updateById(chartId, data) {
        const chart = $(`#${chartId}`).highcharts();
        this.gsLog.debug(`In BillingTimeSeriesChart: updateById(): chart ID (${chartId}), data = `);
        this.gsLog.debug(data);
        if (chart) {
            while (chart.series.length > 0) {
                chart.series[0].remove(false);
            }
            if (data.original) {
                chart.addSeries({
                    customLegendSymbol: true,
                    type: "line",
                    name: "Original Building Load",
                    marker: { enabled: false },
                    data: data.original,
                    color: this.LOAD_GRAPH_TITLE.original.color,
                    zIndex: 100
                }, false);
            }
            if (data.netSolar) {
                chart.addSeries({
                    customLegendSymbol: true,
                    type: "line",
                    name: "Net Solar",
                    marker: { enabled: false },
                    data: data.netSolar,
                    color: this.LOAD_GRAPH_TITLE.solar.color,
                    zIndex: 200
                }, true);
            }
            if (data.netLoad) {
                chart.addSeries({
                    customLegendSymbol: true,
                    type: "line",
                    name: "Net Building Load",
                    marker: { enabled: false },
                    data: data.netLoad,
                    color: this.LOAD_GRAPH_TITLE.netLoad.color,
                    zIndex: 300
                }, true);
            }
        } else {
            this.gsLog.error(`In BillingTimeSeriesChart: updateById(): chart ID (${chartId}) is not initialized`);
        }
    }

    update(data) {
        this.updateById("timeSeriesChart", data);
    }

    init() {
        this.gsLog.debug("In BillingTimeSeriesChart: init(): initializing ...");
        this.pdfGraph = {
            options: {
                chart: {
                    type: "line",
                    alignTicks: false,
                    style: {
                        color: "#2f3e4c"
                    },
                    zoomType: "x",
                    spacingLeft: 0,
                    spacingRight: 0,
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -10
                        },
                        theme: {
                            fill: 'white',
                            stroke: 'grey',
                            r: 4,
                            states: {}
                        }
                    }
                },
                title: {
                    text: null,
                    style: {
                        color: '#FFF',
                        textTransform: 'uppercase',
                        fontSize: '15px',
                        fontWeight: 'normal'
                    }
                },
                subtitle: {
                    style: {
                        color: '#E0E0E3',
                        textTransform: 'uppercase'
                    }
                },
                legend: {
                    itemStyle: {
                        color: "black"
                    }
                },
                plotOptions: {
                    line: {
                        animation: false,
                        marker: {
                            enabled: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime",
                    labels: {
                        style: {
                            color: "black",
                            fontSize: '10px'
                        },
                        formatter: function () {
                            return labelFormatter(this.value, this.axis.min, this.axis.max, this.isFirst, this.isLast, true);
                        }
                    },
                    gridLineColor: '#707073',
                    style: {
                        color: 'black'
                    },
                    lineColor: '#707073',
                    minorGridLineColor: '#505053',
                    tickColor: '#707073',
                },
                yAxis: [
                    {
                        tickWidth: 1,
                        tickInterval: 10,
                        minPadding: 0.2,
                        title: {
                            text: 'kW',
                            style: {
                                color: '#A0A0A3'
                            }
                        },
                        gridLineColor: '#707073',
                        labels: {
                            style: {
                                color: 'black'
                            }
                        },
                        lineColor: '#707073',
                        minorGridLineColor: '#505053',
                        tickColor: '#707073',
                        plotBands: []
                    }
                ]
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: "black",
                        fontSize: '10px'
                    },
                    formatter: function () {
                        return labelFormatter(this.value, this.axis.min, this.axis.max, this.isFirst, this.isLast, true);
                    }
                },
                gridLineColor: '#707073',
                style: {
                    color: 'black'
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
            },
            yAxis: [
                {
                    tickWidth: 1,
                    tickInterval: 10,
                    minPadding: 0.2,
                    title: {
                        text: 'kW',
                        style: {
                            color: '#A0A0A3'
                        }
                    },
                    gridLineColor: '#707073',
                    labels: {
                        style: {
                            color: 'black'
                        }
                    },
                    lineColor: '#707073',
                    minorGridLineColor: '#505053',
                    tickColor: '#707073',
                    plotBands: []
                }
            ],
            series: []
        };
        this.webGraph = {
            options: {
                chart: {
                    type: "line",
                    alignTicks: false,
                    style: {
                        color: "#2f3e4c"
                    },
                    zoomType: "x",
                    backgroundColor: this.TELSTRA_GRAPH.subChart.backgroundColor,
                    spacingLeft: 0,
                    spacingRight: 0,
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -10
                        },
                        theme: {
                            fill: 'white',
                            stroke: 'grey',
                            r: 4,
                            states: {}
                        }
                    }
                },
                title: {
                    text: null,
                    style: {
                        color: '#FFF',
                        textTransform: 'uppercase',
                        fontSize: '15px',
                        fontWeight: 'normal'
                    }
                },
                subtitle: {
                    style: {
                        color: '#E0E0E3',
                        textTransform: 'uppercase'
                    }
                },
                legend: {
                    itemStyle: {
                        color: "#fff"
                    }
                },
                plotOptions: {
                    line: {
                        animation: false,
                        marker: {
                            enabled: false
                        }
                    }
                },
                xAxis: {
                    type: "datetime",
                    labels: {
                        style: {
                            color: "#fff"
                        },
                        formatter: function () {
                            return labelFormatter(this.value, this.axis.min, this.axis.max, this.isFirst, this.isLast, true);
                        }
                    },
                    gridLineColor: '#707073',
                    style: {
                        color: '#fff'
                    },
                    lineColor: '#707073',
                    minorGridLineColor: '#505053',
                    tickColor: '#707073',
                },
                yAxis: [
                    {
                        tickWidth: 1,
                        tickInterval: 10,
                        minPadding: 0.2,
                        title: {
                            text: 'kW',
                            style: {
                                color: '#A0A0A3'
                            }
                        },
                        gridLineColor: '#707073',
                        labels: {
                            style: {
                                color: '#fff'
                            }
                        },
                        lineColor: '#707073',
                        minorGridLineColor: '#505053',
                        tickColor: '#707073',
                        plotBands: []
                    }
                ],
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: "#fff"
                    },
                    formatter: function () {
                        return labelFormatter(this.value, this.axis.min, this.axis.max, this.isFirst, this.isLast, true);
                    }
                },
                gridLineColor: '#707073',
                style: {
                    color: '#fff'
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
            },
            yAxis: [
                {
                    tickWidth: 1,
                    tickInterval: 10,
                    minPadding: 0.2,
                    title: {
                        text: 'kW',
                        style: {
                            color: '#A0A0A3'
                        }
                    },
                    gridLineColor: '#707073',
                    labels: {
                        style: {
                            color: '#fff'
                        }
                    },
                    lineColor: '#707073',
                    minorGridLineColor: '#505053',
                    tickColor: '#707073',
                    plotBands: []
                }
            ],
            series: []
        };
    }
}



export default BillingTimeSeriesChart;
