import { CREATE_SCHEDULE, EXPORT_SCHEDULES, VIEW_SCHEDULES, VIEW_PERFORMANCE_SCHEDULES} from '../actions/scheduleServices';

const INITIAL_STATE = {
  createEvent: false,
  viewSchedulesGridData: null,
  viewSchedulesChartData: null,
  viewPerformanceSchedulesChartData: null
};

export default function scheduleServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case CREATE_SCHEDULE:
      // console.error("In reducer.scheduleServices: payload = ");
      // console.error(payload);
      // console.error(state);
      return Object.assign({}, payload, { createEvent: true, doExport: false });
    case EXPORT_SCHEDULES:
      return Object.assign({}, state, payload);
    case VIEW_SCHEDULES:
      return Object.assign({}, state, payload, { doExport: false });
    case VIEW_PERFORMANCE_SCHEDULES:
      return Object.assign({}, state, payload, { doExport: false });
    default:
      // console.error("In reducer.scheduleServices: (2) payload = ");
      // console.error(payload);
      // console.error(state);
      return Object.assign({}, state, { createEvent: false, doExport: false } );
  }
}
