import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import LoggingService from './service/loggingService';
import store from './service/store';
import './index.css';
import './assets/css/gcn.css';
import './dist/extlibs/foundation.min.css';
// import App from './App';
import LoginContainerComponent from './login/loginContainer.componentReact';
import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faAngleRight, faAngleLeft, faCaretRight, faCaretDown, faAngleDoubleLeft, faAngleDoubleRight, faBars, faExpand, faSync, faSearch, faStar, faCheckSquare, faFileExcel } from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar, faAngleRight, faAngleLeft, faCaretRight, faCaretDown, faAngleDoubleLeft, faAngleDoubleRight, faBars, faExpand, faSync, faSearch, faStar, faCheckSquare, faFileExcel);

LoggingService.debug("Index.js : 000");

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<Provider store={store}><LoginContainerComponent /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
