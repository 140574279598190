import { TOGGLE_FOOTER_EXPAND, TOGGLE_FOOTER_RESET, TOGGLE_FOOTER_RETRACT } from "./toggleFooterServices";
import { toggleSelectorUtil } from "../utils/utils";

export const TOGGLE_HELIOS_EXPANDER = "TOGGLE_HELIOS_EXPANDER";
export const TOGGLE_HELIOS_FOOTER = "TOGGLE_HELIOS_FOOTER";

let HeliosSelectorToggleActionsInstance = null;

const HeliosSelectorToggleActionsConstruction = () => {
  const toggleExpander = () => {
    return (dispatch, getState) => {
      const expander = !getState().toggleHeliosFooterServices.expander;
      const footer = !expander ? false : getState().toggleHeliosFooterServices.footer;

      dispatch({
        type: TOGGLE_HELIOS_EXPANDER,
        payload: {
          expander,
          footer,
          expandMode: expander ? TOGGLE_FOOTER_EXPAND : TOGGLE_FOOTER_RESET
        }
      });
    }
  };

  const toggleFooter = () => {
    return (dispatch, getState) => {
      const footer = !getState().toggleHeliosFooterServices.footer;
      const expander = !footer ? false : getState().toggleHeliosFooterServices.expander;

      dispatch({
        type: TOGGLE_HELIOS_FOOTER,
        payload: {
          expander,
          footer,
          expandMode: footer ? TOGGLE_FOOTER_RETRACT : TOGGLE_FOOTER_RESET
        }
      });
    }
  };

  const toggleSelector = () => {
    return (dispatch, getState) => {
      let { down, expander, footer, expandMode } = getState().toggleHeliosFooterServices;

      const results = toggleSelectorUtil(down, expander, footer, expandMode);

      dispatch({
        type: TOGGLE_HELIOS_FOOTER,
        payload: results
      });
    }
  };

  return {
    toggleExpander,
    toggleFooter,
    toggleSelector,
  }
};

export const HeliosSelectorToggleActions = () => {
  if (HeliosSelectorToggleActionsInstance) {
    return HeliosSelectorToggleActionsInstance;
  } else {
    HeliosSelectorToggleActionsInstance = HeliosSelectorToggleActionsConstruction();
    return HeliosSelectorToggleActionsInstance;
  }
}
