import {default as gsLog} from '../service/loggingService';
import viewStateService from '../service/viewStateService';

export const SELECTED_CUSTOMER = "SELECTED_CUSTOMER";
export const CustomerServicesActions = () => {

  const setCustomer = (customer) => {
    gsLog.debug("In CustomerServicesActions: setCustomer(): customer = ");
    gsLog.debug(customer);

    viewStateService.setSelectedCustomer(customer);
    return {
      type: SELECTED_CUSTOMER,
      payload: {
        customer
      }
    }
  };

  return {
    setCustomer
  };
}

export default CustomerServicesActions; 