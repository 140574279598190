import _ from 'underscore';
import { stringify } from "querystring";
import { forEach } from 'lodash';

export const LoggedInUserCheckInService = () => {
    let notMatchedRoles = [];
    let roleIds = [];
    let allvpps = [];
    let allCustomers = [];
    let allProjects = [];
    let allMiIds = [];
    let errorAndMsg = [{
        errorAlert: String,
        errorType: String
    }];
    let nonBlockerWarnings = [];
    let recursiveRoles = [];
    let temp = [];

    const checkForWarning = (user) => {
        if (!_.isNull(user)) {
            nonBlockerWarnings = [];
            if (user.usrRoles.some((item) => item.roleId === 10)) {
                if (user.customerIds.length >= 1 && user.vppIds.length >= 1) {
                    nonBlockerWarnings.push("You Have 1 or More assigned Customers and Projects");
                    return nonBlockerWarnings;
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 20)) {
                if (user.customerIds.length >= 1) {
                    nonBlockerWarnings.push("You Have One or More Assigned Customer");
                    return nonBlockerWarnings;
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 30)) {
                if (user.etlSpaceIds) {
                    if (user.etlSpaceIds.length >= 1) {
                        nonBlockerWarnings.push("You Have 1 or More assigned ETL");
                        return nonBlockerWarnings;
                    }
                }
                if (user.optimizerSpaceIds) {
                    if (user.optimizerSpaceIds.length >= 1) {
                        nonBlockerWarnings.push("You Have 1 or More assigned optimizer space");
                        return nonBlockerWarnings;
                    }
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 40)) {
                if (user.vppIds.length >= 1) {
                    nonBlockerWarnings.push("You have one or more assigned VPP");
                    return nonBlockerWarnings;
                }
            }
        }
    }

    const checkForBlocker = (user) => {
        if (!_.isNull(user) && !_.isUndefined(user)) {
            errorAndMsg = [];
            if (user.usrRoles.some((item) => item.roleId === 10)) {
                if (user.customerIds.length <= 0 && user.vppIds.length <= 0) {
                    errorAndMsg.push({ errorAlert: "No Customers and Projects are Assigned to you", errorType: 'CSTPRJ' });
                    return errorAndMsg;
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 20)) {
                if (user.customerIds.length <= 0) {
                    errorAndMsg.push({ errorAlert: "No Customers are assigned to you.", errorType: 'CST' });
                    return errorAndMsg;
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 30)) {
                if (user.etlSpaceIds) {
                    if (user.etlSpaceIds.length <= 0) {
                        errorAndMsg.push({ errorAlert: "No ETL Assigned", errorType: 'ETL' });
                        return errorAndMsg;
                    }
                }
                if (user.optimizerSpaceIds) {
                    if (user.optimizerSpaceIds.length <= 0) {
                        errorAndMsg.push({ errorAlert: "No Optimizer Assigned", errorType: 'OPT' });
                        return errorAndMsg;
                    }
                }
            }
            if (user.usrRoles.some((item) => item.roleId === 40)) {
                if (user.vppIds.length <= 0) {
                    errorAndMsg.push({ errorAlert: "No VPP assigned to you", errorType: 'VPP' });
                    return errorAndMsg;
                }
            }
        }
    }

    const checkUserPermission = function (roles) {
        notMatchedRoles = [];
        recursiveRoles = [];
        temp = [];
        var roleObj = roles;

        for (var r = 0; r < roleObj.length; r++) {
            roleIds.push(roleObj[r].roleId)
        }

        for (var j = 0; j < roleObj.length; j++) {
            var preRoles = getReverseRole(roleObj[j].roleId)
            validateRoles(preRoles, roleIds)
        }
        recursiveRoles.push("No Role Assignment found for roles" + " " + notMatchedRoles.toString());
        return recursiveRoles;
    }

    const getReverseRole = function (roleId) {
        var counter = 0;
        for (counter = roleId; (counter % 10) != 0; counter--) {
            temp.push(counter);
        }
        if ((counter % 10) == 0) {
            temp.push(counter);
        }
        return temp;
    }

    const validateRoles = function (reqRoles, roles) {
        // angular.forEach(reqRoles, function (v, k) {
        //     if (!(roles.indexOf(v) > -1)) {
        //         if (notMatchedRoles.indexOf(v) < 0) {
        //             notMatchedRoles.push(v);
        //         }
        //     }
        // });

        reqRoles.forEach( (v) => {
            if (!(roles.indexOf(v) > -1)) {
                if (notMatchedRoles.indexOf(v) < 0) {
                    notMatchedRoles.push(v);
                }
            }
        } )
    }

    const setExcludingVpps = function (vppsApiData) {
        let localStorageExcludedVpps = [];
        var excludedVppsList = [];
        allvpps = [];
        localStorageExcludedVpps = JSON.parse(localStorage.getItem('gsAppLocal.excludedvppIds'));
        allvpps = vppsApiData.data ? vppsApiData.data.vpps : [];
        if (!_.isNull(localStorageExcludedVpps)) {
            var excludeList = []

            // angular.forEach(localStorageExcludedVpps, function (v, k) {
            //     excludeList.push(v.vppId);
            // });
            
            localStorageExcludedVpps.forEach( (v) => {
                excludeList.push(v.vppId);
            });

            excludedVppsList = allvpps.filter((el) => { return (excludeList.indexOf(el.vppId) < 0) });
            return excludedVppsList;
        } else {
            return allvpps;
        }
    }

    const setExcludingCustomers = function (customersApiData) {
        let localStorageCustomers = [];
        var excludedCustomerList = [];
        allCustomers = [];

        localStorageCustomers = JSON.parse(localStorage.getItem('gsAppLocal.excludedCustomerIds'));
        allCustomers = customersApiData.data.content;

        if (!_.isNull(localStorageCustomers)) {
            var excludecstList = [];
            // angular.forEach(localStorageCustomers, function (v, k) {
            //     excludecstList.push(v.customerId);
            // })

            localStorageCustomers.forEach( (v) => {
                excludecstList.push(v.customerId);
            });

            excludedCustomerList = allCustomers.filter((el) => { return (excludecstList.indexOf(el.customerId + "") < 0) });
            return excludedCustomerList;
        } else {
            return allCustomers;
        }
    }

    const setExcludingProjects = function (projectApiData) {
        let localStorageProjects = [];
        var excludedProjectList = [];
        allProjects = projectApiData ? projectApiData.data.content : [];

        localStorageProjects = JSON.parse(localStorage.getItem('gsAppLocal.excludedCustomerIds'));
        if (!_.isNull(localStorageProjects)) {
            var excludecstList = [];

            // angular.forEach(localStorageProjects, function (v, k) {
            //     excludecstList.push(v.projectId);
            // })
            
            localStorageProjects.forEach( (v) => {
                excludecstList.push(v.projectId);
            });

            excludedProjectList = allProjects.filter((el) => { return (excludecstList.indexOf(el.projectId) < 0) });
            return excludedProjectList;
        } else {
            return allProjects;
        }
    }

    const setExcludingMiIds = function (selectedMiidData) {
        allMiIds = selectedMiidData;
        let localStorageMiIds = JSON.parse(localStorage.getItem('gsAppLocal.excludedMiIds'));
        let localStorageNewCreatedMis = JSON.parse(localStorage.getItem('gsAppLocal.newCreatedMi'));
        let excludedMiIds = [];
        if (!_.isNull(localStorageNewCreatedMis)) {
            localStorageNewCreatedMis.filter((el) => { allMiIds.push(el) });
            allMiIds = allMiIds.filter((allMiIds, index, self) =>
                index === self.findIndex((t) => (t.pid === allMiIds.pid && t.sid === allMiIds.sid)));
        }
        if (!_.isNull(localStorageMiIds)) {

            // angular.forEach(allMiIds, function (v, k) {
            //     angular.forEach(localStorageMiIds, function (v1, k1) {
            //         if (v.pid == v1.pid && v.sid == v1.sid && v.sname == v1.sname) {
            //             excludedMiIds.push(v);
            //         }
            //     })
            // })

            allMiIds.forEach((v) => {
                localStorageMiIds.forEach((v1) => {
                    if (v.pid == v1.pid && v.sid == v1.sid && v.sname == v1.sname) {
                        excludedMiIds.push(v);
                    }
                });
            });

            allMiIds = allMiIds.filter((el) => { return (excludedMiIds.indexOf(el) < 0) });
            allMiIds = allMiIds.filter((allMiIds, index, self) =>
                index === self.findIndex((t) => (t.pid === allMiIds.pid && t.sid === allMiIds.sid)));
            return allMiIds;
        }
        return allMiIds;
    }

    const removeDuplicateNewMis = function (newMis) {
        if (!_.isNull(newMis) && !_.isUndefined(newMis)) {
            newMis = newMis.filter((newMis, index, self) =>
                index === self.findIndex((t) => (t.pid === newMis.pid && t.sid === newMis.sid)));
            return newMis;
        } else {
            return newMis;
        }
    }

    return {
        checkUserPermission,
        notMatchedRoles,
        setExcludingVpps,
        setExcludingCustomers,
        setExcludingProjects,
        setExcludingMiIds,
        checkForBlocker,
        checkForWarning,
        removeDuplicateNewMis
    }
}

export default LoggedInUserCheckInService;