import PollingServiceMessageHandler from "../service/pollingServiceMessageHandler";
import {default as gsLog} from '../service/loggingService';
import { SCHEDULE_API } from "../shared/app.constants.all";
import _ from "lodash";
import io from 'socket.io-client';

export const UPDATE_SCHEDULE_POLLING = "UPDATE_SCHEDULE_POLLING";

let SchedulePollingServicesInstance = null;

const SchedulePollingServicesConstruction = () => {
  let pollingServiceMessageHandler = new PollingServiceMessageHandler();
  let SchedulePollingSocket = null;
  const MI_SCHEDULE_CONNECT_ACK = 'miScheduleConnectAck',
    MI_SCHEDULE_QUEUE = 'miScheduleQueue',
    MI_SCHEDULE_QUEUE_ACK = 'miScheduleQueueAck',
    MI_SCHEDULE_STATUS_CHANGED = 'miScheduleStatusChanged',
    SERVER_DISCONNECTED = 'disconnected';
  let _socketId = null;

  const startPolling = (data) => {
    if (SchedulePollingSocket) {
      const waitTime = _socketId ? 0 : 1500;
      
      setTimeout(() => {
        const dataToSend = data.map((item) => {
          item.type = 'schedule';
          item.socketId = _socketId;

          if (item.manualInstrScheduleId) {
            item.miId = item.manualInstrScheduleId;
          }
          
          return item;
        })

        // send
        gsLog.debug(`In SchedulePollingServices: startPolling(): sent data to queue (${MI_SCHEDULE_QUEUE}): data = `);
        gsLog.debug(dataToSend);
        SchedulePollingSocket.emit(MI_SCHEDULE_QUEUE, JSON.stringify({ payload: dataToSend }));
      }, waitTime);
    } else {
      gsLog.error("In SchedulePollingServices: startPolling(): data stream is closed");
    }

    return {
      type: UPDATE_SCHEDULE_POLLING,
      payload: {
        noop: true
      }
    };
  }

  const connectToPolling = (user) => {
    let token = user ? user.aaa_token : "schedule-tk";

    SchedulePollingSocket = io(SCHEDULE_API.POLLING_SERVICE, {
      path: '/ws/socket.io',
      query: { token }
    });

    SchedulePollingSocket.on(MI_SCHEDULE_STATUS_CHANGED, (msg) => {
      gsLog.debug(`In SchedulePollingServices: onReceiveScheduleMessage(): received message from polling service (${MI_SCHEDULE_STATUS_CHANGED}): RE-RENDER UI: msg = `);
      gsLog.debug(msg);

      // re-render UI
      setTimeout(() => {
        pollingServiceMessageHandler.messageReceived(msg);
      }, 500); 
    });

    SchedulePollingSocket.on(MI_SCHEDULE_QUEUE_ACK, (msg) => {
      gsLog.debug(`In SchedulePollingServices: received message from polling service (${MI_SCHEDULE_QUEUE_ACK}): msg = `);
      gsLog.debug(msg);
    });

    SchedulePollingSocket.on(MI_SCHEDULE_CONNECT_ACK, (msg) => {
      gsLog.debug(`In SchedulePollingServices: received message from polling service (${MI_SCHEDULE_CONNECT_ACK}): msg = `);
      gsLog.debug(msg);
      _socketId = msg.socketId;
    });

    SchedulePollingSocket.on(SERVER_DISCONNECTED, (msg) => {
      gsLog.debug(`In SchedulePollingServices: received message from polling service (${SERVER_DISCONNECTED}): SERVER is DISCONNECTED: msg = `);
      gsLog.debug(msg);
      SchedulePollingSocket.disconnect();

      gsLog.debug(`In SchedulePollingServices: received message from polling service (${SERVER_DISCONNECTED}): SERVER is DISCONNECTED: SchedulePollingSocket = `);
      gsLog.debug(SchedulePollingSocket);

      setTimeout(() => {
        connectToPolling(user);
      }, 30000);
    });
  }

  const initSchedulePolling = () => {
    return (dispatch, getState) => {
      gsLog.debug("In SchedulePollingServices: initSchedulePolling(): entering ...");
      const { user } = getState().loggedInUserServices;

      if (_.isNull(SchedulePollingSocket)) {
        connectToPolling(user);
      }

      dispatch({
        type: UPDATE_SCHEDULE_POLLING,
        payload: {
          noop: true
        }
      });
    }
  }

  return {
    initSchedulePolling,
    startPolling
  }
}

export const SchedulePollingServices = () => {
  if (SchedulePollingServicesInstance) {
    return SchedulePollingServicesInstance;
  } else {
    SchedulePollingServicesInstance = SchedulePollingServicesConstruction();
    return SchedulePollingServicesInstance;
  }
}

