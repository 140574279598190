import { HEALTH_API } from '../shared/app.constants.all';
import { default as gsLog } from './loggingService';
import axios from 'axios';

class VitalsHealthCycleApi {
  constructor() {
    this.gsLog = gsLog;
    this.HEALTH_API = HEALTH_API;
  }

  getDischarge(projectUUID, startTime, endTime, clientId = "ui", size = 31) {
    let httpRequest = axios({
      url: `${this.HEALTH_API.BATTERYCYCLE}/${projectUUID}/historicalData`,
      method: "GET",
      params: {
        size: size,
        startDate: startTime,
        endDate: endTime
      }
    });

    return httpRequest;
  }

  getLatestDischarge(projectUUID, size = 31) {
    let httpRequest = axios({
      url: `${this.HEALTH_API.BATTERYCYCLE}/${projectUUID}/latest`,
      method: "GET",
      params: {
        size: size
      }
    });

    return httpRequest;
  }

  getItc(projectUUID, startTime, endTime, size = 31) {
    let httpRequest = axios({
      url: `${this.HEALTH_API.ITC}/${projectUUID}/historicalData`,
      method: "GET",
      params: {
        startDate: startTime,
        endDate: endTime,
        order: 'asc',
        size: size,
        sortBy: 'timestamp'
      }
    });

    return httpRequest;
  }

  getLatestItc(projectUUID) {
    let httpRequest = axios({
      url: `${this.HEALTH_API.ITC}/${projectUUID}/latest`,
      method: "GET"
    });

    return httpRequest;
  }

}

export default VitalsHealthCycleApi;