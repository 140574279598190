import LoggingService from './loggingService';

class LocalStorageServiceFactory {
    constructor() {
        LoggingService.debug("LocalStorageServiceFactory constructor:");
    }
   
    getVal(key) {
        LoggingService.debug("LocalStorageServiceFactory getVal:");
        LoggingService.debug(key);
        LoggingService.debug("LocalStorageServiceFactory getVal: 222");
        LoggingService.debug(localStorage.getItem(key));
        return localStorage.getItem(key);
    }

    setVal(key, val) {
        LoggingService.debug("LocalStorageServiceFactory setVal:");
        LoggingService.debug("LocalStorageServiceFactory setVal key:");
        LoggingService.debug(key);
        LoggingService.debug("LocalStorageServiceFactory setVal val:");
        LoggingService.debug(val);
        return localStorage.setItem(key, val);
    }

    keys() {
        LoggingService.debug("LocalStorageServiceFactory keys:");
        LoggingService.debug(Object.keys(localStorage));
        return Object.keys(localStorage);
    }

    removeAll() {
        LoggingService.debug("LocalStorageServiceFactory removeKey:");
        return localStorage.clear();
    }

    removeKey(key) {
        LoggingService.debug("LocalStorageServiceFactory removeKey:");
        return localStorage.removeItem(key);
    }
}

const localStorageService = new LocalStorageServiceFactory();

export default localStorageService;
