export const TIMEZONE_SERVICES = "TIMEZONE_SERVICES";

export const TimezoneServices = (gsLog) => {

  const updateTimezones = (timezones) => {
    return {
      type: TIMEZONE_SERVICES,
      payload: {
        timezones
      }
    }
  };

  const initTimezone = (usOnly = true) => {
    const timezones = [
      {
        displayName: "US/Hawaii",
        zone: "US/Hawaii",
        name: "Pacific/Honolulu"
      },
      {
        displayName: "US/Pacific",
        zone: "US/Pacific",
        name: "America/Los_Angeles"
      },
      {
        displayName: "US/Mountain",
        zone: "US/Mountain",
        name: "America/Denver"
      },
      {
        displayName: "US/Central",
        zone: "US/Central",
        name: "America/Chicago"
      },
      {
        displayName: "US/Eastern",
        zone: "US/Eastern",
        name: "America/New_York"
      },
      {
        displayName: "UTC",
        zone: "UTC",
        name: ""
      }
    ]

    return {
      type: TIMEZONE_SERVICES,
      payload: {
        timezones
      }
    };
  };

  return {
    initTimezone,
    updateTimezones
  };
};

export default TimezoneServices;

