import _ from 'lodash';
import moment from 'moment';
export const UPDATE_PMT_WIZARD_GENERATORS = "UPDATE_PMT_WIZARD_GENERATORS";
export const SOLAR_MODE_NO = 1;
export const SOLAR_MODE_EXISTING = 2;
export const SOLAR_MODE_NEW = 3;

export const PmtWizardGeneratorServices = (store, rx, gsLog, patApi, pmtWizardServices, SELECT_ONE_ITEM_TARIFF) => {

  const SELECT_ONE_ITEM_GENERATOR_TYPE = {
    generatorTypeId: null,
    generatorType: "-- Select One --"
  };

  const SELECT_ONE_ITEM_GENERATOR = {
    generatorUUID: null,
    generatorName: "-- Select One --"
  };

  const getGenerators = (dispatch, getState) => {

    const { steps } = getState().pmtWizardServices;
    const customerStep = steps.find((item) => item.name === "customer"),
      servicesStep = steps.find((item) => item.name === "services");
    const { createCustomerResults } = getState().pmtWizardCustomerServices;

    const { customerUUID } = customerStep.metadata.createMode ? createCustomerResults : customerStep.formData.existingCustomer;
    const { serviceUUID, utilityId } = servicesStep.formData.existingService;
    gsLog.debug(`In PmtWizardGeneratorServices: getGenerators(): call get generators API with customer ID (${customerUUID}), service ID (${serviceUUID}), utility ID (${utilityId})`);

    const observables = [
      patApi.getGeneratorsByCustomerServiceUUID(customerUUID, serviceUUID).catch((errors) => {
        gsLog.error("In PmtWizardGeneratorServices: getGenerators(): error from get generators API = ");
        gsLog.debug(errors);
        return rx.Observable.just({ apiError: true });
      }),
      patApi.getGeneratorTypes().catch((errors) => {
        gsLog.error("In PmtWizardGeneratorServices: getGenerators(): error from get generator types API = ");
        gsLog.debug(errors);
        return rx.Observable.just({ apiError: true });
      }),
      patApi.getTariffListByUtilIdUsingObservable(utilityId).catch((errors) => {
        gsLog.error("In PmtWizardGeneratorServices: getGenerators(): error from get list of tariffs by utility ID API = ");
        gsLog.debug(errors);
        return rx.Observable.just({ apiError: true });
      }),
    ];

    rx.Observable
      .forkJoin(observables)
      .subscribe(
        ([generatorResults, generatorTypesResults, tariffResults]) => {
          if (generatorResults.apiError) {
            dispatch({
              type: UPDATE_PMT_WIZARD_GENERATORS,
              payload: {
                generatorTypes: [SELECT_ONE_ITEM_GENERATOR_TYPE],
                generators: [SELECT_ONE_ITEM_GENERATOR],
                tariffs: [SELECT_ONE_ITEM_TARIFF],
                excludeSolarDemandSavings: true
              }
            });
          } else {
            gsLog.debug("In PmtWizardGeneratorServices: getGenerators(): response from get generators API = ");
            gsLog.debug(generatorResults);
            gsLog.debug("In PmtWizardGeneratorServices: getGenerators(): response from get generator types API = ");
            gsLog.debug(generatorTypesResults);
            gsLog.debug("In PmtWizardGeneratorServices: getGenerators(): response from get tariffs API = ");
            gsLog.debug(tariffResults);
            const sortedGenerators = generatorResults.data.content.sort((a, b) => a.generatorName > b.generatorName ? 1 : -1);

            let { selectedStep } = getState().pmtWizardServices;
            if (!selectedStep.metadata.createMode &&
              !_.isUndefined(selectedStep.formData.existingGenerator) &&
              !_.isNull(selectedStep.formData.existingGenerator) &&
              !_.isNull(selectedStep.formData.existingGenerator.generatorUUID)
            ) {

              selectedStep.formData.existingGenerator = generatorResults.data.content.find((item) => item.generatorUUID === selectedStep.formData.existingGenerator.generatorUUID);

              if (!_.isUndefined(selectedStep.formData.existingGenerator)) {
                selectedStep.formData.existingGenerator.includeNgomEstimateFlag = false;
                selectedStep.formData.existingGenerator.includeTelemetryEstimateFlag = false;
                const { annualDegradation, annualIncentive, annualIncentiveEscalation, capex, epc, escalation, incentiveDuration, inverterReplacementCost, inverterReplacementYear, opex, upfrontIncentive } = selectedStep.formData.existingGenerator;
                selectedStep.formData.existingGenerator.solarAnnualDegradation = annualDegradation;
                selectedStep.formData.existingGenerator.solarAnnualIncentive = annualIncentive;
                selectedStep.formData.existingGenerator.solarAnnualIncentiveEscalation = annualIncentiveEscalation;
                selectedStep.formData.existingGenerator.solarCapex = capex;
                selectedStep.formData.existingGenerator.solarEpc = epc;
                selectedStep.formData.existingGenerator.solarIncentiveDuration = incentiveDuration;
                selectedStep.formData.existingGenerator.solarInverterReplacementCost = inverterReplacementCost;
                selectedStep.formData.existingGenerator.solarInverterReplacementYear = inverterReplacementYear;
                selectedStep.formData.existingGenerator.solarOpex = opex;
                selectedStep.formData.existingGenerator.solarOpexEscalation = escalation;
                selectedStep.formData.existingGenerator.solarUpfrontIncentive = upfrontIncentive;
              }
            }
            gsLog.debug("In PmtWizardGeneratorServices: getGenerators(): selected step = ");
            gsLog.debug(selectedStep);

            let solarTariffs = [];
            if (tariffResults.data) {
              solarTariffs = [SELECT_ONE_ITEM_TARIFF, ...tariffResults.data];
            }

            if (_.isUndefined(selectedStep.formData.generator) || _.isEmpty(selectedStep.formData.generator)) {
              const { tariffId } = servicesStep.formData.existingService;
              gsLog.debug(`In PmtWizardGeneratorServices: getGenerators(): NO results - tariff ID (${tariffId})`);
              selectedStep.formData.generator = {
                excludeSolarDemandSavings: true,
                includeNgomEstimateFlag: false,
                includeTelemetryEstimateFlag: false,
                selectedGeneratorType: SELECT_ONE_ITEM_GENERATOR_TYPE,
                solarAnnualDegradation: 0.5,
                solarAnnualIncentive: 0,
                solarAnnualIncentiveEscalation: 0,
                solarCapex: 1500,
                solarEpc: 150,
                solarIncentiveDuration: 0,
                solarInverterReplacementCost: 0,
                solarInverterReplacementYear: 0,
                solarOpex: 10,
                solarOpexEscalation: 2,
                solarUpfrontIncentive: 0

              };
              selectedStep.formData.generator.solarTariff = solarTariffs.find((item) => item.tariffId === tariffId);
              if (_.isUndefined(selectedStep.formData.generator.solarTariff) && solarTariffs.length > 0) {
                selectedStep.formData.generator.solarTariff = solarTariffs[0];
              }
            }

            if (selectedStep.formData.generator.solarTariff) {
              const { tariffId } = selectedStep.formData.generator.solarTariff;
              gsLog.debug(`In PmtWizardGeneratorServices: getGenerators(): SETUP solar tariff -- tariff ID (${tariffId})`);
              selectedStep.formData.generator.solarTariff = solarTariffs.find((item) => item.tariffId === tariffId);
              if (_.isUndefined(selectedStep.formData.generator.solarTariff) && solarTariffs.length > 0) {
                selectedStep.formData.generator.solarTariff = solarTariffs[0];
              }
            }

            dispatch({
              type: UPDATE_PMT_WIZARD_GENERATORS,
              payload: {
                generatorTypes: [SELECT_ONE_ITEM_GENERATOR_TYPE, ...generatorTypesResults.data.content],
                generators: [SELECT_ONE_ITEM_GENERATOR, ...sortedGenerators],
                excludeSolarDemandSavings: true,
                solarTariffs
              }
            });
          }
        },
        (errors) => {
          gsLog.error("In PmtWizardGeneratorServices: getGenerators(): error in processing = ");
          gsLog.debug(errors);
          dispatch({
            type: UPDATE_PMT_WIZARD_GENERATORS,
            payload: {
              generatorTypes: [SELECT_ONE_ITEM_GENERATOR_TYPE],
              generators: [SELECT_ONE_ITEM_GENERATOR],
              excludeSolarDemandSavings: true
            }
          });
        },
        () => {
          gsLog.debug("In PmtWizardGeneratorServices: getGenerators(): DONE with processing");
        }
      )
  };

  const initGeneratorForm = () => {
    let { selectedStep } = store.getState().pmtWizardServices;

    gsLog.debug("in PmtWizardGeneratorServices: initGeneratorForm(): selected step = ");
    gsLog.debug(selectedStep);

    if (_.isUndefined(selectedStep.formData.generator)) {
      gsLog.debug("in PmtWizardGeneratorServices: initGeneratorForm(): initialize form data to blank ...");
      selectedStep.formData.generator = {};
    }

    if (!selectedStep.metadata.createMode) {
      if (_.isUndefined(selectedStep.formData.existingGenerator) || _.isNull(selectedStep.formData.existingGenerator)) {
        selectedStep.formData.existingGenerator = SELECT_ONE_ITEM_GENERATOR;
      }

      return (dispatch, getState) => {
        getGenerators(dispatch, getState);
      };
    }
  };

  const createGenerator = () => {

    return (dispatch, getState) => {

      const {
        excludeSolarDemandSavings,
        generatorMeterNumber,
        includeNgomEstimateFlag,
        includeTelemetryEstimateFlag,
        inverterACCapacity,
        inverterDCCapacity,
        installDate,
        isOnline,
        manufacturer,
        solarAnnualDegradation,
        solarAnnualIncentive,
        solarAnnualIncentiveEscalation,
        solarCapex,
        solarEpc,
        solarInverterReplacementCost,
        solarInverterReplacementYear,
        solarOpex,
        solarOpexEscalation,
        solarTariff,
        solarUpfrontIncentive,
        solarIncentiveDuration
      } = getState().pmtWizardServices.selectedStep.formData.generator;
      const generatorParams = {
        annualDegradation: !_.isNull(solarAnnualDegradation) && !_.isUndefined(solarAnnualDegradation) ? solarAnnualDegradation / 100 : null,
        annualIncentive: solarAnnualIncentive,
        annualIncentiveEscalation: !_.isNull(solarAnnualIncentiveEscalation) && !_.isUndefined(solarAnnualIncentiveEscalation) ? solarAnnualIncentiveEscalation / 100 : null,
        capex: solarCapex,
        epc: solarEpc,
        escalation: !_.isNull(solarOpexEscalation) && !_.isUndefined(solarOpexEscalation) ? solarOpexEscalation / 100 : null,
        excludeSolarDemandSavings,
        generatorName: `${manufacturer}-${inverterACCapacity}`,
        generatorType: "Solar",
        generatorMeterNumber: _.isUndefined(generatorMeterNumber) ? null : generatorMeterNumber,
        incentiveDuration: solarIncentiveDuration,
        includeNgomEstimateFlag,
        includeTelemetryEstimateFlag,
        installDate: _.isUndefined(installDate) ? null : moment(installDate).format('YYYY-MM-DDTHH:mm:ss Z'),
        inverterACCapacity: _.isUndefined(inverterACCapacity) ? null : inverterACCapacity,
        inverterDCCapacity: _.isUndefined(inverterDCCapacity) ? null : inverterDCCapacity,
        inverterReplacementCost: solarInverterReplacementCost,
        inverterReplacementYear: solarInverterReplacementYear,
        manufacturer,
        opex: solarOpex,
        solarTariffId: solarTariff ? solarTariff.tariffId : null,
        upfrontIncentive: solarUpfrontIncentive
      };
      gsLog.debug("In PmtWizardGeneratorServices: createGenerator(): generator params = ");
      gsLog.debug(generatorParams);

      let { steps } = getState().pmtWizardServices,
        generatorsStep = steps.find((item) => item.name === "generators");
      return patApi.createGenerator(generatorParams)
        .catch((errors) => {
          gsLog.error(`In PmtWizardServiceServices: createGenerator(): error in creating service API = `);
          gsLog.debug(errors);

          dispatch({
            type: UPDATE_PMT_WIZARD_GENERATORS,
            payload: {
              createGeneratorResults: null
            }
          });
        })
        .then((results) => {
          gsLog.debug("in PmtWizardGeneratorServices: createGenerator(): response from create generator API = ");
          gsLog.debug(results);

          generatorsStep.metadata.solarMode = SOLAR_MODE_EXISTING;
          generatorsStep.formData.existingGenerator = Object.assign({}, results.data, { solarTariff, excludeSolarDemandSavings });

          const { steps } = getState().pmtWizardServices;
          const servicesStep = steps.find((item) => item.name === "services");
          const { serviceUUID } = servicesStep.formData.existingService;

          dispatch(pmtWizardServices.updateSelectedStep(generatorsStep));

          return patApi.createGeneratorServiceLink(results.data.generatorUUID, _.isUndefined(isOnline) ? false : isOnline, serviceUUID);
        })
        .catch((errors) => {
          gsLog.error(`In PmtWizardServiceServices: createGenerator(): error in creating generator service link API = `);
          gsLog.debug(errors);

          dispatch({
            type: UPDATE_PMT_WIZARD_GENERATORS,
            payload: {
              createGeneratorResults: null
            }
          });
        })
        .then((results) => {
          gsLog.debug("in PmtWizardGeneratorServices: createGenerator(): response from create generator service link API = ");
          gsLog.debug(results);

          dispatch({
            type: UPDATE_PMT_WIZARD_GENERATORS,
            payload: {
              createGeneratorResults: generatorsStep.formData.existingGenerator
            }
          });

          return generatorsStep.formData.existingGenerator;
        });
    }
  };

  return {
    createGenerator,
    getGenerators,
    initGeneratorForm
  }
}

PmtWizardGeneratorServices.$inject = [
  "$ngRedux",
  "rx",
  "gs.services.gsLogService",
  "patApi",
  "pmtWizardServices",
  "SELECT_ONE_ITEM_TARIFF"
];
