export const UPDATE_VPP_SELECTOR_SIDE_BAR = "UPDATE_VPP_SELECTOR_SIDE_BAR";

export const VppSelectorSidebarServicesActions = (gsLog) => {

  const updateVppSelectorSidebar = (panelName) => {
    return (dispatch, getState) => {
      const { vppSelectorSidebarServices } = getState();
      let { vppSelector, vppSelectorRevenue, vppSelectorConfig, vppSelectorProject } = vppSelectorSidebarServices;

      switch (panelName) {
        case "vpp-selector":
          vppSelector = !vppSelector;
          break;
        case "vpp-selector-revenue":
          vppSelectorRevenue = !vppSelectorRevenue;
          break;
        case "vpp-selector-config":
          vppSelectorConfig = !vppSelectorConfig;
          break;
        case "vpp-selector-project":
          vppSelectorProject = !vppSelectorProject;
          break;
        default:
          vppSelector = true;
          break;
      }

      return dispatch({
        type: UPDATE_VPP_SELECTOR_SIDE_BAR,
        payload: {
          vppSelector,
          vppSelectorRevenue,
          vppSelectorConfig,
          vppSelectorProject
        }
      });
    }
  };

  return {
    updateVppSelectorSidebar
  };
};

export default VppSelectorSidebarServicesActions;