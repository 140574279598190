import _ from 'underscore';
// import LoggingService from '../service/loggingService';
import { default as gsLog } from '../service/loggingService';

export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const CURRENT_VIEW = "CURRENT_VIEW";
export const SELECTED_ROUTE = "SELECTED_ROUTE";
let STORAGE_PERMISSION = JSON.parse(localStorage.getItem("gsAppLocal.allPermission"));

export const LoggedInUserServices = () => {

  const DEFAULT_TENANT_ID = 1;

  const routeMapping = {
    "dashboard": "app.vpp-overview",
    "savings": "app.vpp-overview.savings",
    "vppMonitor": "app.vitals",
    "projectMonitor": "app.vitals.project",
    "newprojectMonitor": "app.vitals.newproject",
    "schedule": "app.vitals.schedule",
    "frequency": "app.vitals.freq",
    "health": "app.vitals.health",
    "itc": "app.vitals.itc",
    "monthlysavings": "app.vitals.monthlysavings",
    "alerts": "app.vitals.alerts",
    "flexForecast": "app.vitals.flexforecast",
    "forecast": "app.vitals.forecast",
    "performance": "app.vitals.performance",
    "details": "app.vitals.details",
    "billing": "app.vpp-billing",
    "optimizer": "app.analysis.optimizer",
    "financial": "app.analysis.financial",
    "manager": "app.analysis.manager",
    "support": "app.support",
    "diagnosticsPanel": "app.IAP",
    "tariffManagement": "app.tariffs",
    "userSim": "app.impersonate"
  };
  let p = {};
  // const INTERNAL_USER_ORG_ID = 4,
  //   DERMS_USER_ORG_ID = 52,
  //   ADMIN_USER = -1;

  const checkProfile = (user) => {
      gsLog.debug("In LoggedInUserServices: checkProfile(): ");
    p = {
      // Overview
      canViewDashboardTab: false, //user.usrRoles.some((item) => item.roleId === 90),
      canViewSavingsTab: false, //user.usrRoles.some((item) => item.roleId === 90),
      // Operations
      canViewVppMonitorTab: user.usrRoles.some((item) => item.roleId === 40 || item.roleId === 10 || item.roleId === 90),
      canViewProjectMonitorTab: false, //user.usrRoles.some((item) => item.roleId === 10),
      canViewNewProjectMonitorTab: false,//user.usrRoles.some((item) => item.roleId === 10),
      canViewFrequencyTab: false, //user.usrRoles.some((item) => item.roleId === 90),
      canViewScheduleTab: user.usrRoles.some((item) => item.roleId === 40),
      canViewDetailsTab: false, // user.usrRoles.some((item) => item.roleId === 90),
      canViewHealthTab: user.usrRoles.some((item) => item.roleId === 40 || item.roleId === 90),
      canViewItcTab:  user.usrRoles.some((item) => item.roleId === 40 || item.roleId === 90),
      canViewAlertsTab: false, //user.usrRoles.some((item) => item.roleId === 90),
      canViewFlexForecastTab: user.usrRoles.some((item) => item.roleId === 42),
      canViewForecastTab: user.usrRoles.some((item) => item.roleId === 43),
      canViewPerformanceTab: user.usrRoles.some((item) => item.roleId === 43),
      canViewMonthlySavingsTab: user.usrRoles.some((item) => item.roleId === 20),
      // Billing
      canViewBillingTab: user.usrRoles.some((item) => item.roleId === 20),
      canViewCashBill: user.usrRoles.some((item) => item.roleId === 912),
      // Analysis
      canViewOptimizerTab: user.usrRoles.some((item) => item.roleId === 30),
      canViewManagerTab: user.usrRoles.some((item) => item.roleId === 32),
      // Support
      canViewSupportTab: user.usrRoles.some((item) => item.roleId === 30 || item.roleId === 40 || item.roleId === 10 || item.roleId === 20 || item.roleId === 90),
      canDeleteMeter: user.usrRoles.some((item) => item.roleId === 33),
      canViewFinancialModelFormFields: user.usrRoles.some((item) => item.roleId === 31),
      canViewAnalysisFeatures: user.usrRoles.some((item) => item.roleId === 33),
      canViewCompareTool: user.usrRoles.some((item) => item.roleId === 31),
      canViewByPassLogoutTimer: user.usrRoles.some((item) => item.roleId === 99),
      canViewRedundancyStatus: user.usrRoles.some((item) => item.roleId === 412),

      //IAP
      canViewIapTab: user.usrRoles.some((item) => item.roleId === 92),
      canViewTariffManagement: user.usrRoles.some((item) => item.roleId === 911),
      // Impersonator
      canViewImpersonate: user.usrRoles.some((item) => item.roleId === 80),

      //helios  using role 90 as role to view the new helios tab
      canViewHelioTab: user.usrRoles.some((item) => item.roleId === 90)
    }
    return p;
  }
  const updateProfile = (user) => {
      gsLog.debug("In LoggedInUserServices: updateProfile (): ");
    return (dispatch, getState) => {
      gsLog.debug("In LoggedInUserServices: updateProfile(): user = ");
      gsLog.debug(user);

      p = checkProfile(user);

      if (!_.isNull(STORAGE_PERMISSION)) {
        p = STORAGE_PERMISSION;
      }

      p.canViewOverviewTab = false;
      p.canViewOperationsTab = p.canViewVppMonitorTab || p.canViewScheduleTab || p.canViewHealthTab || p.canViewItcTab || p.canViewMonthlySavingsTab;
      p.canViewAnalysisTab = p.canViewOptimizerTab || p.canViewManagerTab;
      dispatch(setDefaultView(p));

      dispatch({
        type: LOGGED_IN_USER,
        payload: {
          user,
          permissions: p,
          defaultTenantId: DEFAULT_TENANT_ID
        }
      });
    };
  };

  // TODO
  const setDefaultView = (permissions) => {
      gsLog.debug("In LoggedInUserServices: setDefaultView (): ");

    const {
      canViewOverviewTab, canViewDashboardTab, canViewSavingsTab,
      canViewOperationsTab, canViewVppMonitorTab, canViewProjectMonitorTab, canViewNewProjectMonitorTab, canViewFrequencyTab, canViewScheduleTab, canViewDetailsTab, canViewHealthTab,
      canViewItcTab, canViewAlertsTab, canViewFlexForecastTab, canViewForecastTab, canViewPerformanceTab,
      canViewBillingTab,
      canViewAnalysisTab, canViewSupportTab,
      canViewIapTab, canViewTariffManagement, canViewImpersonate
    } = permissions;

    let route = null;
    if (canViewOverviewTab) {
      if (canViewDashboardTab) {
        route = routeMapping["dashboard"];
      } else if (canViewSavingsTab) {
        route = routeMapping["savings"];
      }
    } else if (canViewOperationsTab) {
      if (canViewVppMonitorTab) {
        route = routeMapping["vppMonitor"];
      } else if (canViewScheduleTab) {
        route = routeMapping["schedule"];
      }  else if (canViewHealthTab) {
        route = routeMapping["health"];
      } else if (canViewItcTab) {
        route = routeMapping["itc"];
      } else if (canViewFlexForecastTab) {
        route = routeMapping["flexForecast"];
      } else if (canViewForecastTab) {
        route = routeMapping["forecast"];
      } else if (canViewPerformanceTab) {
        route = routeMapping["performance"];
      }
    } else if (canViewBillingTab) {
      route = routeMapping["billing"];
    } else if (canViewAnalysisTab) {
      route = routeMapping["financial"];
    } else if (canViewSupportTab) {
      route = routeMapping["support"];
    } else if (canViewIapTab) {
      route = routeMapping["diagnosticsPanel"];
    } else if (canViewTariffManagement) {
      route = routeMapping["tariffManagement"];
    } else if (canViewImpersonate) {
      route = routeMapping["userSim"];
    } else {
      // can't happen
      gsLog.error("In LoggedInUserServices: setDefaultView(): user doesn't have any access to any tabs !!!");
    }

    gsLog.debug(`In LoggedInUserServices: setDefaultView(): route is (${route})`);

    return {
      type: CURRENT_VIEW,
      payload: {
        initialRoute: route
      }
    }
  };

  const getSelectedRoute = (tabName) => {
      gsLog.debug("In LoggedInUserServices: getSelectedRoute (): ");
    return {
      type: SELECTED_ROUTE,
      payload: {
        selectedRoute: routeMapping["frequency"]
      }
    };
  };

  const setCurrentRoute = (route, url) => {
      gsLog.debug("In LoggedInUserServices: setCurrentRoute(): ");
    return {
      type: CURRENT_VIEW,
      payload: {
        selectedRoute: route,
        url
      }
    };
  };

  return {
    getSelectedRoute,
    setCurrentRoute,
    updateProfile,
    setDefaultView,
    checkProfile
  }
}

// LoggedInUserServices.$inject = [
//   "gs.services.gsLogService",
//   "authorizationService"
// ];

export default LoggedInUserServices;
