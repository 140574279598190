import { PORTFOLIO_ANALYSIS_SELECTOR_TOGGLE_BAR } from '../actions/portfolioAnalysisSelectorSidebar';

const INITIAL_STATE = {
  portfolio: {
    isVisible: false,
    isDisabled: true
  },
  customer: {
    isVisible: true,
    isDisabled: false
  },
  project: {
    isVisible: true,
    isDisabled: false
  },
  service: {
    isVisible: true,
    isDisabled: false
  },
  proposal: {
    isVisible: true,
    isDisabled: false
  },
};

export default function toggleBar(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case PORTFOLIO_ANALYSIS_SELECTOR_TOGGLE_BAR:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
