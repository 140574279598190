import { VPP_LIST, VPP_SEARCH, VPP_SET_SELECTED_VPP, VPP_SET_SELECTED_PROJECT } from '../actions/vppSelectorServices';

const INITIAL_STATE = {

};

export default function vppSelectorServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case VPP_LIST:
      return Object.assign({}, state, payload, { doRefresh: true });
    case VPP_SET_SELECTED_VPP:
      return Object.assign({}, state, payload, { doRefresh: false, doSearch: false });
      case VPP_SET_SELECTED_PROJECT:
        return Object.assign({}, state, payload, { doRefresh: false, doSearch: false });  
    case VPP_SEARCH:
      return Object.assign({}, state, payload, { doSearch: true });
    default:
      return Object.assign({}, state, { doRefresh: false, doSearch: false });
  }
}
