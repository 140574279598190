import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToasterService {
    info(msg, title) {
        toast.info(msg);
    }

    error(msg, title) {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    success(msg, title) {
        toast.success(msg);
    }

    warning(msg, title) {
        toast.warn(msg);
    }

    remove() {
        toast.dismiss();
    }
}

export default new ToasterService();