
import { NON_EXPORT_MODES, FREIGHT_COST_FEATURE_GROUP_ID } from './pmtWizardStorageServices';
import { SOLAR_MODE_EXISTING } from './pmtWizardGeneratorServices';
import Rx from "rx";

export const UPDATE_PMT_WIZARD_TOP_NAV = "UPDATE_PMT_WIZARD_TOP_NAV";
export const UPDATE_PMT_WIZARD_SERVICES = "UPDATE_PMT_WIZARD_SERVICES";
export const PMT_WIZARD_FAILED_VALIDATION = "PMT_WIZARD_FAILED_VALIDATION";
export const RESET_WIZARD = "RESET_WIZARD";

export const PmtWizardServices = (store, gsAnalysisService, patApi, gsLog) => {
  let rx = Rx;

  const failedValidation = (isValid) => {
    return {
      type: PMT_WIZARD_FAILED_VALIDATION,
      payload: {
        failedValidation: isValid
      }
    }
  };

  const previousStep = () => {
    let { currentId, isFirst, nextId, steps } = store.getState().pmtWizardServices;

    if (!isFirst) {
      currentId = currentId - 1;
      const selectedStep = steps.filter((item) => item.id === currentId);
      return {
        type: UPDATE_PMT_WIZARD_TOP_NAV,
        payload: {
          currentId,
          isFirst: currentId === 1,
          isLast: currentId === steps.length,
          nextId: nextId - 1,
          selectedStep: selectedStep && selectedStep.length > 0 ? selectedStep[0] : null
        }
      }
    } else {
      return {
        type: UPDATE_PMT_WIZARD_TOP_NAV,
        payload: {
          currentId,
          nextId,
          isLast: currentId === steps.length,
        }
      };
    }
  };

  const skipPrevStep = () => {
    const { currentId, steps } = store.getState().pmtWizardServices;
    const prevPrevId = currentId - 2;
    let prevPrevStep = steps.filter((item) => item.id === prevPrevId);

    return {
      type: UPDATE_PMT_WIZARD_TOP_NAV,
      payload: {
        currentId: prevPrevId,
        nextId: prevPrevId + 1,
        isFirst: prevPrevId === 1,
        isLast: (prevPrevId === steps.length),
        selectedStep: prevPrevStep && prevPrevStep.length > 0 ? prevPrevStep[0] : null
      }
    };
  };

  const skipNextStep = () => {
    const { nextId, steps } = store.getState().pmtWizardServices;
    let nextSelectedStep = steps.filter((item) => item.id === (nextId + 1));
    if (nextSelectedStep && nextSelectedStep.length > 0) {
      nextSelectedStep[0].metadata.visited = true;
    }

    return {
      type: UPDATE_PMT_WIZARD_TOP_NAV,
      payload: {
        currentId: nextId + 1,
        nextId: (nextId + 1) === steps.length ? null : nextId + 2,
        isLast: (nextId + 1) === steps.length,
        selectedStep: nextSelectedStep && nextSelectedStep.length > 0 ? nextSelectedStep[0] : null
      }
    };
  };

  const nextStep = () => {
    let { currentId, isLast, nextId, steps } = store.getState().pmtWizardServices;
    let nextSelectedStep = steps.filter((item) => item.id === nextId);
    if (nextSelectedStep && nextSelectedStep.length > 0) {
      nextSelectedStep[0].metadata.visited = true;
    }

    if (!isLast) {
      return {
        type: UPDATE_PMT_WIZARD_TOP_NAV,
        payload: {
          currentId: nextId,
          isFirst: nextId === 1,
          isLast: nextId === steps.length,
          nextId: nextId === steps.length ? null : nextId + 1,
          selectedStep: nextSelectedStep && nextSelectedStep.length > 0 ? nextSelectedStep[0] : null
        }
      }
    } else {
      return {
        type: UPDATE_PMT_WIZARD_TOP_NAV,
        payload: {
          currentId,
          nextId,
          isLast: nextId === steps.length
        }
      };
    }
  };

  const resetNextSteps = () => {
    let { selectedStep, steps } = store.getState().pmtWizardServices;

    steps = steps.map((item, idx) => {
      if (item.id > selectedStep.id) {
        item.reset();
      }
      return item;
    });

    return {
      type: RESET_WIZARD,
      payload: {
        currentId: selectedStep.id,
        failedValidation: false,
        isFirst: selectedStep.id === 1,
        isLast: false,
        nextId: selectedStep.id + 1,
        selectedStep,
        steps
      }
    };
  };

  const resetAll = () => {
    gsLog.debug("In PmtWizardServices: resetAll(): ...");
    let { steps } = store.getState().pmtWizardServices;
    steps = steps.map((item, idx) => {
      item.reset();
      return item;
    });

    const selectedStep = steps.find((item) => item.id === 1);
    return ({
      type: RESET_WIZARD,
      payload: {
        currentId: 1,
        failedValidation: false,
        isFirst: true,
        isLast: false,
        nextId: 2,
        selectedStep,
        steps
      }
    });
  };

  const setNoGenerator = () => {
    gsLog.debug("In PmtWizardServices: setNoGenerator(): ...");

    return {
      excludeSolarDemandSavings: true,
      includeNgomEstimateFlag: false,
      includeTelemetryEstimateFlag: false,
      selectedGeneratorType: {
        generatorTypeId: null,
        generatorType: "-- Select One --"
      },
      solarTariff: {}
    };
  };

  const loadCloneGenerator = async (generatorStep, selectedProject, selectedService, selectedProposal) => {
    gsLog.debug("In PmtWizardServices: loadCloneGenerator(): ...");
    gsLog.debug(generatorStep);
    let solarTariff;

    if (generatorStep && generatorStep.id && generatorStep.id === 4) {
      try {
        if (selectedProject && selectedProject.solar && selectedProposal && selectedProposal && selectedProposal.itcRatio > 0) {
          const generatorResults = await patApi.getGeneratorsByProjectUUID(selectedProject.projectUUID);
          if (generatorResults && generatorResults.data && generatorResults.data.content && generatorResults.data.content.length > 0) {
            if (generatorResults && generatorResults.data && generatorResults.data.content.length > 0) {
              if (selectedProject.netSolarTariffId && selectedService.utilityId) {
                const [tariffResults] = await Promise.all(
                  [
                    patApi.getTariffListByUtilIdUsingObservable(selectedService.utilityId).catch((errors) => {
                      gsLog.error("In PmtWizardServices: loadCloneGenerator(): error from get list of tariffs by utility ID API = ");
                      gsLog.debug(errors);
                      return rx.Observable.just({ apiError: true });
                    })
                  ]
                );

                if (tariffResults && tariffResults.data) {
                  solarTariff = tariffResults.data.find((item) => item.tariffId === selectedProject.netSolarTariffId);
                }
              }

              const generatorAttr = generatorResults.data.content[0];
              generatorStep.formData.generator = {
                excludeSolarDemandSavings: selectedProject.excludeSolarDemandSavings,
                includeNgomEstimateFlag: selectedProject.includeNgomEstimateFlag,
                includeTelemetryEstimateFlag: selectedProject.includeTelemetryEstimateFlag,
                selectedGeneratorType: {
                  generatorTypeId: null,
                  generatorType: "-- Select One --"
                },
                solarAnnualDegradation: generatorAttr.annualDegradation * 100,
                solarAnnualIncentive: generatorAttr.annualIncentive,
                solarAnnualIncentiveEscalation: generatorAttr.annualIncentiveEscalation,
                solarCapex: generatorAttr.capex,
                solarEpc: generatorAttr.epc,
                solarIncentiveDuration: generatorAttr.incentiveDuration,
                solarInverterReplacementCost: generatorAttr.inverterReplacementCost,
                solarInverterReplacementYear: generatorAttr.inverterReplacementYear,
                solarOpex: generatorAttr.opex,
                solarOpexEscalation: generatorAttr.escalation * 100,
                solarUpfrontIncentive: generatorAttr.upfrontIncentive,
                solarTariff: solarTariff
              };

              generatorStep.formData.existingGenerator = {
                generatorUUID: generatorAttr.generatorUUID,
                manufacturer: generatorAttr.manufacturer,
                inverterACCapacity: generatorAttr.inverterACCapacity,
                inverterDCCapacity: generatorAttr.inverterDCCapacity
              }

              generatorStep.formData.metadata = {
                attempted: false,
                cloneMode: true,
                createMode: false,
                created: false,
                needToCreate: false,
                optional: false,
                solarMode: 1,
                valid: false,
                validate: true,
                visited: true
              };

              generatorStep.metadata.solarMode = SOLAR_MODE_EXISTING;
            }

          }
          else {
            gsLog.error("In PmtWizardServices: loadCloneGenerator(): error in getGeneratorsByProjectUUID");
            generatorStep.formData.generator = setNoGenerator();
          }
        }
        else {
          generatorStep.formData.generator = setNoGenerator();
        }
      } catch (errors) {
        gsLog.error("In PmtWizardServices: loadCloneGenerator(): error in processing = ");
        gsLog.debug(errors);
      }
    }
    else {
      gsLog.error("In PmtWizardServices: loadCloneGenerator(): generatorStep is undefined");
    }
  };

  const loadCloneGeneratorData = async (generatorDataStep, selectedProject) => {
    if (generatorDataStep && generatorDataStep.id && generatorDataStep.id === 5) {
      if (selectedProject && selectedProject.solar) {
        generatorDataStep.metadata.disableTopNav = false;
      }
      else {
        generatorDataStep.metadata.disableTopNav = true;
      }
    }
    else {
      gsLog.warn("In PmtWizardServices: loadCloneGeneratorData(): generatorDataStep : input parameter mismatch");
    }
  }

  const loadCloneService = async (serviceStep, selectedService) => {
    gsLog.debug("In PmtWizardServices: loadCloneService(): ...");
    gsLog.debug(serviceStep);
    if (serviceStep && serviceStep.id && serviceStep.id === 2 && selectedService) {
      try {
        let selectedBillingCalendar = null;
        if (selectedService.calendarId) {
          const calendarResponse = await gsAnalysisService.getAnalysisCalendarInfo(selectedService.utilityId, selectedService.calendarId);
          if (calendarResponse && calendarResponse.length > 0) {
            selectedBillingCalendar = calendarResponse[0].data;
          }
          else {
            gsLog.error("In PmtWizardServices: loadCloneService(): error in getAnalysisCalendarInfo = ");
          }
        }

        let selectedUtility = null;
        if (selectedService.utilityId) {
          const utilityResults = await patApi.getListOfUtilitiesUsingObservable();
          if (utilityResults && utilityResults.data.content.length > 0) {
            selectedUtility = utilityResults.data.content.find((item) => item.utilityId === selectedService.utilityId);
          }
          else {
            gsLog.error("In PmtWizardServices: loadCloneService(): error in getListOfUtilitiesUsingObservable");
          }
        }

        let selectedTariff = null;
        if (selectedService.tariffId) {
          const tariffResults = await gsAnalysisService.getAnalysisTariffInfo(selectedService.utilityId, selectedService.tariffId);
          if (tariffResults && tariffResults.length > 0) {
            selectedTariff = tariffResults[0].data;
          }
          else {
            gsLog.error("In PmtWizardServices: loadCloneService(): error in getAnalysisTariffInfo");
          }
        }

        serviceStep.formData.existingService = {
          address: selectedService.address,
          calendarId: selectedService.calendarId,
          city: selectedService.city,
          commentIds: selectedService.commentIds,
          comments: selectedService.comments,
          conjunctiveMetering: selectedService.conjunctiveMetering,
          contactIds: selectedService.contactIds,
          contacts: selectedService.contacts,
          customerAccountId: selectedService.customerAccountId,
          customerUUID: selectedService.customerUUID,
          isRealLink: selectedService.isRealLink,
          isRealSite: selectedService.isRealSite,
          isServiceBookmarked: selectedService.isServiceBookmarked,
          latitude: selectedService.latitude,
          longitude: selectedService.longitude,
          meterNumber: selectedService.meterNumber,
          meteringMethod: selectedService.meteringMethod,
          meters: selectedService.meters,
          naicsId: selectedService.naicsId,
          numEV: selectedService.numEV,
          oldSiteId: selectedService.oldSiteId,
          salesTaxRate: selectedService.salesTaxRate,
          selectedBillingCalendar: selectedBillingCalendar,
          selectedUtility: selectedUtility,
          selectedTariff: selectedTariff,
          serviceAccountId: selectedService.serviceAccountId,
          serviceId: selectedService.serviceId,
          serviceName: selectedService.serviceName,
          serviceUUID: selectedService.serviceUUID,
          siteName: selectedService.siteName,
          state: selectedService.state,
          tariffId: selectedService.tariffId,
          timeZone: selectedService.timeZone,
          utilityAPIServiceId: selectedService.utilityAPIServiceId,
          utilityId: selectedService.utilityId,
          version: selectedService.version,
          zipcode: selectedService.zipcode
        };
      } catch (errors) {
        gsLog.error("In PmtWizardServices: loadCloneService(): error in processing = ");
        gsLog.debug(errors);
      }
    }
    else {
      gsLog.error("In PmtWizardServices: loadCloneService(): serviceStep is undefined");
    }
  };

  const loadCloneStorage = async (storageStep, selectedProject, selectedService) => {
    gsLog.debug("In PmtWizardServices: loadCloneStorage(): ...");
    gsLog.debug(selectedProject);
    let storageTariff, freightCost;

    if (selectedProject && selectedService) {
      if (selectedProject.netStorageTariffId && selectedService.utilityId) {
        const [tariffResults] = await Promise.all(
          [
            patApi.getTariffListByUtilIdUsingObservable(selectedService.utilityId).catch((errors) => {
              gsLog.error("In PmtWizardServices: loadCloneStorage(): error from get list of tariffs by utility ID API = ");
              gsLog.debug(errors);
              return rx.Observable.just({ apiError: true });
            })
          ]
        );

        if (tariffResults && tariffResults.data) {
          if (selectedProject.netStorageTariffId) {
            storageTariff = tariffResults.data.find((item) => item.tariffId === selectedProject.netStorageTariffId);
          }
        }
        else {
          gsLog.error("In PmtWizardServices: loadCloneStorage(): error in getTariffListByUtilIdUsingObservable = ");
        }
      }

      if (selectedProject.featureIds) {
        let extractedfeatureId = parseInt(selectedProject.featureIds.split(",")[0]);
        const featureResults = await patApi.getFeatureGroups(FREIGHT_COST_FEATURE_GROUP_ID);

        if (featureResults && featureResults.data) {
          freightCost = featureResults.data.find((item) => item.featureId === extractedfeatureId);
        }
        else {
          gsLog.error("In PmtWizardServices: loadCloneStorage(): error in getFeatureGroups = ");
        }
      }

    }

    if (storageStep && storageStep.id && storageStep.id === 6) {
      // we don't really pass selectedNonExportMode to the backend in the previous creation, that is why there is no way to retrieve it
      storageStep.formData = {
        freightCost: freightCost,
        isPeakShaving: selectedProject.peakShavingBinary ? true : false,
        isEnergyArbitrage: selectedProject.arbitrageBinary ? true : false,
        itcThreshold: selectedProject.itcRatio,
        storageOtherAnnualIncentive: selectedProject.storageOtherAnnualIncentive,
        storageOtherIncentiveDuration: selectedProject.storageOtherIncentiveDuration,
        storageOtherIncentiveEscalation: selectedProject.storageOtherIncentiveEscalation,
        storageOtherUpfrontIncentive: selectedProject.storageOtherUpfrontIncentive,
        storageSgipRate: selectedProject.storageSgipRate,
        storageSmartIncentive: selectedProject.storageSmartIncentive,
        selectedNonExportMode: NON_EXPORT_MODES[2],
        storageTariff: storageTariff
      };
    }
    else {
      gsLog.error("In PmtWizardServices: loadCloneStorage(): storageStep is undefined");
    }
  };

  const loadCloneCustomer = (customerStep, selectedCustomer, naicsList) => {
    gsLog.debug("In PmtWizardServices: loadCloneCustomer(): ...");
    gsLog.debug(customerStep);

    if (customerStep && customerStep.id && customerStep.id === 1 && selectedCustomer) {
      const selectedNaics = naicsList.find((item) => item.naicsId === selectedCustomer.naicsId);

      customerStep.formData.existingCustomer = {
        address: selectedCustomer.address,
        city: selectedCustomer.city,
        commentIds: selectedCustomer.commentIds,
        comments: selectedCustomer.comments,
        contactIds: selectedCustomer.contactIds,
        contacts: selectedCustomer.contacts,
        creditRating: selectedCustomer.creditRating,
        customerAccountId: selectedCustomer.customerAccountId,
        customerCode: selectedCustomer.customerCode,
        customerUUID: selectedCustomer.customerUUID,
        customerName: selectedCustomer.customerName,
        description: selectedCustomer.description,
        isCustomerBookmarked: selectedCustomer.isCustomerBookmarked,
        netSuiteCustomerId: null,
        naics: selectedNaics,
        naicsId: selectedCustomer.naicsId,
        state: selectedCustomer.state,
        tenantId: selectedCustomer.tenantId,
        userId: selectedCustomer.userId,
        utilityAPIAccountIds: selectedCustomer.utilityAPIAccountIds,
        version: selectedCustomer.version,
        zipcode: selectedCustomer.zipcode
      };

      customerStep.formData.developerId = selectedCustomer.tenantId;
    }
    else {
      gsLog.error("In PmtWizardServices: loadCloneCustomer(): customerStep is undefined");
    }
  };

  const setCloneAll = () => {
    gsLog.debug("In PmtWizardServices: setCloneAll(): ...");
    let { steps } = store.getState().pmtWizardServices;
    const { selectedCustomer, selectedService, selectedProject, selectedProposal } = store.getState().portfolioSelectorServices;
    const naicsList = store.getState().pmtWizardCustomerServices.naicsList;

    loadCloneCustomer(steps.find((item) => item.id === 1), selectedCustomer, naicsList);
    loadCloneService(steps.find((item) => item.id === 2), selectedService);
    loadCloneGenerator(steps.find((item) => item.id === 4), selectedProject, selectedService, selectedProposal);
    loadCloneGeneratorData(steps.find((item) => item.id === 5), selectedProject);
    loadCloneStorage(steps.find((item) => item.id === 6), selectedProject, selectedService);

    steps = steps.map((item, idx) => {
      item.setClone();
      return item;
    });

    const selectedStep = steps.find((item) => item.id === 7);
    return ({
      type: RESET_WIZARD,
      payload: {
        currentId: 7,
        failedValidation: false,
        isFirst: true,
        isLast: false,
        nextId: 2,
        selectedStep,
        steps
      }
    });
  };

  const unsetCloneAll = () => {
    gsLog.debug("In PmtWizardServices: unsetCloneAll(): ...");
    let { steps } = store.getState().pmtWizardServices;

    steps = steps.map((item, idx) => {
      item.unsetClone();
      return item;
    });

    const selectedStep = steps.find((item) => item.id === 1);
    return ({
      type: RESET_WIZARD,
      payload: {
        currentId: 1,
        failedValidation: false,
        isFirst: true,
        isLast: false,
        nextId: 2,
        selectedStep,
        steps
      }
    });
  };

  const updateSelectedStep = (selectedStep) => {
    return {
      type: UPDATE_PMT_WIZARD_SERVICES,
      payload: {
        selectedStep,
      }
    }
  };

  const updateSelectedNav = (stepId) => {
    const { currentId, steps } = store.getState().pmtWizardServices;
    const selectedStep = steps.filter((item) => item.id === stepId);

    return {
      type: UPDATE_PMT_WIZARD_TOP_NAV,
      payload: {
        currentId: stepId,
        isFirst: stepId === 1,
        isLast: stepId === steps.length,
        nextId: currentId + 1,
        selectedStep: selectedStep && selectedStep.length > 0 ? selectedStep[0] : null
      }
    };
  };

  const skipStep = () => {
    return nextStep();
  };

  return {
    failedValidation,
    nextStep,
    previousStep,
    resetAll,
    setCloneAll,
    unsetCloneAll,
    resetNextSteps,
    skipNextStep,
    skipPrevStep,
    skipStep,
    updateSelectedNav,
    updateSelectedStep
  }
};

PmtWizardServices.$inject = [
  "$ngRedux",
  "gsAnalysisService",
  "patApi",
  "gs.services.gsLogService"
];
