import {default as gsLog} from '../service/loggingService';
import { PDF_COLORS } from '../shared/constants.js';
import pdfMake from 'pdfmake';

class PdfService {

  constructor() {
    this.gsLog = gsLog;

    pdfMake.tableLayouts = {
      summaryTableZebraLayout: {
        fillColor: (i, node) => { return (i > 0 && i % 2 === 0) ?  PDF_COLORS.altRowGreen : null; },
        hLineColor: (i, node) => {
          return (i === 0 || i === 1 || i === node.table.body.length) ? PDF_COLORS.headerTableColHeader : '';
        },
        vLineColor: (i, node) => {
          return (i === 0 || i === node.table.widths.length) ? PDF_COLORS.headerTableColHeader : PDF_COLORS.headerTableColHeader;
        },
      },
      innerSummaryTableZebraLayout: {
        fillColor: (i, node) => { return (i > 0 && i % 2 === 0) ?  PDF_COLORS.total : null; },
        hLineColor: (i, node) => {
          return (i === 0 || i === 1 || i === node.table.body.length) ? PDF_COLORS.total : '';
        },
        vLineColor: (i, node) => {
          return (i === 0 || i === node.table.widths.length) ? PDF_COLORS.total : PDF_COLORS.total;
        },
      },
      zebraLayout: {
        fillColor: (i, node) => { return (i > 0 && i % 2 === 0) ?  PDF_COLORS.altRow : null; }
      },
      zebraLayoutGreen: {
        fillColor: (i, node) => { return (i > 0 && i % 2 === 0) ?  PDF_COLORS.altRowGreen : null; }
      }
    };
  }

  download(docDefinition, fileName, cb) {
    pdfMake.createPdf(docDefinition).download(fileName, cb);
  }

  print(docDefinition) {
    pdfMake.createPdf(docDefinition).print();
  }

  open(docDefinition) {
    pdfMake.createPdf(docDefinition).open();
  }
}

export default PdfService;
