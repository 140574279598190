import { UPDATE_SCHEDULE_POLLING } from '../actions/schedulePollingServices';

const INITIAL_STATE = {};

export default function schedulePollingServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_SCHEDULE_POLLING:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
