import {default as gsLog} from '../service/loggingService';

export const SHOW_SESSION_EXPIRE = "SHOW_SESSION_EXPIRE";
export const HIDE_SESSION_EXPIRE = "HIDE_SESSION_EXPIRE";

export const SessionCheckServicesActions = () => {
    const showSessionExpire = (remainingTime, triggeredByInActivity) => {
        return (dispatch, getState) => {
            gsLog.debug("In SessionCheckServicesActions: showSessionExpire(): 000");

            dispatch({
                type: SHOW_SESSION_EXPIRE,
                payload: {
                    showModal: true,
                    remainingTime,
                    triggeredByInActivity
                }
            })
        }
    };

     const hideSessionExpire = () => {
        return (dispatch, getState) => {
            gsLog.debug("In SessionCheckServicesActions: hideSessionExpire(): 000");

            dispatch({
                type: HIDE_SESSION_EXPIRE,
                payload: {
                    showModal: false,
                    remainingTime: 10000
                }
            })
        }
    };


    return {
        hideSessionExpire,
        showSessionExpire
    }
};

export default SessionCheckServicesActions;