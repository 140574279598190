import { getDefaultOperationTimezone } from "../react-transition/ReactUtils";
import {default as gsLog} from '../service/loggingService';

export const UPDATE_OPERATION_TIMEZONE = "UPDATE_OPERATION_TIMEZONE";

let OperationTimezoneActionInstance = null;

const OperationTimezoneActionConstruction = () => {
  
  const updateOperationTimezone = (timezone) => {
    return (dispatch, getState) => {
      dispatch({
        type: UPDATE_OPERATION_TIMEZONE,
        payload: {
            timezone
        }
      });
    }
  }

  const initTimezone = () => {
    let timezone =  getDefaultOperationTimezone();
    return (dispatch, getState) => {
        dispatch({
          type: UPDATE_OPERATION_TIMEZONE,
          payload: {
              timezone
          }
        });
      }   
  }

  return {
    updateOperationTimezone,
    initTimezone
  }
};

export  const OperationTimezoneAction = ( ) => {
  if (OperationTimezoneActionInstance) {
    return OperationTimezoneActionInstance;
  } else {
    OperationTimezoneActionInstance = OperationTimezoneActionConstruction();
   return OperationTimezoneActionInstance;
  }
} 