
import LoggingService from '../service/loggingService';

import {
  SELECTED_MI, SELECTED_VPP,
  SELECTED_ETL_SPACE_ID, SELECTED_OPTIMIZER_SPACE_KEY,
  USER_OBJECTS
} from '../actions/cachedObjectServices';

const INITIAL_STATE = {};

export default function cachedObjectServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case USER_OBJECTS:
      LoggingService.debug("In cachedObjectServices: USER_OBJECTS(): 000 ");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    case SELECTED_MI:
      return Object.assign({}, state, payload);
    case SELECTED_VPP:
      return Object.assign({}, state, payload);
    case SELECTED_ETL_SPACE_ID:
      return Object.assign({}, state, payload);
    case SELECTED_OPTIMIZER_SPACE_KEY:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
