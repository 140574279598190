import { default as gsLog } from '../service/loggingService';
import axios from 'axios';
import { MONTHLY_SAVINGS, PMT_API } from '../shared/app.constants.all';
class vitalsMonthlySavingsApi {
  constructor() {
    this.gsLog = gsLog;
    this.PMT_API = PMT_API;
    this.MONTHLY_SAVINGS = MONTHLY_SAVINGS;
  }

  getLatestTransaction(projectUUID) {
    this.gsLog.debug("In vitalsMonthlySavingsApi: projectUUID = ");
    this.gsLog.debug(projectUUID);
    this.gsLog.debug("In vitalsMonthlySavingsApi: url = ");
    this.gsLog.debug(`${this.PMT_API.PMT_PROJECT}/${projectUUID}/summarizedTransactions`);

    let httpRequest = axios({
      url: `${this.PMT_API.PMT_PROJECT}/${projectUUID}/summarizedTransactions`,
      method: "GET",
      params: {
        sortBy: 'indexKey',
        order: 'desc'
      }
    });

    return httpRequest;
  }

  getInvoiceByRefId(refID) {
    this.gsLog.debug("In vitalsMonthlySavingsApi: refID = ");
    this.gsLog.debug(refID);

    let httpRequest = axios({
      url: `${this.MONTHLY_SAVINGS.MONTHLY_INVOICE}/${refID}`,
      method: "GET"
    });

    return httpRequest;
  }
}

export default vitalsMonthlySavingsApi;