
import _ from 'underscore';
import { default as gsLog } from './loggingService';
import storageService from './storageService';
import cryptoService from './cryptoService';

class ViewStateServiceFactory {
    constructor() {
        this.gsLog = gsLog;
        this.gsLog.debug("In ViewStateService: constructor() ....");
        this.CURRENT_USER_KEY = "current";
        this.CUSTOMERS_KEY = "customers";
        this.SELECTED_CUSTOMER_KEY = "customer";
        this.REMEMBER_ME_KEY = "rememberMe";
        this.FORCED_LOGOUT_KEY = "forcedLogout";
        this.SELECTED_SITE_KEY = "selectedSite";
        this.EXPIRES_KEY = "expiresAt";
        this.MIS_KEY = "mis";
        this.SELECTED_MI_KEY = "mi";
        this.VPPS_KEY = "vpps";
        this.SELECTED_VPP_KEY = "vpp";
        this.APP_SECRET = "appSecret";
        this.APP_TOKEN = "appToken";

        this.ETL_SPACE_KEY = "etl";
        this.SELECTED_ETL_SPACE_KEY = "selectedEtl"
        this.OPTIMIZER_SPACE_KEY = "optimizer";
        this.SELECTED_OPTIMIZER_SPACE_KEY = "selectedOptimizer";
        this.BROWSER_ALERT_MESSAGE_STATUS_KEY = "browserAlertMessageStatus";
        this.IMPERSONATE_LOGIN_KEY = "impersonateLogin";
        this.getUser = this.getUser.bind(this);
        this.storageService = storageService;
        this.cryptoService = cryptoService;
    }

    getUser() {
        this.gsLog.debug("In ViewStateService: getUser() ....");
        var token = this.getLoggedInUserToken();
        var data = this.storageService.getVal(token);
        return JSON.parse(cryptoService.decrypt(data));
    };

    setForcedLogout(forcedLogout) {
        this.gsLog.debug("In ViewStateService: setForcedLogout() ....");
        return this.storageService.setVal(this.FORCED_LOGOUT_KEY, forcedLogout);
    }

    getLoggedInUserToken() {
        return this.storageService.getVal(this.CURRENT_USER_KEY);
    }

    getSelectedVpp() {
        return JSON.parse(this.storageService.getVal(this.SELECTED_VPP_KEY));
    }

    setSelectedVpp(vpp) {
        return this.storageService.setVal(this.SELECTED_VPP_KEY, JSON.stringify(vpp));
    }

    getSelectedMi() {
        return JSON.parse(this.storageService.getVal(this.SELECTED_MI_KEY));
    }

    setSelectedMi(mi) {
        return this.storageService.setVal(this.SELECTED_MI_KEY, JSON.stringify(mi));
    }

    getSelectedEtlSpaceId() {
        return JSON.parse(this.storageService.getVal(this.SELECTED_ETL_SPACE_KEY));
    }

    setSelectedEtlSpaceId(etlSpaceId) {
        return this.storageService.setVal(this.SELECTED_ETL_SPACE_KEY, JSON.stringify(etlSpaceId));
    }

    getSelectedOptimizerSpaceId() {
        return JSON.parse(this.storageService.getVal(this.SELECTED_OPTIMIZER_SPACE_KEY));
    }

    setSelectedOptimizerSpaceId(optimizerSpaceId) {
        return this.storageService.setVal(this.SELECTED_OPTIMIZER_SPACE_KEY, JSON.stringify(optimizerSpaceId));
    }

    createUser(userObj) {
        this.gsLog.debug("In ViewStateService: createUser() 000");
        this.gsLog.debug(userObj);

        var lsKeys = this.storageService.getKeys();
        // Clear old in storage. token length will long than 70 the new aaa token is short 
        lsKeys.forEach(function (item) {
            if (item.length > 70) {
                this.storageService.removeKey(item);
            }
        }, this);
        // TODO: store in sessionStorage
        var encrypttoken = this.cryptoService.encrypt(userObj.access_token);
        this.storageService.setVal(encrypttoken, this.cryptoService.encrypt(JSON.stringify(userObj)));
        //Clear old keys
        this.storageService.removeKey(this.CUSTOMERS_KEY);
        this.storageService.removeKey(this.MIS_KEY);
        this.storageService.removeKey(this.VPPS_KEY);
        this.storageService.setVal(this.ETL_SPACE_KEY, JSON.stringify(userObj.etlSpaceIds));
        this.storageService.setVal(this.APP_SECRET, this.cryptoService.encrypt(userObj.appSecret));
        this.storageService.setVal(this.APP_TOKEN, this.cryptoService.encrypt(userObj.appToken));
        return this.storageService.setVal(this.CURRENT_USER_KEY, encrypttoken);
    }

    getRememberMeEmail() {
        return this.storageService.getVal(this.REMEMBER_ME_KEY);
    }

    setRememberMeEmail(rememberMeEmail) {
        return this.storageService.setVal(this.REMEMBER_ME_KEY, rememberMeEmail);
    }

    setImpersonateLogin(impersonateLogin) {
        return this.storageService.setVal(this.IMPERSONATE_LOGIN_KEY, impersonateLogin);
    }

    updateUser(token, val) {
        return this.storageService.setVal(token, this.cryptoService.encrypt(JSON.stringify(val)));
    }

    getTab() {
        var userObj = this.getUser();
        if (userObj && userObj.tab) {
            return userObj.tab;
        }
        return null;
    }

    setTab(tabNum) {
        var userObj = this.getUser();
        if (userObj) {
            _.extend(userObj, { tab: tabNum });
            this.updateUser(this.getLoggedInUserToken(), userObj);
        }
    }

    getImpersonateLogin() {
        this.gsLog.debug("In ViewStateService: getImpersonateLogin() ....");
        return this.storageService.getVal(this.IMPERSONATE_LOGIN_KEY);
    }

    removeUser() {
        this.gsLog.debug("In ViewStateService: removeUser() ....");
        this.storageService.removeKey(this.getLoggedInUserToken());
        this.storageService.removeKey(this.CURRENT_USER_KEY);
        this.storageService.removeKey(this.EXPIRES_KEY);
        this.storageService.removeKey(this.CUSTOMERS_KEY);
        this.storageService.removeKey(this.SELECTED_CUSTOMER_KEY);
        this.storageService.removeKey(this.MIS_KEY);
        this.storageService.removeKey(this.SELECTED_MI_KEY);
        this.storageService.removeKey(this.VPPS_KEY);
        this.storageService.removeKey(this.SELECTED_VPP_KEY);
        this.storageService.removeKey(this.ETL_SPACE_KEY);
        this.storageService.removeKey(this.SELECTED_ETL_SPACE_KEY);
        this.storageService.removeKey(this.OPTIMIZER_SPACE_KEY);
        this.storageService.removeKey(this.SELECTED_OPTIMIZER_SPACE_KEY);
        this.storageService.removeKey(this.IMPERSONATE_LOGIN_KEY);
        localStorage.removeItem('gsAppLocal.defaultUser');
        localStorage.removeItem('gsAppLocal.LoggedinUser');
        localStorage.removeItem('gsAppLocal.excludedCustomerIds');
        localStorage.removeItem('gsAppLocal.excludedMiIds');
        localStorage.removeItem('gsAppLocal.excludedRoles');
        localStorage.removeItem('gsAppLocal.excludedvppIds');
        localStorage.removeItem("gsAppLocal.permission");
        localStorage.removeItem("gsAppLocal.localVpps");
        localStorage.removeItem("gsAppLocal.newCreatedMi");
        localStorage.removeItem("gsAppLocalWarningVar");
        localStorage.removeItem("gsAppLocal.allPermission");
        localStorage.removeItem("gsAppLocal.featureToggleRoles");


        this.storageService.removeKey(this.APP_SECRET);
        this.storageService.removeKey(this.APP_TOKEN);
        return this.storageService.removeKey(this.CURRENT_USER_KEY);
    }

    setSelectedCustomer(customer) {
        return this.storageService.setVal(this.SELECTED_CUSTOMER_KEY, JSON.stringify(customer));
    }
    
    
}

const ViewStateService = new ViewStateServiceFactory();

export default ViewStateService;