
import React from 'react';
import ReactDOM from 'react-dom';

class Spinner extends React.Component {
    render() {
      let bars = [];
  
      for (let i = 0; i < 12; i++) {
        let barStyle = {};
        barStyle.WebkitAnimationDelay = barStyle.animationDelay =
          (i - 12) / 10 + 's';
  
        barStyle.WebkitTransform = barStyle.transform =
          'rotate(' + (i * 30) + 'deg) translate(146%)';
  
        bars.push( 
          <div style={barStyle} className="react-spinner_bar" key={i} />
        );
      }
  
      return (
        <React.Fragment>
          {bars}
        </React.Fragment>  
      );
    }
  };

class SpinnerService {

    spin() {
        ReactDOM.render(<Spinner />, document.getElementById("reactspinner"));
    }

    stop() {
        ReactDOM.unmountComponentAtNode(document.getElementById("reactspinner"))
    }

}

export default new SpinnerService();
