import { PORTFOLIO_SELECTOR_SEARCH,
  PORTFOLIO_SELECTOR_PROJECTS,
  PORTFOLIO_SELECTED_CUSTOMER,
  PORTFOLIO_SELECTED_PORTFOLIO,
  PORTFOLIO_SELECTED_PROJECT,
  PORTFOLIO_SELECTED_PROPOSAL,
  PORTFOLIO_SELECTOR_PROPOSALS,
  PORTFOLIO_SELECTED_SERVICE
} from '../actions/portfolioSelectorServices';

const INITIAL_STATE = {
  selectedSelector: {
    customer: false,
    service: false,
    project: false,
    proposal: false
  }
};

export default function portfolioSelectorServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case PORTFOLIO_SELECTOR_SEARCH:
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTOR_PROJECTS:
      // return Object.assign({}, {selectedProject: state.selectedProject}, payload);
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTED_CUSTOMER:
      // return Object.assign({}, {projects: state.projects, projectParams: state.projectParams}, payload);
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTED_PORTFOLIO:
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTED_PROJECT:
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTED_PROPOSAL:
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTOR_PROPOSALS:
      return Object.assign({}, state, payload);
    case PORTFOLIO_SELECTED_SERVICE:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
