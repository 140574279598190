import { toggleSelectorUtil } from '../utils/utils';
import store from '../service/store';
import {default as gsLog} from '../service/loggingService';

export const TOGGLE_EXPANDER = "TOGGLE_EXPANDER";
export const TOGGLE_ANALYSIS_EXPANDER = "TOGGLE_ANALYSIS_EXPANDER";
export const TOGGLE_FOOTER = "TOGGLE_FOOTER";
export const TOGGLE_ANALYSIS_FOOTER = "TOGGLE_ANALYSIS_FOOTER";
export const TOGGLE_FOOTER_RETRACT = "retract";
export const TOGGLE_FOOTER_EXPAND = "expand";
export const TOGGLE_FOOTER_RESET = "reset";

export const ToggleFooterServicesActions = () => {

  const toggleExpander = (type) => {
    let { expander, footer, expandMode } = type === TOGGLE_EXPANDER ? store.getState().toggleFooterServices : store.getState().toggleAnalysisFooterServices;

    expander = !expander;
    footer = !expander ? false : footer;
    expandMode = expander ? TOGGLE_FOOTER_EXPAND : TOGGLE_FOOTER_RESET;

    return {
      type,
      payload: {
        expander,
        footer,
        expandMode
      }
    };
  };

  const toggleFooter = (type) => {
    gsLog.debug(`In ToggleFooterServicesActions: toggleFooter(): 000`);
    gsLog.debug(store.getState());
    let { expander, footer, expandMode } = type === TOGGLE_FOOTER ? store.getState().toggleFooterServices : store.getState().toggleAnalysisFooterServices;

    footer = !footer;
    expander = !footer ? false : expander;
    expandMode = footer ? TOGGLE_FOOTER_RETRACT : TOGGLE_FOOTER_RESET;

    return {
      type,
      payload: {
        expander,
        footer,
        expandMode
      }
    };
  };

  const toggle = (type) => {
    let { expander, footer, expandMode } = type === TOGGLE_FOOTER ? store.getState().toggleFooterServices : store.getState().toggleAnalysisFooterServices;
    gsLog.debug(`In ToggleFooterServicesActions: toggle(): expand mode (${expandMode}), expander? (${expander}), footer? (${footer})`);
    return toggleFooter(TOGGLE_ANALYSIS_FOOTER);
  };

  const toggleSelector = (type) => {
    let { down, expander, footer, expandMode } = type === TOGGLE_FOOTER ? store.getState().toggleFooterServices : store.getState().toggleAnalysisFooterServices;

    const results = toggleSelectorUtil(down, expander, footer, expandMode);

    return {
      type,
      payload: results
    };
  };

  const updateFooterHeight = (footerHeight) => {
    return {
      type: TOGGLE_FOOTER,
      payload: {
        footerHeight
      }
    };
  };

  return {
    toggle,
    toggleExpander,
    toggleFooter,
    toggleSelector,
    updateFooterHeight
  }
};

export default ToggleFooterServicesActions;
// ToggleFooterServicesActions.$inject = [
//   "$ngRedux",
//   "gs.services.gsLogService"
// ];
