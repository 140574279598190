import { VIEW_REFRESH } from '../actions/pmtTariffRateInfoServices';

const INITIAL_STATE = {
  active : false
};

export default function pmtTariffRateInfoServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case VIEW_REFRESH:
      return Object.assign({}, payload);
    default:
      return Object.assign({});
      // return Object.assign({}, state );
  }
}
