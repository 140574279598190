import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers/rootReducer';
import {default as gsLog} from './loggingService';

class storeFactory {
    constructor() {
        this.gsLog = gsLog;
        this.gsLog.debug("storeService constructor:");
        this.gsLog.debug("entering storeService ...");
    
        if (storeFactory.instance) {
            return storeFactory.instance;
        }

        storeFactory.instance = this;
        this.store = createStore(reducer, compose(applyMiddleware(thunk)));
    }

    getStore() {
        this.gsLog.debug("In storeService: updateState(): 222 ");
        this.gsLog.debug(this.store);
        return this.store;
      }
}

const storeService = new storeFactory();
export default storeService.getStore()