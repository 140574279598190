import { bindActionCreators } from "redux";
import AuthenticationService from './authenticationService';
import moment from 'moment';
import _ from 'underscore';
import ViewStateService from './viewStateService';
import rootScopeService from '../service/rootScopeService';
import { default as gsLog } from './loggingService';
import store from '../service/store';
import CachedObjectServicesActions from '../actions/cachedObjectServices';
import SessionCheckServicesActions from '../actions/sessionCheckServices';

class SessionCheckServiceFactory {
    constructor() {
        if (SessionCheckServiceFactory.instance) {
            return SessionCheckServiceFactory.instance;
        }
        SessionCheckServiceFactory.instance = this;

        this.$rootScope = rootScopeService;
        this.gsLog = gsLog;
        this.viewStateService = ViewStateService;
        this.authenticationService = AuthenticationService;
        this.remainingCount = 0;
        this.countdownInterval = 1000;
        this.timeForLogoutOp = 5;
        this.sessionCheckInterval = 10000; //testing
        this.sessionCounterCheckInterval = 1000;
        this.isSessionChkAlreadyStarted = false;
        this.isAlreadySetup = false;
        this.hasUserActivity = false;
        if (localStorage.getItem('gsAppLocal.bypassLogout') === null) localStorage.setItem('gsAppLocal.bypassLogout', false);
        this.inActivityTimeAllow = 480000; //new requirement
        this.inActivityCountDownTime = 120000;
        this.sessionPromptPopUpTime = 120; // seconds
        this.sessionActivityDetectTime = 300 + this.sessionPromptPopUpTime + this.timeForLogoutOp; // seconds ; 5 min + this.timeForLogoutOp + this.sessionPromptPopUpTime
        this.logout = this.logout.bind(this);
        this.startSessionCheck = this.startSessionCheck.bind(this);

        this.gsLog.debug("In sessionCheckService.js : constructor(): 000");
        this.gsLog.debug(AuthenticationService);

        this.sessionCheckServicesActions = SessionCheckServicesActions();
        this.boundActionCreators = bindActionCreators(this.sessionCheckServicesActions, store.dispatch);
        this.unsubscribe = store.subscribe(this.mapStateToThis.bind(this));
    }

    mapStateToThis() {
        this.gsLog.debug("In SessionCheckServiceFactory: mapStateToThis(): 333");
        this.gsLog.debug(store.getState());
    }

    checkUserActivties() {
        if (!this.isAlreadySetup) {
            this.isAlreadySetup = true;
            // this.gsLog.debug("In sessionCheckService.js : checkUserActivties(): 000 this.inActivityCountDownTime " + this.inActivityCountDownTime);
            document.onclick = document.onkeypress = document.onmousemove = document.onscroll = document.onmousedown = this.setActivity.bind(this);
        }
    }

    setActivity() {
        // this.gsLog.debug("In sessionCheckService.js : setActivity(): 000");
        this.hasUserActivity = true;
    }

    removeActivityEvents() {
        // this.gsLog.debug("In sessionCheckService.js : removeActivityEvent(): 000");
        document.removeEventListener("onclick", this.setActivity);
        document.removeEventListener("onkeypress", this.setActivity);
        document.removeEventListener("onmousemove", this.setActivity);
        document.removeEventListener("onscroll", this.setActivity);
        document.removeEventListener("onmousedown", this.setActivity);
    }

    showSessionExpireForm(remainingTime, triggeredByInActivity) {
        this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm(): 000 triggeredByInActivity " + triggeredByInActivity);
        this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm(): 000 remainingTime " + remainingTime);

        if (!_.isUndefined(this.$rootScope.oauth) && this.authenticationService.allowExpSessionChk && !_.isUndefined(remainingTime)) {
            this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm(): 111");
            // this.gsLog.debug(this.$rootScope.oauth);
            // this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm(): 222");
            // this.gsLog.debug(this.authenticationService.allowExpSessionChk);
            // this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm(): 333");
            // this.gsLog.debug(triggeredByInActivity);
            // this.gsLog.debug("In sessionCheckService.js : showSessionExpireForm() remainingTime: 444");
            // this.gsLog.debug(remainingTime);

            this.authenticationService.allowExpSessionChk = false;
            store.dispatch(this.sessionCheckServicesActions.showSessionExpire(remainingTime, triggeredByInActivity));
        }
    }

    stopSessionCheck() {
        // this.gsLog.debug("Calling stopSessionCheck 000 ");
        if (!_.isUndefined(this.stopInterval)) {
            this.authenticationService.allowExpSessionChk = false;
            clearInterval(this.stopInterval);
            this.isSessionChkAlreadyStarted = false;
        }
        else {
            // this.gsLog.debug("Calling stopSessionCheck 222 ");
        }
    }

    countDown() {
        let expire = moment(this.$rootScope.oauth.aaa_expires);
        let now = moment();
        let duration = moment.duration(expire.diff(now));
        this.remainingCount = Math.round(duration.asSeconds());
        // this.gsLog.debug("Calling countDown 000 : this.remainingCount");
        // this.gsLog.debug(this.remainingCount);
    }

    stopSessionCounter() {
        // this.gsLog.debug("Calling stopSessionCounter 000");
        if (!_.isUndefined(this.stopCounterInterval)) {
            this.remainingCount = 0;
            clearInterval(this.stopCounterInterval);
        }
    }

    startSessionCounter(remainingTime) {
        // this.gsLog.debug("Calling startSessionCounter 000");
        this.remainingCount = (remainingTime > 0) ? Math.round(remainingTime / this.countdownInterval) : 0;
        this.stopCounterInterval = setInterval(this.countDown.bind(this), 1000);
    }

    logout() {
        if (this.viewStateService.getImpersonateLogin()) {
            this.authenticationService.unImpersonateUser()
                .then((response) => {
                    this.authenticationService.logout()
                        .then(() => {
                            return window.location.href = '/';
                        }, (error) => {
                            return window.location.href = '/';
                        })
                        .then((response) => {
                        });
                });
        } else {
            this.authenticationService.logout()
                .then(() => {
                    return window.location.href = '/';
                }, (error) => {
                    this.$state.go('login');
                    return window.location.href = '/';
                })
                .then((response) => {
                });
        }
    }

    isCurrentSessionTokenExpired() {
        this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired 000 ");
        // this.gsLog.debug(this.$rootScope.oauth);

        // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired : current state");
        // this.gsLog.debug(this.$state.current);

        // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired : state");
        // this.gsLog.debug(this.$state);

        if (!_.isUndefined(this.$rootScope.oauth)) {
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired 111 ");
            let expire = moment(this.$rootScope.oauth.aaa_expires);
            let now = moment();
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired in interval now : ");
            // this.gsLog.debug(now);
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired in interval expire : ");
            // this.gsLog.debug(expire);
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired : 111 222 hasUserActivity : " + this.hasUserActivity);

            let duration = moment.duration(expire.diff(now));
            let seconds = duration.asSeconds();
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired in interval duration seconds: ");
            // this.gsLog.debug(seconds);
            // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired authenticationService.allowExpSessionChk : " + this.authenticationService.allowExpSessionChk);

            if (seconds < 0) {
                this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired seconds < 0: ");
                this.stopSessionCounter();
                this.stopSessionCheck();
                this.viewStateService.setForcedLogout(true);
                this.logout();
                // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired seconds < 0: 111 ");
                return true;
            }
            else {
                // this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired seconds > 0: ");
                return false;
            }
        }
        else {
            this.gsLog.debug("gsLog Calling isCurrentSessionTokenExpired 222 ");
            this.stopSessionCounter();
            this.stopSessionCheck();
            this.viewStateService.setForcedLogout(true);
            this.logout();
            return true;
        }

    }

    checkSessionExpired() {
        // this.gsLog.debug("gsLog Calling sessionCheck in interval");
        // this.gsLog.debug(this.$rootScope.oauth);

        // this.gsLog.debug("gsLog Calling sessionCheck : current state");
        // this.gsLog.debug(this.$state.current);

        if (!_.isUndefined(this.$rootScope.oauth)) {
            this.checkUserActivties();
            let expire = moment(this.$rootScope.oauth.aaa_expires);
            // let expire = moment().add(1, "days");
            //HACK
            // testing
            //let expire = moment().add(10, 'seconds'); // exactly 10 second later from now
            //let expire = moment().add(2, 'minutes'); // exactly 2 min later from now

            //let expire = moment().subtract(1, 'minutes');
            //let expire = moment().add(1, 'minutes');
            //let expire = moment().add(2, 'minutes'); // exactly 2 min later from now
            //let expire = moment().add(3, 'minutes'); // exactly 3 min later from now

            let now = moment();
            // this.gsLog.debug("gsLog Calling sessionCheck in interval now : ");
            // this.gsLog.debug(now);
            // this.gsLog.debug("gsLog Calling sessionCheck in interval expire : ");
            // this.gsLog.debug(expire);
            // this.gsLog.debug("gsLog Calling sessionCheck : 111 hasUserActivity : " + this.hasUserActivity);

            let duration = moment.duration(expire.diff(now));
            let seconds = duration.asSeconds();
            //HACK
            // seconds = -1;
            // this.gsLog.debug("gsLog Calling sessionCheck in interval duration seconds: ");
            // this.gsLog.debug(seconds);
            // this.gsLog.debug("gsLog Calling sessionCheck authenticationService.allowExpSessionChk : " + this.authenticationService.allowExpSessionChk);

            if (seconds < 0) {
                this.gsLog.debug("gsLog Calling sessionCheck seconds < 0: ");
                this.stopSessionCounter();
                this.stopSessionCheck();
                this.viewStateService.setForcedLogout(true);
                this.logout();
                // this.gsLog.debug("gsLog Calling sessionCheck seconds < 0: 111 ");
            }
            else {
                if (this.authenticationService.allowExpSessionChk && seconds <= this.sessionActivityDetectTime) {
                    this.gsLog.debug("gsLog Calling sessionCheck : 333 ");
                    // this.gsLog.debug(localStorage.getItem('gsAppLocal.bypassLogout'));
                    // this.gsLog.debug("gsLog Calling sessionCheck : 333 111 ");
                    // this.gsLog.debug(this.hasUserActivity);
                    this.checkUserActivties();
                }
                else {
                    // this.gsLog.debug("gsLog Calling sessionCheck : 444 ");
                    // this.gsLog.debug(localStorage.getItem('gsAppLocal.bypassLogout'));
                    this.hasUserActivity = false;
                    this.removeActivityEvents();
                }

                //HACK
                if (this.authenticationService.allowExpSessionChk && seconds <= this.sessionPromptPopUpTime + this.timeForLogoutOp) {
                    this.gsLog.debug("gsLog Calling sessionCheck : 555 ");
                    // this.gsLog.debug("gsLog Calling sessionCheck : 555 seconcds :  " + seconds);
                    // this.gsLog.debug("gsLog Calling sessionCheck : 555 this.timeForLogoutOp :  " + this.timeForLogoutOp);
                    // this.gsLog.debug("gsLog Calling sessionCheck : 555 econds - this.timeForLogoutOp :  " + (seconds - this.timeForLogoutOp));
                    let remainingTime = (seconds > this.timeForLogoutOp) ? (seconds - this.timeForLogoutOp) * 1000 : 0;
                    // this.gsLog.debug("gsLog Calling sessionCheck : 666 ");
                    // this.gsLog.debug(remainingTime);
                    // this.gsLog.debug("gsLog Calling sessionCheck : 666 111 hasUserActivity : " + this.hasUserActivity);
                    // this.gsLog.debug("gsLog Calling sessionCheck : 666 222 bypassLogout : " + localStorage.getItem('gsAppLocal.bypassLogout'));
                    //HACK
                    if (!this.hasUserActivity && !JSON.parse(localStorage.getItem('gsAppLocal.bypassLogout'))) {
                        this.gsLog.debug("gsLog Calling sessionCheck : 777 ");
                        this.hasUserActivity = false;
                        this.startSessionCounter(remainingTime);
                        this.showSessionExpireForm(remainingTime, false);
                    }
                    else {
                        this.gsLog.debug("gsLog Calling sessionCheck : 888 ");
                        this.hasUserActivity = false;
                        this.authenticationService.refreshToken();
                    }
                }
            }
        }
    }

    startSessionCheck(justDoit = false) {
        this.gsLog.debug("Calling startSessionCheck 000 ");
        if (!this.isCurrentSessionTokenExpired()) {
            this.gsLog.debug("Calling startSessionCheck 111 ");
            if (justDoit) {
                this.gsLog.debug("Calling startSessionCheck 111 111");
                this.stopSessionCheck();
            }

            if (!this.isSessionChkAlreadyStarted) {
                this.gsLog.debug("Calling startSessionCheck 222 ");
                this.gsLog.debug(this.authenticationService);

                this.isSessionChkAlreadyStarted = true;
                this.authenticationService.allowExpSessionChk = true;
                this.checkSessionExpired();
                this.stopInterval = setInterval(this.checkSessionExpired.bind(this), this.sessionCheckInterval);
            }
        }
        else {
            // this.gsLog.debug("Calling startSessionCheck 555 ");
        }
    }

}

const SessionCheckService = new SessionCheckServiceFactory();

export default SessionCheckService;