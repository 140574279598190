import { UPDATE_VPP_SELECTOR_SIDE_BAR } from '../actions/vppSelectorSidebarServices';

const INITIAL_STATE = {
  vppSelector: true,
  vppSelectorRevenue: false,
  vppSelectorConfig: false,
  vppSelectorProject: true
};

export default function updateVppSelectorSidebar(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_VPP_SELECTOR_SIDE_BAR:
      return Object.assign({}, payload);
    default:
      return state;
  }
}
