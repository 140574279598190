import {  TOGGLE_FOOTER_EXPAND, TOGGLE_FOOTER_RESET, TOGGLE_FOOTER_RETRACT } from "../actions/toggleFooterServices";
import { TOGGLE_HELIOS_EXPANDER, TOGGLE_HELIOS_FOOTER } from "../actions/heliosSelectorToggleActions";

const INITIAL_STATE = {
  expander: false,
  footer: false,
  expandMode: TOGGLE_FOOTER_RESET
};

export default function toggleHeliosFooterServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TOGGLE_HELIOS_EXPANDER:
      return Object.assign({}, state, payload);
    case TOGGLE_HELIOS_FOOTER:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
