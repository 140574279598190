import axios from 'axios';
import rootScopeService from '../service/rootScopeService';
import { AAA_SITES } from "../shared/constants";
import { default as gsLog } from '../service/loggingService';
import SpinnerService from "../shared/react-spinner.js";
import ViewStateService from '../service/viewStateService';

axios.interceptors.request.use(config => {

    const log = gsLog;
    log.debug('In app.module.dev: axios interceptor: request 000 ');
    log.debug(config);
    config.headers = config.headers || {};
    let $rootScope = rootScopeService;

    if ($rootScope.oauth && $rootScope.oauth.authenticated) {
        log.debug('In app.module.dev: axios interceptor: request 111 ');
        let isAaaSite = AAA_SITES.some(function (url) {
            return config.url.includes(url);
        });
        const isLogout = config.url.includes("revoke") || config.url.includes("token") || config.url.includes("impersonate-user");
        log.debug("isAaaSite? " + isAaaSite + ", isLogout? " + isLogout + ", config.url = " + config.url);

        log.debug('In app.module.dev: axios interceptor: request 222');
        if (!isLogout) {
            log.debug('In app.module.dev: axios interceptor: request 333');
            config.headers.Authorization = isAaaSite ? "Bearer " + $rootScope.oauth.aaa_token : "Bearer " + $rootScope.oauth.access_token;
        }
    }

    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const rejection = error.response;
    // SpinnerService.stop('spinner');
    const log = gsLog;
    log.error('In app.module.dev: axios interceptor: response error: rejection = ');
    log.error(rejection);

    if (rejection) {
        switch (rejection.status) {
            case 401:
                log.error('In app.module.dev: axios interceptor: HTTP 401: wrong username or password');

                if (rejection.data && rejection.data.fault && rejection.data.fault.code === 900901) {
                    ViewStateService.removeUser();
                    setTimeout(function () {
                        window.location.href = '/';
                    }, 10); 
                }

                if (rejection.data && rejection.data.includes("900901")) {
                    ViewStateService.removeUser();
                    setTimeout(function () {
                        window.location.href = '/';
                    }, 10); 
                }
                break;
            case 403:
                log.error("In app.module.dev: axios interceptor: HTTP 403: you don't have the right to do this");
                //alert("can't access resource: " + rejection.config.url);
                break;
            case 404:
                log.error("In app.module.dev: axios interceptor: HTTP 404: the url " + rejection.config.url + " was not found! params = " + JSON.stringify(rejection.config.params));
                //alert("the url " + rejection.config.url + " was not found! params = " + JSON.stringify(rejection.config.params));
                break;
            case 406:
                log.error("In app.module.dev: axios interceptor: HTTP 406");
                break;
            case 500:
                log.error("In app.module.dev: axios interceptor: HTTP 500: Server internal error: " + rejection.data);
                //alert("internal server error for url " + rejection.config.url);
                //toastr.error('internal server error: ' + rejection.data);
                break;
            default:
                log.error("In app.module.dev: axios interceptor: catch all: Error " + rejection.status + ": " + rejection.data);
                //toastr.error("Error " + rejection.status + ": " + rejection.data);
                break;
        }
    }

    return Promise.reject(error);
});