import LoggingService from '../service/loggingService';
import { CURRENT_VIEW, LOGGED_IN_USER, SELECTED_ROUTE } from '../actions/loggedInUserServices';

const INITIAL_STATE = {};

export default function loggedInUserServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case CURRENT_VIEW:
      LoggingService.debug("In loggedInUserServices reducer: 000 ");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    case LOGGED_IN_USER:
      LoggingService.debug("In loggedInUserServices reducer: 111 ");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    case SELECTED_ROUTE:
      LoggingService.debug("In loggedInUserServices reducer: 222 ");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    default:
      LoggingService.debug("In loggedInUserServices reducer: 333 ");
      LoggingService.debug(payload);
      return state;
  }
}
