import { TOGGLE_FOOTER_EXPAND, TOGGLE_FOOTER_RESET, TOGGLE_FOOTER_RETRACT } from '../actions/toggleFooterServices';
import _ from 'underscore';
import $ from "jquery";
import moment from 'moment';

export const constant = x => () => x;
export const identity = x => x;
export const noop = () => undefined;

export const removeDuplicateItems = arr => [...new Set(arr)];

export const b64EncodeUnicode = (str) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}

export const getDateOfWeek = (startDate, timemodeSelector) => {
  let newStartDate = null,
    endDate = null;
  const { today, oneWeek, oneMonth, sixMonths, oneYear } = timemodeSelector;
  if (today) {
    newStartDate = startDate.clone().startOf('day');
    endDate = newStartDate.clone().endOf('day');
  } else if (oneWeek) {
    newStartDate = startDate.clone().startOf('week');
    endDate = newStartDate.clone().endOf('week');
  } else if (oneMonth) {
    newStartDate = startDate.clone().startOf('month');
    endDate = newStartDate.clone().endOf('month');
  } else if (sixMonths) {
    newStartDate = startDate.clone().startOf('month');
    endDate = newStartDate.clone().endOf('month');
  } else if (oneYear) {
    newStartDate = startDate.clone().startOf('year');
    endDate = newStartDate.clone().endOf('year');
  }

  return {
    startDate: newStartDate,
    endDate
  };
}

export const updateDefaultMiOutput = (inverterCapacity, batteryCapacity, duration) => {
  const secVal = batteryCapacity / duration;
  const defaultOutput = inverterCapacity > secVal ? Math.round(secVal) : Math.round(inverterCapacity);
  return {
    defaultOutput: -defaultOutput,
    minOutput: -defaultOutput,
    maxOutput: defaultOutput
  };
}

export const msToTime = (duration) => {
  if (_.isUndefined(duration) || _.isNull(duration)) {
    return null;
  }

  let hours = '00',
    minutes = '00';
  const durationInSec = parseInt(duration);
  if (durationInSec < 3600) {
    hours = '00';
    minutes = Math.floor(durationInSec / 60);
  } else {
    const containFractions = durationInSec % 3600;
    hours = Math.floor(durationInSec / 3600);
    if (containFractions === 0) {
      minutes = '00';
    } else {
      minutes = Math.floor(containFractions / 60);
    }
  }

  return `${hours}:${minutes}`;
};

export const showPartialString = (str, cutOffLen) => {
  // custOffLen = 10
  if (str.length >= cutOffLen) {
    return `${str.slice(0, cutOffLen)}...`;
  } else {
    return `${str}`;
  }
};

export const toggleSelectorUtil = (down, expander, footer, expandMode) => {
  // initial -- fully expanded --- expandMode("expand"), expander(true), footer(false)
  //         -- mid            --- expandMode("retract"), expander(true), footer(true)
  //         -- collapsed      --- expandMode("reset"), expander(false), footer(false)
  if (!footer && expander && expandMode === TOGGLE_FOOTER_EXPAND) {
    // fully expanded --> mid
    expandMode = TOGGLE_FOOTER_RETRACT;
    expander = true;
    footer = true;
    down = true;
  } else if (footer && expander && expandMode === TOGGLE_FOOTER_RETRACT) {
    if (down) {
      // mid --> reset
      expandMode = TOGGLE_FOOTER_RESET;
      expander = false;
      footer = false;
    } else {
      // mid --> fully expanded
      expandMode = TOGGLE_FOOTER_EXPAND;
      expander = true;
      footer = false;
    }
  } else {
    // collapsed --> mid
    expandMode = TOGGLE_FOOTER_RETRACT;
    expander = true;
    footer = true;
    down = false;
  }

  return {
    down,
    expander,
    footer,
    expandMode
  };
};

export const getBrowserTimezone = () => {
  const tzName = moment.tz.guess();
  const tzAbbr = moment.tz.zone(tzName).abbr(moment().valueOf());
  return {
    name: tzName,
    abbr: tzAbbr
  };
}

export const getDefaultTimezone = (timezones, defaultTimezone) => {
  let selectedTimezone = timezones[0];
  const browserTimezone = getBrowserTimezone();

  timezones = timezones.filter((item) => !item.displayName.startsWith('Browser'));
  const convertBrowser = timezones.find((item) => item.name === browserTimezone.name);
  timezones.push({
    displayName: `Browser (${convertBrowser ? convertBrowser.zone : timezones[0].zone})`,
    zone: convertBrowser ? convertBrowser.zone : timezones[0].zone,
    name: browserTimezone.name
  });
  if (!_.isNull(defaultTimezone) && !_.isUndefined(defaultTimezone)) {
    timezones = timezones.filter((item) => !item.displayName.startsWith('VPP'));
    timezones.push({
      displayName: `VPP (${defaultTimezone})`,
      zone: defaultTimezone,
      name: defaultTimezone
    });
    selectedTimezone = timezones.find((item) => item.displayName === `VPP (${defaultTimezone})`);
  } else {
    selectedTimezone = timezones.find((item) => item.displayName === `Browser (${convertBrowser ? convertBrowser.zone : timezones[0].zone})`);
  }
  
  return {
    selectedTimezone,
    timezones
  };
}

export const roundTo = (n, digits) => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }

  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(2);
  if (negative) {
    n = (n * -1).toFixed(2);
  }
  return n;
}

export const uniqueId = () => {
  return Math.random().toString(36).substr(2, 16);
};

export const pipe = functions => data => {
  return functions.reduce(
    (value, func) => func(value),
    data
  );
};

export const resetChart = (elementId) => {
  let chart = $(`#${elementId}`).highcharts();

  if (chart) {
    while (chart.series.length > 0) {
      chart.series[0].remove(false);
    }
  }
};

export const getForecastVersionByEndpoint = (endPoint, rawData) => {
  let selectedVersion = null;
  let versions = rawData[endPoint] && rawData[endPoint].versions;
  // this.gsLog.debug(`In VitalsForecastContainerComponentCtrl: getVersionsByEndpoint(): end point = ${endPoint}, versions = `);
  // this.gsLog.debug(versions);

  Object.keys(versions).forEach((version) => {
    if (!selectedVersion) {
      selectedVersion = version;
    }
  });

  return versions[selectedVersion];
}

export const labelFormatter = (val, minVal, maxVal, isFirst, isLast, isUtc = false) => {
  const minDate = moment(minVal);
  const maxDate = moment(maxVal);
  const diffInHrs = maxDate.diff(minDate, 'hours');
  let dtFormat = "h:mm a";
  // console.error("In Utils.labelFormatter(): diff in hours = ", diffInHrs, ", date = ", moment(val).format("MMM DD, YYYY HH:mm:ss"), ", isFirst? ", isFirst, ", isLast? ", isLast);
  // gsLog.debug("In vitalsMainChart: labels.formatter: diff in hours: " + diffInHrs);
  if (diffInHrs <= 24) {
    if (isFirst || isLast) {
      dtFormat = "MMM DD H:mm";
    } else {
      dtFormat = "h:mm a";
    }
  } else if (diffInHrs <= 384) {   // 8 days
    /*
    // gsLog.debug("In vitalsMainChart: labels.formatter: 8 days or less ...");
    if (this.isFirst || moment(this.value).hour() === 0) {
    // if (this.isFirst || moment(this.value).tz(service.timezone).hour() === 0) {

        dtFormat = "MMM DD";
    } else {
        //dtFormat = "H:mm a";
        dtFormat = "MMM DD";
    }
    */
    //dtFormat = "MMM DD";
    // console.error("current hour = ", moment(val).hour());
    if (isFirst || moment(val).hour() === 0) {
      dtFormat = "MMM DD";
    } else {
      dtFormat = "h:mm a";
    }
  } else if (diffInHrs <= 731) {  // 1 month
    //gsLog.debug("less than a month: show month & day");
    dtFormat = "MMM DD";
    //service.graph.powerDemandAnalyzer.xAxis.labels.format = "{value:%b %d}";
  } else if (diffInHrs <= 4383) {  // 6 months
    //gsLog.debug("less than 6 months: show month & day");
    //service.graph.powerDemandAnalyzer.xAxis.labels.format = "{value:%b %d}";
    dtFormat = "MMM DD";
  } else if (diffInHrs <= 8766) {  // 1 year
    //gsLog.debug("less than a year: show month & year");
    //service.graph.powerDemandAnalyzer.xAxis.labels.format = "{value:%b %y}";
    dtFormat = "MMM YYYY";
  } else {                        // lifetime
    //gsLog.debug("lifetime: show month, day & year");
    //service.graph.powerDemandAnalyzer.xAxis.labels.format = "{value:%b %d %y}";
    dtFormat = "MMM YYYY";
  }
  // return moment(this.value).tz(service.timezone).format(dtFormat);
  // if (dtFormat) {
  // if (isUtc) {
  //   console.error("In utils.labelFormatter(): date = " + moment(val).utc().format(dtFormat));
  // } else {
  //   console.error("In utils.labelFormatter(): date = " + moment(val).format(dtFormat));
  // }
  return isUtc ? moment(val).utc().format(dtFormat) : moment(val).format(dtFormat);
  // } else {
  //   return "";
  // }
};

export const formatCurrency = (num, numDigits = 2) => {
  return num.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: numDigits });
};

export const isNumberInput = (num) => {
  var valid = !isNaN(num) && num !== " " && num !== "";
  return valid;
}
