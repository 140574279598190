import { UPDATE_SCHEDULE_SIDE_BAR } from '../actions/scheduleSidebarActions';

const INITIAL_STATE = {
  graphView: false,
  gridView: true,
  performanceView: false,
};

export default function scheduleSidebarServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_SCHEDULE_SIDE_BAR:
      return Object.assign({}, payload, { perfRefresh: true });
    default:
      // return state;
      return Object.assign({}, state,  { perfRefresh: false });
  }
}
