import { UPDATE_OPERATION_TIMEZONE } from '../actions/operationsTimezoneAction';
import { getDefaultOperationTimezone } from "../react-transition/ReactUtils";

const INITIAL_STATE ={timezone: getDefaultOperationTimezone()};

export default function OperationTimezoneServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_OPERATION_TIMEZONE:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
