import { VIEW_SET_ACTIVE, VIEW_UNSET_ACTIVE, VIEW_REFRESH, VIEW_RESET_REFRESH } from '../actions/vitalsConflictRejectServices';

const INITIAL_STATE = {
  active : false
};

export default function vitalsConflictRejectServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case VIEW_SET_ACTIVE:
      // console.error("In reducer.vitalsConflictRejectServices : (VIEW_SET_ACTIVE) ");
      return Object.assign({}, state, { active : true });
    case VIEW_UNSET_ACTIVE:
      // console.error("In reducer.vitalsConflictRejectServices : (VIEW_UNSET_ACTIVE) ");
      return Object.assign({}, state, { active : false});
    case VIEW_REFRESH:
      // console.error("In reducer.vitalsConflictRejectServices : (VIEW_REFRESH) ");
      return Object.assign({}, state, payload);
    case VIEW_RESET_REFRESH:
      // console.error("In reducer.vitalsConflictRejectServices : (VIEW_RESET_REFRESH) ");
      return Object.assign({}, state, payload);
    default:
      // console.error("In reducer.vitalsConflictRejectServices : (default) ");
      return Object.assign({}, state );
  }
}
