import {default as gsLog} from '../service/loggingService';
import Rx from "rx";

class PollingServiceMessageHandler {
  constructor() {
    if (PollingServiceMessageHandler.instance) {
      return PollingServiceMessageHandler.instance;
    }

    this.gsLog = gsLog;
    this.rx = Rx;
    this.messageReceivedEvent = new this.rx.Subject();
    PollingServiceMessageHandler.instance = this;
  }

  messageReceived(eventData) {
    this.messageReceivedEvent.onNext({
      name: "POLLING_SERVICE_RECEIVED_MESSAGE",
      data: eventData
    });
  }
}

export default PollingServiceMessageHandler;
