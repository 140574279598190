import _ from 'underscore';
import { default as gsLog } from '../service/loggingService';
import ToasterService from "../shared/toasterService.js";
import rx from 'rx';
import VitalsMonthlySavingsApi from "../service/vitalsMonthlySavingsApi";
import ProposalsApi from "../view-proposals-container/proposalsApi";

export const VIEW_LATEST_MONTLY_SAVINGS = "VIEW_LATEST_MONTLY_SAVINGS";
export const VIEW_UITILITY_NAME = "VIEW_UITILITY_NAME";

export const MonthlySavingsServices = () => {
  let toasterService = ToasterService;
  let vitalsMonthlySavingsApi = new VitalsMonthlySavingsApi();
  let proposalsApi = new ProposalsApi();
  const NO_REFID = -1;

  const parseMainResponse = (response) => {
    let projInfo = {};
    let demandBasedChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let consumptionBasedChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let demandResponseChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let chartCategory = [];
    let savingsChartData = { DEMAND_BASED: 0, CONSUMPTION_BASED: 0, DEMAND_RESPONSE: 0 };

    if (!_.isUndefined(response.data.invoiceMisc) && !_.isNull(response.data.invoiceMisc)) {
      projInfo["tariffCode"] = response.data.invoiceMisc.tariffCode;
      projInfo["customerLifeTime"] = response.data.invoiceMisc.totalSaving.customerLifeTime;
      projInfo["customerYearToDate"] = response.data.invoiceMisc.totalSaving.customerYearToDate;
      projInfo["customerShare"] = response.data.invoiceMisc.totalSaving.customerShare;
      if (!_.isUndefined(response.data.invoiceMisc.savingsByTransactionTypeAndMonth.DEMAND_BASED)) {
        let demandBased = response.data.invoiceMisc.savingsByTransactionTypeAndMonth.DEMAND_BASED;
        chartCategory = Object.keys(demandBased);
        for (let prop in demandBased) {
          if (demandBased.hasOwnProperty(prop)) {
            let pos = chartCategory.indexOf(prop);
            demandBasedChartData[pos] = demandBased[prop].customerShare;
          }
        }
      }
      if (!_.isUndefined(response.data.invoiceMisc.savingsByTransactionTypeAndMonth.CONSUMPTION_BASED) &&
        chartCategory.length > 0) {
        let consumptionBased = response.data.invoiceMisc.savingsByTransactionTypeAndMonth.CONSUMPTION_BASED;

        for (let prop in consumptionBased) {
          if (consumptionBased.hasOwnProperty(prop)) {
            let pos = chartCategory.indexOf(prop);
            consumptionBasedChartData[pos] = consumptionBased[prop].customerShare;
          }
        }
      }

      if (!_.isUndefined(response.data.invoiceMisc.savingsByTransactionTypeAndMonth.DEMAND_RESPONSE) &&
        chartCategory.length > 0) {
        let demandResponse = response.data.invoiceMisc.savingsByTransactionTypeAndMonth.DEMAND_RESPONSE;

        for (let prop in demandResponse) {
          if (demandResponse.hasOwnProperty(prop)) {
            let pos = chartCategory.indexOf(prop);
            demandResponseChartData[pos] = demandResponse[prop].customerShare;
          }
        }
      }

      if (!_.isUndefined(response.data.invoiceMisc.savings)) {
        let savings = response.data.invoiceMisc.savings;

        for (let prop in savings) {
          if (savings.hasOwnProperty(prop)) {
            savingsChartData[prop] = savings[prop].customerShare;
          }
        }
      }
    }

    gsLog.debug(`In MonthlySavingsServices: parseMainResponse() 666: demandBasedChartData = `);
    gsLog.debug(demandBasedChartData);

    gsLog.debug(`In MonthlySavingsServices: parseMainResponse() 666 111: consumptionBasedChartData = `);
    gsLog.debug(consumptionBasedChartData);

    gsLog.debug(`In MonthlySavingsServices: parseMainResponse() 666 222: demandResponseChartData = `);
    gsLog.debug(demandResponseChartData);

    gsLog.debug(`In MonthlySavingsServices: parseMainResponse() 777: chartCategory = `);
    gsLog.debug(chartCategory);

    gsLog.debug(`In MonthlySavingsServices: parseMainResponse() 888: savingsChartData = `);
    gsLog.debug(savingsChartData);

    return {
      demandBasedChartData: demandBasedChartData,
      chartCategory: chartCategory,
      consumptionBasedChartData: consumptionBasedChartData,
      demandResponseChartData: demandResponseChartData,
      savingsChartData: savingsChartData
    };
  };

  const viewLatestMonthlySavings = (projectUUID) => {
    gsLog.debug(`In MonthlySavingsServices: viewLatestMonthlySavings() 000 :`);

    return (dispatch, getState) => {
      gsLog.debug(`In MonthlySavingsServices: viewLatestMonthlySavings() 444 :`);
      gsLog.debug(`In MonthlySavingsServices: viewLatestMonthlySavings(): call API with vpp ID (${projectUUID})`);

      rx.Observable
        .fromPromise(vitalsMonthlySavingsApi.getLatestTransaction(projectUUID))
        .catch((error) => {
          gsLog.error("In MonthlySavingsServices: getLatestTransaction(): error in calling schedules API = ");
          gsLog.debug(error);
          toasterService.error(`error in calling schedules API = ${error.statusText}`);
        })
        .subscribe(
          (response) => {
            gsLog.debug("In MonthlySavingsServices: getLatestTransaction(): response from API = ");
            gsLog.debug(response);

            if (!response.apiError) {
              if (!_.isUndefined(response.data.content) && !_.isNull(response.data.content)) {
                let refId = !_.isUndefined(response.data.content[0]) ? response.data.content[0].demandSavingRefId : NO_REFID;

                if (refId !== NO_REFID) {
                  rx.Observable
                    .fromPromise(vitalsMonthlySavingsApi.getInvoiceByRefId(refId))
                    .subscribe(
                      (response) => {

                        gsLog.debug("In MonthlySavingsServices: getInvoiceByRefId(): response from API = ");
                        gsLog.debug(response);
                        let mainChartData = parseMainResponse(response);

                        dispatch({
                          type: VIEW_LATEST_MONTLY_SAVINGS,
                          payload: {
                            projectUUID: projectUUID,
                            mainChartData: mainChartData,
                            InvoiceMiscData: response.data.invoiceMisc
                          }
                        });
                      },
                      (error) => {
                        gsLog.error("In MonthlySavingsServices: getInvoiceByRefId(): (2) error in calling schedules API = ");
                        gsLog.debug(error);
                        toasterService.error(`error in calling schedules API = ${error.statusText}`);
                      },
                      () => {
                        gsLog.debug("In MonthlySavingsServices: getInvoiceByRefId(): DONE with processing");
                      }
                    );
                }
                else {
                  dispatch({
                    type: VIEW_LATEST_MONTLY_SAVINGS,
                    payload: {
                      projectUUID: NO_REFID,
                      mainChartData: [],
                      InvoiceMiscData: []
                    }
                  });
                }
              }
            }
          },
          (error) => {
            gsLog.error("In MonthlySavingsServices: getLatestTransaction(): (2) error in calling schedules API = ");
            gsLog.debug(error);
            toasterService.error(`error in calling schedules API = ${error.statusText}`);
          },
          () => {
            gsLog.debug("In MonthlySavingsServices: getLatestTransaction(): DONE with processing");
          }
        );
    };
  };

  const getLatestMonthlySavingsUtilityInfo = (serviceUUID) => {
    gsLog.debug(`In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo() 000 :`);
    gsLog.debug(serviceUUID);

    return (dispatch, getState) => {
      gsLog.debug(`In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo() 444 :`);
      gsLog.debug(`In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo(): call API with vpp ID (${serviceUUID})`);

      rx.Observable
        .fromPromise(proposalsApi.getUtilityInfo(serviceUUID))
        .catch((error) => {
          gsLog.error("In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo(): error in calling schedules API = ");
          gsLog.debug(error);
          toasterService.error(`error in calling schedules API = ${error.statusText}`);
        })
        .subscribe(
          (response) => {
            gsLog.debug("In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo(): response from API = ");
            gsLog.debug(response);

            if (!response.apiError) {
              if (!_.isUndefined(response.data) && !_.isNull(response.data)) {
                let utilityName = response.data.utilityName;

                dispatch({
                  type: VIEW_UITILITY_NAME,
                  payload: {
                    utilityName: utilityName
                  }
                });
              }
            }
          },
          (error) => {
            gsLog.error("In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo(): (2) error in calling schedules API = ");
            gsLog.debug(error);
            toasterService.error(`error in calling schedules API = ${error.statusText}`);
          },
          () => {
            gsLog.debug("In MonthlySavingsServices: getLatestMonthlySavingsUtilityInfo(): DONE with processing");
          }
        );
    };
  };

  return {
    viewLatestMonthlySavings,
    getLatestMonthlySavingsUtilityInfo
  };
};

export default MonthlySavingsServices;