import { default as gsLog } from '../service/loggingService';
import store from '../service/store';

export const UPDATE_WIZARD_CUSTOMER_SERVICES = "UPDATE_WIZARD_CUSTOMER_SERVICES";

export const PmtWizardCustomerServices = (pmtWizardServices, portfolioSelectorApi, gsAnalysisService, patApi, loggedInUserCheckInService) => {

  const ADD_NEW_CUSTOMER_ITEM = {
    customerUUID: null,
    customerName: "+ Add New Customer",
    customerCode: null,
    naics: null
  };

  const resetCustomerForm = () => {
    let { selectedStep } = store.getState().pmtWizardServices;

    if (selectedStep.metadata.createMode) {
      selectedStep.formData.customer = ADD_NEW_CUSTOMER_ITEM;
    }

    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        selectedStep
      }
    };
  };

  const createCustomer = () => {
    return (dispatch, getState) => {
      gsLog.debug(`In PmtWizardCustomerServices: createCustomer(): ...`);
      let { selectedStep } = getState().pmtWizardServices,
        { naicsList } = getState().pmtWizardCustomerServices;

      const customerParams = {
        customerCode: selectedStep.formData.customer.customerCode,
        customerName: selectedStep.formData.customer.customerName,
        naicsId: selectedStep.formData.customer.naics.naicsId
      };

      return patApi.createCustomer(customerParams)
        .then((results) => {
          gsLog.debug("In PmtWizardCustomerServices: createCustomer(): results from create customer API = ");
          gsLog.debug(results);

          selectedStep.metadata.created = true;
          selectedStep.metadata.createMode = false;

          selectedStep.formData.existingCustomer = results.data;
          selectedStep.formData.existingCustomer.naics = naicsList.find((item) => item.naicsId === results.data.naicsId);

          dispatch({
            type: UPDATE_WIZARD_CUSTOMER_SERVICES,
            payload: {
              createCustomerResults: results.data,
              selectedStep
            }
          });

          return results.data;
        })
        .catch((errors) => {
          gsLog.error(`In PmtWizardCustomerServices: createCustomer(): error in creating customer API = `);
          gsLog.error(errors);

          dispatch({
            type: UPDATE_WIZARD_CUSTOMER_SERVICES,
            payload: {
              createCustomerResults: null,
              selectedStep
            }
          });
        });
    }
  };

  const changeCustomer = (selectedCustomer) => {
    let { selectedStep } = store.getState().pmtWizardServices;
    const { naicsList } = store.getState().pmtWizardCustomerServices;
    const naics = naicsList.find((item) => item.naicsId === selectedCustomer.naicsId);
    gsLog.debug(`In PmtWizardCustomerServices: changeCustomer(): found matching NAICS ID (${selectedCustomer.naicsId}), = `);
    gsLog.debug(naics);

    selectedStep.formData.existingCustomer.naics = naics || null;

    return pmtWizardServices.updateSelectedStep(selectedStep);
  };

  const refreshCustomersList = (developerId) => {
    gsLog.debug(`In PmtWizardCustomerServices: refreshCustomersList(): developer ID (${developerId})`);

    return async (dispatch, getState) => {
      try {
        const customerResults = await portfolioSelectorApi.getCustomers('customerName', 10000, developerId);
        const naicsResults = await gsAnalysisService.searchNAICSByName("");

        //Restrict LocalStorage Customer
        customerResults.data.content = loggedInUserCheckInService.setExcludingCustomers(customerResults);

        gsLog.debug("In PmtWizardCustomerServices: refreshCustomersList(): results from customers API = ");
        gsLog.debug(customerResults);
        gsLog.debug("In PmtWizardCustomerServices: refreshCustomersList(): results from naics API = ");
        gsLog.debug(naicsResults);

        let customers = [],
          naicsList = [];
        if (customerResults.data) {
          customers = [...customerResults.data.content];
        }
        if (naicsResults && naicsResults.content) {
          naicsList = naicsResults.content.sort((a, b) => a.shortDescription > b.shortDescription ? 1 : -1);
        }

        dispatch({
          type: UPDATE_WIZARD_CUSTOMER_SERVICES,
          payload: {
            customers,
            naicsList
          }
        });
      } catch (errors) {
        gsLog.error(`In PmtWizardCustomerServices: refreshCustomersList(): errors in processing = `);
        gsLog.error(errors);
        dispatch({
          type: UPDATE_WIZARD_CUSTOMER_SERVICES,
          payload: {
            customers: [],
            naicsList: []
          }
        });
      }
    }
  };

  const resetCustomerList = () => {
    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        customers: [],
        naicsList: []
      }
    };
  };

  const setCreatingCustomer = () => {
    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        creatingCustomer: true
      }
    };
  };

  const unsetCreatingCustomer = () => {
    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        creatingCustomer: false
      }
    };
  };

  const setUserSelectedAcustomer = () => {
    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        userSelectedAcustomer: true
      }
    };
  };

  const unsetUserSelectedAcustomer = () => {
    return {
      type: UPDATE_WIZARD_CUSTOMER_SERVICES,
      payload: {
        userSelectedAcustomer: false
      }
    };
  };

  const initCustomerForm = () => {
    return async (dispatch, getState) => {
      const { canViewAnalysisFeatures } = store.getState().loggedInUserServices.permissions,
        { defaultTenantId } = store.getState().loggedInUserServices,
        { steps } = store.getState().pmtWizardServices;
      ;
      const tenantId = canViewAnalysisFeatures ? defaultTenantId : null;
      const selectedStep = steps && steps.find((item) => item.name === "customer");
      if (selectedStep) {
        gsLog.debug(`In PmtWizardCustomerServices: initCustomerForm(): tenantId (${tenantId}), selected step = `);
        gsLog.debug(selectedStep);
        selectedStep.metadata.canViewAnalysisFeatures = canViewAnalysisFeatures;
        if (!selectedStep.formData) {
          selectedStep.formData = {};
        }
        selectedStep.formData.developerId = tenantId;
      }
      dispatch(refreshCustomersList(tenantId));
    }
  };

  return {
    changeCustomer,
    createCustomer,
    initCustomerForm,
    refreshCustomersList,
    resetCustomerForm,
    resetCustomerList,
    setCreatingCustomer,
    setUserSelectedAcustomer,
    unsetCreatingCustomer,
    unsetUserSelectedAcustomer,
  }
}

export default PmtWizardCustomerServices;

// PmtWizardCustomerServices.$inject = [
//   "$ngRedux",
//   "gs.services.gsLogService",
//   "pmtWizardServices",
//   "portfolioSelectorApi",
//   "gsAnalysisService",
//   "patApi",
//   "loggedInUserCheckInService"
// ];
