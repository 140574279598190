import { getDefaultTimezone, msToTime } from '../utils/utils';
import _ from 'lodash';
import moment from 'moment';
import alasql from 'alasql';

export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const VIEW_SCHEDULES = "VIEW_SCHEDULES";
export const VIEW_PERFORMANCE_SCHEDULES = "VIEW_PERFORMANCE_SCHEDULES";
export const EXPORT_SCHEDULES = "EXPORT_SCHEDULES";

export const ScheduleServices = (store, gsLog, rx, vitalsScheduleApi, vitalsAuxMeasurerApi, toasterService, spinnerService, SCHEDULE_COMPONENT, SCHEDULE_COMPONENTS, SCHEDULE_OBJECT, SCHEDULE_OBJECTS, SCHEDULE_PROGRAMS, schedulePollingServices, timezoneServices) => {

  const DELETE_TYPE = {
    DELETE: 0,
    UPDATE: 1
  };

  const COMPONENTS = [
    {
      id: 1,
      name: "REAL (kW)"
    },
    {
      id: 2,
      name: "REACTIVE (kVAR)"
    },
    {
      id: 3,
      name: "PHI (dg)"
    }
  ];

  const INITIAL_ACK_STATUS = "Querying";

  const ACK_STATUS = [
    {
      ackStatus: "UNKNOWN",
      ackStatusId: 0,
      status: "Error"
    },
    {
      ackStatus: "SCHEDULED_RECEIVED",
      ackStatusId: 1,
      status: "Accepted"
    },
    {
      ackStatus: "CANCELED_PENDING",
      ackStatusId: 2,
      status: "Pending Cancel"
    },
    {
      ackStatus: "SCHEDULED_PENDING",
      ackStatusId: 3,
      status: "Pending Acceptance"
    },
    {
      ackStatus: "CANCELED_RECEIVED",
      ackStatusId: 4,
      status: "Cancelled"
    },
    {
      ackStatus: "INTERRUPTED_RECEIVED",
      ackStatusId: 5,
      status: "Interrupted"
    },
    {
      ackStatus: "INTERRUPTED_PENDING",
      ackStatusId: 6,
      status: "Pending Cancel"
    }
  ];

  function getValueFromLevelkVar(levelkW, levelkVar) {
    return !_.isUndefined(levelkW) && !_.isNull(levelkW) ? { value: levelkW, component: this.SCHEDULE_COMPONENT.REAL } : { value: levelkVar, component: this.SCHEDULE_COMPONENT.REACTIVE };
  }

  const updateEventInRepeatedSeries = (dispatch, metadata, deleteType) => {
    gsLog.debug(`In ScheduleServices: updateEventInRepeatedSeries(): delete type = ${deleteType}, metadata = `);
    gsLog.debug(metadata);

    let addlData = Object.assign(metadata, { scheduleExceptionType: deleteType, firstExceptionTime: metadata.repetitionInstanceStartTime }, getValueFromLevelkVar(metadata.levelkW, metadata.levelkVar));
    gsLog.debug(`In ScheduleServices: updateEventInRepeatedSeries(): data to update = `);
    gsLog.debug(addlData);

    //
    rx.Observable
      .fromPromise(vitalsScheduleApi.updateEventInRepeatedSeries(addlData))
      .catch((error) => {
        gsLog.error("In ScheduleServices: updateEventInRepeatedSeries(): error from update event API = ");
        gsLog.debug(error);
        return rx.Observable.just({ apiError: true });
      })
      .subscribe(
        (results) => {
          gsLog.debug("In ScheduleServices: updateEventInRepeatedSeries(): results from updating event = ");
          gsLog.debug(results);
          if (results.apiError) {
            toasterService.error("Error in updating event");
            return dispatch({
              type: CREATE_SCHEDULE,
              payload: {
                results: {},
                errors: results.apiError
              }
            });
          } else {
            toasterService.info("Event was updated successfully");
            return dispatch({
              type: CREATE_SCHEDULE,
              payload: {
                results,
                errors: {}
              }
            });
          }
        },
        (error) => {
          gsLog.error("In ScheduleServices: updateEventInRepeatedSeries(): (error) in updating event = ");
          gsLog.debug(error);
          return dispatch({
            type: CREATE_SCHEDULE,
            payload: {
              results: {},
              errors: error
            }
          });
        },
        () => {
          gsLog.debug("In ScheduleServices: updateEventInRepeatedSeries(): updating event: DONE");
        }
      );
  };

  const createUpdateSchedule = (dispatch, httpMethod, formData) => {
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): HTTP METHOD = ${httpMethod}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): manualInstrScheduleId = ${formData.manualInstrScheduleId}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): periodStartTime = ${formData.periodStartTime}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): periodDuration = ${formData.periodDuration}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): granularity = ${formData.granularity}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): daysOfWeekMask = ${formData.daysOfWeekMask}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): startTime = ${formData.startTime}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): untilDate = ${formData.untilDate}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): scheduleRepetitionType = ${formData.scheduleRepetitionType}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): scheduleType = ${formData.scheduleType}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): program = ${formData.program}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): levelkW = ${formData.levelkW}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): levelkVar = ${formData.levelkVar}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): leveldg = ${formData.leveldg}`);
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): vppList = `);
    gsLog.debug(formData.vppList);

    let levelkWMode = !_.isUndefined(formData.levelkW) && !_.isNull(formData.levelkW);
    let levelkVARMode = !_.isUndefined(formData.levelkVar) && !_.isNull(formData.levelkVar);
    let leveldgMode = !_.isUndefined(formData.leveldg) && !_.isNull(formData.leveldg);

    // TODO - check scheduleType
    const scheduleType = SCHEDULE_OBJECTS.find((item) => item.id === +formData.scheduleType);

    //
    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): levelkW mode? ${levelkWMode}, level kVAR mode? ${levelkVARMode}`);

    spinnerService.spin("spinner");

    //
    let observables = [],
      operator = "<=";

    gsLog.debug(`In ScheduleServices: createUpdateSchedule(): scheduleType = `);
    gsLog.debug(scheduleType);

    if (levelkWMode) {
      if (scheduleType.name === "SYSTEM") {
        if (formData.levelkW >= 0) {
          operator = ">=";
        }
      }
      gsLog.debug(`In ScheduleServices: createUpdateSchedule(): levelkWMode - operator (${operator})`);
      observables.push(vitalsScheduleApi.createUpdateSchedule(httpMethod, Object.assign(formData, { value: formData.levelkW, operator: operator, component: SCHEDULE_COMPONENT.REAL }))
        .catch((error) => {
          gsLog.debug("In ScheduleServices: createUpdateSchedule(): error from create schedule API: level kW = ");
          gsLog.error(error);
          toasterService.error(`Error in ${httpMethod === "PUT" ? "updating" : "creating"} schedule for kw`);
          return rx.Observable.just({ levelkWError: true });
        })
      );
    }

    if (levelkVARMode) {
      if (scheduleType.name === "SYSTEM") {
        if (formData.levelkVar >= 0) {
          operator = ">=";
        }
      }
      gsLog.debug(`In ScheduleServices: createUpdateSchedule(): levelkVARMode - operator (${operator})`);
      observables.push(vitalsScheduleApi.createUpdateSchedule(httpMethod, Object.assign(formData, { value: formData.levelkVar, operator: operator, component: SCHEDULE_COMPONENT.REACTIVE }))
        .catch((error) => {
          gsLog.debug("In ScheduleServices: createUpdateSchedule(): error from create schedule API: level kVAR = ");
          gsLog.error(error);
          toasterService.error(`Error in ${httpMethod === "PUT" ? "updating" : "creating"} schedule for kvar`);
          return rx.Observable.just({ levelkVARError: true });
        })
      );
    }

    if (leveldgMode) {
      if (scheduleType.name === "SYSTEM") {
        if (formData.leveldg >= 0) {
          operator = ">=";
        }
      }
      gsLog.debug(`In ScheduleServices: createUpdateSchedule(): leveldgMode - operator (${operator})`);
      observables.push(vitalsScheduleApi.createUpdateSchedule(httpMethod, Object.assign(formData, { value: formData.leveldg, operator: operator, component: SCHEDULE_COMPONENT.PHI }))
        .catch((error) => {
          gsLog.debug("In ScheduleServices: createUpdateSchedule(): error from create schedule API: level dg = ");
          gsLog.error(error);
          toasterService.error(`Error in ${httpMethod === "PUT" ? "updating" : "creating"} schedule for dg`);
          return rx.Observable.just({ leveldgError: true });
        })
      );
    }

    rx.Observable
      .forkJoin(observables)
      .subscribe(
        ([schedule1Result, schedule2Result]) => {
          gsLog.debug("In ScheduleServices: createUpdateSchedule(): response from create schedule APIs: result 1 = ");
          gsLog.debug(schedule1Result);
          if (schedule1Result && schedule1Result.data) {
            const program = SCHEDULE_PROGRAMS.find((item) => schedule1Result.data.program === item.id);
            const component = COMPONENTS.find((item) => schedule1Result.data.component === item.id);
            toasterService.info(`Was successful in ${httpMethod === "PUT" ? "updating" : "creating"} program ${program.name} with component ${component.name}`);
            return dispatch({
              type: CREATE_SCHEDULE,
              payload: {
                results: schedule1Result.data
              }
            });
          }

          gsLog.debug("In ScheduleServices: createUpdateSchedule(): response from create schedule APIs: result 2 = ");
          gsLog.debug(schedule2Result);
          if (schedule2Result && schedule2Result.data) {
            const program = SCHEDULE_PROGRAMS.find((item) => schedule2Result.data.program === item.id);
            const component = COMPONENTS.find((item) => schedule2Result.data.component === item.id);
            toasterService.info(`Was successful in ${httpMethod === "PUT" ? "updating" : "creating"} program ${program.name} with component ${component.name}`);
          }
        },
        (error) => {
          gsLog.debug(`In ScheduleServices: createUpdateSchedule(): error from ${httpMethod === "PUT" ? "updating" : "creating"} schedule API = `);
          gsLog.error(error);
          spinnerService.stop("spinner");
          return dispatch({
            type: CREATE_SCHEDULE,
            payload: {
              results: {},
              errors: error
            }
          });
        },
        () => {
          gsLog.debug(`In ScheduleServices: createUpdateSchedule(): ${httpMethod === "PUT" ? "updating" : "creating"} schedules is DONE`);
          spinnerService.stop("spinner");
        }
      );
  };

  const createScheduleUsingFormData = ({ createMode, byEvent, formData }) => {
    return (dispatch, getState) => {
      gsLog.debug(`In ScheduleServices: createScheduleUsingFormData(): createMode? ${createMode}, byEvent? ${byEvent}, form data = `);
      gsLog.debug(formData);

      if (createMode) {
        // create event
        createUpdateSchedule(dispatch, "POST", Object.assign(formData));
      } else {
        // update mode
        const hasSeries = hasSeries(formData);
        gsLog.debug(`In ScheduleServices: createScheduleUsingFormData(): has series? (${hasSeries})`);
        if (!hasSeries) {
          // update event
          createUpdateSchedule(dispatch, "PUT", Object.assign(formData, { manualInstrScheduleId: formData.manualInstrScheduleId }));
        } else {
          // update series: by event or series
          gsLog.debug(`In ScheduleServices: createScheduleUsingFormData(): do update: by event? (${byEvent}) ...`);
          if (byEvent) {
            gsLog.debug(`In ScheduleServices: createScheduleUsingFormData(): metadata = `);
            gsLog.debug(formData);
            updateEventInRepeatedSeries(dispatch, Object.assign(formData, { manualInstrScheduleId: formData.manualInstrScheduleId, repetitionInstanceStartTime: formData.repetitionInstanceStartTime }), DELETE_TYPE.UPDATE);
          } else {
            createUpdateSchedule(dispatch, "PUT", Object.assign(formData, { manualInstrScheduleId: formData.manualInstrScheduleId }));
          }
        }
      }
    };
  };

  const createSchedule = ({ value, startTime, endTime, overrideAutoCurtailments, component = SCHEDULE_COMPONENT.REAL, program = 1, operator = ">=",
    scheduleType = SCHEDULE_OBJECT.SYSTEM, scheduleRepetitionType = 0, granularity = 15 }) => {

    return async (dispatch, getState) => {

      const { vppId } = getState().vppSelectorServices.selectedVpp,
        { timeSelectorOptions } = getState().scheduleServices;

      const params = {
        value,
        operator,
        component,
        vppList: [
          {
            manualInstrScheduleId: null,
            vppId
          }
        ],
        program,
        priority: overrideAutoCurtailments ? 1 : null,
        scheduleType,
        scheduleRepetitionType,
        startTime: startTime.valueOf(),
        untilDate: endTime.valueOf(),
        granularity,
        periodDuration: endTime.diff(startTime, "minutes"),  // minutes
        periodStartTime: startTime.format("HH:mm"), // HH:mm
      };
      gsLog.debug(`In ScheduleServices: createSchedule(): input params = `);
      gsLog.debug(params);

      try {
        const results = await vitalsScheduleApi.createUpdateSchedule("POST", params);
        gsLog.debug(`In ScheduleServices: createSchedule(): results from create schedule API = `);
        gsLog.debug(results);

        if (results) {
          toasterService.info("Was successful in creating schedule");
        }
        dispatch({
          type: CREATE_SCHEDULE,
          payload: {
            results: results.data
          }
        });
        dispatch(viewSchedules(vppId, startTime, endTime, timeSelectorOptions));
      } catch (errors) {
        gsLog.debug(`In ScheduleServices: createSchedule(): error calling create schedule API = `);
        gsLog.debug(errors);
        toasterService.error("An error occurred while trying to create the schedule");
        return dispatch({
          type: CREATE_SCHEDULE,
          payload: {
            results: {},
            errors
          }
        });
      }
    }
  };

  const createEmptyGraph = (startDate, endDate, data) => {
    if (data && data.length < 1) {
      toasterService.info("There is no data for the selected date range");
    }

    return data && data.length > 0 ? data : [
      [
        {
          name: "EMPTY",
          data: [
            {
              x: startDate.valueOf(),
              y: 0,
              metadata: {
                object: 2,
                component: 1
              }
            },
            {
              x: endDate.valueOf(),
              y: 0,
              metadata: {
                object: 2,
                component: 1
              }
            },
            {
              x: startDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 1
              }
            },
            {
              x: endDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 1
              }
            },
            {
              x: startDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 2
              }
            },
            {
              x: endDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 2
              }
            },
            {
              x: startDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 3
              }
            },
            {
              x: endDate.valueOf(),
              y: 0,
              metadata: {
                object: 1,
                component: 3
              }
            }
          ]
        }
      ]
    ];
  };

  const fillGap = (startDate, endDate, selectedMiApps, data) => {
    let dataWithGap = [...data];

    gsLog.debug("In ScheduleServices: fillGap(): before parse = ");
    gsLog.debug(data);

    for (let i = 0; i <= dataWithGap.length - 1; i++) {
      for (let j = 0; j <= dataWithGap[i].length - 1; j++) {
        if (dataWithGap[i][j].data.length > 0) {

          let [first, ...bottom] = dataWithGap[i][j].data;
          let last = bottom[bottom.length - 1];

          gsLog.debug(`In ScheduleServices: fillGap(): for object ID (${dataWithGap[i][j].objectId}),
            component ID (${dataWithGap[i][j].componentId}), name (${dataWithGap[i][j].name})
            `);
          gsLog.debug("In ScheduleServices: fillGap(): first = ");
          gsLog.debug(first);
          gsLog.debug("In Vita!pslsScheduleContainerComponentCtrl: fillGap(): last = ");
          gsLog.debug(last);
          gsLog.debug("In ScheduleServices: fillGap(): bottom = ");
          gsLog.debug(bottom);

          if (first && first[0].x > startDate.valueOf()) {
            gsLog.debug("In ScheduleServices: fillGap(): add 1 element to beginning of array!");
            dataWithGap[i][j].data.unshift(
              [
                {
                  x: startDate.valueOf(),
                  y: null,
                  metadata: first[0].metadata
                }
              ]
            );
          }

          if (last && last[0].x < endDate.valueOf()) {
            gsLog.debug("In ScheduleServices: fillGap(): add 1 element to end of array!");
            dataWithGap[i][j].data.push(
              [
                {
                  x: endDate.valueOf(),
                  y: null,
                  metadata: last[0].metadata
                }
              ]
            );
          } else if (_.isUndefined(last)) {
            gsLog.debug("In ScheduleServices: fillGap(): last is undefined: add 1 element to end of array!");
            dataWithGap[i][j].data.push(
              [
                {
                  x: endDate.valueOf(),
                  y: null,
                  metadata: { noData: true, object: dataWithGap[i][j].objectId, component: dataWithGap[i][j].componentId, program: selectedMiApps && selectedMiApps.length > 0 ? +selectedMiApps[0].sid : 0 }
                }
              ]
            );
          }
        } else {
          // TODO hard-coding program for now
          dataWithGap[i][j].noData = true;
          dataWithGap[i][j].data.push(
            [
              {
                x: startDate.valueOf(),
                y: 10,
                metadata: { noData: true, object: dataWithGap[i][j].objectId, component: dataWithGap[i][j].componentId, program: selectedMiApps && selectedMiApps.length > 0 ? +selectedMiApps[0].sid : 0 }
              }
            ]
          );
          dataWithGap[i][j].data.push(
            [
              {
                x: endDate.valueOf(),
                y: 0,
                metadata: { noData: true, object: dataWithGap[i][j].objectId, component: dataWithGap[i][j].componentId, program: selectedMiApps && selectedMiApps.length > 0 ? +selectedMiApps[0].sid : 0 }
              }
            ]
          );
        }
      }
    }

    return dataWithGap;
  };

  const addDuration = (data) => {
    return data.map((item) => {
      gsLog.debug("In ScheduleServices: addDuration(): for charting = ");
      gsLog.debug(item);
      let forChart = item.map((row) => {
        let data = row.data.map((point) => {
          return [
            {
              x: point.x,
              y: point.y,
              metadata: point.metadata
            },
            {
              // x: point.x + (point.metadata ? point.metadata.periodDuration * 60000 : 0),
              x: (point.metadata ? point.metadata.end : point.x),
              y: point.y,
              metadata: point.metadata
            }
          ]
        });
        return {
          name: row.name,
          objectId: row.objectId,
          componentId: row.componentId,
          data: data
        };
      });
      return forChart;
    });
  };

  const filterEmptyData = (data) => {
    gsLog.debug(`In ScheduleServices: filterEmptyData(): data = `);
    gsLog.debug(data);

    return data.filter((item) => {
      let numEmpty = 0;
      item.map((row) => {
        if (row.data.length < 1) {
          numEmpty += 1;;
        }
      });

      if (numEmpty === item.length) {
        return false;
      }
      return true;
    });
  };

  const parseFilterResponse = (startDate, endDate, data) => {
    gsLog.debug(`In ScheduleServices: parseFilterResponse(): start date = ${startDate.format("MMM DD, YYYY HH:mm:ss")}, end date = ${endDate.format("MMM DD, YYYY HH:mm:ss")}`);
    gsLog.debug(data);

    return SCHEDULE_OBJECTS.map((scheduleObject) => {
      let components = COMPONENTS.map((component) => {
        let graphData = data
          .filter((item) => item.priority > 0)
          .filter((item) => item.object === scheduleObject.id)
          .filter((item) => item.component === component.id)
          .filter((item) => (item.start >= startDate.valueOf() && item.start <= endDate.valueOf()))
          .sort((a, b) => a.start > b.start ? 1 : -1)
          .map((item) => {
            return {
              x: item.start,
              y: item.value,
              metadata: item
            };
          });

        return {
          name: `${scheduleObject.name} - ${component.name}`,
          objectId: scheduleObject.id,
          componentId: component.id,
          data: graphData
        };

      });

      return components;
    });
  };

  const refreshGraph = (startDate, endDate, selectedMiApps, data) => {
    return _.flow(
      parseFilterResponse.bind(this, startDate, endDate),
      filterEmptyData.bind(this),
      addDuration.bind(this),
      fillGap.bind(this, startDate, endDate, selectedMiApps),
      createEmptyGraph.bind(this, startDate, endDate),
      // displayGraph.bind(this, startDate, endDate)
    )(data);
  };

  const viewScheduleCount = (vppId, programId) => {
    return async (dispatch, getState) => {
      try {
        const clientId = "ui";

        // current day
        const todayStart = moment().startOf("day");
        const todayEnd = moment().endOf("day");

        // current month Apr 1 to 30
        const currMonthStart = moment().startOf("month");
        const currMonthEnd = moment().endOf("month");

        // YTD Jan 2018 to Apr 19
        const currYearStart = moment().startOf("year");
        const now = moment();

        const [dayResponse, monthResponse, ytdResponse] = await Promise.all(
          [
            vitalsScheduleApi.getGraph(vppId, todayStart.valueOf(), todayEnd.valueOf(), clientId, programId),
            vitalsScheduleApi.getGraph(vppId, currMonthStart.valueOf(), currMonthEnd.valueOf(), clientId, programId),
            vitalsScheduleApi.getGraph(vppId, currYearStart.valueOf(), now.valueOf(), clientId, programId)
          ]
        );
        gsLog.debug("In ScheduleServices: viewScheduleCount(): today's response from API = ");
        gsLog.debug(dayResponse);
        const miScheduleCountByDay = dayResponse && dayResponse.data && dayResponse.data.pageResponse ? dayResponse.data.pageResponse.totalElements : null;
        gsLog.debug("In ScheduleServices: viewScheduleCount(): current month response from API = ");
        gsLog.debug(monthResponse);
        const miScheduleCountByMonth = monthResponse && monthResponse.data && monthResponse.data.pageResponse ? monthResponse.data.pageResponse.totalElements : null;
        gsLog.debug("In ScheduleServices: viewScheduleCount(): YTD response from API = ");
        gsLog.debug(ytdResponse);
        const miScheduleCountByYTD = ytdResponse && ytdResponse.data && ytdResponse.data.pageResponse ? ytdResponse.data.pageResponse.totalElements : null;

        dispatch({
          type: VIEW_SCHEDULES,
          payload: {
            miScheduleCountByDay,
            miScheduleCountByMonth,
            miScheduleCountByYTD
          }
        });
      } catch (errors) {
        dispatch({
          type: VIEW_SCHEDULES,
          payload: {
            miScheduleCountByDay: null,
            miScheduleCountByMonth: null,
            miScheduleCountByYTD: null
          }
        });
      }
    }
  };

  const viewSchedules = (vppId, startDate, endDate, timeSelectorOptions, returnCanceledMis = false, returnDeletedMis = false) => {
    return async (dispatch, getState) => {
      const { selectedMi } = getState().cachedObjectServices;
      const { timezones } = getState().timezoneServices,
        { selectedVpp } = getState().vppSelectorServices;
      const { selectedTimezone, timezones: updatedTimezones } = getDefaultTimezone(timezones, selectedVpp.timezone);
      gsLog.debug(`In ScheduleServices: viewSchedules(): selected timezone for vpp timezone (${selectedVpp.timezone}) = `);
      gsLog.debug(selectedTimezone);
      gsLog.debug(`In ScheduleServices: viewSchedules(): updated timezones = `);
      gsLog.debug(updatedTimezones);
      dispatch(timezoneServices.updateTimezones(updatedTimezones));

      if (selectedMi.length < 1) {
        gsLog.debug(`In ScheduleServices: viewSchedules(): this VPP ID (${vppId}) has no applications`);

        dispatch({
          type: VIEW_SCHEDULES,
          payload: {
            scheduleData: [],
            selectedTimezone,
            viewSchedulesChartData: createEmptyGraph(startDate, endDate),
            startDate,
            endDate,
            timeSelectorOptions
          }
        });
      } else {
        gsLog.debug(`In ScheduleServices: viewSchedules(): call API with vpp ID (${vppId}), start date = ${startDate.format('MMM DD, YYYY HH:mm:ss')}, end date = ${endDate.format('MMM DD, YYYY HH:mm:ss')}`);

        try {
          dispatch({
            type: VIEW_SCHEDULES,
            payload: {
              isLoading: true
            }
          });
          const response = await vitalsScheduleApi.getGraph(vppId, startDate.valueOf(), endDate.valueOf(), "ui", null, 1000, returnCanceledMis, returnDeletedMis);
          gsLog.debug("In ScheduleServices: viewSchedules(): response from API = ");
          gsLog.debug(response);

          const { selectedMiApps } = getState().cachedObjectServices.selectedMi;

          let viewSchedulesChartData = response.data.vppEventDataResponse.map((item) => {
            item.status = INITIAL_ACK_STATUS;
            item = Object.assign(item, applyTimezone(selectedTimezone, item));
            return item;
          });
          gsLog.debug(`In ScheduleServices: viewSchedules(): grid schedule data without miAckStatus: = `);
          gsLog.debug(viewSchedulesChartData);

          const modScheduleData = refreshGraph(startDate, endDate, selectedMiApps, response.data.vppEventDataResponse);
          gsLog.debug("In ScheduleServices: viewSchedules(): schedule data with gaps = ");
          gsLog.debug(modScheduleData);

          const pollingData = viewSchedulesChartData.filter((item) => item.status === INITIAL_ACK_STATUS);
          if (pollingData.length > 0) {
            gsLog.debug(`In ScheduleServices: viewSchedules(): start polling with ${pollingData.length} items ...`);
            schedulePollingServices.startPolling(pollingData);
          }

          dispatch({
            type: VIEW_SCHEDULES,
            payload: {
              isLoading: false,
              pollingData,
              selectedTimezone,
              scheduleData: modScheduleData,
              viewSchedulesChartData,
              startDate,
              endDate,
              timeSelectorOptions
            }
          });
        } catch (errors) {
          gsLog.error("In ScheduleServices: viewSchedules(): error in calling schedules API = ");
          gsLog.debug(errors);
          toasterService.error(`error in calling schedules API = ${errors.statusText}`);
          dispatch({
            type: VIEW_SCHEDULES,
            payload: {
              isLoading: false,
              scheduleData: [],
              selectedTimezone,
              viewSchedulesChartData: createEmptyGraph(startDate, endDate),
              startDate,
              endDate,
              timeSelectorOptions
            }
          });
        }
      }
    };
  };

  const parseAuxMeasurer = (response) => {
    let result = {};
    if (!_.isUndefined(response) && _.isArray(response.content)) {
      gsLog.debug("In ScheduleServices: parseAuxMeasurer(): 111");
      for (let i = 0; i < response.content.length; i++) {
        gsLog.debug("In ScheduleServices: parseAuxMeasurer(): 222");
        //assume the response is sorted in desc order
        if (!result.hasOwnProperty(response.content[i].vppEventId)) {
          gsLog.debug("In ScheduleServices: parseAuxMeasurer(): 333");
          result[response.content[i].vppEventId] = response.content[i].result.avg;
        }
      }
    }

    gsLog.debug("In ScheduleServices: parseAuxMeasurer(): reulst = ");
    gsLog.debug(result);
    return result;
  };

  const viewPerformanceSchedules = (vppId, startDate, endDate, timeSelectorOptions) => {
    return async (dispatch, getState) => {
      const { selectedMi } = getState().cachedObjectServices;
      if (selectedMi.length < 1) {
        gsLog.debug(`In ScheduleServices: viewPerformanceSchedules(): this VPP ID (${vppId}) has no applications`);

        dispatch({
          type: VIEW_PERFORMANCE_SCHEDULES,
          payload: {
            scheduleData: [],
            viewPerformanceSchedulesChartData: createEmptyGraph(startDate, endDate),
            auxMeasurerMap: null,
            startDate,
            endDate,
            timeSelectorOptions
          }
        });
      } else {
        gsLog.debug(`In ScheduleServices: viewPerformanceSchedules(): call API with vpp ID (${vppId}), start date = ${startDate.format('MMM DD, YYYY HH:mm:ss')}, end date = ${endDate.format('MMM DD, YYYY HH:mm:ss')}`);

        try {
          dispatch({
            type: VIEW_PERFORMANCE_SCHEDULES,
            payload: {
              isLoading: true
            }
          });
          const response = await vitalsScheduleApi.getPerformanceGraph(vppId, startDate.valueOf(), endDate.valueOf());
          gsLog.debug("In ScheduleServices: viewPerformanceSchedules(): response from API = ");
          gsLog.debug(response);

          const { selectedMiApps } = getState().cachedObjectServices.selectedMi;
          let viewPerformanceSchedulesChartData = [];
          let program = [];
          try {
            viewPerformanceSchedulesChartData = response.data.vppEventDataResponse;
            gsLog.debug(`In ScheduleServices: viewPerformanceSchedules(): grid schedule data with status: = `);
            gsLog.debug(viewPerformanceSchedulesChartData);

            if (!_.isUndefined(viewPerformanceSchedulesChartData) && _.isArray(viewPerformanceSchedulesChartData) && viewPerformanceSchedulesChartData.length > 0) {
              viewPerformanceSchedulesChartData.forEach(element => {
                if (program.indexOf(element.program) === -1) {
                  program.push(element.program);
                }
              });
            }
            else {
              gsLog.error("In ScheduleServices: viewPerformanceSchedules(): missing programin ID ");
            }

          } catch (errors) {
            gsLog.error("In ScheduleServices: viewPerformanceSchedules(): error in calling miAckStatus API = ");
            gsLog.debug(errors);
          }

          const modScheduleData = refreshGraph(startDate, endDate, selectedMiApps, response.data.vppEventDataResponse);
          gsLog.debug("In ScheduleServices: viewPerformanceSchedules(): schedule data with gaps = ");
          gsLog.debug(modScheduleData);

          let auxMeasurerMap = {};
          program.forEach(async (element) => {
            const auxMeasurerResponse = await vitalsAuxMeasurerApi.getAuxMeasurerByVpp(vppId, moment(startDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
              moment(endDate).format("YYYY-MM-DDTHH:mm:ss") + "Z", element);

            auxMeasurerMap = Object.assign(auxMeasurerMap, parseAuxMeasurer(auxMeasurerResponse.data));
          });

          dispatch({
            type: VIEW_PERFORMANCE_SCHEDULES,
            payload: {
              auxMeasurerMap,
              isLoading: false,
              scheduleData: modScheduleData,
              viewPerformanceSchedulesChartData,
              startDate,
              endDate,
              timeSelectorOptions
            }
          });
        } catch (errors) {
          gsLog.error("In ScheduleServices: viewPerformanceSchedules(): error in calling schedules API = ");
          gsLog.debug(errors);
          toasterService.error(`error in calling schedules API = ${errors.statusText}`);
          dispatch({
            type: VIEW_PERFORMANCE_SCHEDULES,
            payload: {
              isLoading: false,
              scheduleData: [],
              viewPerformanceSchedulesChartData: createEmptyGraph(startDate, endDate),
              auxMeasurerMap: null,
              startDate,
              endDate,
              timeSelectorOptions
            }
          });
        }
      }
    };
  };

  const applyTimezone = (selectedTimezone, item) => {
    if (selectedTimezone) {
      if (selectedTimezone.zone === "UTC") {
        item.start = moment(item.start).utc();
        item.start = moment(item.start).utc();
        item.end = moment(item.end).utc();
        item.createdTimestamp = moment(item.createdTimestamp).utc();
        item.miCreatedTimestamp = moment(item.miCreatedTimestamp).utc();
        item.miLastModifiedTimestamp = moment(item.miLastModifiedTimestamp).utc();
      } else {
        item.start = moment(item.start).tz(selectedTimezone.name);
        item.start = moment(item.start).tz(selectedTimezone.name);
        item.end = moment(item.end).tz(selectedTimezone.name);
        item.createdTimestamp = moment(item.createdTimestamp).tz(selectedTimezone.name);
        item.miCreatedTimestamp = moment(item.miCreatedTimestamp).tz(selectedTimezone.name);
        item.miLastModifiedTimestamp = moment(item.miLastModifiedTimestamp).tz(selectedTimezone.name);
      }
    }

    return item;
  };

  const changeTimezone = (timezone) => {
    gsLog.debug("In ScheduleServices: changeTimezone(): selected timezone = ");
    gsLog.debug(timezone);

    let { viewSchedulesChartData } = store.getState().scheduleServices;

    viewSchedulesChartData = viewSchedulesChartData.map((item) => {
      item = Object.assign(item, applyTimezone(timezone, item));
      return item;
    });

    return {
      type: VIEW_SCHEDULES,
      payload: {
        selectedTimezone: timezone,
        viewSchedulesChartData
      }
    };
  };

  const refreshSchedules = (miEvent) => {
    const { selectedVpp } = store.getState().cachedObjectServices;
    let { viewSchedulesChartData } = store.getState().scheduleServices;

    gsLog.debug(`In ScheduleServices: refreshSchedules(): vpp ID (${selectedVpp.vppId}), MI event = `);
    gsLog.debug(miEvent);

    if (miEvent) {
      viewSchedulesChartData = viewSchedulesChartData.map((item_original) => {
        let item = Object.assign({}, item_original);

        if (item.vppEventId === miEvent.vppEventId) {
          const selectedStatus = ACK_STATUS.find((ackStatusItem) => ackStatusItem.ackStatus === miEvent.ackStatus);
          item.status = selectedStatus ? selectedStatus.status : 'No Mapping';
        }

        return item;
      });
    }

    return {
      type: VIEW_SCHEDULES,
      payload: {
        viewSchedulesChartData
      }
    };
  };

  const exportSchedulesPerformance = () => {
    const { auxMeasurerMap, viewPerformanceSchedulesChartData } = store.getState().scheduleServices,
      { selectedVpp } = store.getState().cachedObjectServices,
      { selectedMi } = store.getState().cachedObjectServices;
    gsLog.debug(`In ScheduleServices: exportSchedulesPerformance(): selected VPP ID (${selectedVpp.vppId})`);

    if (viewPerformanceSchedulesChartData) {
      if (viewPerformanceSchedulesChartData.length < 1) {
        toasterService.error("There are no schedules to export");

        return {
          type: "EXPORT_SCHEDULES",
          payload: {
            doExport: false
          }
        }
      } else {
        const forExcel = viewPerformanceSchedulesChartData.map((item) => {
          const selectedProgram = selectedMi.find((mi) => +mi.sid === +item.program);
          const { component, createdUserId, description, granularity, lastModifiedUserId, manualInstrScheduleId, miCreatedTimestamp, miLastModifiedTimestamp, priority, start, vppEventId, value } = item;
          const selectedComponent = SCHEDULE_COMPONENTS[component];
          return {
            "Event ID": manualInstrScheduleId,
            "Start Time": moment(start).format('dddd MMM D, YYYY HH:mm'),
            "Duration": msToTime(granularity),
            "Output (Requested)": value,
            "Output (Delivered)": auxMeasurerMap && auxMeasurerMap.hasOwnProperty(vppEventId) ? (auxMeasurerMap[vppEventId] / 1000).toFixed(1) : "N/A",
            "Units": selectedComponent ? selectedComponent.name : "",
            "Priority": priority,
            "Program": selectedProgram ? selectedProgram.sname : "",
            "Notes": description,
            "Creation Time": moment(miCreatedTimestamp).format('dddd MMM D, YYYY HH:mm'),
            "Creation User ID": !_.isNull(createdUserId) ? createdUserId : "",
            "Last Modified": moment(miLastModifiedTimestamp).format("dddd MMM DD, YYYY HH:mm"),
            "Last Modified User ID": lastModifiedUserId
          }
        });
        //
        const { vppId, vppName } = selectedVpp;
        const exportFilename = `${vppId}_${vppName}.csv`;
        gsLog.debug(`In ScheduleServices: exportSchedulesPerformance(): file name = ${exportFilename}`);
        alasql('SELECT * INTO CSV("' + exportFilename + '", {sheetid:"load", headers:true, separator:","}) FROM ?', [forExcel]);

        return {
          type: "EXPORT_SCHEDULES",
          payload: {
            doExport: true
          }
        };
      }
    } else {
      return {
        type: "EXPORT_SCHEDULES",
        payload: {
          doExport: false
        }
      }
    }
  }

  const exportSchedulesGrid = () => {
    const { viewSchedulesChartData } = store.getState().scheduleServices,
      { selectedVpp } = store.getState().cachedObjectServices,
      { selectedMi } = store.getState().cachedObjectServices;
    gsLog.debug(`In ScheduleServices: exportSchedulesGrid(): selected VPP ID (${selectedVpp.vppId})`);

    if (viewSchedulesChartData) {
      if (viewSchedulesChartData.length < 1) {
        toasterService.error("There are no schedules to export");

        return {
          type: "EXPORT_SCHEDULES",
          payload: {
            doExport: false
          }
        }
      } else {
        const forExcel = viewSchedulesChartData.map((item) => {
          const selectedProgram = selectedMi.find((mi) => +mi.sid === +item.program);
          const { component, createdUserId, description, granularity, lastModifiedUserId, manualInstrScheduleId, miCreatedTimestamp, miLastModifiedTimestamp, priority, start, status, value } = item;
          const selectedComponent = SCHEDULE_COMPONENTS[component];
          return {
            "Status": status,
            "Event ID": manualInstrScheduleId,
            "Start Time": moment(start).format('dddd MMM D, YYYY HH:mm'),
            "Duration": msToTime(granularity),
            "Output (Requested)": value,
            "Units": selectedComponent ? selectedComponent.name : "",
            "Priority": priority,
            "Program": selectedProgram ? selectedProgram.sname : "",
            "Notes": description,
            "Creation Time": moment(miCreatedTimestamp).format('dddd MMM D, YYYY HH:mm'),
            "Creation User ID": !_.isNull(createdUserId) ? createdUserId : "",
            "Last Modified": moment(miLastModifiedTimestamp).format("dddd MMM DD, YYYY HH:mm"),
            "Last Modified User ID": lastModifiedUserId
          }
        });
        //
        const { vppId, vppName } = selectedVpp;
        const exportFilename = `${vppId}_${vppName}.csv`;
        gsLog.debug(`In ScheduleServices: exportSchedulesGrid(): file name = ${exportFilename}`);
        alasql('SELECT * INTO CSV("' + exportFilename + '", {sheetid:"load", headers:true, separator:","}) FROM ?', [forExcel]);

        return {
          type: "EXPORT_SCHEDULES",
          payload: {
            doExport: true
          }
        };
      }
    } else {
      return {
        type: "EXPORT_SCHEDULES",
        payload: {
          doExport: false
        }
      }
    }
  }

  return {
    changeTimezone,
    createSchedule,
    createScheduleUsingFormData,
    exportSchedulesGrid,
    exportSchedulesPerformance,
    refreshSchedules,
    viewSchedules,
    viewPerformanceSchedules,
    viewScheduleCount
  };
};

export default ScheduleServices