import { VPP_LIST } from "./vppSelectorServices";
import LoggedInUserServices from "./loggedInUserServices";
import {default as gsLog} from '../service/loggingService';
import viewStateService from '../service/viewStateService';
import rootScopeService from "../service/rootScopeService";
import authorizationService from '../service/authorizationService';
import store from '../service/store';
import { bindActionCreators } from "redux";
import _ from 'underscore';

export const USER_OBJECTS = "USER_OBJECTS";
export const CUSTOMER_IDS = "customerIds";
export const HEADER_CUSTOMER_IDS = "GC-Customer-Ids";
export const HEADER_MI_IDS = "GC-MI-Ids";
export const HEADER_VPP_IDS = "GC-VPP-Ids";
export const HEADER_ETL_SPACE_IDS = "GC-ETL-Ids";
export const HEADER_OPTIMIZER_SPACE_IDS = "GC-Optimizer-Ids";
export const HEADER_PROJECT_IDS = "GC-Project-Ids";
export const SELECTED_ETL_SPACE_ID = "cachedObjectServices/selectedEtlSpaceId";
export const HEADER_USR_ROLES = "GC-Usr-Roles";
export const SELECTED_MI = "cachedObjectServices/selectedMi";
export const SELECTED_OPTIMIZER_SPACE_KEY = "cachedObjectServices/selectedOptimizerSpaceId";
export const SELECTED_VPP = "cachedObjectServices/selectedVpp";
export const HEADER_USR_IMPERSONATION_TARGETS = "GC-Usr-Impersonation-Targets";

export const CachedObjectServicesActions = () => {
  const colors = ["#fbc704", "#ee7600", "#55BF3B", "#85d5e0", "#e9967a", "#add8e6", "#3e525f"];
  let $rootScope = rootScopeService;
  let loggedInUserServices = LoggedInUserServices();
  let boundActionCreators = bindActionCreators(loggedInUserServices, store.dispatch);

  const setDefaultVpp = (dispatch, vpps, mis) => {
    let selectedVpp = viewStateService.getSelectedVpp();
    gsLog.debug("In CachedObjectServicesActions: setDefaultVpp(): selected VPP from local storage = ");
    gsLog.debug(selectedVpp);

    if (_.isNull(selectedVpp)) {
      if (vpps && vpps.length > 0) {
        selectedVpp = vpps[0];
        viewStateService.setSelectedVpp(vpps[0]);
      }
    } else {
      // Make sure cached selected vpp is in allow to access vpp list 
      if (vpps && vpps.length > 0) {
        let vpp = vpps.find(item => {
          return item.vppId === selectedVpp.vppId;
        })

        if(_.isUndefined(vpp) || _.isNull(vpp)) {
          selectedVpp = vpps[0];
          viewStateService.setSelectedVpp(vpps[0]);
          dispatch({
            type: VPP_LIST,
            payload: {
              selectedVpp: null,
              vppListResults: null,
              vppRevenueStreams: [],
              vppApps: [],
              aggregratedProjects: []
            }
          });
        }
      }
    }

    setDefaultMi(dispatch, selectedVpp, mis);
    dispatch({
      type: SELECTED_VPP,
      payload: {
        selectedVpp
      }
    })
  };

  const setDefaultMi = (dispatch, defaultVpp, mis) => {
    if (mis && mis.length > 0) {
      let colorsIdx = 0;
      const selectedMis = mis
        .filter((item) => item.pid === defaultVpp.vppId)
        .map((item, idx) => {
          colorsIdx = +item.sid < colors.length ? +item.sid : +item.sid % colors.length;
          return Object.assign({}, item, {on: true, color: colors[colorsIdx]});
        });
      gsLog.debug("In CachedObjectServicesActions: setDefaultMi(): selected MI IDs with addl props = ");
      gsLog.debug(selectedMis);
      dispatch(setSelectedMi(selectedMis));
      viewStateService.setSelectedMi(selectedMis);
    }
  };

  const setDefaultEtlSpaceId = (dispatch, etlSpaceIds) => {
    let selectedEtlSpaceId = viewStateService.getSelectedEtlSpaceId();
    gsLog.debug("In CachedObjectServicesActions: setDefaultEtlSpaceId(): selected ETL space ID from local storage = ");
    gsLog.debug(selectedEtlSpaceId);

    if (_.isNull(selectedEtlSpaceId)) {
      if (etlSpaceIds && etlSpaceIds.length > 0) {
        selectedEtlSpaceId = etlSpaceIds[0];
        viewStateService.setSelectedEtlSpaceId(selectedEtlSpaceId);
      }
    }

    dispatch({
      type: SELECTED_ETL_SPACE_ID,
      payload: {
        selectedEtlSpaceId
      }
    });
  };

  const setDefaultOptimizerSpaceId = (dispatch, optimizerSpaceIds) => {
    let selectedOptimizerSpaceId = viewStateService.getSelectedOptimizerSpaceId();
    gsLog.debug("In CachedObjectServicesActions: setDefaultOptimizerSpaceId(): selected Optimizer space ID from local storage = ");
    gsLog.debug(selectedOptimizerSpaceId);

    gsLog.debug("In CachedObjectServicesActions: setDefaultOptimizerSpaceId(): optimizerSpaceIds = ");
    gsLog.debug(optimizerSpaceIds);

    if (_.isNull(selectedOptimizerSpaceId)) {
      gsLog.debug("In CachedObjectServicesActions: setDefaultOptimizerSpaceId(): 333 = ");
      if (optimizerSpaceIds && optimizerSpaceIds.length > 0) {
        gsLog.debug("In CachedObjectServicesActions: setDefaultOptimizerSpaceId(): 444 = ");
        selectedOptimizerSpaceId = optimizerSpaceIds[0];
        viewStateService.setSelectedOptimizerSpaceId(selectedOptimizerSpaceId);
      }
    }

    dispatch({
      type: SELECTED_OPTIMIZER_SPACE_KEY,
      payload: {
        selectedOptimizerSpaceId
      }
    });
  };

  const updateRootscope = (user) => {
    $rootScope.oauth = {};

    const { access_token, aaa_token, aaa_expires, aaa_expires_in, appSecret, appToken, refresh_token, roles, orgId } = user;

    $rootScope.oauth.access_token = access_token;
    $rootScope.oauth.aaa_token = aaa_token;
    $rootScope.oauth.aaa_expires = aaa_expires;
    $rootScope.oauth.aaa_expires_in = aaa_expires_in;
    $rootScope.oauth.authenticated = true;
    $rootScope.oauth.appSecret = appSecret;
    $rootScope.oauth.appToken = appToken;
    $rootScope.oauth.refresh_token = refresh_token;
    $rootScope.oauth.roles = roles;
    $rootScope.oauth.org_id = orgId;
    gsLog.debug("In CachedObjectServicesActions: updateRootscope(): $rootScope.oauth = ");
    gsLog.debug($rootScope.oauth);
    gsLog.debug("In CachedObjectServicesActions: updateRootscope(): aaa_expires = ");
    gsLog.debug($rootScope.oauth.aaa_expires);
    gsLog.debug(user);
  };

  const updateAuthService = (user) => {
    const { canViewFlexForecast, canViewScheduler, roles } = user;
    authorizationService.setCanViewFlexForecast(canViewFlexForecast);
    authorizationService.setCanViewScheduler(canViewScheduler);
    authorizationService.setUserRole(roles);
  };

  const transformUsrRoles = (userRoles) => {
    let modUserRoles = [];

    if (userRoles) {
      if (!_.isArray(userRoles)) {
        const parsedUserRoles = JSON.parse(userRoles);
        gsLog.debug("In CachedObjectServicesActions: transformUsrRoles(): parsed user roles = ");
        gsLog.debug(parsedUserRoles);
        modUserRoles = Object.keys(parsedUserRoles).map((key) => {
          return { roleId: parsedUserRoles[key].rnum, roleName: parsedUserRoles[key].name };
        });
      } else {
        modUserRoles = [...userRoles];
      }
    }
    gsLog.debug("In CachedObjectServicesActions: transformUsrRoles(): user roles = ");
    gsLog.debug(modUserRoles);
    return modUserRoles;
  };

  const transformCustomerIds = (userCustomerIds) => {
    let customers = [];

    if (userCustomerIds) {
      if (!_.isArray(userCustomerIds)) {
        const customerIds = JSON.parse(userCustomerIds);
        gsLog.debug("In CachedObjectServicesActions: transformCustomerIds(): parsed customer IDs = ");
        gsLog.debug(customerIds);
        if (_.isArray(customerIds)) {
          customers = customerIds.map((item) => {
            return { customerId: item.pid, customerName: item.name };
          });
        } else {
          customers = Object.keys(customerIds).map((key) => {
            return { customerId: key, customerName: customerIds[key].name };
          });
        }
      } else {
        customers = [...userCustomerIds];
      }
    }
    gsLog.debug("In CachedObjectServicesActions: transformCustomerIds(): parsed customers = ");
    gsLog.debug(customers);
    return customers;
  };

  const transformEtlSpaceIds = (etlSpaceIds) => {
    let transformed = [];

    if (etlSpaceIds) {
      if (!_.isArray(etlSpaceIds)) {
        const ids = JSON.parse(etlSpaceIds);
        gsLog.debug("In CachedObjectServicesActions: transformEtlSpaceIds(): parsed ETL Space IDs = ");
        gsLog.debug(ids);
        if(_.isArray(ids))
        {
          transformed = ids.map(function(item) {
              return item.pid;
          });
        } else{
          transformed = Object.keys(ids);
        }
      } else {
        transformed = [...etlSpaceIds];
      }
    }

    gsLog.debug("In CachedObjectServicesActions: transformEtlSpaceIds(): transformed ETL Space IDs = ");
    gsLog.debug(transformed);

    return transformed;
  };

  const transformOptimizerSpaceIds = (optimizerSpaceIds) => {
    let transformed = [];

    if (optimizerSpaceIds) {
      if (!_.isArray(optimizerSpaceIds)) {
        const ids = JSON.parse(optimizerSpaceIds);
        gsLog.debug("In CachedObjectServicesActions: transformOptimizerSpaceIds(): parsed Optimizer Space IDs = ");
        gsLog.debug(ids);
        if(_.isArray(ids))
        {
          transformed = ids.map(function(item){
              return item.pid;
          });
        } else{
          transformed = Object.keys(ids);
        }
      } else {
        transformed = [...optimizerSpaceIds];
      }
    }

    gsLog.debug("In CachedObjectServicesActions: transformOptimizerSpaceIds(): transformed Optimizer Space IDs = ");
    gsLog.debug(transformed);

    return transformed;
  };

  const transformMiIds = (userMiIds) => {
    let mis = [];

    if (userMiIds) {
      if (!_.isArray(userMiIds)) {
        const miIds = JSON.parse(userMiIds);
        gsLog.debug("In CachedObjectServicesActions: transformMiIds(): parsed MI IDs = ");
        gsLog.debug(miIds);
        if (_.isArray(miIds)) {
          mis = miIds.map((item) => {
            return { pid: item.pid, pname: item.name, ktype: item.stype, sid: item.sid, sname: item.sname };
          });
        } else {
          mis = Object.keys(miIds).map((key, idx) => {
            return Object.assign({}, miIds[key]);
          });
        }
      } else {
        mis = [...userMiIds];
      }
    }

    gsLog.debug("In CachedObjectServicesActions: transformMiIds(): parsed MI IDs with addl props = ");
    gsLog.debug(mis);

    return mis;
  };

  const transformVppIds = (userVppIds) => {
    let vpps = [];

    if (userVppIds) {
      if (!_.isArray(userVppIds)) {
        const vppIds = JSON.parse(userVppIds);
        gsLog.debug("In CachedObjectServicesActions: transformVppIds(): parsed VPP IDs = ");
        gsLog.debug(vppIds);
        if (_.isArray(vppIds)) {
          vpps = vppIds.map((item) => {
            let newitem = { vppId: item["pid"], vppName: item["name"] }
            return newitem;
          })
        } else {
          vpps = Object.keys(vppIds).map((key) => {
            return { vppId: key, vppName: vppIds[key].name };
          });
        }
      } else {
        vpps = [...userVppIds];
      }
    }

    return vpps;
  };

  const transformImpersonationTargets = (impersonationTargets) => {
    let transformed = [];

    if (impersonationTargets) {
      if (!_.isArray(impersonationTargets)) {
        const parsedImpersonationTargets = JSON.parse(impersonationTargets);
        transformed = Object.keys(parsedImpersonationTargets).map((key) => {
            return { label: parsedImpersonationTargets[key].userEmail, value: parsedImpersonationTargets[key].userEmail };
        });
      } else {
        transformed = [...transformed];
      }
    }
    gsLog.debug("In CachedObjectServicesActions: transformImpersonationTargets(): Impersonation Targets = ");
    gsLog.debug(transformed);
    return transformed;
  }
  const updateUserDefaults = (user) => {
    return (dispatch, getState) => {
      // update user roles
      let userRoles = transformUsrRoles(user.usrRoles);
      dispatch(updateObjects(HEADER_USR_ROLES, userRoles));

      // update customer IDs
      let customers = transformCustomerIds(user.customerIds);
      dispatch(updateObjects(HEADER_CUSTOMER_IDS, customers));

      // ImpersonationTargets
      let impersonationTargets = transformImpersonationTargets(user.impersonationTargets);
      dispatch(updateObjects(HEADER_USR_IMPERSONATION_TARGETS, impersonationTargets));

      // update VPP IDs and MI IDs
      let vpps = transformVppIds(user.vppIds),
          etlSpaceIds = transformEtlSpaceIds(user.etlSpaceIds),
          mis = transformMiIds(user.miIds),
          optimizerSpaceIds = transformOptimizerSpaceIds(user.optimizerSpaceIds);
      vpps.sort((a,b) => {return (a.vppId > b.vppId) ? 1 : ((b.vppId > a.vppId) ? -1 : 0);} );
      setDefaultVpp(dispatch, vpps, mis);
      setDefaultEtlSpaceId(dispatch, etlSpaceIds);
      setDefaultOptimizerSpaceId(dispatch, optimizerSpaceIds);
      dispatch(updateObjects(HEADER_VPP_IDS, vpps));
      dispatch(updateObjects(HEADER_MI_IDS, mis));
      dispatch(updateObjects(HEADER_ETL_SPACE_IDS, etlSpaceIds));
      dispatch(updateObjects(HEADER_OPTIMIZER_SPACE_IDS, optimizerSpaceIds));

      // update user
      user.customerIds = [...customers];
      user.vppIds = [...vpps];
      user.miIds = [...mis];
      user.usrRoles = [...userRoles];
      user.etlSpaceIds = [...etlSpaceIds];
      user.optimizerSpaceIds = [...optimizerSpaceIds];

      // store user in local storage
      gsLog.debug("In CachedObjectServicesActions: updateUserDefaults(): 555");
      viewStateService.createUser(user);
      if (user.rememberMe) {
        viewStateService.setRememberMeEmail(user.userName);
      } else {
        viewStateService.setRememberMeEmail("");
      }

      if (user.targetUser) {
        viewStateService.setImpersonateLogin(true);
      } else {
        viewStateService.setImpersonateLogin(false);
      }

      updateRootscope(user);
      updateAuthService(user);

      store.dispatch(loggedInUserServices.updateProfile(user));
    };
  };

  const setSelectedVpp = (vpp) => {
    return (dispatch, getState) => {
      gsLog.debug("In CachedObjectServicesActions: setSelectedVpp(): vpp = ");
      gsLog.debug(vpp);

      const miIds = getState().cachedObjectServices[HEADER_MI_IDS];
      setDefaultMi(dispatch, vpp, miIds);

      viewStateService.setSelectedVpp(vpp);
      dispatch({
        type: SELECTED_VPP,
        payload: {
          selectedVpp: vpp
        }
      });
    };
  };

  const setSelectedMi = (mi) => {
    gsLog.debug("In CachedObjectServicesActions: setSelectedMi(): mi = ");
    gsLog.debug(mi);

    viewStateService.setSelectedMi(mi);
    return {
      type: SELECTED_MI,
      payload: {
        selectedMi: mi
      }
    }
  };

  const updateObjects = (name, values) => {
    return {
      type: USER_OBJECTS,
      payload: {
        [name]: values
      }
    };
  };

  return {
    setSelectedMi,
    setSelectedVpp,
    updateObjects,
    updateUserDefaults,
    transformUsrRoles,
    transformMiIds,
    transformCustomerIds,
    transformVppIds,
    transformEtlSpaceIds,
    transformImpersonationTargets
  }
};


export default CachedObjectServicesActions;