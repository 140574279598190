import { formatCurrency } from '../utils/utils';
import { PDF_COLORS } from "../shared/constants";
import { default as gsLog } from '../service/loggingService';

//Singleton service for sharing 
class TwelveMonthsSavingChart {
  constructor() {
    if (TwelveMonthsSavingChart.instance) {
      return TwelveMonthsSavingChart.instance;
    }
    this.gsLog = gsLog;
    this.PDF_COLORS = PDF_COLORS;
    this.graph = {};
    this.init();
    this.twelveMonthsSavingChartRef = null;
    TwelveMonthsSavingChart.instance = this;
  }

  setChartRef(chartRef) {
    if (chartRef) this.twelveMonthsSavingChartRef = chartRef;
  }

  update(categories, monthsSavingValues) {
    this.gsLog.debug("In TwelveMonthsSavingChart: update(): 000");

    const chart = this.twelveMonthsSavingChartRef && this.twelveMonthsSavingChartRef.current ? this.twelveMonthsSavingChartRef.current.chart : null;

    if (chart) {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }

      this.gsLog.debug("In TwelveMonthsSavingChart: update(): categories = ");
      this.gsLog.debug(categories);
      this.gsLog.debug("In TwelveMonthsSavingChart: update(): monthsSavingValues = ");
      this.gsLog.debug(monthsSavingValues);
      let redraw = false;

      if (monthsSavingValues) {
        chart.addSeries({
          type: "column",
          name: "12 Months Saaving",
          color: this.PDF_COLORS.title,
          data: monthsSavingValues
        }, true);
        redraw = true;
      }
    }
  }

  init() {
    this.gsLog.debug("In TwelveMonthsSavingChart: init(): entering ...");
    this.graph = {
      options: {
        colors: [this.PDF_COLORS.title],
        chart: {
          type: 'column',
          backgroundColor: this.PDF_COLORS.altRow
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            pointWidth: 30,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: 8,
                fontWeight: 'normal',
                color: 'black',
                textOutline: null,
                textShadow: '0 0'
              },
              formatter: function () {
                return formatCurrency(this.y);
              }
            }
          }
        },
        yAxis: [
          {
            gridLineColor: "#fff",
            labels: {
              style: {

                color: 'black'
              },
              format: '${value:,.0f}'
            },
            title: {
              text: null,
              style: {
                color: '#A0A0A3'
              }
            }
          }
        ],
        xAxis: {
          type: 'category',
          tickColor: "#979ea5",
          labels: {
            style: {
              color: 'black',
              fontSize: 8
            }
          }
        },
        series: []
      }
    };
  }
}

export default TwelveMonthsSavingChart;
