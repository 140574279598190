import * as log from 'loglevel';
import moment from 'moment-timezone';
import { TIME_ZONE_US_ONLY } from "../shared/constants";
import _ from 'underscore';

export const getAngularService = () => {
    console.error("Calling getAngularService() ******************");
    return log;
};

export const hashFragmentQueryStringParse = (string) => {
    let parsed = {};

    if (string !== '') {
        let queryString = string.substring(string.indexOf('?')+1);
        let p1 = queryString.split('&');
        p1.map(function(value) {
            let params = value.split('=');
            parsed[params[0]] = params[1];
        });
    }

    return parsed
}

export const changeDateBaseOnTimezone = (date, timezone) => {
    let dateString = moment(date).format("YYYY-MM-DD HH:mm");
    let newDate = moment.tz(dateString, timezone.name);
    return newDate;
}

export const dateToYYYYMMDDHHmm = (date) => {
    //create format as: YYYY-MM-DD HH:mm
    let yy = date.getFullYear();
    let month = ("0"+(date.getMonth()+1)).slice(-2);
    let day =  ("0" + date.getDate()).slice(-2);
    let hh = ("0" + date.getHours()).slice(-2);
    let mm = ("0" + date.getMinutes()).slice(-2);
    let datestring = `${yy}-${month}-${day} ${hh}:${ mm}`;
    return datestring;
}

export const getDefaultOperationTimezone = () => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timezone = TIME_ZONE_US_ONLY.find((item) => item.name === tz);

  if (_.isNull(timezone)) {
    timezone = TIME_ZONE_US_ONLY[0];
  }
  return timezone;
};

export const sleep = (milliseconds=500) => new Promise(resolve => setTimeout(resolve, milliseconds))