export const VIEW_SET_ACTIVE = "VIEW_SET_ACTIVE";
export const VIEW_UNSET_ACTIVE = "VIEW_UNSET_ACTIVE";
export const VIEW_REFRESH = "VIEW_REFRESH";
export const VIEW_RESET_REFRESH = "VIEW_RESET_REFRESH";

export const VitalsConflictRejectServices = (gsLog) => {

  const setActive = () => {
      return (dispatch, getState) => {
        dispatch({
            type: VIEW_SET_ACTIVE,
            payload: {}
        });

      };
  };

  const unsetActive = () => {
      return (dispatch, getState) => {
        dispatch({
            type: VIEW_UNSET_ACTIVE,
            payload: {}
        });

      };
  };

  const refreshConflictRejectView = () => {
      return (dispatch, getState) => {
        dispatch({
            type: VIEW_REFRESH,
            payload: {refresh : true}
        });

      };
  };

  const refreshResetConflictRejectView = () => {
      return (dispatch, getState) => {
        dispatch({
            type: VIEW_RESET_REFRESH,
            payload: {refresh : false}
        });

      };
  };

  return {
    setActive,
    unsetActive,
    refreshConflictRejectView,
    refreshResetConflictRejectView
  };
};

export default VitalsConflictRejectServices;
