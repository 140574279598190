import Rx from "rx";
import SpinnerService from "../shared/react-spinner.js";
import HeliosProjectApi from "../service/heliosProjectApi";
import _ from "underscore";
import {default as gsLog} from '../service/loggingService';

export const SOLAR_PROJECT_LIST = "SOLAR_PROJECT_LIST";
export const SOLAR_PROJECT_SEARCH = "SOLAR_PROJECT_SEARCH";
export const SOLAR_PROJECT_SET_SELECTED_PROJECT = "SOLAR_PROJECT_SET_SELECTED_PROJECT";

let HeliosProjectSelectorActionsInstance = null;

const HeliosProjectSelectorActionsConstruction = () => {
  let rx = Rx;
  let heliosProjectApi = new HeliosProjectApi();

  const searchSolarProject = (results) => {
    return (dispatch, getState) => {
      return dispatch({
        type: SOLAR_PROJECT_SEARCH,
        payload: {
          doSearch: results.search,
          searchResults: results.list
        }
      })
    }
  };

  const setSelectedSolarProject = (selectedSolar) => {
    return (dispatch, getState) => {
      gsLog.debug("In HeliosProjectSelectorActionsConstruction: setSelectedSolarProject(): selected SOLAR = ");
      gsLog.debug(selectedSolar);

      return dispatch({
        type: SOLAR_PROJECT_SET_SELECTED_PROJECT,
        payload: {
          selectedSolarProject: selectedSolar
        }
      })
    }
  };

  const getSolarProjectList = (isAllSolarProject = false) => {
    return (dispatch, getState) => {
      SpinnerService.spin();
      const observables = [
        heliosProjectApi.getSolarProjectList().catch((errors) => {
          gsLog.error("In HeliosProjectSelectorActionsConstruction: getSolarProjectList(): error calling vpp list API = ");
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        })
      ];

      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([solarProjectListResults]) => {
            if (solarProjectListResults.apiError) {
              return dispatch({
                type: SOLAR_PROJECT_LIST,
                payload: {
                  selectedSolarProject: null,
                  solarProjectListResults: null,
                }
              });
            } else {
              gsLog.debug("In HeliosProjectSelectorActionsConstruction: getSolarProjectList(): Solar Project list API results = ");
              gsLog.debug(solarProjectListResults);

              //Restricting API DATA for Solars
              if (solarProjectListResults.data) {
                let selectedSolarProject = getState().heliosProjectSelectorServices.selectedSolarProject;

                if (selectedSolarProject) {
                  let solarProject = solarProjectListResults.data.vpps.find((item) => item.vppId === selectedSolarProject.vppId);

                  if (!_.isUndefined(solarProject) && !_.isNull(solarProject)) {
                    selectedSolarProject = solarProject;
                  } else {
                    selectedSolarProject = solarProjectListResults.data.vpps[0];
                  }
                } else if (!isAllSolarProject && !selectedSolarProject) {
                  selectedSolarProject = solarProjectListResults.data.vpps[0];
                }

                setSelectedSolarProject(selectedSolarProject);

                return dispatch({
                  type: SOLAR_PROJECT_LIST,
                  payload: {
                    solarProjectListResults: solarProjectListResults.data,
                    selectedSolarProject
                  }
                });
              } else {
                return dispatch({
                  type: SOLAR_PROJECT_LIST,
                  payload: {
                    selectedSolarProject: null,
                    solarProjectListResults: null,
                  }
                });
              }
            }
          },
          (errors) => {
            gsLog.error("In HeliosProjectSelectorActionsConstruction: getSolarProjectList(): error in processing Solar Project list API results = ");
            gsLog.debug(errors);
          },
          () => {
            SpinnerService.stop();
            gsLog.debug("In HeliosProjectSelectorActionsConstruction: getSolarProjectList(): DONE with processing Solar Project list API results");
          }
        )
    };
  };

  return {
    getSolarProjectList,
    searchSolarProject,
    setSelectedSolarProject
  };
}

export const HeliosProjectSelectorActions = ( ) => {
  if (HeliosProjectSelectorActionsInstance) {
    return HeliosProjectSelectorActionsInstance;
  } else {
    HeliosProjectSelectorActionsInstance = HeliosProjectSelectorActionsConstruction();
   return HeliosProjectSelectorActionsInstance;
  }
} 

