import { TOGGLE_ANALYSIS_EXPANDER, TOGGLE_ANALYSIS_FOOTER,
  TOGGLE_FOOTER_EXPAND, TOGGLE_FOOTER_RESET, TOGGLE_FOOTER_RETRACT
} from '../actions/toggleFooterServices';

const INITIAL_STATE = {
  expander: true,
  footer: false,
  expandMode: TOGGLE_FOOTER_EXPAND
};

export default function toggleAnalysisFooterServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TOGGLE_ANALYSIS_EXPANDER:
      return Object.assign({}, state, payload);
    case TOGGLE_ANALYSIS_FOOTER:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
  // let expander,
  //   footer;
  // switch (type) {
  //   case TOGGLE_EXPANDER:
  //     expander = !state.expander;
  //     footer = !expander ? false : state.footer;
  //     return Object.assign({}, state, { expander, expandMode: expander ? TOGGLE_FOOTER_EXPAND : TOGGLE_FOOTER_RESET, footer });
  //   case TOGGLE_FOOTER:
  //     footer = !state.footer;
  //     expander = !footer ? false : state.expander;
  //     return Object.assign({}, state, { expander, footer, expandMode: footer ? TOGGLE_FOOTER_RETRACT : TOGGLE_FOOTER_RESET });
  //   default:
  //     return state;
  // }
}
