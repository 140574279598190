import '../react-transition/interceptor.js';
import axios from 'axios';
// import { getAngularService } from '../react-transition/ReactUtils';
import { PMT_API, VPP_API, PIPELINE_API } from '../shared/app.constants.all';
import { default as gsLog } from './loggingService';
import _ from 'underscore';
import moment from 'moment';

class VppApi {
    constructor() {
        if (VppApi.instance) {
            return VppApi.instance;
        }

        this.gsLog = gsLog;
        // this.$q = getAngularService("$q");
        this.VPP_API = VPP_API;
        this.PMT_API = PMT_API;
        this.PIPELINE_API = PIPELINE_API;
        VppApi.instance = this;
    }

    resetGetPipelineConfigcanceller() {
        this.gsLog.debug(`In vppApi: resetGetPipelineConfigcanceller(): 000`);
        this.httpRequestCanceller = undefined;
    }

    getPipelineConfig(siteId) {
        this.gsLog.debug(`Calling getPipelineConfig 111 url : ${this.VPP_API.PIPELINE_SITE}/${siteId} `);

        if (!_.isUndefined(this.httpRequestCanceller)) {
            this.gsLog.debug(`In vppApi: getPipelineConfig(): 222`);
            this.httpRequestCanceller.resolve();
        }
        else {
            this.gsLog.debug(`In vppApi: getPipelineConfig(): 333`);
            //this.httpRequestCanceller = this.$q.defer();
        }

        let request = axios({
            url: this.VPP_API.PIPELINE_SITE + "/" + siteId,
            method: "GET",
           // timeout: this.httpRequestCanceller.promise
        });

        return request;
    }

    getBillingSchedules(calendarId, searchDate) {
        let httpRequest = axios({
            url: this.VPP_API.BILLING_SCHEDULES,
            method: "GET",
            params: {
                calendarId: calendarId + "",
                date: searchDate
            }
        });

        return httpRequest;
    }

    getTariff(tariffId, chargeType, calendarId, startDate, endDate, ignoreFromToAndGetRecent) {
        let httpRequest = axios({
            url: this.VPP_API.TARIFF,
            method: "GET",
            params: {
                tariffId: tariffId + "",
                chargeType: chargeType,
                calendarId: calendarId + "",
                startDate: startDate,
                endDate: endDate,
                ignoreFromToAndGetRecent: !_.isUndefined(ignoreFromToAndGetRecent) ? ignoreFromToAndGetRecent : false
            }
        });
        return httpRequest;
    }

    getPipelineSixtyMinsGraphByDateRangeEndPintConfigs(projectUUID, vppId, startDate, endDate, endPintConfigs) {
        this.gsLog.debug("In vppApi: getPipelineSixtyMinsGraphByDateRangeEndPintConfigs() 000 ");
        this.gsLog.debug(this.PIPELINE_API);
        this.gsLog.debug("In vppApi: getPipelineSixtyMinsGraphByDateRangeEndPintConfigs() 111 " + startDate);
        this.gsLog.debug("In vppApi: getPipelineSixtyMinsGraphByDateRangeEndPintConfigs() 222 " + endDate);

        let sites = [];
        let endPointsSet = new Set();

        endPintConfigs.forEach(element => {
            endPointsSet.add(element.endpoint);
        });
        let endPoints = Array.from(endPointsSet);

        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi:  getPipelineSixtyMinsGraphByDateRangeEndPintConfigs() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi:  getPipelineSixtyMinsGraphByDateRangeEndPintConfigs() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs(projectUUID, vppId, startDate, endDate, endPintConfigs) {
        this.gsLog.debug("In vppApi: getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs() 000 ");
        this.gsLog.debug(this.PIPELINE_API);
        this.gsLog.debug("In vppApi: getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs() 111 " + startDate);
        this.gsLog.debug("In vppApi: getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs() 222 " + endDate);

        let sites = [];
        let endPointsSet = new Set();

        endPintConfigs.forEach(element => {
            endPointsSet.add(element.endpoint);
        });
        let endPoints = Array.from(endPointsSet);

        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi:  getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi:  getPipelineEnergyArbitrageGraphByDateRangeEndPintConfigs() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineEnergyArbitrageGraphByDateRange(projectUUID, vppId, startDate, endDate) {
        this.gsLog.debug("In vppApi: error in getPipelineEnergyArbitrageGraphByDateRange() 000 ");
        this.gsLog.debug(this.PIPELINE_API);
        this.gsLog.debug("In vppApi: error in getPipelineEnergyArbitrageGraphByDateRange() 111 " + startDate);
        this.gsLog.debug("In vppApi: error in getPipelineEnergyArbitrageGraphByDateRange() 222 " + endDate);

        let sites = [];
        let endPoints = ["virtual.system"];
        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi: error in getPipelineEnergyArbitrageGraphByDateRange() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getPipelineEnergyArbitrageGraphByDateRange() = ");
                this.gsLog.error(e);
            });
    }

    getEnergyArbitrageGraphByDateRange(vppId, startDate, endDate) {
        return axios({
            url: `${this.VPP_API.ENERGY_ARBITRAGE_GRAPH}/${vppId}`,
            method: "GET",
            params: {
                "start": startDate,
                "end": endDate
            }
        })
            .then(function (response) {
                return response.data.results;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getEnergyArbitrageGraphByDateRange() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables(projectUUID, vppId, startDate, endDate, endpointList) {
        this.gsLog.debug("In vppApi: getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables() 000 " + vppId);
        this.gsLog.debug("In vppApi: getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables() 111 " + startDate);
        this.gsLog.debug("In vppApi: getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables() 222 " + endDate);

        let sites = [];
        let endPointsSet = new Set();

        endpointList.forEach(element => {
            endPointsSet.add(element.endpoint);
        });

        let endPoints = Array.from(endPointsSet);
        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_FIFTEEN_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi: getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: getPipelineOpsBuildingLoadByDateRangeEndpointConfigsUsingObservables() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineOpsBuildingLoadByDateRangeUsingObservables(projectUUID, vppId, startDate, endDate) {
        this.gsLog.debug("In vppApi: error in getPipelineOpsBuildingLoadByDateRangeUsingObservables() 000 " + vppId);
        this.gsLog.debug("In vppApi: error in getPipelineOpsBuildingLoadByDateRangeUsingObservables() 111 " + startDate);
        this.gsLog.debug("In vppApi: error in getPipelineOpsBuildingLoadByDateRangeUsingObservables() 222 " + endDate);

        let sites = [];
        let endPoints = ["virtual.netsolar", "virtual.orig", "virtual.building", "ctl.power", "dpg_tot.q0x", "virtual.pv", "dpg_tot.stored_energy", "virtual.system"];
        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_FIFTEEN_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi: error in getPipelineOpsBuildingLoadByDateRangeUsingObservables() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getPipelineOpsBuildingLoadByDateRangeUsingObservables() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineSolarGenerationGraphByDateRangeEndPointConfigs(projectUUID, vppId, startDate, endDate, endPintConfig) {
        this.gsLog.debug("In getPipelineSolarGenerationGraphByDateRangeEndPointConfigs() 000 " + vppId);
        this.gsLog.debug("In getPipelineSolarGenerationGraphByDateRangeEndPointConfigs() 111 ");
        this.gsLog.debug(this.PIPELINE_API);
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 222 " + startDate);
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 333 " + endDate);

        let sites = [];
        let endPointsSet = new Set();

        endPintConfig.forEach(element => {
            endPointsSet.add(element.endpoint);
        });

        let endPoints = Array.from(endPointsSet);
        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In getPipelineSolarGenerationGraphByDateRangeEndPointConfigs() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In getPipelineSolarGenerationGraphByDateRangeEndPointConfigs() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineSolarGenerationGraphByDateRange(projectUUID, vppId, startDate, endDate) {
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 000 " + vppId);
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 111 ");
        this.gsLog.debug(this.PIPELINE_API);
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 222 " + startDate);
        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 333 " + endDate);

        let sites = [];
        let endPoints = ["virtual.pv"];
        let params = null;

        if (projectUUID != null) {
            sites.push(projectUUID);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                siteIds: sites.join(),
                endpoints: endPoints.join()
            }
        } else {
            sites.push(vppId);
            params = {
                first: moment(startDate).valueOf(),
                last: moment(endDate).valueOf(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        }

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        })
            .then((response) => {
                this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() 444 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getPipelineSolarGenerationGraphByDateRange() = ");
                this.gsLog.error(e);
            });
    }

    getPipelineSolarGenerationGraphByTs(vppId, numDateRange) {
        let dateRange = this.vppBuildingLoadGraphService.getStartEndFromTs();

        this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByTs() 000 ");
        this.gsLog.debug(this.PIPELINE_API);
        let sites = [];
        sites.push(vppId);
        let endPoints = ["virtual.pv"];

        return axios({
            url: this.PIPELINE_API.EVERY_SIXTY_MIN,
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                first: moment(dateRange.startDateStr).valueOf(),
                last: moment(dateRange.endDateStr).valueOf(),
                //siteIds: sites.join(),
                vppIds: sites.join(),
                endpoints: endPoints.join()
            }
        })
            .then((response) => {
                this.gsLog.debug("In vppApi: error in getPipelineSolarGenerationGraphByTs() 111 ");
                this.gsLog.debug(response);
                return response.data;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getPipelineSolarGenerationGraphByTs() = ");
                this.gsLog.error(e);
            });
    }

    getSolarGenerationGraphByTs(vppId, numDateRange) {
        return axios({
            url: this.VPP_API.SOLAR_GENERATION_GRAPH + "/" + vppId,
            method: "GET",
            params: {
                "ts": numDateRange
            }
        })
            .then(function (response) {
                return response.data.results;
            })
            .catch((e) => {
                this.gsLog.error("In vppApi: error in getSolarGenerationGraphByTs() = ");
                this.gsLog.error(e);
            });
    }

    getVppList(clientId = "UI", sortBy = "vppName") {
        this.gsLog.debug("In vppApi: getVppList(): 000 ");
        const httpRequest = axios({
            url: this.VPP_API.VPP_LIST,
            method: "GET",
            params: {
                clientId,
                sortBy
            }
        });

        return httpRequest;
    }

    getAllPmtProjects() {
        this.gsLog.debug("In vppApi: getAllPmtProjects(): 000 ");
        const httpRequest = axios({
            url: this.PMT_API.PMT_PROJECT,
            method: "GET",
            params: {
                clientId: "UI",
                size: 10000
            }
        });

        return httpRequest;
    }
}

export default VppApi;
