import { formatCurrency } from '../utils/utils';
import { default as gsLog } from '../service/loggingService';
import { PDF_COLORS } from "../shared/constants";
import $ from "jquery";

//Singleton service for sharing 
class CustomerBenefitYearChart {
  constructor() {
    if (CustomerBenefitYearChart.instance) {
        return CustomerBenefitYearChart.instance;
    } 
    this.gsLog = gsLog;
    this.PDF_COLORS = PDF_COLORS;
    this.graph = {};
    this.init();
    CustomerBenefitYearChart.instance = this;
  }

  update(categories, dmValues, eaValues, gsValues) {
    const chart = $("#customerBenefitYearChart").highcharts();
    if (chart) {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      this.gsLog.debug("In CustomerBenefitYearChart: update(): categories = ");
      this.gsLog.debug(categories);
      this.gsLog.debug("In CustomerBenefitYearChart: update(): dmValues = ");
      this.gsLog.debug(dmValues);
      this.gsLog.debug("In CustomerBenefitYearChart: update(): eaValues = ");
      this.gsLog.debug(eaValues);
      this.gsLog.debug("In CustomerBenefitYearChart: update(): gmValues = ");
      this.gsLog.debug(gsValues);
      let redraw = false;
      this.graph.xAxis.categories = categories;
      if (dmValues) {
        chart.addSeries({
          type: "column",
          name: "Demand Charge Savings",
          color: "#37b9c3",
          data: dmValues
        }, true);
        redraw = true;
      }
      if (eaValues) {
        chart.addSeries({
          type: "column",
          name: "Energy Arbitrage Savings",
          color: this.PDF_COLORS.energyArbitrage,
          data: eaValues
        }, true);
        redraw = true;
      }
      if (gsValues && gsValues.length > 0) {
        chart.addSeries({
          type: "column",
          name: "Grid Services Revenue",
          color: this.PDF_COLORS.gridServices,
          data: gsValues
        }, true);
        redraw = true;
      }
    }
  }

  init() {
    this.gsLog.debug("In CustomerBenefitYearChart: init(): entering ...");
    this.graph = {
        options: {
            colors: ["#55BF3B", "#5b7a99"],
            chart: {
                type: 'column',
                backgroundColor: this.PDF_COLORS.altRow
            },
            title: {
              text: null,
              style: {
                 color: '#E0E0E3',
                 textTransform: 'uppercase',
                 fontSize: '15px'
              }
            },
            legend: {
              itemStyle: {
                color: "black"
              }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointWidth: 90,
                    dataLabels: {
                        enabled: true,
                        style: {
                          fontSize: 11,
                          fontWeight: 'normal',
                          color: 'black',
                          textOutline: null,
                          textShadow: '0 0'
                        },
                        formatter: function() {
                          return formatCurrency(this.y);
                        }
                    }
                }
            },
            xAxis: {
              categories: [],
              tickColor: "#979ea5",
              labels: {
                  style: {
                      color: 'black'
                  }
              },
              title: {
                style: {
                  color: 'black'
                }
              }
          },
          yAxis: [
            {
              gridLineColor: "#fff",
              labels: {
                  style: {

                      color: 'black'
                  },
                  format: '${value:,.0f}'
              },
              title: {
                text: null,
                style: {
                  color: '#A0A0A3'
                }
              },
              stackLabels: {
                  enabled: false,
                  style: {
                      fontSize: 8,
                      fontWeight: 'normal',
                      color: 'white'
                      
                  }
              }
            }
          ]
        },
        xAxis: {
            categories: [],
            tickColor: "#979ea5",
            labels: {
                style: {
                    color: 'black'
                }
            },
            title: {
              style: {
                color: 'black'
              }
            }
        },
        yAxis: [
          {
            gridLineColor: "#fff",
            labels: {
                style: {
                    color: 'black'
                },
                format: '${value:,.0f}'
            },
            title: {
              text: null,
              style: {
                color: '#A0A0A3'
              }
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontSize: 8,
                    fontWeight: 'normal',
                    color: 'white'
                }
            }
          }
        ],
        series: []
      };
  }
}

export default CustomerBenefitYearChart;
