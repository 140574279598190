import { VIEW_LATEST_MONTLY_SAVINGS, VIEW_UITILITY_NAME } from '../actions/monthlySavingsServices';

const INITIAL_STATE = {
  viewEvent: true
};

export default function monthlySavingsServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case VIEW_UITILITY_NAME:
      return Object.assign({}, state, payload);
    case VIEW_LATEST_MONTLY_SAVINGS:
      return Object.assign({}, state, payload);
    default:
      return Object.assign({}, state, { viewEvent: true} );
  }
}
