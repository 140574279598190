import {default as gsLog} from '../service/loggingService';
export const OPERATIONS_STATUS_BAR = "OPERATIONS_STATUS_BAR";
export const OPERATIONS_STATUS_BAR_MONITOR = "OPERATIONS_STATUS_BAR_MONITOR";
export const OPERATIONS_STATUS_BAR_PROJECTS = "OPERATIONS_STATUS_BAR_PROJECTS";
export const OPERATIONS_STATUS_BAR_FREQUENCY = "OPERATIONS_STATUS_BAR_FREQUENCY";
export const OPERATIONS_STATUS_BAR_SCHEDULE = "OPERATIONS_STATUS_BAR_SCHEDULE";
export const OPERATIONS_STATUS_BAR_DETAILS = "OPERATIONS_STATUS_BAR_DETAILS";
export const OPERATIONS_STATUS_BAR_HEALTH = "OPERATIONS_STATUS_BAR_HEALTH";
export const OPERATIONS_STATUS_BAR_ITC = "OPERATIONS_STATUS_BAR_ITC";
export const OPERATIONS_STATUS_BAR_ALERTS = "OPERATIONS_STATUS_BAR_ALERTS";
export const OPERATIONS_STATUS_BAR_FLEX_FORECAST = "OPERATIONS_STATUS_BAR_FLEX_FORECAST";
export const OPERATIONS_STATUS_BAR_FORECAST = "OPERATIONS_STATUS_BAR_FORECAST";
export const OPERATIONS_STATUS_BAR_PERFORMANCE = "OPERATIONS_STATUS_BAR_PERFORMANCE";
export const OPERATIONS_STATUS_BAR_MONTHLYSAVINGS = "OPERATIONS_STATUS_BAR_MONTLYSAVINGS";

const OperationsStatusBarServices = () => {

  const updateStatusBar = (tabName, val = false) => {
    return async (dispatch, getState) => {
      let { alerts, details, flexForecast, forecast, frequency, health, itc, monitor, performance, monthlySavings, projects, schedule } = getState().operationsStatusBarServices;

      switch (tabName) {
        case OPERATIONS_STATUS_BAR_MONITOR:
          monitor = true;
          if (monitor) {
            alerts = details = flexForecast = forecast = frequency = health = itc = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_PROJECTS:
          projects = true;
          if (projects) {
            alerts = details = flexForecast = forecast = frequency = health = itc = monitor = performance = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_FREQUENCY:
          frequency = true;
          if (frequency) {
            alerts = details = flexForecast = forecast = health = itc = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_SCHEDULE:
          schedule = true;
          if (schedule) {
            alerts = details = flexForecast = forecast = frequency = health = itc = monitor = performance = projects = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_DETAILS:
          details = true;
          if (details) {
            alerts = flexForecast = forecast = frequency = health = itc = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_HEALTH:
          health = true;
          if (health) {
            alerts = details = flexForecast = forecast = frequency = monitor = performance = projects = schedule = itc = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_ITC:
          itc = true;
          if (itc) {
            health = alerts = details = flexForecast = forecast = frequency = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_ALERTS:
          alerts = true;
          if (alerts) {
            details = flexForecast = forecast = frequency = health = itc = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_FLEX_FORECAST:
          flexForecast = true;
          if (flexForecast) {
            alerts = details = forecast = frequency = health = itc = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_FORECAST:
          forecast = true;
          if (forecast) {
            alerts = details = flexForecast = frequency = health = itc = monitor = performance = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_PERFORMANCE:
          performance = true;
          if (performance) {
            alerts = details = flexForecast = forecast = frequency = health = itc = monitor = projects = schedule = monthlySavings = false;
          }
          break;
        case OPERATIONS_STATUS_BAR_MONTHLYSAVINGS:
          monthlySavings = true;
          if (monthlySavings) {
            alerts = details = flexForecast = forecast = frequency = health = itc = monitor = projects = schedule = false;
          }
          break;
        default:
          break;
      }

      dispatch( {
        type: OPERATIONS_STATUS_BAR,
        payload: {
          alerts,
          details,
          flexForecast,
          forecast,
          frequency,
          health,
          itc,
          monitor,
          performance,
          monthlySavings,
          projects,
          schedule
        }
      });

    }
  };

  return {
    updateStatusBar
  };
};

export default OperationsStatusBarServices;