import { default as gsLog } from '../service/loggingService';

let aaa_gw;
let noc_api;
let opt_api;
let invoicing_api;
let invoicearchive_api;
let pm_api;
let fm_api;
let vpp_api;
let vpp2_api;
let vpp3_api;
let vpp4_api;
let tariff_api;
let etl_api;
let measurer_api;
let measurer_api_2;
let mi_api;
let ep15_api;
let eph_api;
let pt_api;
let tos_api;
let support_api;
let forecast_api;
let ht_api;
let curtailments_api;
let change_pwd;
let impersonate_api;
let AAA_LOGIN;
let AAA_REFRESH_TOKEN;
let AAA_LOGOUT;
let LOGIN_WEB_API_URL;
let PASSWORD_RESET_WEB_API_URL;
let LOGOUT_WEB_API_URL;
let PROFILE_WEB_API_URL;
let REFRESH_TOKEN;
let VALIDATE_DOMAIN;
let IMPERSONATE_USER_URL;
let SOLAR;
let NON_SOLAR;
let forecast_algid;
let TOS_STATUS_URL;
let TOS_ACCEPT_URL;
let OPT_API_VERSION;
let INVOICING_API_VERSION;
let ETL_API_VERSION;
let VPP2_API_VERSION;
let TARIFF_API_VERSION;
let MEASURER2_API_VERSION;
let MI_API_VERSION;
let PIPELINE_ENDPOINT_VERSION;
let BASE_CURTAILMENT;
let BILLING_SCHEDULES;
let SAVINGS_WEB_API_URL;
let MAP_SITES_WEB_API_URL;
let BUILDING_POWER_WEB_API_URL;
let INFO_BY_SITE_WEB_API_URL;
let SAVINGS_BY_SITE_WEB_API_URL;
let CURRENT_LAST_YEAR_TOTAL_SAVINGS_BY_SITE_WEB_API_URL;
let INVOICES_WEB_API_URL;
let BILLING_GRAPH_WEB_API_URL;
let INVOICE_DETAILS_WEB_API_URL;
let SITE_LOAD_WEB_API_URL;
let CHANGE_PASSWORD_URL;
let EMAIL_SUPPORT_URL;
let EMAIL_SUPPORT_URL_V1;
let SUMMARY_WEB_API_URL;
let UPLOAD_SITE_IMAGE;
let BILLING_GRAPH;
let FINAL_INVOICE;
let INVOICE_ARCHIVE;
let INVOICE_TS;
let UNMODIFIED_INVOICE_TS;
let INVOICES;
let INVOICES_BY_CUSTOMER_ID;
let INVOICES_LIST;
let INVOICE_DETAILS;
let PROJECT_NAMES_BY_CUSTOMER;
let HISTORICAL_TRANSACTION_SUMMARY;
let CUSTOMER;
let FINANCE_CONFIGS;
let INSTALLATION_CONFIGS;
let MAP;
let METERS;
let NAICS;
let ORGS;
let PARTNERS;
let PICKLISTS;
let PROJECTS;
let SITES;
let SYSTEM_CONFIGS;
let USERS;
let ANALYSIS;
let ANALYSIS_VPP;
let ANALYSIS_RESULTLISTS;
let ANALYSIS_ETL;
let ANALYSIS_FEATURE_GROUPS;
let ANALYSIS_HISTORICAL;
let ANALYSIS_HIST_UPLOAD;
let ANALYSIS_START_END_DATE;
let ANALYSIS_UTILITYLISTS;
let ANALYSIS_UTILITYSEARCHEDLISTS;
let ANALYSIS_BILLINGSCYCLE;
let ANALYSIS_BILLINGPERIODS;
let ANALYSIS_SORT_FILTER_RESULTLISTS;
let ANALYSIS_CUSTOMER;
let ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK;
let ANALYSIS_MANUFACTURERS;
let ANALYSIS_PROPOSAL;
let ANALYSIS_PORTFOLIO;
let ANALYSIS_GENERATOR;
let ANALYSIS_GENERATOR_BY_PROJECT;
let ANALYSIS_GENERATOR_TYPES;
let ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS;
let ANALYSIS_SERVICE;
let ANALYSIS_SYSTEM;
let ANALYSIS_GENERATOR_SERVICE_LINK;
let ANALYSIS_SEARCH_CUSTOMER;
let ANALYSIS_SEARCH_PROPOSAL;
let ANALYSIS_SEARCH_SERVICE;
let ANALYSIS_SEARCH_PROJECT;
let ANALYSIS_SEARCH_PORTFOLIO;
let ANALYSIS_SEARCH_NAICSID;
let ANALYSIS_PROJECT_PARENT;
let ANALYSIS_NAICSID;
let ANALYSIS_SYSTEMS;
let PMT_PROJECT;
let PMT_TARIFF_RATE;
let PMT_TEMPLAT
let MONTHLY_TRANSACTIONS;
let MONTHLY_INVOIC
let CURTAIL;
let FORECAST;
let FLEX_FORECAST;
let EVERY_SIXTY_MIN;
let EVERY_FIFTEEN_MI
let BY_SITE;
let CLUSTERS;
let DAILY_SNAPSHOTS;
let CREATE;
let EXCEPTIONS;
let GRAPH;
let POLLING_SERVICE;
let MI_ACK_STATUS;
let BATTERYCYCLE;
let ITC;
let VPPEVENT_STAT;
let ANALYSIS_SITELISTS;
let ANALYSIS_CUSTOMERLISTS;
let ANALYSIS_METERLISTS;
let ANALYSIS_TARIFFLISTS;
let ANALYSIS_BILLSCHLISTS;
let ANALYSIS_ESSATRIFFLISTS;
let ANALYSIS_METER_BY_ANALYSISNAME_LISTS;
let DEMAND_RESPONSE_GRAPH;
let BILLING_SNAPSHOT_DATE;
let ECONOMIC;
let ENERGY_ARBITRAGE_GRAPH;
let OVERVIEW_STATS;
let OVERVIEW_SAVINGS;
let OPS_BUILDING_LOAD;
let FORECAST_ORIG_LOAD;
let PEAK_SHAVING_BAR;
let PIPELINE_CONFIG;
let PIPELINE_SITE;
let VPP_LIST;
let VPP_LIST_HOME;
let REVENUES;
let SOLAR_GENERATION_GRAPH;
let TARIFF;
let VITALS_ALERTS;
let VITALS_HEALTH;
let VPP_SITES;
let PMT_TEMPLATE;
let MONTHLY_INVOICE;
let EVERY_FIFTEEN_MIN;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'staging') {
//HACK
// if (false) {
    gsLog.debug("Using app.constants development");
    aaa_gw = "https://staging.api.greencharge.net";
    noc_api = "https://staging.app.greencharge.net/api";
    opt_api = aaa_gw + "/optimizer/v2.0.0";
    invoicing_api = aaa_gw + "/invoicing/v1.0.0";
    invoicearchive_api = aaa_gw + "/invoicearchive/v1.0.0";
    pm_api = aaa_gw + "/pm/v2.0.0";
    fm_api = aaa_gw + "/fm/v1.0.0";
    vpp_api = aaa_gw + "/vpp/v1.0.0";
    vpp2_api = aaa_gw + "/vpp/v2.0.0";
    vpp3_api = aaa_gw + "/vpp/v3.0.0";
    vpp4_api = aaa_gw + "/vpp/v4.0.0";
    tariff_api = aaa_gw + "/tariff/v1.0.0";
    etl_api = aaa_gw + "/etl/v1.0.0";
    measurer_api = aaa_gw + "/measurer/v1.0.0";
    measurer_api_2 = aaa_gw + "/measurer/v2.0.0";
    mi_api = aaa_gw + "/mi/v3.0.0";
    ep15_api = aaa_gw + "/ep15/v2.0.0";
    eph_api = aaa_gw + "/eph/v2.0.0";
    pt_api = aaa_gw + "/pt/v1.0.0";
    tos_api = aaa_gw + "/tos/v1.0.0";
    support_api = aaa_gw + "/support/v1.0.0";
    forecast_api = aaa_gw + "/forecast/v2.0.0";
    ht_api = aaa_gw + "/ht/v1.0.0";
    curtailments_api = aaa_gw + "/curtailments/v2.0.0";
    //  aux_api = aaa_gw + "/auxMeasurerService/v1";
    change_pwd = aaa_gw + "/um/v1.0.0";
    impersonate_api = aaa_gw + "/impersonate-user/v1.0.0";
    SOLAR = 5;
    NON_SOLAR = 6;
    AAA_LOGIN = aaa_gw + "/token";
    AAA_REFRESH_TOKEN = aaa_gw + "/token";
    AAA_LOGOUT = aaa_gw + "/revoke";
    LOGIN_WEB_API_URL = noc_api + "/login";
    PASSWORD_RESET_WEB_API_URL = noc_api + "/account/resetPassword";
    LOGOUT_WEB_API_URL = noc_api + "/logout";
    PROFILE_WEB_API_URL = noc_api + "/account/profile";
    REFRESH_TOKEN = noc_api + "/refresh";
    VALIDATE_DOMAIN = aaa_gw + "/lh/v1.0.0/validate";
    IMPERSONATE_USER_URL = impersonate_api + "/impersonate";
    forecast_algid = "v01_alg_NOC";
    TOS_STATUS_URL = tos_api + "/checkUserServiceTosStatus";
    TOS_ACCEPT_URL = tos_api + "/acceptUserServiceTos";
    OPT_API_VERSION = opt_api + "/version";
    INVOICING_API_VERSION = invoicing_api + "/version";
    ETL_API_VERSION = etl_api + "/version";
    VPP2_API_VERSION = vpp2_api + "/version";
    TARIFF_API_VERSION = tariff_api + "/version";
    MEASURER2_API_VERSION = measurer_api_2 + "/version";
    MI_API_VERSION = mi_api + "/version";
    PIPELINE_ENDPOINT_VERSION = aaa_gw + "/ep/v2.0.0/version";
    BASE_CURTAILMENT = noc_api + "/curtailments";
    BILLING_SCHEDULES = noc_api + "/sites/schedules";
    SAVINGS_WEB_API_URL = noc_api + "/savings/overview";
    MAP_SITES_WEB_API_URL = noc_api + "/sites/map";
    BUILDING_POWER_WEB_API_URL = noc_api + "/graph/buildings";
    INFO_BY_SITE_WEB_API_URL = noc_api + "/sites/view";
    SAVINGS_BY_SITE_WEB_API_URL = noc_api + "/savings/site";
    CURRENT_LAST_YEAR_TOTAL_SAVINGS_BY_SITE_WEB_API_URL = noc_api + "/savings/siteoverview";
    INVOICES_WEB_API_URL = noc_api + "/savings/invoices";
    BILLING_GRAPH_WEB_API_URL = noc_api + "/savings/graph";
    INVOICE_DETAILS_WEB_API_URL = noc_api + "/savings/view";
    SITE_LOAD_WEB_API_URL = noc_api + "/sites/load";
    CHANGE_PASSWORD_URL = change_pwd;
    EMAIL_SUPPORT_URL = noc_api + "/account/support";
    EMAIL_SUPPORT_URL_V1 = support_api + "/supportEmail";
    SUMMARY_WEB_API_URL = noc_api + "/savings/summary";
    UPLOAD_SITE_IMAGE = noc_api + "/vpp/image"
    BILLING_GRAPH = noc_api + "/savings/graph";
    FINAL_INVOICE = invoicing_api + "/invoices";
    INVOICE_ARCHIVE = invoicearchive_api;
    INVOICE_TS = invoicing_api + "/ts";
    UNMODIFIED_INVOICE_TS = invoicing_api + "/unmodifiedTs";
    INVOICES = noc_api + "/savings/invoices";
    INVOICES_BY_CUSTOMER_ID = invoicing_api + "/customers";
    INVOICES_LIST = pm_api + "/customers";
    INVOICE_DETAILS = noc_api + "/savings/view";
    PROJECT_NAMES_BY_CUSTOMER = pm_api + "/customers";
    HISTORICAL_TRANSACTION_SUMMARY = pm_api + "/customers"
    CUSTOMER = "https://52.25.212.233:8081/customers";
    FINANCE_CONFIGS = "https://52.25.212.233:8080/financeitemconfigs";
    INSTALLATION_CONFIGS = "https://52.25.212.233:8080/installationconfigs";
    MAP = "ws://52.25.212.233:8089/maps/stream";
    METERS = "https://52.25.212.233:8081/meters";
    NAICS = "https://52.25.212.233:8081/naics";
    ORGS = "https://52.25.212.233:8080/orgs";
    PARTNERS = "https://52.25.212.233:8081/partners";
    PICKLISTS = "https://52.25.212.233:8080/picklists/list";
    PROJECTS = "https://52.25.212.233:8081/projects";
    SITES = "https://52.25.212.233:8081/sites";
    SYSTEM_CONFIGS = "https://52.25.212.233:8080/systemconfigs";
    USERS = "https://52.25.212.233:8080/users"
    ANALYSIS = opt_api;
    ANALYSIS_RESULTLISTS = opt_api + "/analyses";
    ANALYSIS_ETL = etl_api;
    ANALYSIS_FEATURE_GROUPS = pm_api + "/featureGroups";
    ANALYSIS_HISTORICAL = ht_api;
    ANALYSIS_HIST_UPLOAD = ht_api + "/upload";
    ANALYSIS_START_END_DATE = ht_api + "/upload";
    ANALYSIS_UTILITYLISTS = tariff_api + "/utilities";
    ANALYSIS_UTILITYSEARCHEDLISTS = opt_api + "/utilities/_search";
    ANALYSIS_BILLINGSCYCLE = tariff_api + "/_search/billingCycle";
    ANALYSIS_BILLINGPERIODS = tariff_api + "/_search/billingPeriodsListByQueryDate";
    ANALYSIS_SORT_FILTER_RESULTLISTS = opt_api + "/analyses/_search";
    ANALYSIS_CUSTOMER = pm_api + "/customers";
    ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK = pm_api + "/customerServiceProjectLinks";
    ANALYSIS_MANUFACTURERS = pm_api + "/manufacturers";
    ANALYSIS_PROPOSAL = pm_api + "/proposals";
    ANALYSIS_PORTFOLIO = pm_api + "/portfolios";
    ANALYSIS_GENERATOR = pm_api + "/generators";
    ANALYSIS_GENERATOR_BY_PROJECT = pm_api;
    ANALYSIS_GENERATOR_TYPES = pm_api + "/generatorTypes";
    ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS = pm_api + "/generatorServiceProjectLinks";
    ANALYSIS_SERVICE = pm_api + "/services";
    ANALYSIS_SYSTEM = pm_api + "/systems";
    ANALYSIS_GENERATOR_SERVICE_LINK = pm_api + "/generatorservicelinks";
    ANALYSIS_SEARCH_CUSTOMER = pm_api + "/_search/customers";
    ANALYSIS_SEARCH_PROPOSAL = pm_api + "/_search/proposals";
    ANALYSIS_SEARCH_SERVICE = pm_api + "/_search/services";
    ANALYSIS_SEARCH_PROJECT = pm_api + "/_search/projects";
    ANALYSIS_SEARCH_PORTFOLIO = pm_api + "/_search/portfolios";
    ANALYSIS_SEARCH_NAICSID = pm_api + "/_search/naics";
    ANALYSIS_PROJECT_PARENT = pm_api + "/convenientapis/projectparentlookup";
    ANALYSIS_NAICSID = pm_api + "/naics";
    ANALYSIS_SYSTEMS = pm_api + "/systems"
    PMT_PROJECT = pm_api + "/projects";
    PMT_TARIFF_RATE = tariff_api + "/utilities";
    PMT_TEMPLATE = fm_api + "/templates"
    MONTHLY_TRANSACTIONS = pm_api + "/transactions";
    MONTHLY_INVOICE = invoicing_api + "/invoices"
    CURTAIL = curtailments_api;
    FORECAST = forecast_api;
    FLEX_FORECAST = forecast_api;
    EVERY_SIXTY_MIN = eph_api;
    EVERY_FIFTEEN_MIN = ep15_api
    BY_SITE = pt_api + "/sites";
    CLUSTERS = pt_api + "/closedBillingSnapshotClusters";
    DAILY_SNAPSHOTS = pt_api + "/timeseries/dailySnapshots"
    CREATE = mi_api + "/schedules";
    EXCEPTIONS = mi_api + "/scheduleExceptions";
    GRAPH = mi_api + "/dynamic/vppevents";
    POLLING_SERVICE = "wss://om.staging.app.greencharge.net:3000";
    MI_ACK_STATUS = mi_api + "/gs-ops-monitor-data-query/v1/data/miAckStatus"
    BATTERYCYCLE = measurer_api_2 + "/aux/batteryCycleData/projects";
    ITC = measurer_api_2 + "/aux/itcData/projects"
    VPPEVENT_STAT = measurer_api_2 + "/aux/statsDataByVppEvents"
    ANALYSIS_VPP = noc_api + "/vpp/analysis";
    ANALYSIS_SITELISTS = noc_api + "/vpp/analysis/list";
    ANALYSIS_CUSTOMERLISTS = noc_api + "/vpp/analysis/customer";
    ANALYSIS_METERLISTS = noc_api + "/vpp/analysis/meter";
    ANALYSIS_TARIFFLISTS = noc_api + "/vpp/analysis/tariff";
    ANALYSIS_BILLSCHLISTS = noc_api + "/vpp/analysis/billsch";
    ANALYSIS_ESSATRIFFLISTS = noc_api + "/vpp/analysis/esstariff";
    ANALYSIS_HIST_UPLOAD = noc_api + "/vpp/analysis/historyupload";
    ANALYSIS_RESULTLISTS = noc_api + "/vpp/analysis/result";
    ANALYSIS_METERLISTS = noc_api + "/vpp/analysis/meter_result";
    ANALYSIS_METER_BY_ANALYSISNAME_LISTS = noc_api + "/vpp/analysis/meter_by_analyname_result";
    BILLING_SCHEDULES = tariff_api + "/_search/billingCycle";
    DEMAND_RESPONSE_GRAPH = noc_api + "/vpp/dr";
    BILLING_SNAPSHOT_DATE = measurer_api + "/sites";
    ECONOMIC = noc_api + "/economic";
    ENERGY_ARBITRAGE_GRAPH = noc_api + "/vpp/energy-arb";
    FORECAST = noc_api + "/vpp/forecast";
    OVERVIEW_STATS = noc_api + "/vpp/current-stats";
    OVERVIEW_SAVINGS = noc_api + "/vpp/savings/overview";
    OPS_BUILDING_LOAD = noc_api + "/vpp/load";
    FORECAST_ORIG_LOAD = forecast_api;
    PEAK_SHAVING_BAR = noc_api + "/vpp/peak-shaving/overview";
    PIPELINE_CONFIG = vpp_api;
    PIPELINE_SITE = vpp4_api;
    VPP_LIST = vpp4_api;
    VPP_LIST_HOME = vpp4_api;
    REVENUES = noc_api + "/vpp/savings/revenue";
    SOLAR_GENERATION_GRAPH = noc_api + "/vpp/solar";
    TARIFF = tariff_api + "/_search/rateClusterByChargingCycleRangePlotting";
    VITALS_ALERTS = noc_api + "/alerts/vpp";
    VITALS_HEALTH = noc_api + "/vpp/health";
    VPP_SITES = noc_api + "/vpp/list"
    } else if (process.env.NODE_ENV === 'production') {
    //     //HACK
// } else if (true) {
    gsLog.debug("Using app.constants production");
    aaa_gw = "https://api.greencharge.net";
    noc_api = "https://api.greencharge.net/api";
    opt_api = aaa_gw + "/optimizer/v2.0.0";
    invoicing_api = aaa_gw + "/invoicing/v1.0.0";
    invoicearchive_api = aaa_gw + "/invoicearchive/v1.0.0";
    pm_api = aaa_gw + "/pm/v2.0.0";
    fm_api = aaa_gw + "/fm/v1.0.0";
    vpp_api = aaa_gw + "/vpp/v1.0.0";
    vpp2_api = aaa_gw + "/vpp/v2.0.0";
    vpp3_api = aaa_gw + "/vpp/v3.0.0";
    vpp4_api = aaa_gw + "/vpp/v4.0.0";
    tariff_api = aaa_gw + "/tariff/v1.0.0";
    etl_api = aaa_gw + "/etl/v1.0.0";
    measurer_api = aaa_gw + "/measurer/v1.0.0";
    measurer_api_2 = aaa_gw + "/measurer/v2.0.0";
    mi_api = aaa_gw + "/mi/v3.0.0";
    ep15_api = aaa_gw + "/ep15/v2.0.0";
    eph_api = aaa_gw + "/eph/v2.0.0";
    pt_api = aaa_gw + "/pt/v1.0.0";
    tos_api = aaa_gw + "/tos/v1.0.0";
    support_api = aaa_gw + "/support/v1.0.0";
    forecast_api = aaa_gw + "/forecast/v2.0.0";
    ht_api = aaa_gw + "/ht/v1.0.0";
    curtailments_api = aaa_gw + "/curtailments/v2.0.0";
    //aux_api = aaa_gw + "/auxMeasurerService/v1";
    change_pwd = aaa_gw + "/um/v1.0.0";
    impersonate_api = aaa_gw + "/impersonate-user/v1.0.0";
    SOLAR = 12;
    NON_SOLAR = 13;
    forecast_algid = "v1.0.0";
    TOS_STATUS_URL = tos_api + "/checkUserServiceTosStatus";
    TOS_ACCEPT_URL = tos_api + "/acceptUserServiceTos";
    AAA_LOGIN = aaa_gw + "/token";
    AAA_REFRESH_TOKEN = aaa_gw + "/token";
    AAA_LOGOUT = aaa_gw + "/revoke";
    LOGIN_WEB_API_URL = noc_api + "/login";
    PASSWORD_RESET_WEB_API_URL = noc_api + "/account/resetPassword";
    LOGOUT_WEB_API_URL = noc_api + "/logout";
    PROFILE_WEB_API_URL = noc_api + "/account/profile";
    REFRESH_TOKEN = noc_api + "/refresh";
    VALIDATE_DOMAIN = aaa_gw + "/lh/v1.0.0/validate";
    IMPERSONATE_USER_URL = impersonate_api + "/impersonate";
    OPT_API_VERSION = opt_api + "/version";
    INVOICING_API_VERSION = invoicing_api + "/version";
    ETL_API_VERSION = etl_api + "/version";
    VPP2_API_VERSION = vpp2_api + "/version";
    TARIFF_API_VERSION = tariff_api + "/version";
    MEASURER2_API_VERSION = measurer_api_2 + "/version";
    MI_API_VERSION = mi_api + "/version";
    PIPELINE_ENDPOINT_VERSION = aaa_gw + "/ep/v2.0.0/version";
    BASE_CURTAILMENT = noc_api + "/curtailments";
    BILLING_SCHEDULES = noc_api + "/sites/schedules";
    SAVINGS_WEB_API_URL = noc_api + "/savings/overview";
    MAP_SITES_WEB_API_URL = noc_api + "/sites/map";
    BUILDING_POWER_WEB_API_URL = noc_api + "/graph/buildings";
    INFO_BY_SITE_WEB_API_URL = noc_api + "/sites/view";
    SAVINGS_BY_SITE_WEB_API_URL = noc_api + "/savings/site";
    CURRENT_LAST_YEAR_TOTAL_SAVINGS_BY_SITE_WEB_API_URL = noc_api + "/savings/siteoverview";
    INVOICES_WEB_API_URL = noc_api + "/savings/invoices";
    BILLING_GRAPH_WEB_API_URL = noc_api + "/savings/graph";
    INVOICE_DETAILS_WEB_API_URL = noc_api + "/savings/view";
    SITE_LOAD_WEB_API_URL = noc_api + "/sites/load";
    CHANGE_PASSWORD_URL = change_pwd;
    EMAIL_SUPPORT_URL = noc_api + "/account/support";
    EMAIL_SUPPORT_URL_V1 = support_api + "/supportEmail";
    SUMMARY_WEB_API_URL = noc_api + "/savings/summary";
    UPLOAD_SITE_IMAGE = noc_api + "/vpp/image";
    BILLING_GRAPH = noc_api + "/savings/graph";
    FINAL_INVOICE = invoicing_api + "/invoices";
    INVOICE_ARCHIVE = invoicearchive_api;
    INVOICE_TS = invoicing_api + "/ts";
    UNMODIFIED_INVOICE_TS = invoicing_api + "/unmodifiedTs";
    INVOICES = noc_api + "/savings/invoices";
    INVOICES_BY_CUSTOMER_ID = invoicing_api + "/customers";
    INVOICES_LIST = pm_api + "/customers";
    INVOICE_DETAILS = noc_api + "/savings/view";
    PROJECT_NAMES_BY_CUSTOMER = pm_api + "/customers";
    HISTORICAL_TRANSACTION_SUMMARY = pm_api + "/customers";
    CUSTOMER = "https://pm.greencharge.net/customers";
    FINANCE_CONFIGS = "https://pm.greencharge.net/financeitemconfigs";
    INSTALLATION_CONFIGS = "https://pm.greencharge.net/installationconfigs";
    MAP = "ws://52.25.212.233:8089/maps/stream";
    METERS = "https://pm.greencharge.net/meters";
    NAICS = "https://pm.greencharge.net/naics";
    ORGS = "https://pm.greencharge.net/orgs";
    PARTNERS = "https://pm.greencharge.net/partners";
    PICKLISTS = "https://pm.greencharge.net/picklists/list";
    PROJECTS = "https://pm.greencharge.net/projects";
    SITES = "https://pm.greencharge.net/sites";
    SYSTEM_CONFIGS = "https://pm.greencharge.net/systemconfigs";
    USERS = "https://pm.greencharge.net/users";
    ANALYSIS = opt_api;
    ANALYSIS_RESULTLISTS = opt_api + "/analyses";
    ANALYSIS_ETL = etl_api;
    ANALYSIS_FEATURE_GROUPS = pm_api + "/featureGroups";
    ANALYSIS_HISTORICAL = ht_api;
    ANALYSIS_HIST_UPLOAD = ht_api + "/upload";
    ANALYSIS_START_END_DATE = ht_api + "/upload";
    ANALYSIS_UTILITYLISTS = tariff_api + "/utilities";
    ANALYSIS_UTILITYSEARCHEDLISTS = opt_api + "/utilities/_search";
    ANALYSIS_BILLINGSCYCLE = tariff_api + "/_search/billingCycle";
    ANALYSIS_BILLINGPERIODS = tariff_api + "/_search/billingPeriodsListByQueryDate";
    ANALYSIS_SORT_FILTER_RESULTLISTS = opt_api + "/analyses/_search";
    ANALYSIS_CUSTOMER = pm_api + "/customers";
    ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK = pm_api + "/customerServiceProjectLinks";
    ANALYSIS_MANUFACTURERS = pm_api + "/manufacturers";
    ANALYSIS_PROPOSAL = pm_api + "/proposals";
    ANALYSIS_PORTFOLIO = pm_api + "/portfolios";
    ANALYSIS_GENERATOR = pm_api + "/generators";
    ANALYSIS_GENERATOR_BY_PROJECT = pm_api;
    ANALYSIS_GENERATOR_TYPES = pm_api + "/generatorTypes";
    ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS = pm_api + "/generatorServiceProjectLinks";
    ANALYSIS_SERVICE = pm_api + "/services";
    ANALYSIS_SYSTEM = pm_api + "/systems";
    ANALYSIS_GENERATOR_SERVICE_LINK = pm_api + "/generatorservicelinks";
    ANALYSIS_SEARCH_CUSTOMER = pm_api + "/_search/customers";
    ANALYSIS_SEARCH_PROPOSAL = pm_api + "/_search/proposals";
    ANALYSIS_SEARCH_SERVICE = pm_api + "/_search/services";
    ANALYSIS_SEARCH_PROJECT = pm_api + "/_search/projects";
    ANALYSIS_SEARCH_PORTFOLIO = pm_api + "/_search/portfolios";
    ANALYSIS_SEARCH_NAICSID = pm_api + "/_search/naics";
    ANALYSIS_PROJECT_PARENT = pm_api + "/convenientapis/projectparentlookup";
    ANALYSIS_NAICSID = pm_api + "/naics";
    ANALYSIS_SYSTEMS = pm_api + "/systems";
    PMT_PROJECT = pm_api + "/projects";
    PMT_TARIFF_RATE = tariff_api + "/utilities";
    PMT_TEMPLATE = fm_api + "/templates";
    MONTHLY_TRANSACTIONS = pm_api + "/transactions";
    PMT_TARIFF_RATE = tariff_api + "/utilities";
    MONTHLY_INVOICE = invoicing_api + "/invoices";
    CURTAIL = curtailments_api;
    FORECAST = forecast_api;
    FLEX_FORECAST = forecast_api;
    EVERY_SIXTY_MIN = eph_api;
    EVERY_FIFTEEN_MIN = ep15_api
    BY_SITE = pt_api + "/sites";
    CLUSTERS = pt_api + "/closedBillingSnapshotClusters";
    DAILY_SNAPSHOTS = pt_api + "/timeseries/dailySnapshots";
    CREATE = mi_api + "/schedules";
    EXCEPTIONS = mi_api + "/scheduleExceptions";
    GRAPH = mi_api + "/dynamic/vppevents";
    POLLING_SERVICE = "wss://om.app.greencharge.net:3000";
    MI_ACK_STATUS = mi_api + "/gs-ops-monitor-data-query/v1/data/miAckStatus";
    BATTERYCYCLE = measurer_api_2 + "/aux/batteryCycleData/projects";
    ITC = measurer_api_2 + "/aux/itcData/projects";
    VPPEVENT_STAT = measurer_api_2 + "/aux/statsDataByVppEvents";
    ANALYSIS_VPP = noc_api + "/vpp/analysis";
    ANALYSIS_SITELISTS = noc_api + "/vpp/analysis/list";
    ANALYSIS_CUSTOMERLISTS = noc_api + "/vpp/analysis/customer";
    ANALYSIS_METERLISTS = noc_api + "/vpp/analysis/meter";
    ANALYSIS_TARIFFLISTS = noc_api + "/vpp/analysis/tariff";
    ANALYSIS_BILLSCHLISTS = noc_api + "/vpp/analysis/billsch";
    ANALYSIS_ESSATRIFFLISTS = noc_api + "/vpp/analysis/esstariff";
    ANALYSIS_HIST_UPLOAD = noc_api + "/vpp/analysis/historyupload";
    ANALYSIS_RESULTLISTS = noc_api + "/vpp/analysis/result";
    ANALYSIS_METERLISTS = noc_api + "/vpp/analysis/meter_result";
    ANALYSIS_METER_BY_ANALYSISNAME_LISTS = noc_api + "/vpp/analysis/meter_by_analyname_result";
    BILLING_SCHEDULES = tariff_api + "/_search/billingCycle";
    DEMAND_RESPONSE_GRAPH = noc_api + "/vpp/dr";
    BILLING_SNAPSHOT_DATE = measurer_api + "/sites";
    ECONOMIC = noc_api + "/economic";
    ENERGY_ARBITRAGE_GRAPH = noc_api + "/vpp/energy-arb";
    FORECAST = noc_api + "/vpp/forecast";
    OVERVIEW_STATS = noc_api + "/vpp/current-stats";
    OVERVIEW_SAVINGS = noc_api + "/vpp/savings/overview";
    OPS_BUILDING_LOAD = noc_api + "/vpp/load";
    FORECAST_ORIG_LOAD = forecast_api;
    PEAK_SHAVING_BAR = noc_api + "/vpp/peak-shaving/overview";
    PIPELINE_CONFIG = vpp_api;
    PIPELINE_SITE = vpp4_api;
    VPP_LIST = vpp4_api;
    VPP_LIST_HOME = vpp4_api;
    REVENUES = noc_api + "/vpp/savings/revenue";
    SOLAR_GENERATION_GRAPH = noc_api + "/vpp/solar";
    TARIFF = tariff_api + "/_search/rateClusterByChargingCycleRangePlotting";
    VITALS_ALERTS = noc_api + "/alerts/vpp";
    VITALS_HEALTH = noc_api + "/vpp/health";
    VPP_SITES = noc_api + "/vpp/list";
}

export const TEMPLATE_ID = {
    SOLAR,
    NON_SOLAR
};

export const TOS_URLS = {
    TOS_STATUS_URL,
    TOS_ACCEPT_URL,
};

export const FORECAST_ALGID = forecast_algid;

export const LOGIN_URLS = {
    AAA_LOGIN,
    AAA_REFRESH_TOKEN,
    AAA_LOGOUT,
    LOGIN_WEB_API_URL,
    PASSWORD_RESET_WEB_API_URL,
    LOGOUT_WEB_API_URL,
    PROFILE_WEB_API_URL,
    REFRESH_TOKEN,
    VALIDATE_DOMAIN,
    IMPERSONATE_USER_URL
};

export const API_VERSION = {
    OPT_API_VERSION,
    INVOICING_API_VERSION,
    ETL_API_VERSION,
    VPP2_API_VERSION,
    TARIFF_API_VERSION,
    MEASURER2_API_VERSION,
    MI_API_VERSION,
    PIPELINE_ENDPOINT_VERSION
};

export const NOC_API = {
    BASE_CURTAILMENT,
    BILLING_SCHEDULES,
    SAVINGS_WEB_API_URL,
    MAP_SITES_WEB_API_URL,
    BUILDING_POWER_WEB_API_URL,
    INFO_BY_SITE_WEB_API_URL,
    SAVINGS_BY_SITE_WEB_API_URL,
    CURRENT_LAST_YEAR_TOTAL_SAVINGS_BY_SITE_WEB_API_URL,
    INVOICES_WEB_API_URL,
    BILLING_GRAPH_WEB_API_URL,
    INVOICE_DETAILS_WEB_API_URL,
    SITE_LOAD_WEB_API_URL,
    CHANGE_PASSWORD_URL,
    EMAIL_SUPPORT_URL,
    EMAIL_SUPPORT_URL_V1,
    SUMMARY_WEB_API_URL,
    UPLOAD_SITE_IMAGE
};

export const BILLING_API = {
    BILLING_GRAPH,
    FINAL_INVOICE,
    INVOICE_ARCHIVE,
    INVOICE_TS,
    UNMODIFIED_INVOICE_TS,
    INVOICES,
    INVOICES_BY_CUSTOMER_ID,
    INVOICES_LIST,
    INVOICE_DETAILS,
    PROJECT_NAMES_BY_CUSTOMER,
    HISTORICAL_TRANSACTION_SUMMARY
};

export const PORTFOLIO_API = {
    CUSTOMER,
    FINANCE_CONFIGS,
    INSTALLATION_CONFIGS,
    MAP,
    METERS,
    NAICS,
    ORGS,
    PARTNERS,
    PICKLISTS,
    PROJECTS,
    SITES,
    SYSTEM_CONFIGS,
    USERS
};

export const PAT_API = {
    ANALYSIS,
    ANALYSIS_RESULTLISTS,
    ANALYSIS_ETL,
    ANALYSIS_FEATURE_GROUPS,
    ANALYSIS_HISTORICAL,
    ANALYSIS_HIST_UPLOAD,
    ANALYSIS_START_END_DATE,
    ANALYSIS_UTILITYLISTS,
    ANALYSIS_UTILITYSEARCHEDLISTS,
    ANALYSIS_BILLINGSCYCLE,
    ANALYSIS_BILLINGPERIODS,
    ANALYSIS_SORT_FILTER_RESULTLISTS,
    ANALYSIS_CUSTOMER,
    ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK,
    ANALYSIS_MANUFACTURERS,
    ANALYSIS_PROPOSAL,
    ANALYSIS_PORTFOLIO,
    ANALYSIS_GENERATOR,
    ANALYSIS_GENERATOR_BY_PROJECT,
    ANALYSIS_GENERATOR_TYPES,
    ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS,
    ANALYSIS_SERVICE,
    ANALYSIS_SYSTEM,
    ANALYSIS_GENERATOR_SERVICE_LINK,
    ANALYSIS_SEARCH_CUSTOMER,
    ANALYSIS_SEARCH_PROPOSAL,
    ANALYSIS_SEARCH_SERVICE,
    ANALYSIS_SEARCH_PROJECT,
    ANALYSIS_SEARCH_PORTFOLIO,
    ANALYSIS_SEARCH_NAICSID,
    ANALYSIS_PROJECT_PARENT,
    ANALYSIS_NAICSID,
    ANALYSIS_SYSTEMS
};

export const PMT_API = {
    PMT_PROJECT,
    PMT_TARIFF_RATE,
    PMT_TEMPLATE,
};
export const MONTHLY_SAVINGS = {
    MONTHLY_TRANSACTIONS,
    MONTHLY_INVOICE,
};
export const PIPELINE_API = {
    CURTAIL,
    FORECAST,
    FLEX_FORECAST,
    EVERY_SIXTY_MIN,
    EVERY_FIFTEEN_MIN,
};
export const BILLING_SNAPSHOTS_API = {
    BY_SITE,
    CLUSTERS,
    DAILY_SNAPSHOTS,
};
export const SCHEDULE_API = {
    CREATE,
    EXCEPTIONS,
    GRAPH,
    //   POLLING_SERVICE,
    //   POLLING_SERVICE,
    POLLING_SERVICE,
    MI_ACK_STATUS,
};
export const HEALTH_API = {
    BATTERYCYCLE,
    ITC,
};
export const AUX_API = {
    VPPEVENT_STAT,
};
export const VPP_API = {
    ANALYSIS: ANALYSIS_VPP,
    ANALYSIS_SITELISTS,
    ANALYSIS_CUSTOMERLISTS,
    ANALYSIS_METERLISTS,
    ANALYSIS_UTILITYLISTS,
    ANALYSIS_TARIFFLISTS,
    ANALYSIS_BILLSCHLISTS,
    ANALYSIS_ESSATRIFFLISTS,
    ANALYSIS_HIST_UPLOAD,
    ANALYSIS_RESULTLISTS,
    ANALYSIS_METERLISTS,
    ANALYSIS_METER_BY_ANALYSISNAME_LISTS,
    BILLING_SCHEDULES,
    DEMAND_RESPONSE_GRAPH,
    BILLING_SNAPSHOT_DATE,
    ECONOMIC,
    ENERGY_ARBITRAGE_GRAPH,
    FORECAST,
    OVERVIEW_STATS,
    OVERVIEW_SAVINGS,
    OPS_BUILDING_LOAD,
    FORECAST_ORIG_LOAD,
    PEAK_SHAVING_BAR,
    PIPELINE_CONFIG,
    PIPELINE_SITE,
    VPP_LIST,
    VPP_LIST_HOME,
    REVENUES,
    SOLAR_GENERATION_GRAPH,
    TARIFF,
    VITALS_ALERTS,
    VITALS_HEALTH,
    VPP_SITES,
};

export const CHART_OVERRIDE = [
    { id: "9a048b9", override: { originalTimeSeries: true, originalNameLabel: "Original", socTimeSeries: true, socNameLabel: "Battery SOC", solarTimeSeries: true, solarNameLabel: "Solar Generation", netLoadSeries: true, netLoadNameLabel: "Aggregate Generation", solarColChart: true, solarColChartLabel: "Solar Generated", energyColChart: true, energyColChartLabel: "Storage Output", demandThresholdSeries: true, demandThresholdNameLabel: "Demand Threshold" } }
];
