import { combineReducers } from 'redux';

// import browserWarningServices from './browserWarningServices';
import cachedObjectServices from './cachedObjectServices';
import sessionCheckServices from './sessionCheckServices';
// import customerServices from './customerServices';
// import expandRetractServices from './expandRetractServices';
// import floatingBarServices from './floatingBarServices';
import loggedInUserServices from './loggedInUserServices';
// import overviewServices from './overviewServices';
// import forecastServices from './forecastServices';
// import historicalViewServices from './historicalViewServices';
import invoiceServices from './invoiceServices';
import operationsStatusBarServices from './operationsStatusBarServices';
// import overviewStatusBarServices from './overviewStatusBarServices';
// import pmtProposalServices from './pmtProposalServices';
// import pmtWizardAddMeterServices from './pmtWizardAddMeterServices';
import pmtWizardCustomerServices from './pmtWizardCustomerServices';
// import pmtWizardUtilityServices from './pmtWizardUtilityServices';
// import pmtWizardGeneratorServices from './pmtWizardGeneratorServices';
// import pmtWizardProjectServices from './pmtWizardProjectServices';
// import pmtWizardServiceServices from './pmtWizardServiceServices';
import pmtWizardServices from './pmtWizardServices';
// import pmtTariffServices from './pmtTariffServices';
// import pmtWizardStorageServices from './pmtWizardStorageServices';
import portfolioSelectorServices from './portfolioSelectorServices';
// import tariffSelectorServices from './tariffSelectorServices';
import portfolioAnalysisSelectorSidebar from './portfolioAnalysisSelectorSidebar';
// import portfolioSelectorSidebar from './portfolioSelectorSidebar';
import schedulePollingServices from './schedulePollingServices';
import scheduleServices from './scheduleServices';
import healthCycleServices from './healthCycleServices';
import itcServices from './itcServices';
import monthlySavingsServices from './monthlySavingsServices';
import vitalsConflictRejectServices from './vitalsConflictRejectServices';
import pmtTariffRateInfoServices from './pmtTariffRateInfoServices';
import scheduleSidebarServices from './scheduleSidebarServices';
// import healthCycleSidebarServices from './healthCycleSidebarServices';
// import itcSidebarServices from './itcSidebarServices';
import timezoneServices from './timezoneServices';
import toggleAnalysisFooterServices from './toggleAnalysisFooterServices';
import toggleFooterServices from './toggleFooterServices';
// import vitalsForecastSidebarServices from './vitalsForecastSidebarServices';
// import vitalsForecastTimeSelectorServices from './vitalsForecastTimeSelectorServices';
// import vitalsGridPowerServices from './vitalsGridPowerServices';
import vppSelectorServices from './vppSelectorServices';
import vppSelectorSidebarServices from './vppSelectorSidebarServices';
import vppSelectorToggleServices from './vppSelectorToggleServices';
import operationTimezoneServices from './operationTimezoneServices';
import toggleHeliosFooterServices from './toggleHeliosFooterServices';
import heliosProjectSelectorServices from './heliosProjectSelectorServices';
// import vpp from './vppList';
// import vppServices from './vppServices';
// import iapStatusBarService from './iapStatusBarService';

const rootReducer = combineReducers({
  // browserWarningServices,
  cachedObjectServices,
  sessionCheckServices,
  // customerServices,
  // expandRetractServices,
  // floatingBarServices,
  // forecastServices,
  // historicalViewServices,
  invoiceServices,
  loggedInUserServices,
  // overviewServices,
  operationsStatusBarServices,
  // iapStatusBarService,
  // overviewStatusBarServices,
  // pmtProposalServices,
  // pmtWizardAddMeterServices,
  pmtWizardCustomerServices,
  // pmtWizardUtilityServices,
  // pmtWizardGeneratorServices,
  // pmtWizardProjectServices,
  // pmtWizardServiceServices,
  pmtWizardServices,
  // pmtTariffServices,
  // pmtWizardStorageServices,
  // vpp: vppServices,
  portfolioSelectorServices,
  // tariffSelectorServices,
  portfolioAnalysisSelectorSidebar,
  // portfolioSelectorSidebar,
  schedulePollingServices,
  scheduleServices,
  healthCycleServices,
  itcServices,
  monthlySavingsServices,
  vitalsConflictRejectServices,
  pmtTariffRateInfoServices,
  scheduleSidebarServices,
  // healthCycleSidebarServices,
  // itcSidebarServices,
  timezoneServices,
  toggleAnalysisFooterServices,
  toggleFooterServices,
  // vitalsForecastSidebarServices,
  // vitalsForecastTimeSelectorServices,
  // vitalsGridPowerServices,
  vppSelectorServices,
  vppSelectorSidebarServices,
  vppSelectorToggleServices,
  operationTimezoneServices,
  heliosProjectSelectorServices,
  toggleHeliosFooterServices
  // vpp: vppServices
});

export default rootReducer;
