import { PAT_API, PMT_API } from '../shared/app.constants.all';
import { default as gsLog } from '../service/loggingService';
import axios from 'axios';
class ProposalsApi {
  constructor() {
    this.axios = axios;
    this.gsLog = gsLog;
    this.PAT_API = PAT_API;
  }

  getProposals({ serviceUUID = undefined, projectUUID = undefined, page = undefined, size = undefined, sortBy = undefined, order = undefined }) {
    let params = {};
    this.gsLog.debug(`In ProposalsApi: getProposals(): serviceUUID = ${serviceUUID}, projectUUID = ${projectUUID}, page = ${page}, size=${size}, sortBy = ${sortBy}, order=${order}`);

    if (serviceUUID) {
      params.serviceUUID = serviceUUID;
    }

    if (projectUUID) {
      params.projectUUID = projectUUID;
    }

    if (page) {
      params.page = page;
    }

    if (size) {
      params.size = size;
    }

    if (sortBy) {
      params.sortBy = sortBy;
    }

    if (order) {
      params.order = order;
    }

    let httpRequest = this.axios({
      method: "GET",
      url: this.PAT_API.ANALYSIS_PROPOSAL,
      params
    });

    return httpRequest;
  }

  getServiceInfo(serviceUUID) {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/0/services/${serviceUUID}`
    });

    return httpRequest;
  }

  getProjectInfo(projectUUID) {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/0/services/0/projects/${projectUUID}`
    });

    return httpRequest;
  }

  getTariffInfo(tariffId, utilityId = "0") {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}/tariffs/${tariffId}`
    });

    return httpRequest;
  }

  getUtilityInfo(utilityId) {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}?compact=true`
    });

    return httpRequest;
  }

  getCalendarInfo(calendarId, utilityId = "0") {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}/calendars/${calendarId}`
    });

    return httpRequest;
  }

  getSystemInfo(systemId) {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_SYSTEMS}/${systemId}`
    });

    return httpRequest;
  }

  getListOfUtilities() {
    let httpRequest = this.axios({
      url: this.PAT_API.ANALYSIS_UTILITYLISTS + "?compact=true",
      method: "GET",
    });

    return httpRequest;
  }

  getListOfUtilitiesForDev() {
    let httpRequest = this.axios({
      method: "GET",
      url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}`,
      params: {
        compact: true
      }
    });

    return httpRequest;
  }
}

ProposalsApi.$inject = [
  "$http",
  "gs.services.gsLogService",
  "PAT_API"
];

export default ProposalsApi;
