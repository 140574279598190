
import '../react-transition/interceptor.js';
import axios from 'axios';
import { default as gsLog } from '../service/loggingService';
import { BILLING_API, PAT_API, NOC_API, PMT_API } from '../shared/app.constants.all';


class PatApi {
    constructor() {
        if (PatApi.instance) {
            return PatApi.instance;
        }
        this.BILLING_API = BILLING_API;
        this.PMT_API = PMT_API;
        this.PAT_API = PAT_API;
        this.NOC_API = NOC_API;
        this.gsLog = gsLog;
        this.bookmarkFilter = "None";
        PatApi.instance = this;
    }

    getCustomers(sortBy = "customerName", size = 10000, tenantId = null) {
        let params = {};

        if (size) {
            params.size = size;
        }
        if (sortBy) {
            params.sortBy = sortBy;
        }
        if (tenantId) {
            params.tenantId = tenantId;
        }
        if (this.bookmarkFilter != "None") {
            if (this.bookmarkFilter == "bookmarkedCustomerOnly")
                params.bookmarkedCustomerOnly = true;
            if (this.bookmarkFilter == "bookmarkedServiceOnly")
                params.bookmarkedServiceOnly = true;
            if (this.bookmarkFilter == "bookmarkedProjectOnly")
                params.bookmarkedProjectOnly = true;
            if (this.bookmarkFilter == "bookmarkedProposalOnly")
                params.bookmarkedProposalOnly = true;
            if (this.bookmarkFilter == "onlineProjectsOnly")
                params.onlineProjectsOnly = true;

        }
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}`,
            method: "GET",
            // headers: {
            //   adminKey: "thisisatest"
            // },
            params
        });

        return request;
    }

    getServices(customerUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services`,
            method: "GET"
        });

        return request;
    }

    getService(customerUUID, serviceUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}`,
            method: "GET"
        });

        return request;
    }

    getServicesBycustomerUUID(customerUUID, size = 10000) {
        let params = {};

        if (size) {
            params.size = size;
        }
        if (this.bookmarkFilter != "None") {
            if (this.bookmarkFilter == "bookmarkedCustomerOnly")
                params.bookmarkedCustomerOnly = true;
            if (this.bookmarkFilter == "bookmarkedServiceOnly")
                params.bookmarkedServiceOnly = true;
            if (this.bookmarkFilter == "bookmarkedProjectOnly")
                params.bookmarkedProjectOnly = true;
            if (this.bookmarkFilter == "bookmarkedProposalOnly")
                params.bookmarkedProposalOnly = true;
            if (this.bookmarkFilter == "onlineProjectsOnly")
                params.onlineProjectsOnly = true;

        }
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/pagedServices`,
            method: "GET",
            params: params
        });

        return request;
    }

    createCustomerProjectLink(customerUUID, projectUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                customerUUID,
                projectUUID
            }
        });

        return request;
    }

    createServiceProjectLink(serviceUUID, projectUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER_SERVICE_PROJECT_LINK}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                serviceUUID,
                projectUUID
            }
        });

        return request;
    }

    createGeneratorServiceLink(generatorUUID, isReal, serviceUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                generatorUUID,
                serviceUUID,
                isReal
            }
        });

        return request;
    }

    createGeneratorProjectLink(generatorUUID, isReal, projectUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_GENERATOR_SERVICE_PROJECT_LINKS}`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                generatorUUID,
                projectUUID,
                isReal
            }
        });

        return request;
    }

    getSystems(filterDate, manufacturerId, size = 1000) {
        const request = axios({
            url: this.PAT_API.ANALYSIS_SYSTEM,
            method: "GET",
            params: {
                filterDate,
                manufacturerId,
                size
            }
        }).then((response) => {
            let list = response.data && response.data.content ? response.data.content : [];
            list = list.slice().sort((a, b) => {
                // sorted by inverterACCapacity first,
                const compareA = a.inverterACCapacity;
                const compareB = b.inverterACCapacity;
                //if same inverterACCapacity, sorted by name
                const compare2A = a.systemName;
                const compare2B = b.systemName;
                let comparison = 0;

                if (compareA > compareB) {
                    comparison = 1;
                } else if (compareA < compareB) {
                    comparison = -1;
                }

                if (comparison == 0) {
                    if (compare2A > compare2B) {
                        comparison = 1;
                    } else if (compare2A < compare2B) {
                        comparison = -1;
                    }
                }

                return comparison;
            });

            response.data.content = list;
            return response;
        });

        return request;
    }

    getSystemsById(systemId, featureIds, size = 1000) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_SYSTEM}/${systemId}/componentsByFeatureGroups`,
            method: "GET",
            params: {
                size,
                featureIds
            }
        });

        return request;
    }

    createService({ serviceUUID, serviceName, address, city, customerUUID, meterNumber, naicsId = null, serviceAccountId, calendarId, tariffId, utilityId, siteName = null, state, zipcode, timeZone }) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                serviceUUID,
                serviceName,
                address,
                calendarId,
                city,
                customerUUID,
                meterNumber,
                naicsId,
                serviceAccountId,
                siteName,
                state,
                tariffId,
                timeZone,
                utilityId,
                zipcode
            }
        });

        return request;
    }

    createGenerator({
        generatorUUID,
        annualDegradation,
        annualIncentive,
        annualIncentiveEscalation,
        capex,
        epc,
        escalation,
        excludeSolarDemandSavings,
        generatorMeterNumber,
        generatorName,
        generatorType,
        incentiveDuration,
        includeNgomEstimateFlag,
        includeTelemetryEstimateFlag,
        installDate,
        inverterACCapacity,
        inverterDCCapacity,
        inverterReplacementCost,
        inverterReplacementYear,
        manufacturer,
        opex,
        solarTariffId,
        upfrontIncentive
    }) {
        return axios({
            url: this.PAT_API.ANALYSIS_GENERATOR,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                generatorUUID,
                annualDegradation,
                annualIncentive,
                annualIncentiveEscalation,
                capex,
                epc,
                escalation,
                excludeSolarDemandSavings,
                generatorMeterNumber,
                generatorName,
                generatorType,
                incentiveDuration,
                includeNgomEstimateFlag,
                includeTelemetryEstimateFlag,
                installDate,
                inverterACCapacity,
                inverterDCCapacity,
                inverterReplacementCost,
                inverterReplacementYear,
                manufacturer,
                opex,
                solarTariffId,
                upfrontIncentive
            }
        });
    }

    getManufacturers() {
        const request = axios({
            url: this.PAT_API.ANALYSIS_MANUFACTURERS,
            method: "GET"
        });

        return request;
    }

    getGeneratorTypes() {
        const request = axios({
            url: this.PAT_API.ANALYSIS_GENERATOR_TYPES,
            method: "GET"
        });

        return request;
    }

    getGeneratorsByCustomerServiceUUID(customerUUID, serviceUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}/generators`,
            method: "GET"
        });

        return request;
    }

    getGeneratorsByProjectUUID(projectUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_GENERATOR_BY_PROJECT}/projects/${projectUUID}/generators`,
            method: "GET"
        });

        return request;
    }

    getLocationPriceBySystemId(systemId) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_SYSTEM}/${systemId}/systemAdjustments`,
            method: "GET"
        });

        return request;
    }

    getFeatureGroups(featureGroupId) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_FEATURE_GROUPS}/${featureGroupId}/features`,
            method: "GET"
        });

        return request;
    }

    createCustomer({ customerName, customerCode, naicsId }) {
        const request = axios({
            url: this.PAT_API.ANALYSIS_CUSTOMER,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                customerCode,
                customerName,
                naicsId
            }
        });

        return request;
    }

    createUtility({ utilityCode, utilityName, timeZone, currency, ownership }) {
        const request = axios({
            url: this.PAT_API.ANALYSIS_UTILITYLISTS,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                utilityCode,
                utilityName,
                timeZone,
                currency,
                ownership
            }
        });

        return request;
    }

    updateService({ serviceUUID, utilityId, tariffId, calendarId, serviceName, siteName, serviceAccountId, meterNumber, customerUUID, zipcode, state, city, address }) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                utilityId,
                tariffId,
                calendarId,
                serviceName,
                siteName,
                serviceAccountId,
                meterNumber,
                zipcode,
                state,
                city,
                address
            }
        });

        return request;
    }

    updateCustomer({ customerUUID, customerName, naicsId }) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                customerName,
                naicsId
            }
        });

        return request;
    }

    updateProject({ projectUUID, projectName, adminStage }) {
        const request = axios({
            url: `${this.PMT_API.PMT_PROJECT}/${projectUUID}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                projectName,
                adminStage
            }
        });

        return request;
    }

    createProject({
        arbitrageBinary,
        backfeedMode,
        backupMode,
        codDate,
        contractType,
        demandEscalationY1,
        demandEscalationY11,
        energyEscalationY1,
        energyEscalationY11,
        excludeSolarDemandSavings,
        fixedEscalationY1,
        fixedEscalationY11,
        featureIds,
        guaranteeDemandThreshold,
        guaranteeOption,
        hasSolar,
        includeNgomEstimateFlag,
        includeTelemetryEstimateFlag,
        itcMode,
        itcRatio,
        manufacturerId,
        netSolarTariffId,
        netStorageTariffId,
        originalTariffId,
        peakShavingBinary,
        projectCombinedTaxRate,
        projectDepreciationSchedule,
        projectLifetime,
        projectName,
        projectNpvDiscountRate,
        projectSalesTax,
        siteIdentifier,
        storageOtherAnnualIncentive,
        storageOtherIncentiveDuration,
        storageOtherIncentiveEscalation,
        storageOtherUpfrontIncentive,
        storageSgipRate,
        storageSmartIncentive,
        adminStage = "ANALYSIS" }) {
        const request = axios({
            url: this.PMT_API.PMT_PROJECT,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                adminStage,
                arbitrageBinary,
                backfeedMode,
                backupMode,
                codDate,
                contractType,
                demandEscalationY1,
                demandEscalationY11,
                energyEscalationY1,
                energyEscalationY11,
                excludeSolarDemandSavings,
                featureIds,
                fixedEscalationY1,
                fixedEscalationY11,
                guaranteeDemandThreshold,
                guaranteeOption,
                includeNgomEstimateFlag,
                includeTelemetryEstimateFlag,
                itcMode,
                itcRatio,
                manufacturerId,
                netSolarTariffId,
                netStorageTariffId,
                originalTariffId,
                peakShavingBinary,
                projectCombinedTaxRate,
                projectDepreciationSchedule,
                projectLifetime,
                projectName,
                projectNpvDiscountRate,
                projectSalesTax,
                siteIdentifier,
                solar: hasSolar,
                storageOtherAnnualIncentive,
                storageOtherIncentiveDuration,
                storageOtherIncentiveEscalation,
                storageOtherUpfrontIncentive,
                storageSgipRate,
                storageSmartIncentive
            }
        });

        return request;
    }

    getHistoricalBillingPeriods(queryDate, calendarId = 22, periods = 12) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_BILLINGPERIODS}`,
            method: "GET",
            params: {
                calendarId,
                periods,
                queryDate
            }
        });

        return request;
    }

    bookmarkCustomer(id = -1, isbookMarked) {
        let isCustomerBookmarked = isbookMarked;
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${id}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: { isCustomerBookmarked: isCustomerBookmarked }
        });
        return request;
    }

    bookmarkService(id = -1, customerUUID, isbookMarked) {
        let isServiceBookmarked = isbookMarked;
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${id}`,
            //url: `${this.PAT_API.ANALYSIS_SERVICE}/${id}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: { isServiceBookmarked: isServiceBookmarked }
        });
        return request;
    }

    bookmarkProject(id = -1, isbookMarked) {
        let isProjectBookmarked = isbookMarked;
        const request = axios({
            url: `${this.PMT_API.PMT_PROJECT}/${id}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: { isProjectBookmarked: isProjectBookmarked }
        });
        return request;
    }

    bookmarkProposal(id = -1, isbookMarked) {
        let isProposalBookmarked = isbookMarked;
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_PROPOSAL}/${id}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: { isProposalBookmarked: isProposalBookmarked }
        });
        return request;
    }

    deleteMeter(meterName = "_service_test") {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_HISTORICAL}/epMeters/${meterName}`,
            method: "DELETE"
        });

        return request;
    }

    deleteCustomer(customerUUID = -1) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}`,
            method: "DELETE"
        });

        return request;
    }

    deleteService(serviceUUID = -1, customerUUID = -1) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}`,
            method: "DELETE"
        });

        return request;
    }

    deleteSoftProposal(proposalUUID = -1) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_PROPOSAL}/${proposalUUID}`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                projectUUID: -1
            }
        });

        return request;
    }

    deleteProposal(proposalUUID = -1) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_PROPOSAL}/${proposalUUID}`,
            method: "DELETE"
        });

        return request;
    }

    deleteProject(projectUUID = -1) {
        const request = axios({
            url: `${this.PMT_API.PMT_PROJECT}/${projectUUID}`,
            method: "DELETE"
        });

        return request;
    }

    getHistoricalMeters(meterName = "_service_test") {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_HISTORICAL}/epMeters/${meterName}/summary`,
            method: "GET"
        });

        return request;
    }

    getHistoricalEp15({ endPoints, startDateStr, endDateStr, sites }) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_HISTORICAL}/ep15`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                endPoints,
                first: startDateStr,
                last: endDateStr,
                sites
            }
        });

        return request;
    }

    getTariffListByUtilId(utilityId) {
        return axios({
            url: this.PAT_API.ANALYSIS_UTILITYLISTS + "/" + utilityId + "/tariffs?compact=true",
            method: "GET"
        })
            .then(function (response) {
                return response.data;
            });
    }

    getTariffListByUtilIdUsingObservable(utilityId) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}/tariffs`,
            method: "GET",
            params: {
                compact: true
            }
        });

        return request;
    }

    getTariffListByUtilIdUsingSortedObservable(utilityId) {
        this.gsLog.debug("PatApi getTariffListByUtilIdUsingSortedObservable 000");
        this.gsLog.debug(utilityId);

        const request = axios({
            url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}/pagedTariffs?sortBy=tariffCode&order=asc&size=10000`,
            method: "GET"
        });

        return request;
    }

    getBillScheduleListByUtilId(utilityId, calendarType = null) {
        return axios({
            url: this.PAT_API.ANALYSIS_UTILITYLISTS + "/" + utilityId + "/calendars?calendarType=BILLING&compact=true",
            method: "GET",
            params: {
                compact: true,
                calendarType
            }
        })
            .then(function (response) {
                return response.data;
            });
    }

    getBillingCalendarUsingObservable(utilityId, calendarType = "BILLING") {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_UTILITYLISTS}/${utilityId}/calendars`,
            method: "GET",
            params: {
                compact: true,
                calendarType
            }
        });

        return request;
    }

    getAnalysisSearchedUtilLists(searchName) {
        return axios({
            url: this.PAT_API.ANALYSIS_UTILITYSEARCHEDLISTS,
            method: "GET",
            params: {
                'name': searchName
            }
        })
            .then(function (response) {
                return response.data;
            });
    }

    getServiceListByCustId(customerUUID) {
        return axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services`,
            method: "GET"
        })
            .then(function (response) {
                return response.data;
            });
    }

    getGeneratorListByServId(serviceUUID) {
        return axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/0/services/${serviceUUID}/generators`,
            method: "GET"
        })
            .then(function (response) {
                return response.data.content;
            });
    }

    getAllCustomer() {
        return axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}?sortBy=customerName&order=asc&size=2000`,
            method: "GET"
        })
            .then(function (response) {
                return response.data;
            });
    }

    getAllSystem() {
        return axios({
            url: `${this.PAT_API.ANALYSIS_SYSTEM}?sortBy=systemName&order=asc&size=2000`,
            method: "GET"
        })
            .then(function (response) {
                return response.data;
            });
    }

    getListOfUtilities() {
        this.gsLog.debug("PatApi getListOfUtilities fired!");

        return axios({
            url: this.PAT_API.ANALYSIS_UTILITYLISTS + "?compact=true&size=10000",
            method: "GET"
        })
            .then(function (response) {
                return response.data.content;
            });
    }

    getListOfUtilitiesUsingObservable(sortByField) {
        this.gsLog.debug("PatApi getListOfUtilitiesUsingObservable fired!");

        let httpRequest = axios({
            url: this.PAT_API.ANALYSIS_UTILITYLISTS,
            method: "GET",
            params: {
                compact: true,
                size: 10000,
                sortBy: sortByField
            }
        });

        return httpRequest;
    }

    getAnalysis(analysisId, spaceId) {
        this.gsLog.debug("PatApi getAnalysis fired!");
        this.gsLog.debug(this.PAT_API.ANALYSIS);
        const url = spaceId ? `${this.PAT_API.ANALYSIS}/spaces/${spaceId}/pat/analyses/${analysisId}` : `${this.PAT_API.ANALYSIS}/analyses/${analysisId}`;
        return axios({
            url,
            method: "GET"
        });
    }

    getProposalsByCustomerserviceUUID(customerUUID, serviceUUID) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}/proposals`,
            method: "GET"
        });

        return request;
    }

    getProposals(proposalUUIDs) {
        var deferred = this.$q.defer();
        var urlCalls = [];

        for (let i = 0; i < proposalUUIDs.length; i++) {
            urlCalls.push(axios({
                url: this.PAT_API.ANALYSIS_PROPOSAL + "/" + proposalUUIDs[i],
                method: "GET"
            })
            );
        }

        this.$q.all(urlCalls)
            .then(function (result) {
                deferred.resolve(result);
            },
                function (errors) {
                    deferred.reject(errors);
                }
            );

        return deferred.promise;

    }

    createOptimizer({ analysisName, customerName, siteName, user = "noemail", meterName = "meterName", tariffId, tariffCode = "", tariffName = "", serviceClassification = "", calendarId, calendarName = "", utilityId, utilityName = "", inverterCapacity, batteryCapacity, peakShavingBinary, arbitrageBinary,
        ignoreTariffLimits, itcMode, itcRatio, acRTEfficiency, minSOC, maxSOC, systemId = 0, systemName = "" }, backfeedMode = "Rejected", backupMode = "None", minimumBackupDuration = 0, generators = [], serviceUUID = 0) {
        return axios({
            url: this.PAT_API.ANALYSIS,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                meterName: meterName,
                tariffId: tariffId,
                calendarId: calendarId,
                utilityId: utilityId,
                inverterCapacity: inverterCapacity,
                batteryCapacity: batteryCapacity,
                peakShavingBinary: peakShavingBinary ? 1 : 0,
                arbitrageBinary: arbitrageBinary ? 1 : 0,
                ignoreTariffLimits: ignoreTariffLimits ? 1 : 0,
                backfeedMode: backfeedMode,
                backupMode: backupMode,
                minimumBackupDuration: minimumBackupDuration,
                itcMode: itcMode,
                itcRatio: itcRatio,
                acRTEfficiency: acRTEfficiency,
                minSOC: minSOC,
                maxSOC: maxSOC,
                generators: generators,
                autoGenerate: true,
                metaData: {
                    analysisName: analysisName,
                    siteName: siteName,
                    customerName: customerName,
                    user: user,
                    utilityName: utilityName,
                    tariffCode: tariffCode,
                    tariffName: tariffName,
                    serviceClassification: serviceClassification,
                    serviceUUID: serviceUUID,
                    systemId: systemId,
                    systemName: systemName,
                    utilityName: utilityName,
                    calendarName: calendarName
                }
            }
        })
            .then(function (response) {
                return response.data;
            });
    }

    createProposal(analysisId, proposalName = "", serviceUUID = 0, systemId = 0, utilityId = 0, originalTariffId = 0, peakShavingBinary = 0, arbitrageBinary = 0, itcRatio = 0, originalCalendarId = 0, backfeedMode = "Rejected", backupMode = "None", minimumBackupDuration = 0, itcMode = "None") {
        return axios({
            url: this.PAT_API.ANALYSIS_PROPOSAL,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                proposalName,
                serviceUUID,
                systemId,
                utilityId,
                originalTariffId,
                netSolarTariffId: originalTariffId,
                netStorageTariffId: originalTariffId,
                arbitrageBinary: arbitrageBinary ? 1 : 0,
                peakShavingBinary: peakShavingBinary ? 1 : 0,
                originalCalendarId,
                itcRatio: itcRatio,
                backfeedMode,
                backupMode,
                minimumBackupDuration,
                itcMode,
                analysisId
            }
        })
            .then(function (response) {
                return response.data;
            });
    }

    createAnalysis(
        { startDate, endDate, callMeasurer, derateModes, guaranteeDemandPriceLimit, solarDemandMonthDerate, financialAnalysisConfig },
        vpps,
        projects,
        generators,
        { analysisName },
        spaceId) {

        const url = `${this.PAT_API.ANALYSIS}/spaces/${spaceId}/pat/analyses`;
        const request = axios({
            url,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                // meterName,
                startDate,
                endDate,
                callMeasurer,
                derateModes,
                guaranteeDemandPriceLimit,
                solarDemandMonthDerate,
                financialAnalysisConfig,
                // tariffId,
                // calendarId,
                // utilityId,
                // inverterCapacity,
                // batteryCapacity,
                // peakShavingBinary,
                // arbitrageBinary,
                // backfeedMode,
                // backupMode,
                // minimumBackupDuration,
                // itcMode,
                // itcRatio,
                // acRTEfficiency,
                // minSOC,
                // maxSOC,
                // initialSOC,
                // ignoreTariffLimits,
                vpps,
                projects,
                generators,
                metaData: {
                    analysisName,
                    // proposalUUID,
                    // customerName,
                    // utilityName,
                    // tariffCode,
                    // calendarName,
                    // systemName
                }
            }
        });

        return request;
    }

    createProposalUsingObservable({
        analysisId,
        arbitrageBinary,
        backfeedMode,
        backupMode,
        calendarName,
        codDate,
        commentIds,
        comments,
        contracted,
        contractType,
        creditRating,
        customerShare,
        demandEscalationY1,
        demandEscalationY11,
        energyEscalationY1,
        energyEscalationY11,
        equipmentPrice,
        equipmentReplacementEpc,
        excludeSolarDemandSavings,
        featureIds,
        firstYearMiscPrice,
        firstYearOmPrice,
        firstYearTotalOptimalSavings,
        fixedEscalationY1,
        fixedEscalationY11,
        freightCost,
        includeNgomEstimateFlag,
        includeTelemetryEstimateFlag,
        incentiveType,
        incentiveValue,
        installPrice,
        interconnectionType,
        itcMode,
        itcRatio,
        minimumBackupDuration,
        modelFile,
        netSolarTariffId,
        netStorageTariffId,
        originalCalendarId,
        originalTariffId,
        peakShavingBinary,
        projectCombinedTaxRate,
        projectDepreciationSchedule,
        projectLifetime,
        projectNpvDiscountRate,
        projectSalesTax,
        postTaxIRR,
        preTaxIRR,
        programEnrolled,
        projectUUID,
        projections,
        proposalName,
        ptoDate,
        secondYearTotalOptimalSavings,
        serviceUUID,
        serviceName,
        storageAnnualSupportCost,
        storageEpc,
        solarOpex,
        solarOpexEscalation,
        solarAnnualDegradation,
        solarInverterReplacementCost,
        solarInverterReplacementYear,
        solarCapex,
        solarUpfrontIncentive,
        solarAnnualIncentive,
        solarAnnualIncentiveEscalation,
        solarIncentiveDuration,
        storageEquipmentReplacementCost,
        storageHardwareCost,
        storageInvestmentTaxCreditFlag,
        storageOtherAnnualIncentive,
        storageOtherIncentiveDuration,
        storageOtherIncentiveEscalation,
        storageOtherUpfrontIncentive,
        storageSgipRate,
        storageSmartIncentive,
        storageSoftwareLicenseCost,
        systemId,
        tariffCode,
        timestamp,
        toDateTime,
        utilityId,
        yearToPayback
    }) {
        return axios({
            url: this.PAT_API.ANALYSIS_PROPOSAL,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                analysisId,
                arbitrageBinary,
                backfeedMode,
                backupMode,
                calendarName,
                codDate,
                commentIds,
                comments,
                contracted,
                contractType,
                creditRating,
                customerShare,
                demandEscalationY1,
                demandEscalationY11,
                energyEscalationY1,
                energyEscalationY11,
                equipmentPrice,
                equipmentReplacementEpc,
                excludeSolarDemandSavings,
                featureIds,
                firstYearMiscPrice,
                firstYearOmPrice,
                firstYearTotalOptimalSavings,
                fixedEscalationY1,
                fixedEscalationY11,
                freightCost,
                includeNgomEstimateFlag,
                includeTelemetryEstimateFlag,
                incentiveType,
                incentiveValue,
                installPrice,
                interconnectionType,
                itcMode,
                itcRatio,
                minimumBackupDuration,
                modelFile,
                netSolarTariffId,
                netStorageTariffId,
                originalCalendarId,
                originalTariffId,
                peakShavingBinary,
                projectCombinedTaxRate,
                projectDepreciationSchedule,
                projectLifetime,
                projectNpvDiscountRate,
                projectSalesTax,
                postTaxIRR,
                preTaxIRR,
                programEnrolled,
                projectUUID,
                projections,
                proposalName,
                ptoDate,
                secondYearTotalOptimalSavings,
                serviceUUID,
                serviceName,
                storageAnnualSupportCost,
                storageEpc,
                solarOpex,
                solarOpexEscalation,
                solarAnnualDegradation,
                solarInverterReplacementCost,
                solarInverterReplacementYear,
                solarCapex,
                solarUpfrontIncentive,
                solarAnnualIncentive,
                solarAnnualIncentiveEscalation,
                solarIncentiveDuration,
                storageEquipmentReplacementCost,
                storageHardwareCost,
                storageInvestmentTaxCreditFlag,
                storageOtherAnnualIncentive,
                storageOtherIncentiveDuration,
                storageOtherIncentiveEscalation,
                storageOtherUpfrontIncentive,
                storageSgipRate,
                storageSmartIncentive,
                storageSoftwareLicenseCost,
                systemId,
                tariffCode,
                timestamp,
                toDateTime,
                utilityId,
                yearToPayback
            }
        });
    }

    getAnalysisStartEndDate(meterName) {
        return axios({
            url: this.PAT_API.ANALYSIS_START_END_DATE + "/" + meterName,
            method: "GET",
            params: {}
        })
            .then(function (response) {
                return response.data;
            });
    }

    getJobStatus(jobId, spaceId) {
        const request = axios({
            url: `${this.PAT_API.ANALYSIS_ETL}/datauploaderetl/spaces/${spaceId}/jobs/${jobId}`,
            method: "GET"
        });

        return request;
    }

    runJob(spaceId, inputFileId, validationMode, skipTransformation = false) {
        const url = spaceId ? `${this.PAT_API.ANALYSIS_ETL}/datauploaderetl/spaces/${spaceId}/jobs` : `${this.PAT_API.ANALYSIS_ETL}/jobs`;

        const request = axios({
            url,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            transformRequest: function (data) {
                return JSON.stringify(data);
            },
            data: {
                inputFileId,
                validationMode,
                skipTransformation
            }
        });

        return request;
    }

    uploadMeter(spaceId, data, UUID) {
        const url = spaceId ? `${this.PAT_API.ANALYSIS_ETL}/datauploaderetl/spaces/${spaceId}/data-files` : `${this.PAT_API.ANALYSIS_ETL}/datauploaderetl/data-files`;
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("dataType", data.dataType)
        formData.append("isIntervalStart", data.intervalLabel);
        formData.append("intervalLength", data.intervalDuration);
        formData.append("meterName", UUID);
        formData.append("timeZone", data.timeZone);
        formData.append("units", data.units);
        formData.append("validationMode", data.validationMode)
        const request = axios.post(
            url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        return request;
    }

    getTariffRate(tariffId, utilityId) {
        const request = axios({
            url: `${this.PMT_API.PMT_TARIFF_RATE}/${utilityId}/tariffs/${tariffId}`,
            method: "GET"
        });

        return request;
    }
}

export default PatApi;