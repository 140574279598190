import { TOGGLE_FOOTER_EXPAND, TOGGLE_FOOTER_RESET, TOGGLE_FOOTER_RETRACT } from './toggleFooterServices';
import store from '../service/store';
import { toggleSelectorUtil } from '../utils/utils';

export const VPP_SELECTOR_TOGGLE_EXPANDER = "VPP_SELECTOR_TOGGLE_EXPANDER";
export const VPP_SELECTOR_TOGGLE_FOOTER = "VPP_SELECTOR_TOGGLE_FOOTER";

export const VppSelectorToggleServices = () => {

  const toggleExpander = () => {
    const expander = !store.getState().vppSelectorToggleServices.expander;
    const footer = !expander ? false : store.getState().vppSelectorToggleServices.footer;

    return {
      type: VPP_SELECTOR_TOGGLE_EXPANDER,
      payload: {
        expander,
        expandMode: expander ? TOGGLE_FOOTER_EXPAND : TOGGLE_FOOTER_RESET,
        footer
      }
    };
  };

  const toggleFooter = () => {
    const footer = !store.getState().vppSelectorToggleServices.footer;
    const expander = !footer ? false : store.getState().vppSelectorToggleServices.expander;

    return {
      type: VPP_SELECTOR_TOGGLE_FOOTER,
      payload: {
        expander,
        footer,
        expandMode: footer ? TOGGLE_FOOTER_RETRACT : TOGGLE_FOOTER_RESET
      }
    };
  };

  const toggleSelector = () => {
    let { down, expander, footer, expandMode } = store.getState().vppSelectorToggleServices;

    const results = toggleSelectorUtil(down, expander, footer, expandMode);

    return {
      type: VPP_SELECTOR_TOGGLE_FOOTER,
      payload: results
    };
  };

  return {
    toggleExpander,
    toggleFooter,
    toggleSelector,
  }
};

export default VppSelectorToggleServices;