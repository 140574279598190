import { OPERATIONS_STATUS_BAR } from '../actions/operationsStatusBarServices';

const INITIAL_STATE = {
  monitor: false,
  projects: false,
  frequency: false,
  schedule: false,
  details: false,
  health: false,
  itc: false,
  alerts: false,
  flexForecast: false,
  forecast: false,
  monthlySavings: false,
  performance: false
};

export default function operationsStatusBarServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case OPERATIONS_STATUS_BAR:
      return Object.assign({}, state, payload);
      return;
    default:
      return state;
  }
};
