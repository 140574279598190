import AuthenticationService from '../service/authenticationService';
import ViewStateService from '../service/viewStateService';
import SessionCheckService from '../service/sessionCheckService';
import {default as gsLog} from '../service/loggingService';
import appService from '../service/appService';
import './loginContainer.componentReact.js.css';
import React, { Suspense } from 'react';
import _ from 'underscore';
import {Helmet} from 'react-helmet';
const Login = React.lazy(() => import('./login.component'));
const Routing = React.lazy(() => import('../routing/routing.component'));

class LoginContainerComponent extends React.Component {

    constructor(props) {
        super(props);
        this.gsLog = gsLog;
        this.gsLog.debug("LoginContainerComponent constructor:");
        this.sessionCheckService = SessionCheckService;
        ViewStateService.getUser();

        let model = {
            email: "",
            error: false,
            isUserValid: false,
            password: null,
            forcedLogout: false,
            rememberMe: false
        };

        const userObj = ViewStateService.getUser();
        if (userObj) {
            model.email = userObj.email;
        }

        if (ViewStateService.getRememberMeEmail) {
            model.rememberMe = true;
            model.email = ViewStateService.getRememberMeEmail();
        } else {
            model.email = "";
        }

        this.state = {
            isLoggedIn: false,
            model
        };

        appService.refresh() && this.sessionCheckService.startSessionCheck();
    }

    componentDidMount() {
        //DO somthing if need init something just like $onInit 
        this.gsLog.debug("LoginContainerController: componentDidMount() 000");
        this.gsLog.debug(AuthenticationService.authenticated);
        this.setState({ isLoggedIn: AuthenticationService.authenticated});
    }

    componentWillUnmount() {
        // As $onDestroy 
    }

    login(email, password, rememberMe) {
        this.gsLog.debug("LoginContainerController: login() email");
        this.gsLog.debug(email);
        this.gsLog.debug("LoginContainerController: login() password");
        this.gsLog.debug(password);
        this.gsLog.debug("LoginContainerController: login() rememberME");
        this.gsLog.debug(rememberMe);

        //HACK
        // password = "";

        if (_.isEmpty(email) ||
            _.isEmpty(password)
        ) {
            this.setState(prevState => ({
                model: Object.assign({}, prevState.model, { error: true })
            }));
            return false;
        }

        AuthenticationService.validateUserDomain(email)
            .then((validateDomainResults) => {
                this.gsLog.debug(`In LoginContainerController: login(): validate domain results = `);
                this.gsLog.debug(validateDomainResults);
                const { success } = validateDomainResults;
                this.setState(prevState => ({
                    model: Object.assign({}, prevState.model, { error: !success, isUserValid: success })
                }));

                if (success) {
                    AuthenticationService.login(email, password, rememberMe)
                        .then(() => {
                            this.gsLog.debug('In LoginContainerController: login(): auth successful in ctrl ...');
                            this.gsLog.debug(AuthenticationService.user);

                            if (!AuthenticationService.authenticated) {
                                this.setState(prevState => ({
                                    model: Object.assign({}, prevState.model, { error: true, isUserValid: true, forcedLogout: false })
                                }));
                                this.gsLog.debug('In LoginContainerController: login(): model = ');
                                this.gsLog.debug(this.state.model);
                                return false;
                            }

                            this.gsLog.debug(`In LoginContainerController: login(): initialRoute (${this.props.initialRoute})`);
                            this.setState({ isLoggedIn: true });
                            this.sessionCheckService.startSessionCheck();
                        });
                    return true;
                } else {
                    this.setState(prevState => ({
                        model: Object.assign({}, prevState.model, { error: true, isUserValid: true, forcedLogout: false })
                    }));
                    this.gsLog.debug('In LoginContainerController: login(): model2 = ');
                    this.gsLog.debug(this.state.model);
                    return false;
                }
            });
    }

    validateUserDomain(email) {
        this.gsLog.debug("LoginContainerComponent : validateUserDomain() 000");

        AuthenticationService.validateUserDomain(email)
            .then((response) => {
                this.gsLog.debug(`In LoginContainerController: validateUserDomain(): 111 response = `);
                this.gsLog.debug(response);
                const { success } = response;
                this.setState(prevState => ({
                    model: Object.assign({}, prevState.model, { error: !success, isUserValid: success })
                }));
                this.gsLog.debug("React updated state: ");
                this.gsLog.debug(this.state);
            });
    }

    render() {
        this.gsLog.debug("LoginContainerComponent : render() 000");
        const isLoggedIn = this.state.isLoggedIn;
        let initScreen;
        let backGrd;

        this.gsLog.debug("LoginContainerComponent : render() 111");
        this.gsLog.debug(isLoggedIn);
        if (isLoggedIn) {
            backGrd = 'dim-gray';
            initScreen = <Routing
                email={this.state.model.email}
                forced-logout={this.state.model.forcedLogout}
                isError={this.state.model.error}
                isUserValid={this.state.model.isUserValid}
                password={this.state.model.password}
                rememberMe={this.state.model.rememberMe}
                onLogin={this.login.bind(this)}
                onValidateUserDomain={this.validateUserDomain.bind(this)}
            >
            </Routing>
        }
        else {
            initScreen = <Login
                email={this.state.model.email}
                forced-logout={this.state.model.forcedLogout}
                isError={this.state.model.error}
                isUserValid={this.state.model.isUserValid}
                password={this.state.model.password}
                rememberMe={this.state.model.rememberMe}
                onLogin={this.login.bind(this)}
                onValidateUserDomain={this.validateUserDomain.bind(this)}
            >
            </Login>
        }

        return (
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <Helmet>
                        <body className={backGrd} />
                    </Helmet>
                    {initScreen}
                </Suspense>
            </div>
        );
    }

}

export default LoginContainerComponent