
import LoggingService from '../service/loggingService';

import {
  HIDE_SESSION_EXPIRE,
  SHOW_SESSION_EXPIRE
} from '../actions/sessionCheckServices';

const INITIAL_STATE = {};

export default function sessionCheckServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case HIDE_SESSION_EXPIRE:
      LoggingService.debug("In sessionCheckServices: USER_OBJECTS(): 222");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    case SHOW_SESSION_EXPIRE:
      LoggingService.debug("In sessionCheckServices: USER_OBJECTS(): 333");
      LoggingService.debug(payload);
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
