import axios from "axios";
import { b64EncodeUnicode } from "../utils/utils";
import moment from "moment";
import _ from 'underscore';
import { default as gsLog } from "./loggingService";
import ViewStateService from "./viewStateService";
import userAuthenticatedNotificationService from "./userAuthenticatedNotificationService";
import UserAuthenticatedService from "./userAuthenticatedService";
import qs from "qs";
import storageService from "../service/storageService";
import rootScopeService from "../service/rootScopeService";
import { LOGIN_URLS } from "../shared/app.constants.all";

import {
  HEADER_CUSTOMER_IDS,
  HEADER_ETL_SPACE_IDS,
  HEADER_MI_IDS,
  HEADER_OPTIMIZER_SPACE_IDS,
  HEADER_USR_ROLES,
  HEADER_VPP_IDS,
  HEADER_PROJECT_IDS,
  HEADER_USR_IMPERSONATION_TARGETS,
} from "../actions/cachedObjectServices";

const LIST_OF_SCOPES = `curtailments_get curtailments_post_put_delete ep15_get ep15_post_put_delete eph_get eph_post_put_delete etl_get etl_post etl_put_delete etl_spaceadmin forecast_get forecast_post forecast_put_delete ht_get ht_post_put ht_delete invoicing_get invoicing_post_put_delete mi_get mi_post_put_delete measurer_get measurer_post measurer_delete measurer2_get measurer2_post measurer2_delete optimizer_get optimizer_post optimizer_delete optimizer2_get optimizer2_post optimizer2_delete pm_get pm_post_put pm_delete tariff_get tariff_post_put tariff_delete vpp_get vpp_post_put_delete fm_get measurer2aux_get measurer2aux_post measurer2aux_delete measurer2_worker_delete measurer2_worker_get_post pipeline_mgmt de_get_post_put_delete`;

class AuthenticationServiceFactory {
  constructor() {
    if (AuthenticationServiceFactory.instance) {
      return AuthenticationServiceFactory.instance;
    }
    this.gsLog = gsLog;
    this.gsLog.debug("AuthenticationServiceFactory constructor:");
    this.validateUserDomain = this.validateUserDomain.bind(this);
    this.login = this.login.bind(this);
    this.user = {};
    this.authenticated = false;
    this.storageService = storageService;
    this.viewStateService = ViewStateService;
    this.userAuthenticatedService = UserAuthenticatedService;
    this.$rootScope = rootScopeService;
    this.allowExpSessionChk = false;
    this.LOGIN_URLS = LOGIN_URLS;
    AuthenticationServiceFactory.instance = this;
  }

  validateUserDomain(userName) {
    const { 1: inputDomain } = userName.split("@");
    return axios(
      // 'https://10.0.40.26/lh/v1.0.0/validate',
      // 'https://staging.api.greencharge.net/lh/v1.0.0/validate',
      this.LOGIN_URLS.VALIDATE_DOMAIN,
      {
        params: {
          domain: inputDomain,
        },
      }
    ).then(
      (results) => {
        const { appSecret, appToken, found } = results.data;
        this.user.appSecret = appSecret;
        this.user.appToken = appToken;
        return {
          success: found,
        };
      },
      (error) => {
        this.user = {};
        this.user.email = userName;
        this.authenticated = false;
        return {
          success: false,
        };
      }
    );
  }

  async login(userName, userPassword, rememberMe) {
    try {
    const { appSecret, appToken } = this.user;
    this.gsLog.debug(
      `In AuthenticationService: login(): app token (${appToken}), app secret (${appSecret})`
    );
    const b64Str = b64EncodeUnicode(`${appToken}:${appSecret}`);
    //   const response = await this.$http({
    //     url: this.LOGIN_URLS.AAA_LOGIN,
    //     method: "POST",
    //     headers: {
    //       'Authorization': `Basic ${b64Str}`,
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     data: {
    //       grant_type: 'password',
    //       username: userName,
    //       password: userPassword,
    //       scope: LIST_OF_SCOPES
    //     }
    //   });

    const payload = {
      grant_type: "password",
      username: userName,
      password: userPassword,
      scope: LIST_OF_SCOPES,
    };

    const response = await axios.post(
      // 'https://10.0.40.26/token',
      // 'https://staging.api.greencharge.net/token',
      this.LOGIN_URLS.AAA_LOGIN,
      qs.stringify(payload),
      {
        headers: {
          Authorization: `Basic ${b64Str}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    this.gsLog.debug(
      `In AuthenticationService: login-AAA(): results from login web api = `
    );
    this.gsLog.debug(response);
    const authorizationsData = response.data.authorizations;
    this.gsLog.debug(
      `In AuthenticationService: login-AAA(): results from login web api: authorizations data = `
    );
    this.gsLog.debug(authorizationsData);

    const {
      access_token: aaaAccessToken,
      expires_in: aaaExpiresIn,
      organization,
      roles,
      organization_id,
      flexSchedule,
      miScheduler,
      refresh_token,
      scope,
    } = response.data;

    this.user = {
      access_token: aaaAccessToken,
      aaa_expires: moment().add(aaaExpiresIn, "seconds"),
      aaa_expires_in: aaaExpiresIn,
      aaa_token: aaaAccessToken,
      scope,
      appSecret,
      appToken,
      canViewFlexForecast: flexSchedule,
      canViewScheduler: miScheduler,
      customerIds: authorizationsData[HEADER_CUSTOMER_IDS],
      email: userName,
      etlSpaceIds: authorizationsData[HEADER_ETL_SPACE_IDS],
      miIds: authorizationsData[HEADER_MI_IDS],
      optimizerSpaceIds: authorizationsData[HEADER_OPTIMIZER_SPACE_IDS],
      projects: authorizationsData[HEADER_PROJECT_IDS],
      organization,
      orgId: organization_id,
      refresh_token,
      rememberMe,
      roles,
      userName,
      usrRoles: authorizationsData[HEADER_USR_ROLES],
      vppIds: authorizationsData[HEADER_VPP_IDS],
      impersonationTargets:
        authorizationsData[HEADER_USR_IMPERSONATION_TARGETS],
      oldUsrAppToken: appToken,
      oldUsrAppSecret: appSecret,
    };

    this.authenticated = true;
    this.viewStateService.setForcedLogout(false);
    this.allowExpSessionChk = true;
    this.gsLog.debug(`In AuthenticationService: login-AAA(): 555`);
    this.gsLog.debug(this.userAuthenticatedService);
    this.userAuthenticatedService.updateState(aaaAccessToken);
    userAuthenticatedNotificationService.refresh(this.user);
     } catch (error) {
        this.gsLog.error("login error:");
        this.gsLog.error(error);
        this.user = {
         email: userName
       };
     this.authenticated = false;
     this.gsLog.debug("In AuthenticationService: login(): error in calling login web api: " + error);
     }
  }

  refreshUser() {
    this.gsLog.debug("In AuthenticationService: refreshUser() ....");

    let user = this.viewStateService.getUser();
    this.gsLog.debug("In AuthenticationService: refreshUser() 111");
    this.gsLog.debug(user);
    //HACK
    // user = null;
    if (user) {
      this.gsLog.debug("In AuthenticationService: refreshUser(): user = ");
      this.gsLog.debug(user);
      this.user = user;
      this.authenticated = true;
      userAuthenticatedNotificationService.refresh(this.user);
      return true;
    }

    this.gsLog.debug("In AuthenticationService: refreshUser() 333");
    return false;
  }

  async unImpersonateUser() {
    try {
      let {
        oldUsrAppSecret,
        oldUsrAppToken,
        userName,
        targetUser,
        oldUsrAcsToken,
      } = this.viewStateService.getUser();
      if(!oldUsrAcsToken) {
          oldUsrAcsToken = this.userAuthenticatedService.getAaaToken();
      }
      this.gsLog.debug(
        `In AuthenticationService: unImpersonateUser(): app token (${oldUsrAppToken}), app secret (${oldUsrAppSecret})`
      );

      // const unImpersonateResponse = await this.$http({
      //     url: this.LOGIN_URLS.IMPERSONATE_USER_URL,
      //     method: "DELETE",
      //     headers: {
      //         'Authorization': `Bearer ${oldUsrAcsToken}`,
      //         'Content-Type': 'application/json'
      //     },
      //     transformRequest: function (data) {
      //         return JSON.stringify(data);
      //     },
      //     data: {
      //         grantType: 'password',
      //         userName: userName,
      //         targetUserEmail: targetUser,
      //         consumerKey: oldUsrAppToken,
      //         consumerSecret: oldUsrAppSecret
      //     }
      // });

      const payload = {
        grantType: "password",
        userName: userName,
        targetUserEmail: targetUser,
        consumerKey: oldUsrAppToken,
        consumerSecret: oldUsrAppSecret,
      };

      const unImpersonateResponse = await axios.delete(
        this.LOGIN_URLS.IMPERSONATE_USER_URL,
        {
            data: payload,
          headers: {
            Authorization: `Bearer ${oldUsrAcsToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      this.gsLog.debug(
        "In AuthenticationService: unImpersonateUser(): un-impersonte successfully...."
      );
      this.revokeOldToken();
    } catch (error) {
      this.gsLog.error(
        "In AuthenticationService: unImpersonateUser(): error in calling um-impersonate web api: "
      );
      this.gsLog.error(error);
      return error;
    }
  }

  async logout() {
    try {
      const { appSecret, appToken } = this.viewStateService.getUser();
      this.gsLog.debug(
        `In AuthenticationService: logout(): app token (${appToken}), app secret (${appSecret})`
      );
      const b64Str = b64EncodeUnicode(`${appToken}:${appSecret}`);
      this.gsLog.debug("In AuthenticationService: logout 111 (): aaa token = ");
      this.gsLog.debug(this.userAuthenticatedService.getAaaToken());

      //   const logoutResponse = await this.$http({
      //     url: this.LOGIN_URLS.AAA_LOGOUT,
      //     method: "POST",
      //     headers: {
      //       'Authorization': `Basic ${b64Str}`,
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: {
      //       token: this.userAuthenticatedService.getAaaToken()
      //     }
      //   });

      //   const revokeTokenResponse = await this.$http({
      //     url: this.LOGIN_URLS.AAA_LOGOUT,
      //     method: "POST",
      //     headers: {
      //       'Authorization': `Basic ${b64Str}`,
      //       'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data: {
      //       token: this.userAuthenticatedService.getRefreshToken()
      //     }
      //   });

      const payload = {
        token: this.userAuthenticatedService.getAaaToken(),
      };

      const logoutResponse = await axios.post(
        this.LOGIN_URLS.AAA_LOGOUT,
        qs.stringify(payload),
        {
          headers: {
            Authorization: `Basic ${b64Str}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const revokePayload = {
        token: this.userAuthenticatedService.getRefreshToken(),
      };

      const revokeTokenResponse = await axios.post(
        this.LOGIN_URLS.AAA_LOGOUT,
        qs.stringify(revokePayload),
        {
          headers: {
            Authorization: `Basic ${b64Str}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      this.gsLog.debug("In AuthenticationService: logout(): ");
      this.gsLog.debug(logoutResponse);
      this.viewStateService.removeUser();
      this.userAuthenticatedService.clearState();
      localStorage.removeItem("gsAppLocal.defaultUser");
      localStorage.removeItem("gsAppLocal.LoggedinUser");
      localStorage.removeItem("gsAppLocal.Projects");
      localStorage.removeItem("gsAppLocal.excludedCustomerIds");
      localStorage.removeItem("gsAppLocal.excludedMiIds");
      localStorage.removeItem("gsAppLocal.excludedRoles");
      localStorage.removeItem("gsAppLocal.excludedvppIds");
      localStorage.removeItem("gsAppLocal.permission");
      localStorage.removeItem("gsAppLocal.localVpps");
      localStorage.removeItem("gsAppLocal.newCreatedMi");
      localStorage.removeItem("gsAppLocalWarningVar");
      localStorage.removeItem("gsAppLocal.allPermission");
      localStorage.removeItem("gsAppLocal.featureToggleRoles");
      localStorage.removeItem("gsAppLocal.bypassLogout");
      this.authenticated = false;

      return this.authenticated;
    } catch (error) {
      this.gsLog.error(
        "In AuthenticationService: logout(): error in calling logout web api: " +
          error
      );
      this.viewStateService.removeUser();
      this.userAuthenticatedService.clearState();
      this.authenticated = false;

      localStorage.removeItem("gsAppLocal.defaultUser");
      localStorage.removeItem("gsAppLocal.LoggedinUser");
      localStorage.removeItem("gsAppLocal.Projects");
      localStorage.removeItem("gsAppLocal.excludedCustomerIds");
      localStorage.removeItem("gsAppLocal.excludedMiIds");
      localStorage.removeItem("gsAppLocal.excludedRoles");
      localStorage.removeItem("gsAppLocal.excludedvppIds");
      localStorage.removeItem("gsAppLocal.permission");
      localStorage.removeItem("gsAppLocal.localVpps");
      localStorage.removeItem("gsAppLocal.newCreatedMi");
      localStorage.removeItem("gsAppLocalWarningVar");
      localStorage.removeItem("gsAppLocal.allPermission");
      localStorage.removeItem("gsAppLocal.featureToggleRoles");
      localStorage.removeItem("gsAppLocal.bypassLogout");
      return error;
    }
  }

  async loginWithImpersonateUser(
    userName,
    userPassword,
    targetUser,
    rememberMe
  ) {
    let oldUsrAcsToken = this.userAuthenticatedService.getAaaToken();
    let oldUsrRefToken = this.userAuthenticatedService.getRefreshToken();
    this.gsLog.debug(
      `In AuthenticationService: loginWithImpersonateUser(): old user accessToken: (${oldUsrAcsToken}), refreshToken:(${oldUsrRefToken})`
    );
    try {
      const { appSecret, appToken } = this.user;
      const { oldUsrAppSecret, oldUsrAppToken, aaa_expires } =
        this.viewStateService.getUser();
      this.gsLog.debug(
        `In AuthenticationService: loginWithImpersonateUser(): app token (${oldUsrAppToken}), app secret (${oldUsrAppSecret})`
      );
      const payload = {
        grantType: "password",
        userName: userName,
        password: userPassword,
        targetUserEmail: targetUser,
        consumerKey: appToken,
        consumerSecret: appSecret,
        scope: LIST_OF_SCOPES,
      };

      const response = await axios.post(
        // 'https://10.0.40.26/token',
        // 'https://staging.api.greencharge.net/token',
        this.LOGIN_URLS.IMPERSONATE_USER_URL,
        payload,
        {
          headers: {
            Authorization: `Bearer ${oldUsrAcsToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      this.gsLog.debug(
        `In AuthenticationService: loginWithImpersonateUser-AAA(): results from impersonate login web api = `
      );
      this.gsLog.debug(response);
      const authorizationsData = response.data.authorizations;
      this.gsLog.debug(
        `In AuthenticationService: loginWithImpersonateUser-AAA(): results from impersonate login web api: authorizations data = `
      );
      this.gsLog.debug(authorizationsData);
      const {
        access_token: aaaAccessToken,
        expires_in: aaaExpiresIn,
        organization,
        roles,
        organization_id,
        flexSchedule,
        miScheduler,
        refresh_token,
        scope,
      } = response.data;

      this.user = {
        access_token: aaaAccessToken,
        aaa_expires: moment().add(aaaExpiresIn, "seconds"),
        aaa_expires_in: aaaExpiresIn,
        aaa_token: aaaAccessToken,
        scope,
        appSecret,
        appToken,
        canViewFlexForecast: flexSchedule,
        canViewScheduler: miScheduler,
        customerIds: authorizationsData[HEADER_CUSTOMER_IDS],
        email: targetUser,
        etlSpaceIds: authorizationsData[HEADER_ETL_SPACE_IDS],
        miIds: authorizationsData[HEADER_MI_IDS],
        optimizerSpaceIds: authorizationsData[HEADER_OPTIMIZER_SPACE_IDS],
        projects: authorizationsData[HEADER_PROJECT_IDS],
        organization,
        orgId: organization_id,
        refresh_token,
        rememberMe,
        roles,
        userName,
        targetUser,
        usrRoles: authorizationsData[HEADER_USR_ROLES],
        vppIds: authorizationsData[HEADER_VPP_IDS],
        impersonationTargets:
          authorizationsData[HEADER_USR_IMPERSONATION_TARGETS],
        oldUsrAppSecret,
        oldUsrAppToken,
        oldUsrAcsToken,
        oldUsrRefToken,
        oldUsrAaaExpiers: aaa_expires,
      };

      this.authenticated = true;
      this.viewStateService.setForcedLogout(false);
      this.allowExpSessionChk = true;
      this.userAuthenticatedService.updateState(aaaAccessToken);

      userAuthenticatedNotificationService.refresh(this.user);
    } catch (error) {
        this.gsLog.error("impersonal error:");
        this.gsLog.error(error);
        this.user = {
            email: userName,
        };
        this.authenticated = false;
        this.gsLog.error(
            "In AuthenticationService: loginWithImpersonateUser(): error in calling impersonate login web api: " +
            error
        );
    }
  }

  async refreshToken() {
    this.gsLog.debug(
      "In AuthenticationService: refreshToken(): session refresh"
    );
    const { appSecret, appToken } = this.user;
    this.gsLog.debug(
      `In AuthenticationService: refreshToken(): app token (${appToken}), app secret (${appSecret})`
    );
    const b64Str = b64EncodeUnicode(`${appToken}:${appSecret}`);
    let token = this.userAuthenticatedService.getAaaToken();

    const payload = {
      grant_type: "refresh_token",
      refresh_token: this.userAuthenticatedService.getRefreshToken(),
    };

    const response = await axios.post(
      this.LOGIN_URLS.AAA_REFRESH_TOKEN,
      qs.stringify(payload),
      {
        headers: {
          Authorization: `Basic ${b64Str}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    this.gsLog.debug(
      "In AuthenticationService: refreshToken(): session refresh 555"
    );
    this.gsLog.debug(response);
    const {
      expires_in: aaaExpiresIn,
      access_token: aaaAccessToken,
      refresh_token,
    } = response.data;
    const aaaExpires = moment().add(aaaExpiresIn, "seconds");
    let user = this.viewStateService.getUser();
    user.aaa_token = aaaAccessToken;
    user.aaa_expires = aaaExpires;
    user.aaa_expires_in = aaaExpiresIn;
    user.refresh_token = refresh_token;
    this.viewStateService.createUser(user);
    this.$rootScope.oauth.aaa_token = aaaAccessToken;
    this.$rootScope.oauth.aaa_expires = aaaExpires;
    this.$rootScope.oauth.aaa_expires_in = aaaExpiresIn;
    this.$rootScope.oauth.refresh_token = refresh_token;
    this.gsLog.debug(
      "In AuthenticationService: refreshToken(): session refresh 666"
    );
    this.gsLog.debug(user);

    // HACK
    // this.$rootScope.$broadcast("REFRESHTOKEN");

    localStorage.removeItem("gsAppLocal.defaultUser");
    localStorage.removeItem("gsAppLocal.LoggedinUser");
    localStorage.removeItem("gsAppLocal.Projects");
    localStorage.removeItem("gsAppLocal.excludedCustomerIds");
    localStorage.removeItem("gsAppLocal.excludedMiIds");
    localStorage.removeItem("gsAppLocal.excludedRoles");
    localStorage.removeItem("gsAppLocal.excludedvppIds");
    localStorage.removeItem("gsAppLocal.permission");
    localStorage.removeItem("gsAppLocal.localVpps");
    localStorage.removeItem("gsAppLocal.newCreatedMi");
    localStorage.removeItem("gsAppLocalWarningVar");
    localStorage.removeItem("gsAppLocal.allPermission");
    localStorage.removeItem("gsAppLocal.featureToggleRoles");

    setTimeout(() => {
      this.gsLog.debug(
        "In AuthenticationService: refreshToken(): session refresh 777"
      );
      this.allowExpSessionChk = true;
    }, 5000);
  }

  async revokeOldToken() {
    try {
      const {
        oldUsrAppSecret,
        oldUsrAppToken,
        oldUsrAcsToken,
        oldUsrRefToken,
      } = this.viewStateService.getUser();
      this.gsLog.debug(
        `In AuthenticationService: revokeOldToken(): app token (${oldUsrAppToken}), app secret (${oldUsrAppSecret})`
      );
      const b64Str = b64EncodeUnicode(`${oldUsrAppToken}:${oldUsrAppSecret}`);
      this.gsLog.debug(
        "In AuthenticationService: revokeOldToken 111 (): aaa token = "
      );
      this.gsLog.debug(oldUsrAcsToken);
      this.gsLog.debug(oldUsrRefToken);
      const payload = {
        token: oldUsrAcsToken,
      };

      const logoutResponse = await axios.post(
        this.LOGIN_URLS.AAA_LOGOUT,
        qs.stringify(payload),
        {
          headers: {
            Authorization: `Basic ${b64Str}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const revokePayload = {
        token: oldUsrRefToken,
      };

      const revokeTokenResponse = await axios.post(
        this.LOGIN_URLS.AAA_LOGOUT,
        qs.stringify(revokePayload),
        {
          headers: {
            Authorization: `Basic ${b64Str}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      /*await this.$http({
            url: this.LOGIN_URLS.AAA_LOGOUT,
            method: "POST",
            headers: {
              'Authorization': `Basic ${b64Str}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
              token: oldUsrAcsToken
            }
          });
    
          await this.$http({
            url: this.LOGIN_URLS.AAA_LOGOUT,
            method: "POST",
            headers: {
              'Authorization': `Basic ${b64Str}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
              token: oldUsrRefToken
            }
          }); */
      this.gsLog.debug(
        "In AuthenticationService: revokeOldToken(): revoked successfully..."
      );
    } catch (error) {
      this.gsLog.error(
        "In AuthenticationService: revokeOldToken(): error in calling revoke token web api: "
      );
      this.gsLog.error(error);
      return error;
    }
  }

  async refreshOldToken() {
    try {
      const { oldUsrAppSecret, oldUsrAppToken, oldUsrRefToken } =
        this.viewStateService.getUser();
      this.gsLog.debug(
        `In AuthenticationService: refreshOldToken(): app token (${oldUsrAppToken}), app secret (${oldUsrAppSecret})`
      );
      const b64Str = b64EncodeUnicode(`${oldUsrAppToken}:${oldUsrAppSecret}`);

      const payload = {
        grant_type: "refresh_token",
        refresh_token: oldUsrRefToken,
      };

      const response = await axios.post(
        this.LOGIN_URLS.AAA_REFRESH_TOKEN,
        qs.stringify(payload),
        {
          headers: {
            Authorization: `Basic ${b64Str}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      /*  const response = await this.$http({
            url: this.LOGIN_URLS.AAA_REFRESH_TOKEN,
            method: "POST",
            headers: {
              'Authorization': `Basic ${b64Str}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
              grant_type: 'refresh_token',
              refresh_token: oldUsrRefToken
            }
          });*/

      this.gsLog.debug(
        "In AuthenticationService: refreshToken(): session refresh 555"
      );
      this.gsLog.debug(response);
      const {
        expires_in: aaaExpiresIn,
        access_token: aaaAccessToken,
        refresh_token,
      } = response.data;
      const aaaExpires = moment().add(aaaExpiresIn, "seconds");
      let user = this.viewStateService.getUser();
      user.oldUsrAcsToken = aaaAccessToken;
      user.oldUsrAaaExpiers = aaaExpires;
      user.oldUsrRefToken = refresh_token;
      this.viewStateService.createUser(user);
      this.gsLog.debug(
        "In AuthenticationService: refreshOldToken(): session refresh 666"
      );
      this.gsLog.debug(user);
    } catch (error) {
      this.gsLog.debug(
        `get old session details for refreshing token error: ${error}`
      );
    }
  }

  startOldSessionCheck() {
    if (!this.isSessionChkAlreadyStarted) {
      this.isSessionChkAlreadyStarted = true;
      this.stopInterval = setInterval(
        this.isOldSessionTokenExpired.bind(this),
        15000
      );
    }
  }

  stopOldSessionCheck() {
    if (!_.isUndefined(this.stopInterval)) {
        clearInterval(this.stopInterval);
        this.isSessionChkAlreadyStarted = false;
    }
  }

  isOldSessionTokenExpired() {
    let oldUsrAaaExpiers = this.viewStateService.getUser().oldUsrAaaExpiers;

    if (oldUsrAaaExpiers) {
      let expire = moment(oldUsrAaaExpiers);
      let now = moment();

      let duration = moment.duration(expire.diff(now));
      let seconds = duration.asSeconds();
      if (seconds < 20) {
        this.refreshOldToken();
      }
      return true;
    } else {
      return false;
    }
  }
}

const AuthenticationService = new AuthenticationServiceFactory();

export default AuthenticationService;
