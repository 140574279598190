import {default as gsLog} from './loggingService';
import Rx from "rx";

class UserAuthenticatedNotificationService {
    constructor() {
        this.gsLog = gsLog;
        this.gsLog.debug("UserAuthenticatedNotificationService constructor:");
        this.gsLog.debug("entering userAuthenticatedNotificationService ...");

        this.rx = Rx;
        this.myEvent = new this.rx.Subject();
    }
   
    refresh(data) {
        this.gsLog.debug("In UserAuthenticatedNotificationService: notify user authenticated event ...");
        this.myEvent.onNext({
          name: "USER_AUTHENTICATED_NOTIFICATION_SERVICE",
          data
        });
      }
}

const userAuthenticatedNotificationService = new UserAuthenticatedNotificationService();

export default userAuthenticatedNotificationService;
