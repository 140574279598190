import LoggingService from './loggingService';
import { _ } from "lodash";

class AuthorizationServiceFactory {
    constructor() {
        LoggingService.debug("AuthorizationServiceFactory constructor:");
        LoggingService.debug("AuthorizationService fired");
        this.canViewFlexForecast = false;
        this.canViewScheduler = false;

        this.permissions = [
          {name: "canViewSites", value: 1},
          {name: "canViewVitals", value: 2},
          {name: "canViewInvoices", value: 4},
          {name: "canViewSavingsBar", value: 8},
          {name: "canUploadSiteImage", value: 16},
          {name: "canViewPortfolio", value: 32},
          {name: "canViewAnalysis", value: 64},
          {name: "canViewSchedule", value: 128},
          {name: "canViewHealth", value: 256},
          {name: "canViewAlerts", value: 512}
        ];

        this.canDoRules = [];
    }

    isPortfolioUser() {
        return (this.userRole === 32 || this.userRole === 96);
    };
   
    isPatUser() {
        return (this.userRole === 64 || this.userRole === 96);
      };

    setUserRole(userRole) {
        this.userRole = userRole;
        this.updateCanDoRules();
    };

    setCanViewScheduler(perm) {
        this.canViewScheduler = perm;
    };

    getCanViewScheduler() {
        return this.canViewScheduler;
    };

    setCanViewFlexForecast(perm) {
        this.canViewFlexForecast = perm;
    };

    getCanViewFlexForecast() {
        return this.canViewFlexForecast;
    };

    updateCanDoRules() {
        var allowAccess = false;
        LoggingService.debug("In authorizationService: updateCanDoRules(): user role = " + this.userRole);
        if (this.isPortfolioUser() && this.isPatUser()) {
            // has access to both PAT & PMT
            allowAccess = false;
            this.permissions.forEach(function (perm) {
                allowAccess = false;
                if (perm.name === "canViewPortfolio" || perm.name === "canViewAnalysis") {
                    allowAccess = true;
                }
                this.canDoRules.push({
                    name: perm.name,
                    value: allowAccess
                });
            });
        } else if (this.isPortfolioUser()) {
            // only has access to PMT
            allowAccess = false;
            this.permissions.forEach(function (perm) {
                allowAccess = false;
                if (perm.name === "canViewPortfolio") {
                    allowAccess = true;
                }
                this.canDoRules.push({
                    name: perm.name,
                    value: allowAccess
                });
            });
        } else if (this.isPatUser()) {
            // only has access to PAT
            allowAccess = false;
            this.permissions.forEach(function (perm) {
                allowAccess = false;
                if (perm.name === "canViewAnalysis") {
                    allowAccess = true;
                }
                this.canDoRules.push({
                    name: perm.name,
                    value: allowAccess
                });
            });
        } else {
            if (this.userRole || this.userRole === -1) {
                this.permissions.forEach(function (perm) {
                    this.canDoRules.push({
                        name: perm.name,
                        value: ((this.userRole & perm.value) === perm.value)
                    });
                });
            }
        }

        LoggingService.debug("In authorizationService: updateCanDoRules(): cando rules = ");
        LoggingService.debug(this.canDoRules);
    };

    canView(permissionName) {
        LoggingService.debug("In authorizationService: canView(): getting rule for permission = " + permissionName);
        var returnObj = _.find(this.canDoRules, function (rule) {
            if (rule.name === permissionName) {
                LoggingService.debug("In authorizationService: canView(): found rule = " + permissionName);
                return rule;
            }
        });
        if (returnObj) {
            return returnObj.value;
        }
        return false;
    };
}

const authorizationService = new AuthorizationServiceFactory();

export default authorizationService;
