import _ from 'underscore';
import '../react-transition/interceptor.js';
import axios from 'axios';
import {default as gsLog} from '../service/loggingService';
import { PAT_API, PMT_API } from '../shared/app.constants.all';
import { callbackify } from "util";

class PortfolioSelectorApi {
  // constructor(axios, gsLog, PMT_API, PAT_API, $q) {
  constructor() {
    if (PortfolioSelectorApi.instance) {
      return PortfolioSelectorApi.instance;
    }
    this.axios = axios;
    this.gsLog = gsLog;
    this.PAT_API = PAT_API;
    this.PMT_API = PMT_API;
    //HACK
    // this.$q = $q;

    this.DEFAULT_PAGE_SIZE = 40;
    this.PROJECTS_DEFAULT_PAGE_SIZE = 100;
    this.bookmarkFilter = "None";
    this.httpRequestCanceller = null;
    PortfolioSelectorApi.instance = this;
  }

  setbookmarkFilter(filter)
  {
      this.gsLog.debug(`In PortfolioSelectorApi: setbookmarkFilter(): 000`);
      this.gsLog.debug();
      this.bookmarkFilter = filter;
  }

  getCustomers(sortBy = "customerName", size = 10000, tenantId = null) {
    let params = {};

    if (size) {
      params.size = size;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (tenantId) {
      params.tenantId = tenantId;
    }
    if(this.bookmarkFilter != "None")
    {
      if (this.bookmarkFilter == "bookmarkedCustomerOnly")
        params.bookmarkedCustomerOnly = true;
      if (this.bookmarkFilter == "bookmarkedServiceOnly")
        params.bookmarkedServiceOnly = true;
      if (this.bookmarkFilter == "bookmarkedProjectOnly")
        params.bookmarkedProjectOnly = true;
      if (this.bookmarkFilter == "bookmarkedProposalOnly")
        params.bookmarkedProposalOnly = true;
      if (this.bookmarkFilter == "onlineProjectsOnly")
        params.onlineProjectsOnly = true;        
    }

    this.gsLog.debug(`In PortfolioSelectorApi: getCustomers(): 555 ${this.PAT_API.ANALYSIS_CUSTOMER}`);
    this.gsLog.debug(`In PortfolioSelectorApi: getCustomers(): 666 ${params}`);

    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}`,
      method: "GET",
      params
    });

    return request;
  }
  
  getCustomer(customerUUID) {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}`,
      method: "GET"
    });

    return request;
  }

  getCustServByProjects(projectIds) {
    const request = this.axios({
      url: this.PAT_API.ANALYSIS_PROJECT_PARENT,
      method: "GET",
      params: {
        projectIds: projectIds.toString()
      }
    });

    return request;
  }

  getSelectedPortfolio(portfolioId, byType = 'customers') {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_PORTFOLIO}/${portfolioId}/${byType}`,
      method: "GET"
    });

    return request;
  }

  getProjectsByServiceUUID(serviceUUID, size = 10000) {

    let params = {};

    if (size) {
      params.size = size;
    }
    if(this.bookmarkFilter != "None")
    {
      if (this.bookmarkFilter == "bookmarkedCustomerOnly")
        params.bookmarkedCustomerOnly = true;
      if (this.bookmarkFilter == "bookmarkedServiceOnly")
        params.bookmarkedServiceOnly = true;
      if (this.bookmarkFilter == "bookmarkedProjectOnly")
        params.bookmarkedProjectOnly = true;
      if (this.bookmarkFilter == "bookmarkedProposalOnly")
        params.bookmarkedProposalOnly = true;
      if (this.bookmarkFilter == "onlineProjectsOnly")
        params.onlineProjectsOnly = true;        
    }

    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_SERVICE}/${serviceUUID}/projects`,
      method: "GET",
      // headers: {
      //   adminKey: "thisisatest"
      // },
      params: params
    });

    return request;
  }

  getProjectsBycustomerUUID(customerUUID) {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/projects`,
      method: "GET"
    });

    return request;
  }

  getGeneratorTypesByServiceUUID(serviceUUID, customerUUID) {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}/generatorsByTypes`,
      method: "GET"
    });

    return request;
  }

  getServicesByProjectUUID(projectUUID) {
    const request = this.axios({
      url: `${this.PMT_API.PMT_PROJECT}/${projectUUID}/services`,
      method: "GET"
    });

    return request;
  }

  resetServicesBycustomerUUIDcanceller() {
      this.gsLog.debug(`In PortfolioSelectorApi: resetServicesBycustomerUUIDcanceller(): 000`);
      this.httpRequestCanceller = undefined;
  }

  getServicesBycustomerUUID(customerUUID, size = 10000) {
    this.gsLog.debug(`In PortfolioSelectorApi: getServicesBycustomerUUID(): url (${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/pagedServices})`);
    let params = {};

    // if (!_.isUndefined(this.httpRequestCanceller)) {
    if (this.httpRequestCanceller) {
      this.httpRequestCanceller.resolve();
    }
    else {
      // this.httpRequestCanceller = this.$q.defer();
    }

    if (size) {
      params.size = size;
    }

    if(this.bookmarkFilter != "None")
    {
      if (this.bookmarkFilter == "bookmarkedCustomerOnly")
        params.bookmarkedCustomerOnly = true;
      if (this.bookmarkFilter == "bookmarkedServiceOnly")
        params.bookmarkedServiceOnly = true;
      if (this.bookmarkFilter == "bookmarkedProjectOnly")
        params.bookmarkedProjectOnly = true;
      if (this.bookmarkFilter == "bookmarkedProposalOnly")
        params.bookmarkedProposalOnly = true;
      if (this.bookmarkFilter == "onlineProjectsOnly")
        params.onlineProjectsOnly = true;        

    }
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/pagedServices`,
      method: "GET",
      // timeout: this.httpRequestCanceller.promise,
      params: params
    });
    
    return request;
  }

  getServices(customerUUID) {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services`,
      method: "GET"
    });

    return request;
  }

  getService(customerUUID, serviceUUID) {
    const request = this.axios({
      url: `${this.PAT_API.ANALYSIS_CUSTOMER}/${customerUUID}/services/${serviceUUID}`,
      method: "GET"
    });

    return request;
  }

  getCustomerByPageSorting({ page = 0, size = this.DEFAULT_PAGE_SIZE, sortBy = "customerName", order = "asc", queryString = "", tenantId = "" }) {
    let params = {};

    this.gsLog.debug(`In PortfolioSelectorApi: getCustomerByPageSorting(): query string (${queryString})`);
    if (page) {
      params.page = page;
    }
    if (size) {
      params.size = size;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (order) {
      params.order = order;
    }
    if (tenantId) {
      params.tenantId = tenantId;
    }
    if(queryString == "" &&  order == "asc" )
    {
      return this.getCustomers(sortBy, size, tenantId);
    }
    if(this.bookmarkFilter != "None")
    {
      if (this.bookmarkFilter == "bookmarkedCustomerOnly")
        params.bookmarkedCustomerOnly = true;
      if (this.bookmarkFilter == "bookmarkedServiceOnly")
        params.bookmarkedServiceOnly = true;
      if (this.bookmarkFilter == "bookmarkedProjectOnly")
        params.bookmarkedProjectOnly = true;
      if (this.bookmarkFilter == "bookmarkedProposalOnly")
        params.bookmarkedProposalOnly = true;
      if (this.bookmarkFilter == "onlineProjectsOnly")
        params.onlineProjectsOnly = true;        
    }
    params.queryString = queryString;

    const request = this.axios({
      url: this.PAT_API.ANALYSIS_SEARCH_CUSTOMER,
      method: "GET",
      // headers: {
      //   adminKey: "thisisatest"
      // },
      params
    });

    return request;
  }

  getProjectByPageSorting({ page = 0, size = this.PROJECTS_DEFAULT_PAGE_SIZE, sortBy = "projectName", order = "asc", queryString = "" }) {
    let params = {};

    if (page) {
      params.page = page;
    }
    if (size) {
      params.size = size;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (order) {
      params.order = order;
    }
    params.queryString = queryString;

    const request = this.axios({
      url: this.PAT_API.ANALYSIS_SEARCH_PROJECT,
      method: "GET",
      params
    });

    return request;
  }

  getPortfolioByPageSorting({ page = 0, size = this.DEFAULT_PAGE_SIZE, sortBy = "portfolioName", order = "asc", queryString = "" }) {
    let params = {};

    if (page) {
      params.page = page;
    }
    if (size) {
      params.size = size;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (order) {
      params.order = order;
    }
    params.queryString = queryString;

    const request = this.axios({
      url: this.PAT_API.ANALYSIS_SEARCH_PORTFOLIO,
      method: "GET",
      params
    });

    return request;
  }

  getServiceByPageSorting({ page = 0, size = this.DEFAULT_PAGE_SIZE, sortBy = "siteName", order = "asc", queryString = "" }) {
    let params = {};

    if (page) {
      params.page = page;
    }
    if (size) {
      params.size = size;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (order) {
      params.order = order;
    }
    params.queryString = queryString;

    const request = this.axios({
      url: this.PAT_API.ANALYSIS_SEARCH_SERVICE,
      method: "GET",
      params
    });

    return request;
  }
}

// PortfolioSelectorApi.$inject = [
//   "axios",
//   "gs.services.gsLogService",
//   "PMT_API",
//   "PAT_API",
//   "$q"
// ];

export default PortfolioSelectorApi;
