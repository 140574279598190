import {default as gsLog} from './loggingService';

class RootScopeServiceFactory {
  constructor() {
    if (RootScopeServiceFactory.instance) {
      return RootScopeServiceFactory.instance;
    }

    this.gsLog = gsLog;
    this.tariffs = [];
    this.oauth = {aaa_token: ""};
    RootScopeServiceFactory.instance = this;
  }

  test(data) {
    this.gsLog.debug("In RootScopeServiceFactory: parse(): data to parse = ");
    this.gsLog.debug(data);

    this.gsLog.debug("In RootScopeServiceFactory: parse(): parsed tariffs = ");
    this.gsLog.debug(this.tariffs);
  }
}

const rootScopeService = new RootScopeServiceFactory();
export default rootScopeService;
