import moment from 'moment';
import _ from 'lodash';
import { gsCompanyImage } from '../billing-container/gsCompanyImage';
import { formatCurrency } from '../utils/utils';
import { BILLING_TS_CHART, PDF_COLORS, CUSTOMER_CONTRACT_TYPE } from '../shared/constants.js';
import {default as gsLog} from '../service/loggingService';
import PdfService from "../shared/pdfService.js";

export const CREATE_PDF = "CREATE_PDF";

const PdfServicesActions = () => {
  const MULTI_PAGES_THRESHOLD = 12;
  const pageWidth = 595;
  const pageHeight = 842;
  let numDemandChargesProjects = 0;
  let numGridServicesProjects = 0;
  let multiPage = false;
  let projectPageNumMap = [];
  const pdfService = new PdfService();

  const downloadPdf = (cusomterTwelveMonthsChartImage, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries,
    invoicesWithTwelveMonthImage, fileName, cb) => (dispatch, getState) => {
      gsLog.debug("In PdfServicesActions: download(): pdf ...");
      var contactType = CUSTOMER_CONTRACT_TYPE.PEA;

      if (invoiceSummaries && invoiceSummaries.length > 0) {
        contactType = invoiceSummaries[0].projectInfo.contractType;
      }

      const isCachCumstomer = contactType === CUSTOMER_CONTRACT_TYPE.CASH;

      let contentConfig = [];

      contentConfig = doCreateInvoice(isCachCumstomer, cusomterTwelveMonthsChartImage,
        invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries, invoicesWithTwelveMonthImage);

      pdfService.download(contentConfig, fileName, cb);
    }

  const printPdf = (cusomterTwelveMonthsChartImage, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries,
    invoicesWithTwelveMonthImage) => (dispatch, getState) => {
      gsLog.debug("In PdfServicesActions: print(): pdf ...");
      var contactType = CUSTOMER_CONTRACT_TYPE.PEA;

      if (invoiceSummaries && invoiceSummaries.length > 0) {
        contactType = invoiceSummaries[0].projectInfo.contractType;
      }

      const isCachCumstomer = contactType === CUSTOMER_CONTRACT_TYPE.CASH;
      let contentConfig = [];

      contentConfig = doCreateInvoice(isCachCumstomer, cusomterTwelveMonthsChartImage,
        invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries, invoicesWithTwelveMonthImage);

      pdfService.print(contentConfig);
    }

  const createListOfInvoices = (isCachCumstomer, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries) => {
    gsLog.debug("In PdfServicesActions: createListOfInvoices(): invoice summaries (demand charge) = ");
    gsLog.debug(invoiceSummaries);
    gsLog.debug("In PdfServicesActions: createListOfInvoices(): invoice summaries (energy arbitrage) = ");
    gsLog.debug(invoiceSummariesEnergyArbitrage);
    gsLog.debug("In PdfServicesActions: createListOfInvoices(): grid services invoice summaries = ");
    gsLog.debug(gsInvoiceSummaries);

    let demandCharges = [],
      energyArbitrage = [],
      gridServices = [],
      allSitesSavings = [],
      addressStr = "";

    const { totalByTransactionTypes } = invoiceSummary;

    if (invoiceSummaries.length > 0) {
      const demandChargesTotal = totalByTransactionTypes.DEMAND_BASED,
        demandChargeBorder = [false, false, false, false];

      const energyArbitrageTotal = totalByTransactionTypes.CONSUMPTION_BASED,
        energyArbitrageBorder = [false, false, false, false];
      //SitesSaving 
      const savingBorder = [false, false, false, false];
      const savingTotalBorder = [false, true, false, false];

      if (isCachCumstomer) {
        allSitesSavings.push([
          { text: "SITE", style: "tableHeaderBlue", border: savingBorder },
          { text: "TOTAL SAVINGS", style: "tableHeaderBlue", border: savingBorder },
        ]);
      } else {
        allSitesSavings.push([
          { text: "SITE", style: "tableHeaderBlue", border: savingBorder },
          { text: "TOTAL SAVINGS", style: "tableHeaderBlue", border: savingBorder },
          { text: "CUSTOMER SHARE", style: "tableHeaderBlue", border: savingBorder },
          { text: "PEA FEE", style: "tableHeaderBlue", border: savingBorder },
          { text: "CUSTOMER SAVINGS", style: "tableHeaderBlue", border: savingBorder }
        ]);
      }

      allSitesSavings = invoiceSummaries.reduce((acc, item, idx) => {
        addressStr = "";
        const { projectInfo, summary, serviceInfo } = item;

        if (serviceInfo && projectInfo) {
          addressStr = `${projectInfo.projectName}\n${serviceInfo.address}\n${serviceInfo.city}, ${serviceInfo.state} ${serviceInfo.zipcode}\nSAID #${serviceInfo.serviceAccountId ? serviceInfo.serviceAccountId : ""}\nInvoice: #${summary.invoiceNumber}`;
        }

        let totalRevenue = summary.saving.totalRevenue;
        let gcnShare = summary.saving.gcnShare;
        let customerShare = summary.saving.customerShare;
        let customerShareRatio = summary.saving.customerShareRatio;
        let customerShareRatioText = `${summary.saving.customerShareRatio * 100}%`

        if (totalByTransactionTypes.CONSUMPTION_BASED) {
          let found = invoiceSummariesEnergyArbitrage.find(inv => inv.summary.invoiceNumber === summary.invoiceNumber);

          if (found) {
            totalRevenue += found.summary.saving.totalRevenue;
            gcnShare += found.summary.saving.gcnShare;
            customerShare += found.summary.saving.customerShare;

            if (customerShareRatio !== found.summary.saving.customerShareRatio) {
              customerShareRatioText = "See Site Invoice";
            }

          }
        }

        if (isCachCumstomer) {
          acc.push([
            { text: addressStr, style: "tableRowFirstCol", border: demandChargeBorder },
            { text: formatCurrency(totalRevenue), style: "tableRow", border: demandChargeBorder }
          ]);
        } else {
          acc.push([
            { text: addressStr, style: "tableRowFirstCol", border: demandChargeBorder },
            { text: formatCurrency(totalRevenue), style: "tableRow", border: demandChargeBorder },
            { text: customerShareRatioText, style: "tableRow", border: demandChargeBorder },
            { text: formatCurrency(gcnShare), style: "tableRow", border: demandChargeBorder },
            { text: formatCurrency(customerShare), style: "tableRow", border: demandChargeBorder }
          ]);
        }

        return acc;
      }, allSitesSavings);

      let totalRevenueAll = demandChargesTotal.totalRevenue;
      let gcnShareAll = demandChargesTotal.gcnShare;
      let customerShareAll = demandChargesTotal.customerShare;

      if (totalByTransactionTypes.CONSUMPTION_BASED) {
        totalRevenueAll += energyArbitrageTotal.totalRevenue;
        gcnShareAll += energyArbitrageTotal.gcnShare;
        customerShareAll += energyArbitrageTotal.customerShare;
      }

      if (isCachCumstomer) {
        allSitesSavings.push([
          { text: "TOTAL", style: "totalLabel", border: savingTotalBorder },
          { text: formatCurrency(totalRevenueAll), style: "tableRow", border: savingTotalBorder }
        ]);
      } else {
        allSitesSavings.push([
          { text: "TOTAL", style: "totalLabel", border: savingTotalBorder },
          { text: formatCurrency(totalRevenueAll), style: "tableRow", border: savingTotalBorder },
          { text: "", border: savingTotalBorder },
          { text: formatCurrency(gcnShareAll), style: "tableRow", border: savingTotalBorder },
          { text: formatCurrency(customerShareAll), style: "tableRow", border: savingTotalBorder }
        ]);
      }

      // Demand Charges
      demandCharges.push([
        { text: "INVOICE NUMBER", style: "tableHeaderBlue", border: demandChargeBorder },
        { text: "SITE", style: "tableHeaderBlue", border: demandChargeBorder },
        { text: "DEMAND CHARGE SAVINGS", style: "tableHeaderBlue", border: demandChargeBorder },
        { text: "CUSTOMER SHARE", style: "tableHeaderBlue", border: demandChargeBorder },
        { text: "DEMAND CHARGE FEE", style: "tableHeaderBlue", border: demandChargeBorder },
        { text: "CUSTOMER SAVINGS", style: "tableHeaderBlue", border: demandChargeBorder }
      ]);

      demandCharges = invoiceSummaries.reduce((acc, item, idx) => {
        addressStr = "";
        const { projectInfo, summary, serviceInfo } = item;

        if (serviceInfo && projectInfo) {
          addressStr = `${projectInfo.projectName}\n${serviceInfo.address}\n${serviceInfo.city}, ${serviceInfo.state} ${serviceInfo.zipcode}\nSAID #${serviceInfo.serviceAccountId ? serviceInfo.serviceAccountId : ""}`;
        }

        acc.push([
          { text: summary.invoiceNumber, style: "tableRow", border: demandChargeBorder },
          { text: addressStr, style: "tableRowFirstCol", border: demandChargeBorder },
          { text: formatCurrency(summary.saving.totalRevenue), style: "tableRow", border: demandChargeBorder },
          { text: summary.saving.customerShareRatio * 100 + "%", style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(summary.saving.gcnShare), style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(summary.saving.customerShare), style: "tableRow", border: demandChargeBorder }
        ]);

        return acc;
      }, demandCharges);

      demandCharges.push([
        { text: "TOTAL", style: "totalLabel", border: demandChargeBorder, colSpan: 2 },
        {},
        { text: formatCurrency(demandChargesTotal.totalRevenue), style: "tableRow", border: demandChargeBorder },
        { text: "", border: demandChargeBorder },
        { text: formatCurrency(demandChargesTotal.gcnShare), style: "tableRow", border: demandChargeBorder },
        { text: formatCurrency(demandChargesTotal.customerShare), style: "tableRow", border: demandChargeBorder }
      ]);

      // Energy Arbitrage
      if (totalByTransactionTypes.CONSUMPTION_BASED) {
        energyArbitrage.push([
          { text: "INVOICE NUMBER", style: "tableHeaderBlue", border: demandChargeBorder },
          { text: "SITE", style: "tableHeaderBlue", border: energyArbitrageBorder },
          { text: "ENERGY ARBITRAGE SAVINGS", style: "tableHeaderBlue", border: energyArbitrageBorder },
          { text: "CUSTOMER SHARE", style: "tableHeaderBlue", border: energyArbitrageBorder },
          { text: "ENERGY ARBITRAGE FEE", style: "tableHeaderBlue", border: energyArbitrageBorder },
          { text: "CUSTOMER SAVINGS", style: "tableHeaderBlue", border: energyArbitrageBorder }
        ]);

        energyArbitrage = invoiceSummariesEnergyArbitrage.reduce((acc, item, idx) => {
          addressStr = "";
          const { projectInfo, summary, serviceInfo } = item;

          if (serviceInfo && projectInfo) {
            addressStr = `${projectInfo.projectName}\n${serviceInfo.address}\n${serviceInfo.city}, ${serviceInfo.state} ${serviceInfo.zipcode}\nSAID #${serviceInfo.serviceAccountId ? serviceInfo.serviceAccountId : ""}\n`;
          }

          acc.push([
            { text: summary.invoiceNumber, style: "tableRow", border: demandChargeBorder },
            { text: addressStr, style: "tableRowFirstCol", border: energyArbitrageBorder },
            { text: formatCurrency(summary.saving.totalRevenue), style: "tableRow", border: energyArbitrageBorder },
            { text: summary.saving.customerShareRatio * 100 + "%", style: "tableRow", border: energyArbitrageBorder },
            { text: formatCurrency(summary.saving.gcnShare), style: "tableRow", border: energyArbitrageBorder },
            { text: formatCurrency(summary.saving.customerShare), style: "tableRow", border: energyArbitrageBorder }
          ]);

          return acc;
        }, energyArbitrage);

        energyArbitrage.push([
          { text: "TOTAL", style: "totalLabel", border: energyArbitrageBorder, colSpan: 2 },
          {},
          { text: formatCurrency(energyArbitrageTotal.totalRevenue), style: "tableRow", border: energyArbitrageBorder },
          { text: "", border: energyArbitrageBorder },
          { text: formatCurrency(energyArbitrageTotal.gcnShare), style: "tableRow", border: energyArbitrageBorder },
          { text: formatCurrency(energyArbitrageTotal.customerShare), style: "tableRow", border: energyArbitrageBorder }
        ]);
      }

      // Grid Services
      if (gsInvoiceSummaries.length > 0) {
        const gsTotal = totalByTransactionTypes.DEMAND_RESPONSE,
          noBorder = [false, false, false, false];

        gridServices.push([
          { text: "INVOICE NUMBER", style: "tableHeaderBlue", border: demandChargeBorder },
          { text: "SITE", style: "tableHeaderBlue", border: noBorder },
          { text: "GRID SERVICES REVENUE", style: "tableHeaderBlue", border: noBorder },
          { text: "CUSTOMER SHARE", style: "tableHeaderBlue", border: noBorder },
          { text: "ENGIE STORAGE SHARE", style: "tableHeaderBlue", border: noBorder },
          { text: "CUSTOMER REVENUE", style: "tableHeaderBlue", border: noBorder }
        ]);

        gridServices = gsInvoiceSummaries.reduce((acc, item, idx) => {
          addressStr = "";
          const { projectInfo, summary, serviceInfo } = item;

          if (projectInfo && serviceInfo) {
            addressStr = `${projectInfo.projectName}\n${serviceInfo.address}\n${serviceInfo.city}, ${serviceInfo.state} ${serviceInfo.zipcode}\nSAID #${serviceInfo.serviceAccountId ? serviceInfo.serviceAccountId : ""}\n`;
          }

          acc.push([
            { text: summary.invoiceNumber, style: "tableRow", border: demandChargeBorder },
            { text: addressStr, style: "tableRowFirstCol", border: noBorder },
            { text: formatCurrency(summary.saving.totalRevenue), style: "tableRow", border: noBorder },
            { text: summary.saving.customerShareRatio * 100 + "%", style: "tableRow", border: noBorder },
            { text: formatCurrency(summary.saving.gcnShare), style: "tableRow", border: noBorder },
            { text: formatCurrency(summary.saving.customerShare), style: "tableRow", border: noBorder }
          ]);

          return acc;
        }, gridServices);

        gridServices.push([
          { text: "TOTAL", style: "totalLabel", border: noBorder, colSpan: 2 },
          {},
          { text: formatCurrency(gsTotal.totalRevenue), style: "tableRow", border: noBorder },
          { text: "", border: noBorder },
          { text: formatCurrency(gsTotal.gcnShare), style: "tableRow", border: noBorder },
          { text: formatCurrency(gsTotal.customerShare), style: "tableRow", border: noBorder }
        ]);
      }
    }

    gsLog.debug(`In PdfServicesActions: createListOfInvoices(): # of projects for dm (${demandCharges.length}), for energy arbitrage (${energyArbitrage.length}), for grid services (${gridServices.length})`);
    numDemandChargesProjects = demandCharges.length;
    numGridServicesProjects = gridServices.length;

    return {
      allSitesSavings,
      demandCharges,
      energyArbitrage,
      gridServices
    };
  };

  const remittancePageContent = (isCachCumstomer, invoiceSummaries, invoiceSummary, invoicesWithTwelveMonthImage, responses) => {
    let content = [];
    const noBorder = [false, false, false, false];
    gsLog.debug("In PdfServicesActions: remittancePageContent(): invoice summary = ");
    gsLog.debug(invoiceSummary);

    const { summary } = invoiceSummaries[0];

    if (isCachCumstomer) {
      return Object.assign({}, responses, { content: responses.content });
    }

    // main header
    content.push(
      {
        pageBreak: "before",
        alignment: "center",
        text: [
          { text: invoiceSummary.customerName, fontSize: 12, bold: true, style: "title" },
          "\n"
        ]
      },
      {
        alignment: "center",
        text: [
          { text: `Bill Date ${moment(summary.finalizedTimestamp).format("MM/DD/YYYY")}`, fontSize: 10, bold: false },
          "\n"
        ]
      },
      {
        text: "\n"
      }
    );
    // Summary top
    content.push(
      {
        columns: [
          {
            width: "90%",
            fontSize: 8,
            text: [
              { text: [`Please return this payment page with check payable to `, { text: `ENGIE Storage Services NA LLC.\n`, color: PDF_COLORS.title }] },
              "\n",
              { text: "Mail Payment to:\n" },
              "\n",
              { text: "ENGIE Storage \n" },
              { text: "1360 Post Oak Blvd, Ste 400 \n " },
              { text: "Houston, TX 77056 \n" }
            ]

          }
        ]

      },
      {
        text: "\n"
      }

    );
    // total table
    const { totalBalance, total, totalCarryOverBalance } = invoiceSummary;
    content.push(
      {
        layout: "noBorders",
        style: 'savingsTable',
        table: {
          widths: ['*'],
          body: [
            [{
              layout: {
                hLineColor: (i, node) => {
                  return (i === 3 || i == 0 ) ? PDF_COLORS.title : 'white';
                },
                vLineColor: (i, node) => {
                  return (i === 0 || i === 1 || i === node.table.widths.length) ? PDF_COLORS.title : 'white';
                }
              },
              table: {
                dontBreakRows: true,
                widths: ["60%", '20%', '20%'],
                body: [
                  [
                    { text: "AGGREGATE PAYMENT", colSpan:3, style: 'financialSummaryColHeader' },
                    {},
                    {}
                  ],
                  [
                    [{
                      columns: [
                        {
                          image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH4AAABFCAIAAAAD/MWxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAFPSURBVHhe7dTBjYJQFEZhS6ECNjRwC2FNMU4Fs6I7pwyG5BFNCHHuIPEAnpN/48bcfM94GQxKeizpsaTHetB/H6fp4oP3oO+67nKEmqaZLj540mPN6eu6/rnd9rm2baVnJj026bFJj016bNJjkx6b9NikxyY9NumxSY9NemzSY5Mem/TYpMcmPTbpsW1I3/d9REwfoD6OfkSvqmr8npA+vxfp7+ilkD6/1fQz9FJIn98K+kX0Ukif37/on6CXQvr8kvR/opcCarryZPRJdLbpVn/172+60v96sNPSl548QEif3wr60uIDhPT5raYvzR4gpM/vRfrS/QFC+vw2oS+NDxDS57ch/R6SHkt6LOmxpMeSHkt6LOmxpMeSHkt6LOmxpMeSHkt6LOmxpMdaoP+6Xve5k9PvP+mxTkhvb056LOmxpMeSHkt6LOmxpIcahl807vwmpjaSQgAAAABJRU5ErkJggg==',
                          fit: [25, 25], style: "financialSummaryLabel", width: 50
                        },
                        { text: 'Mark here for aggregate payment.', width: "*", fontSize: 8.5, margin: [0, 1] }
                      ], border: [true, false, true, false]
                    }],
                    { text: 'Amount Due:', style: "financialSummaryLabel" },
                    { text: `${totalBalance ? formatCurrency(totalBalance) : "$0"}`, style: "totalRevenue" }

                  ],
                  [
                    { text: " " },
                    { text: `Total Enclosed:`, style: "financialSummaryLabel", fillColor: "#cce4ea", border: [true, false, false, true] },
                    { text: `${totalBalance ? formatCurrency(totalBalance) : "$0"}`, style: "totalRevenue", fillColor: "#cce4ea", border: [false, false, true, true], color: PDF_COLORS.title }
                  ]
                ]
              }
            }]
          ]
        }
      },
      {
        text: "\n"
      }
    );

    //create invice table body 
    let invoicesRows = [];
    let addressStr = "";

    const { totalByTransactionTypes } = invoiceSummary;

    if (invoiceSummaries.length > 0) {
      invoicesRows.push ([
        { text: "SEPARATE PAYMENTS", border: noBorder, style: 'financialSummaryColHeader', colSpan:4 },
        {},
        {},
        {}
      ]);
      invoicesRows.push ([
        { text: 'Mark the box corresponding to invoice(s) covered in payment.', border: noBorder, style: 'financialSummaryColHeader', bold: false, colSpan: 4 },
        {},
        {},
        {}
      ]);

      // Demand Charges
      const demandChargesTotal = totalByTransactionTypes.DEMAND_BASED,
        demandChargeBorder = [false, false, false, false];

      invoicesRows = invoicesWithTwelveMonthImage.reduce((acc, item, idx) => {
        gsLog.debug(`In PdfServicesActions: remittancePageContent(): processing invoice # ${idx} of ${invoicesWithTwelveMonthImage.length - 1} ...`);

        const { invoice, projectInfo } = item;
        const { invoiceMisc } = invoice;

        if (projectInfo) {
          addressStr = `${projectInfo.projectName}`;
        }

        acc.push([
          {
            columns: [
              {
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH4AAABFCAIAAAAD/MWxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAFPSURBVHhe7dTBjYJQFEZhS6ECNjRwC2FNMU4Fs6I7pwyG5BFNCHHuIPEAnpN/48bcfM94GQxKeizpsaTHetB/H6fp4oP3oO+67nKEmqaZLj540mPN6eu6/rnd9rm2baVnJj026bFJj016bNJjkx6b9NikxyY9NumxSY9NemzSY5Mem/TYpMcmPTbpsW1I3/d9REwfoD6OfkSvqmr8npA+vxfp7+ilkD6/1fQz9FJIn98K+kX0Ukif37/on6CXQvr8kvR/opcCarryZPRJdLbpVn/172+60v96sNPSl548QEif3wr60uIDhPT5raYvzR4gpM/vRfrS/QFC+vw2oS+NDxDS57ch/R6SHkt6LOmxpMeSHkt6LOmxpMeSHkt6LOmxpMeSHkt6LOmxpMdaoP+6Xve5k9PvP+mxTkhvb056LOmxpMeSHkt6LOmxpIcahl807vwmpjaSQgAAAABJRU5ErkJggg==',
                fit: [25, 25], style: "financialSummaryLabel", width: 30
              },
              { text: `Invoice #${invoiceMisc.invoiceNumber}`, width: "*", style: "tableRowFirstColLg", margin: [0, 2], border: [false, false, false, false] }
            ], border: [true, false, false, false]
          },
          { text: addressStr, style: "tableRowFirstColLg", border: [true, false, false, false], margin: [0, 2] },
          { text: 'Amount Due:', style: "financialSummaryLabel", border: [true, false, false, false] },
          { text: `${formatCurrency(invoiceMisc.projectBalance ? invoiceMisc.projectBalance : 0)}`, style: "totalRevenue", border: [false, false, true, true] }
        ]);

        return acc;
      }, invoicesRows);
      invoicesRows.push([
        { text: "", border: [true, false, false, true] },
        { text: "", border: [false,false, false, true] },
        { text: "Total Enclosed", border: [true, false, false, true], fillColor: "#cce4ea", style: "financialSummaryLabel" },
        { text: "$", style: "totalRevenue", border: [false, false, true, true], fillColor: "#cce4ea", style: "totalRevenue", color: PDF_COLORS.title }
      ]);
    }

    //Invoice Detail
    content.push(
      {
        layout: 'noBorders',
        style: 'savingsTable',
        table: {
          widths: ['*'],
          body: [
            [{
              layout: {
                vLineColor: (i, node) => {
                  return (i===0 ||i === 2 || i === node.table.widths.length) ? PDF_COLORS.title : "white";
                },
                hLineColor: (i, node) => {
                  return (i === node.table.body.length || i == 0 ) ? PDF_COLORS.title : 'white';
                },
                fillColor: (i, node) => { return (i % 2 === 0 && i < node.table.body.length - 1) ? PDF_COLORS.altRowGreen : null; }
              },
              table: {
                dontBreakRows: true,
                widths: ['25%', '35%', '20%', '20%'],
                body: invoicesRows
              }
            }]
          ]
        }
      }
    );


    return Object.assign({}, responses, { content: responses.content.concat(content) });

  }

  const createSavingBySite = (invoiceSummary, invoiceSummaries, gsInvoiceSummaries, customerCashMonthPerformanceChartImage, responses) => {
    let content = [];
    content.push({
      alignment: "center",
      pageBreak: "before",
      text: [
        {
          text: `Site by Site savings  ${invoiceSummary.monthName} breakdown`,
          style: "tableCashsubTitle"
        }
      ]
    }, "\n");
    let images = customerCashMonthPerformanceChartImage.slice(0);

    while (images.length > 0) {
      let temp = images.splice(0, 2);
      
      if (temp.length == 2) {
        content.push(
          {
            columns: [
              {
                image: temp[0].image,
                width: '50%',
                fit: [250, 250]

              },
              {
                image: temp[1].image,
                width: '50%',
                fit: [250, 250]

              }
            ]
          },
          "\n"

        );
      } else {
        content.push(
          {
            columns: [
              {
                image: temp[0].image,
                width: '50%',
                fit: [250, 250]

              }
            ]
          },
          "\n"
        );
      }
    }
    return Object.assign({}, responses, { content: responses.content.concat(content) });
  }

  const creatMonthPerformance = (invoiceSummary, invoiceSummaries, gsInvoiceSummaries, invoicesWithTwelveMonthImage, customerCashMonthPerformanceChartImage) => {
    let content = [];
    const noBorder = [false, false, false, false];
    let lifetime = 0;
    let yearly = 0;
    let monthly = 0;
    invoicesWithTwelveMonthImage.forEach(item => {
      lifetime += item.invoice.invoiceMisc.totalSaving.totalLifeTime;
      yearly += item.invoice.invoiceMisc.totalSaving.totalYearToDate;
      monthly += item.invoice.invoiceMisc.totalSaving.totalRevenue;
    })
    gsLog.debug("In CreatePdfService: creatMonthPerformance(): invoice summary = ");
    gsLog.debug(invoiceSummary);
    // main header
    content.push(
      {
        alignment: "center",
        text: [
          { text: `${invoiceSummary.customerName} ${invoiceSummary.monthName} performance report`, fontSize: 15, bold: true, fillColor: "#247e7f" },
          "\n",
          "\n",
          "\n"
        ]
      }
    );
    const { totalLifeTime, totalRevenue, totalYearToDate } = invoiceSummary.total;

    content.push(
      {
        alignment: "center",
        columns: [
          {
            text: "Lifetime Saving",
            style: "tableCashSummTitleTop"
          },
          {
            text: "Year Saving",
            style: "tableCashSummTitleTop"
          },
          {
            text: "Month Saving",
            style: "tableCashSummTitleTop"
          }
        ]
      },
      {
        alignment: "center",
        columns: [
          {
            text: formatCurrency(totalLifeTime),
            style: "tableCashSummTitle"
          },
          {
            text: formatCurrency(totalYearToDate),
            style: "tableCashSummTitle"
          },
          {
            text: formatCurrency(totalRevenue),
            style: "tableCashSummTitle"
          }
        ]
      },
      "\n",
      "\n",
      "\n"
    );

    content.push(
      {
        alignment: "center",
        columns: [
          {
            text: "Current Month performance",
            style: 'tableCashsubTitle'
          }
        ]
      },
      "\n",
      {
        columns: [
          {
            image: customerCashMonthPerformanceChartImage,
            width: 495,
            height: 500

          }
        ]
      }
    );

    return Object.assign({}, { content });


  }


  const summaryPageContent = (isCachCumstomer, invoiceSummaries, invoiceSummary, cusomterTwelveMonthsChartImage, responses) => {
    let content = [];
    const noBorder = [false, false, false, false];
    const { totalBalance, total, totalCarryOverBalance } = invoiceSummary;
    const { summary } = invoiceSummaries[0];

    gsLog.debug("In PdfServicesActions: summaryPageContent(): invoice summary = ");
    gsLog.debug(invoiceSummary);

    // main header
    content.push(
      {
        alignment: "center",
        text: [
          { text: invoiceSummary.customerName, fontSize: 12, style: "title" },
          "\n"
        ]
      },
      {
        alignment: "center",
        text: [
          { text: `Bill Date ${moment(summary.finalizedTimestamp).format("MM/DD/YYYY")}`, fontSize: 10, bold: false },
          "\n"
        ]
      },
      {
        text: "\n"
      }
    );

    // financial summary section
    if (isCachCumstomer) {
      //cach perfomance report
      content.push(
        {
          columns: [
            {
              width: '63%',
              fontSize: 8,
              bold: false,
              text: `Thank you for being an ENGIE Storage customer. For your convenience
              this is your performance summary for the listed billing period. Individual
              performance reports for each site follow this summary.`
            },
            {
              layout: 'noBorders',
              width: '56%',
              style: "summaryTable",
              table: {
                body: [
                  [{
                    layout: "summaryTableZebraLayout",
                    style: 'fillwidthTable',
                    table: {
                      body: [
                        [
                          {
                            text: 'FINANCIAL SUMMARY',
                            fontSize: 8.5,
                            bold: true,
                            style: 'financialSummaryColHeader'
                          }
                        ],
                        [
                          {
                            // layout: "zebraLayout",
                            table: {
                              body: [
                                [{ text: 'Total Saving Generated:', style: "financialSummaryLabel", border: [false, false, false, false] },
                                { text: `${formatCurrency(total.totalRevenue)}`, style: "amountDue", border: [false, false, false, false] }]
                              ]
                            }
                          }
                        ]
                      ]
                    }
                  }],
                  [{ text: "\n", border: noBorder, style: "financialSummaryLabel" }],
                ]
              }
            }
          ]
        },
        {
          text: "\n"
        }
      );
    } else {
      content.push(
        {
          columns: [
            {
              width: '63%',
              fontSize: 8,
              bold: false,
              text: [`Thank you for being an ENGIE Storage customer. For your convenience
            this is your invoice summary for the listed billing period. Individual invoices
            for each site follow this summary. PEA invoices do not include revenues
            generated from grid services.
            Payment for each site can be made individually or in aggregate. Please
            make payment within 30 days post receipt and note invoice numbers
            covered in each payment (single or multiple sites). Make checks payable to \n`,
            {text: "ENGIE Storage Services NA LLC. ", color: PDF_COLORS.title},
            `If mailing payment, send to 1360 Post Oak Blvd, Ste 400, Houston, TX 77056`
              ]
            },
            {
              layout: 'noBorders',
              width: '56%',
              style: "summaryTable",
              table: {
                body: [
                  [{
                    layout: "summaryTableZebraLayout",
                    style: 'fillwidthTable',
                    table: {
                      body: [
                        [
                          {
                            text: 'FINANCIAL SUMMARY',
                            fontSize: 8.5,
                            bold: true,
                            style: 'financialSummaryColHeader'
                          }
                        ],
                        [
                          {
                            // layout: "zebraLayout",
                            table: {
                              body: [
                                [{ text: 'Total Saving Generated:', style: "financialSummaryLabel", border: [false, false, false, true] }, { text: `${formatCurrency(total.totalRevenue)}`, style: "totalRevenue", border: [false, false, false, true] }],
                                [{ text: 'Outstanding Balance:', style: "financialSummaryLabel", border: noBorder }, { text: `${totalCarryOverBalance ? formatCurrency(totalCarryOverBalance) : "$0"}`, style: "totalRevenue", border: noBorder }],
                                [{ text: 'New Charges:', style: "financialSummaryLabel", border: noBorder }, { text: `${total.gcnShare ? formatCurrency(total.gcnShare) : "$0"}`, style: "totalRevenue", border: noBorder }],
                                [{ text: 'Amount Due:', style: "financialSummaryLabel", border: noBorder }, { text: `${totalBalance ? formatCurrency(totalBalance) : '$0'}`, style: "amountDue", border: noBorder }]
                              ]
                            },
                            layout: {
                              hLineColor: function (i, node) {
                                return (i === 1) ? PDF_COLORS.total : '';
                              }
                            }
                          }
                        ]
                      ]
                    }
                  }],
                  [{ text: "\n", border: noBorder, style: "financialSummaryLabel" }],
                ]
              }
            }
          ]
        },
        {
          text: "\n"
        }
      );
    }

    const { allSitesSavings, demandCharges, energyArbitrage, gridServices } = responses;
    multiPage = (allSitesSavings.length) > 12;
    let columnWidth = ['36%', '16%', '16%', '16%', '16%'];

    if (isCachCumstomer) {
      columnWidth = ['40%', '60%'];
    }

    if (allSitesSavings.length > 0) {
      content.push(
        {
          text: "Savings by Site",
          style: "tableTitle"
        },
        {
          style: 'savingsTable',
          layout: {
            hLineColor: (i, node) => {
              return (i === node.table.body.length - 1) ? PDF_COLORS.total : "";
            },
            fillColor: (i, node) => {
              return (i > 0 && i % 2 === 0) ? PDF_COLORS.altRowGreen : null;
            }
          },
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            widths: columnWidth,
            body: allSitesSavings
          }
        },
        {
          text: "\n"
        }
      );
    }

    // customer 12-Months of Total Savings chart
    let chartsContent = {
      columns: [
        {
          text: "12-Months of Total Savings (all sites combined)",
          style: "tableTitle"
        }
      ]
    }
    
    if (multiPage) {
      chartsContent = {
        pageBreak: "before",
        columns: [
          {
            text: "12-Months of Total Savings (all sites combined)",
            style: "tableTitle"
          }
        ]
      }
    }

    content.push(
      chartsContent,
      {
        columns: [
          {
            image: cusomterTwelveMonthsChartImage,
            width: 500,
            height: 150
          }
        ]
      }
    );

    return Object.assign({}, responses, { content });
  };

  const createDemandChargesByInvoice = (isCachCumstomer, invoicesWithTwelveMonthImage, responses) => {
    let demandCharges = invoicesWithTwelveMonthImage.reduce((acc, item) => {
      const { invoice, projectInfo } = item;
      let actualPeak = null,
      originalPeak = null,
      netSolarPeak = null,
      billDemandCost = 0,
      demandChargeBorder = [false, false, false, false];

      const initial = [];
      let demandRow = invoice.savingBill.breakDownByBillingPeriods.reduce((demand, billingPeriod, idx1) => {
        if (!_.isNull(billingPeriod.billDemandCost) && !_.isUndefined(billingPeriod.billDemandCost)) {
          billDemandCost = billingPeriod.billDemandCost;
        }

        billingPeriod.demandBilling.map((billing, idx2) => {
          billing.chargePeriods.map((period, idx3) => {
            period.tiers.map((tier, idx4) => {
              if (tier.tierPrice > 0) {
                gsLog.debug(`In PdfServicesActions: createDemandChargesByInvoice(): use tier at idx1 (${idx1}), idx2 (${idx2}), idx3 (${idx3}), idx4 (${idx4})`);

                originalPeak = invoice.originalBill ? invoice.originalBill.breakDownByBillingPeriods[idx1].demandBilling[idx2].rateVolume : null;
                actualPeak = invoice.buildingBill ? invoice.buildingBill.breakDownByBillingPeriods[idx1].demandBilling[idx2].rateVolume : "0.0";
                netSolarPeak = invoice.netSolarBill ? invoice.netSolarBill.breakDownByBillingPeriods[idx1].demandBilling[idx2].rateVolume : null;
                gsLog.debug(`In PdfServicesActions: createDemandChargesByInvoice(): original peak (${originalPeak}), actual peak (${actualPeak}), net solar peak (${netSolarPeak})`);

                demand.push({
                  rateName: billing.rateName,
                  originalPeak,
                  netSolarPeak,
                  actualPeak,
                  rateVolume: billing.rateVolume,
                  tierPrice: tier.tierPrice,
                  tierCost: tier.tierCost,
                  billDemandCost
                });
              }
            });
          });
        });

        return demand;
      }, initial);

      acc.push(demandRow);
      return acc;
    }, []);

    gsLog.debug("In PdfServicesActions: createDemandChargesByInvoice(): demand charges = ");
    gsLog.debug(demandCharges);

    return Object.assign({}, responses, { demandChargesByProject: demandCharges });
  };

  const createEnergyArbitrageByInvoice = (isCachCumstomer, invoicesWithTwelveMonthImage, responses) => {
    let energyArbitrage = invoicesWithTwelveMonthImage.reduce((acc, item) => {
      const { invoice, projectInfo } = item;
      let actualPeak = null,
      originalPeak = null,
      netSolarPeak = null,
      billDemandCost = 0;

      const initial = [];
      let demandRow = invoice.savingBill.breakDownByBillingPeriods.reduce((demand, billingPeriod, idx1) => {
        if (billingPeriod.billDemandCost > 0) {
          billDemandCost = billingPeriod.billDemandCost;
        }

        billingPeriod.consumptionBilling.map((billing, idx2) => {
          billing.chargePeriods.map((period, idx3) => {
            period.tiers.map((tier, idx4) => {
              if (tier.tierPrice > 0) {
                gsLog.debug(`In PdfServicesActions: createEnergyArbitrageByInvoice(): use tier at idx1 (${idx1}), idx2 (${idx2}), idx3 (${idx3}), idx4 (${idx4})`);

                originalPeak = invoice.originalBill ? invoice.originalBill.breakDownByBillingPeriods[idx1].consumptionBilling[idx2].rateVolume : null;
                actualPeak = invoice.buildingBill ? invoice.buildingBill.breakDownByBillingPeriods[idx1].consumptionBilling[idx2].rateVolume : "0.0";
                netSolarPeak = invoice.netSolarBill ? invoice.netSolarBill.breakDownByBillingPeriods[idx1].consumptionBilling[idx2].rateVolume : null;
                gsLog.debug(`In PdfServicesActions: createEnergyArbitrageByInvoice(): original peak (${originalPeak}), actual peak (${actualPeak}), net solar peak (${netSolarPeak})`);

                demand.push({
                  rateName: billing.rateName,
                  originalPeak,
                  netSolarPeak,
                  actualPeak,
                  rateVolume: billing.rateVolume,
                  tierPrice: tier.tierPrice,
                  tierCost: tier.tierCost,
                  billDemandCost
                });
              }
            });
          });
        });

        return demand;
      }, initial);

      acc.push(demandRow);
      return acc;
    }, []);

    gsLog.debug("In PdfServicesActions: createEnergyArbitrageByInvoice(): energy arbitrage = ");
    gsLog.debug(energyArbitrage);

    return Object.assign({}, responses, { energyArbitrageByProject: energyArbitrage });
  };

  const createContentForEachInvoice = (isCachCumstomer, invoiceSummaries, invoiceSummariesEnergyArbitrage, invoicesWithTwelveMonthImage, responses) => {
    //
    // Invoices by Projects
    //
    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): responses = ");
    gsLog.debug(responses);
    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): invoice summaries = ");
    gsLog.debug(invoiceSummaries);
    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): invoices with ts images = ");
    gsLog.debug(invoicesWithTwelveMonthImage);
    const noBorder = [false, false, false, false];

    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): demand charges data = ");
    gsLog.debug(responses.demandChargesByProject);
    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): energy arbitrage data = ");
    gsLog.debug(responses.energyArbitrageByProject);
    gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): invoiceSummariesEnergyArbitrage = ");
    gsLog.debug(invoiceSummariesEnergyArbitrage);
    const content = invoicesWithTwelveMonthImage.reduce((acc, item, idx) => {
      gsLog.debug(`In PdfServicesActions: createContentForEachInvoice(): processing invoice # ${idx} of ${invoicesWithTwelveMonthImage.length - 1} ...`);

      const { invoice, projectInfo, serviceInfo } = item;
      const { invoiceMisc } = invoice;
      projectPageNumMap.push(idx);
      //
      // Main header
      //
      acc.push(
        {
          pageBreak: "before",
          alignment: "center",
          text: [
            { text: projectInfo ? projectInfo.projectName : "", fontSize: 12, bold: true, style: "title" },
            "\n"
          ]
        },
        {
          alignment: "center",
          text: [
            { text: `Issue Date ${moment(invoice.finalizedTimestamp).format("MM/DD/YYYY")}`, fontSize: 10 },
            "\n"
          ]
        },
        {
          text: "\n"
        }
      );

      //
      // Financial summary
      //
      let summaryTableBody = [
        [{ text: 'Total Value Generated:', style: "financialSummaryLabel", border: [false, false, false, true] }, { text: `${formatCurrency(invoiceMisc.totalSaving.totalRevenue)}`, style: "totalRevenue", border: [false, false, false, true] }],
        [{ text: 'Outstanding Balance:', style: "financialSummaryLabel", border: noBorder }, { text: `${formatCurrency(invoiceMisc.carryOverBalance ? invoiceMisc.carryOverBalance : 0)}`, style: "totalRevenue", border: noBorder }],
        [{ text: 'New Charges:', style: "financialSummaryLabel", border: noBorder }, { text: `${formatCurrency(invoiceMisc.totalSaving.gcnShare ? invoiceMisc.totalSaving.gcnShare : 0)}`, style: "totalRevenue", border: noBorder }],
        [{ text: 'Amount Due:', style: "financialSummaryLabel", border: noBorder }, { text: `${formatCurrency(invoiceMisc.projectBalance ? invoiceMisc.projectBalance : 0)}`, style: "amountDue", border: noBorder }]
      ];

      if (isCachCumstomer) {
        summaryTableBody = [
          [{ text: 'Total Value Generated:', style: "financialSummaryLabel", border: [false, false, false, false] }, { text: `${formatCurrency(invoiceMisc.totalSaving.totalRevenue)}`, style: "amountDue", border: [false, false, false, false] }],
        ];
      }

      const reportType = isCachCumstomer ? "Report" : "Invoice";

      acc.push(
        {
          columns: [
            {
              width: '55%',
              fontSize: 8,
              table: {
                widths: ["*", "70%"],
                body: [
                  [
                    { text: "Address: ", style: "financialSummaryLabel", border: noBorder }, 
                    { text: serviceInfo ? `${serviceInfo.address},\n ${serviceInfo.city}, ${serviceInfo.state} ${serviceInfo.zipcode}` : "", style: "totalRevenue", border: noBorder },
                  ],
                  [
                    { text: "SAID Number: ", style: "financialSummaryLabel",  border: noBorder }, 
                    { text: serviceInfo && serviceInfo.serviceAccountId ? serviceInfo.serviceAccountId : "", style: "totalRevenue", border: noBorder }
                  ],
                  [
                    { text: `${reportType} Number: `, style: "financialSummaryLabel", border: noBorder }, 
                    { text: invoiceMisc.invoiceNumber, style: "totalRevenue", border: noBorder }
                  ],
                  [
                    { text: "Tariff Details: ", style: "financialSummaryLabel", border: noBorder }, 
                    { text: invoiceMisc.tariffName, style: "totalRevenue", border: noBorder }
                  ]
                ]
              }
            },
            {
              layout: "summaryTableZebraLayout",
              style: "summaryTable",
              width: '43%',
              margin: [10, 0, 0, 0],
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      text: 'FINANCIAL SUMMARY',
                      style: 'financialSummaryColHeader'
                    }
                  ],
                  [
                    {
                      // layout: "zebraLayout",
                      table: {
                        widths: ['*', "*"],
                        body: summaryTableBody
                      },
                      layout: {
                        hLineColor: function (i, node) {
                          return (i === 1) ? PDF_COLORS.total : '';
                        }
                      }
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          text: "\n"
        }
      );

      //
      // Demand Charge savings
      //
      let demandCharges = [],
        energyArbitrage = [],
        billDemandCost = 0,
        energyArbitrageCost = 0,
        demandChargeBorder = [false, false, false, false],
        widths = ['*', '*', '*', '*', '*'];

      responses.demandChargesByProject[idx].map((row, dmIdx) => {
        let rowData = [],
          rowTotal = [];

        if (dmIdx === 0) {
          let headerRows = [];

          headerRows.push({ text: "TIME OF USE", style: "tableColHeader", border: demandChargeBorder });
          widths = ['*', '*', '*', '*', '*'];

          if (!_.isNull(row.originalPeak)) {
            headerRows.push({ text: "ORIGINAL PEAK", style: "tableColHeader", border: demandChargeBorder });
            widths.push('*');
          }

          if (!_.isNull(row.netSolarPeak)) {
            headerRows.push({ text: "NET SOLAR PEAK", style: "tableColHeader", border: demandChargeBorder });
            widths.push('*');
          }

          headerRows.push(
            { text: "ACTUAL PEAK", style: "tableColHeader", border: demandChargeBorder },
            { text: "DEMAND REDUCED", style: "tableColHeader", border: demandChargeBorder },
            { text: "TARIFF", style: "tableColHeader", border: demandChargeBorder },
            { text: "$ VALUE", style: "tableColHeader", border: demandChargeBorder }
          );
          demandCharges.push(headerRows);
        }

        billDemandCost = row.billDemandCost;

        rowData.push({ text: row.rateName, style: "tableRow", border: demandChargeBorder });
        if (!_.isNull(row.originalPeak)) {
          rowData.push({ text: `${row.originalPeak} kW`, style: "tableRow", border: demandChargeBorder });
        }
        if (!_.isNull(row.netSolarPeak)) {
          rowData.push({ text: `${row.netSolarPeak} kW`, style: "tableRow", border: demandChargeBorder });
        }
        rowData.push(
          { text: `${row.actualPeak} kW`, style: "tableRow", border: demandChargeBorder },
          { text: `${row.rateVolume} kW`, style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(row.tierPrice), style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(row.tierCost), style: "tableRow", border: demandChargeBorder }
        );
        demandCharges.push(rowData);

        if (dmIdx === responses.demandChargesByProject[idx].length - 1) {
          rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          if (!_.isNull(row.originalPeak)) {
            rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          }
          if (!_.isNull(row.netSolarPeak)) {
            rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          }
          rowTotal.push(
            { text: "", style: "tableRow", border: demandChargeBorder },
            { text: "", style: "tableRow", border: demandChargeBorder },
            { text: "TOTAL", style: "tableRow", border: [false, true, false, false] },
            { text: formatCurrency(billDemandCost), style: "tableRow", border: [false, true, false, false] }
          );
          demandCharges.push(rowTotal);
        }
      });

      gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): demand charges content = ");
      gsLog.debug(demandCharges);

      const { DEMAND_BASED, CONSUMPTION_BASED, DEMAND_RESPONSE } = invoice.invoiceMisc.savings;
      const { demandResponseInvoice } = invoice;
      gsLog.debug(`In PdfServicesActions: createContentForEachInvoice(): processing invoice ID (${invoice.invoiceServiceId}), DEMAND_BASED = `);
      gsLog.debug(DEMAND_BASED);
      gsLog.debug(`In PdfServicesActions: createContentForEachInvoice(): processing invoice ID (${invoice.invoiceServiceId}), CONSUMPTION_BASED = `);
      gsLog.debug(CONSUMPTION_BASED);
      const diffDays = moment(invoice.end).diff(moment(invoice.start), "days");

      if (demandCharges.length > 0) {
        acc.push(
          {
            text: `Demand Charge Savings`,
            style: "tableTitle"
          },
          {
            width: '100%',
            layout: {
              hLineColor: (i, node) => {
                return (i === node.table.body.length - 1) ? PDF_COLORS.total : "";
              },
              fillColor: (i, node) => {
                return (i > 0 && i % 2 === 0) ? PDF_COLORS.altRowGreen : null;
              }
            },
            style: 'savingsTable',
            table: {
              alignment: "right",
              widths,
              headerRows: 1,
              dontBreakRows: true,
              keepWithHeaderRows: 1,
              body: demandCharges
            }
          },
          {
            text: "\n"
          }
        );
      }

      //
      // demand charges savings calculation
      //
      if (!isCachCumstomer) {
        acc.push(
          {
            columns: [
              {
                width: '53%',
                text: ""
              },
              {
                width: "46%",
                layout: "innerSummaryTableZebraLayout",
                style: "summaryTable",
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        text: 'DEMAND CHARGE SAVINGS CALCULATION',
                        style: 'innerTableColHeader'
                      }
                    ],
                    [
                      {
                        // layout: "zebraLayout",
                        table: {
                          body: [
                            [{ text: 'Demand Charge Savings:', style: "financialSummaryLabel", border: noBorder }, { text: formatCurrency(DEMAND_BASED.totalRevenue), style: "totalRevenue", border: noBorder }],
                            [{ text: 'Customer Share:', style: "financialSummaryLabel", border: noBorder }, { text: `${DEMAND_BASED.customerShareRatio * 100}%`, style: "totalRevenue", border: noBorder }],
                            [{ text: 'Customer Demand Charge Savings:', style: "financialSummaryLabel", border: noBorder }, { text: formatCurrency(DEMAND_BASED.customerShare), style: "chargeSavings", border: noBorder }]
                          ]
                        }
                      }
                    ]
                  ]
                }
              }
            ]
          },
          {
            text: "\n"
          }
        );
      }

      //
      // Energy Arbitrage savings
      //
      responses.energyArbitrageByProject[idx].map((row, dmIdx) => {
        let rowData = [],
          rowTotal = [];

        if (dmIdx === 0) {
          let headerRows = [];

          headerRows.push({ text: "TIME OF USE", style: "tableColHeader", border: demandChargeBorder });
          widths = ['*', '*', '*', '*', '*'];
          if (!_.isNull(row.originalPeak)) {
            headerRows.push({ text: "ORIGINAL ENERGY", style: "tableColHeader", border: demandChargeBorder });
            widths.push('*');
          }
          if (!_.isNull(row.netSolarPeak)) {
            headerRows.push({ text: "NET SOLAR ENERGY", style: "tableColHeader", border: demandChargeBorder });
            widths.push('*');
          }
          headerRows.push(
            { text: "ACTUAL ENERGY", style: "tableColHeader", border: demandChargeBorder },
            { text: "ENERGY SHIFTED", style: "tableColHeader", border: demandChargeBorder },
            { text: "TARIFF", style: "tableColHeader", border: demandChargeBorder },
            { text: "$ VALUE", style: "tableColHeader", border: demandChargeBorder }
          );

          energyArbitrage.push(headerRows);
        }

        rowData.push({ text: row.rateName, style: "tableRow", border: demandChargeBorder });
        if (!_.isNull(row.originalPeak)) {
          rowData.push({ text: `${row.originalPeak} kWh`, style: "tableRow", border: demandChargeBorder });
        }
        if (!_.isNull(row.netSolarPeak)) {
          rowData.push({ text: `${row.netSolarPeak} kWh`, style: "tableRow", border: demandChargeBorder });
        }
        rowData.push(
          { text: `${row.actualPeak} kWh`, style: "tableRow", border: demandChargeBorder },
          { text: `${row.rateVolume} kWh`, style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(row.tierPrice), style: "tableRow", border: demandChargeBorder },
          { text: formatCurrency(row.tierCost), style: "tableRow", border: demandChargeBorder }
        );
        energyArbitrage.push(rowData);

        if (dmIdx === responses.energyArbitrageByProject[idx].length - 1) {
          energyArbitrageCost = energyArbitrageCost + row.tierCost;

          rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          if (!_.isNull(row.originalPeak)) {
            rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          }
          if (!_.isNull(row.netSolarPeak)) {
            rowTotal.push({ text: "", style: "tableRow", border: demandChargeBorder });
          }
          rowTotal.push(
            { text: "", style: "tableRow", border: demandChargeBorder },
            { text: "", style: "tableRow", border: demandChargeBorder },
            { text: "TOTAL", style: "tableRow", border: [false, true, false, false] },
            { text: formatCurrency(energyArbitrageCost), style: "tableRow", border: [false, true, false, false] }
          );
          energyArbitrage.push(rowTotal);
          energyArbitrageCost = 0;
        }
        else {
          energyArbitrageCost = energyArbitrageCost + row.tierCost;
        }
      });

      gsLog.debug("In PdfServicesActions: createContentForEachInvoice(): energy arbitrage content = ");
      gsLog.debug(energyArbitrage);

      if (energyArbitrage.length > 0 && invoiceSummariesEnergyArbitrage.length > 0) {
        acc.push(
          {
            text: `Energy Arbitrage Savings`,
            style: "tableTitle"
          },
          {
            width: '100%',
            layout: {
              hLineColor: (i, node) => {
                return (i === node.table.body.length - 1) ? PDF_COLORS.total : "";
              },
              fillColor: (i, node) => {
                return (i > 0 && i % 2 === 0) ? PDF_COLORS.altRowGreen : null;
              }
            },
            style: 'savingsTable',
            table: {
              alignment: "right",
              widths,
              headerRows: 1,
              dontBreakRows: true,
              keepWithHeaderRows: 1,
              body: energyArbitrage
            }
          },
          {
            text: "\n"
          }
        );
      }

      //
      // energy charges savings calculation
      //
      if (CONSUMPTION_BASED && invoiceSummariesEnergyArbitrage.length > 0 && !isCachCumstomer) {
        let pageBreak = null;
        acc.push(
          {
            pageBreak,
            columns: [
              {
                width: '53%',
                text: ""
              },
              {
                layout: "innerSummaryTableZebraLayout",
                style: "summaryTable",
                width: "46%",
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        text: 'ENERGY ARBITRAGE SAVINGS CALCULATION',
                        style: 'innerTableColHeader'
                      }
                    ],
                    [
                      {
                        // layout: "zebraLayout",
                        table: {
                          body: [
                            [{ text: 'Energy Arbitrage Savings:', style: "financialSummaryLabel", border: noBorder }, { text: formatCurrency(CONSUMPTION_BASED.totalRevenue), style: "totalRevenue", border: noBorder }],
                            [{ text: 'Customer Share:', style: "financialSummaryLabel", border: noBorder }, { text: `${CONSUMPTION_BASED.customerShareRatio * 100}%`, style: "totalRevenue", border: noBorder }],
                            [{ text: 'Customer Energy Charge Savings:', style: "financialSummaryLabel", border: noBorder }, { text: formatCurrency(CONSUMPTION_BASED.customerShare), style: "chargeSavings", border: noBorder }]
                          ]
                        }
                      }
                    ]
                  ]
                }
              }
            ]
          },
          {
            text: "\n"
          }
        );
      }
      //
      // 12 month saving chart
      //
      acc.push(
        {
          columns: [
            {
              text: "12-Months of Total Savings ",
              style: "tableTitle"
            }
          ]
        },
        {
          image: item.chartImg,
          width: 500,
            height: 150
        },
        {
          text: "\n"
        }
      );

      return acc;
    }, []);

    return Object.assign({}, responses, { content: responses.content.concat(content) });
  };

  const summaryCashHeader = (invoiceSummary) => {
    const lineSeparator = headerLineSeparator();

    return [
      {
        columns: [
          {
            margin: [40, 0, 0, 0],
            width: 450,
            text: [
              { text: 'Report', style: 'headerMainTitle' },
              '\n',
              { text: `${invoiceSummary.monthName} ${invoiceSummary.year}`, style: 'header' }
            ]
          },
          {
            margin: [40, 5, 0, 0],
            image: gsCompanyImage,
            fit: [100, 100]
          }
        ]
      },
      lineSeparator
    ];
  };

  const summaryHeader = (isCachCumstomer, invoiceSummary) => {
    const lineSeparator = headerLineSeparator();

    const headText = isCachCumstomer ? "PERFORMANCE SUMMARY" : 'PEA INVOICE'

    return [
      {
        // alignment: 'justify',
        columns: [
          {
            margin: [40, 5, 0, 0],
            width: 450,
            text: [
              { text: headText, style: 'headerMainTitle' },
              '\n',
              { text: `${invoiceSummary.monthName} ${invoiceSummary.year}`, style: 'header' }
            ]
          },
          {
            margin: [40, 5, 0, 0],
            image: gsCompanyImage,
            fit: [100, 100]
          }
        ]
      },
      lineSeparator
    ];
  };

  const headerLineSeparator = () => {
    const xOffset = 10;

    return {
      canvas: [
        {
          type: 'line',
          x1: xOffset,
          y1: 5,
          x2: pageWidth - xOffset,
          y2: 5,
          lineWidth: 2,
          lineColor: PDF_COLORS.headerTableColHeader
        }
      ]
    }
  };

  const footerLineSeparator = () => {
    const xOffset = 20;

    return {
      canvas: [
        {
          type: 'line',
          x1: -xOffset,
          y1: 20,
          x2: pageWidth - xOffset,
          y2: 20,
          lineWidth: 1,
          lineColor: PDF_COLORS.headerTableColHeader
        }
      ]
    }
  };

  const footer = (page, pages) => {
    const lineSeparator = footerLineSeparator();

    return {
      columns: [
        lineSeparator,
      ],
      columns: [
        {
          margin: [100, 0, 0, 0],
          image: gsCompanyImage,
          fit: [100, 100]
        },
        {
          // width: 300,
          style: "footer",
          text: [
            { text: "Questions?", bold: true },
            '\n',
            { text: "All inquiries email engiestorage-support@engie.com or call client services 408-707-0828." },
            '\n',
            { text: "Billing specific inquiries email engiestorage-billing@engie.com or call 408-638-0072." }
          ]
        }
      ]
    }
  };

  const projectHeader = (isCachCumstomer, currentPage, pageCount, invoicesWithTwelveMonthImage) => {
    let currentInvoice = null;
    gsLog.debug(`In PdfServicesActions: projectHeader(): header: # dm projects (${numDemandChargesProjects}), # gs projects (${numGridServicesProjects}), pageCount(${pageCount}), currentPage (${currentPage}), # 12months (${invoicesWithTwelveMonthImage.length})`);

    let offsetCount = 2;
    let offsetPageNum = 3;

    if (isCachCumstomer) {
      offsetCount = 1;
      offsetPageNum = 2;
    }

    if (multiPage) {
      offsetCount += 1;
      offsetPageNum += 1;
    }

    if (pageCount === invoicesWithTwelveMonthImage.length + offsetCount) {
      gsLog.debug(`In PdfServicesActions: projectHeader(): header: getting invoice at index (${currentPage - offsetCount})`);
      currentInvoice = invoicesWithTwelveMonthImage[currentPage - offsetPageNum].invoice;
    } else {
      // TODO
      gsLog.warn(`In PdfServicesActions: projectHeader(): header: list of invoices span multiple pages use case ---`);
      let pageIdx = currentPage === pageCount ? invoicesWithTwelveMonthImage.length - 1 : currentPage - offsetPageNum;

      if (pageIdx >= invoicesWithTwelveMonthImage.length) {
        pageIdx = invoicesWithTwelveMonthImage.length - 1;
      }

      currentInvoice = invoicesWithTwelveMonthImage[pageIdx].invoice;
    }

    gsLog.debug(`In PdfServicesActions: projectHeader(): header: current invoice = `);
    gsLog.debug(currentInvoice);

    const diffDays = moment(currentInvoice.end).diff(moment(currentInvoice.start), "days");
    const lineSeparator = headerLineSeparator();

    const headTextPrefix = isCachCumstomer ? "PERFORMANCE REPORT" : "PEA INVOICE";

    return [
      {
        columns: [
          {
            margin: [40, 5, 0, 0],
            width: 450,
            text: [
              { text: `${headTextPrefix} ${currentInvoice.invoiceMisc.invoiceNumber}`, style: 'headerMainTitle' },
              '\n',
              { text: `Billing Period ${moment(currentInvoice.start).format("MM/DD/YYYY")} - ${moment(currentInvoice.end).format("MM/DD/YYYY")} (${diffDays} days)`, style: 'header' }
            ]
          },
          {
            margin: [40, 5, 0, 0],
            image: gsCompanyImage,
            fit: [100, 100]
          }
        ]
      },
      lineSeparator
    ]
  };

  const renderContent = (isCachCumstomer, invoiceSummary, invoicesWithTwelveMonthImage, responses) => {
    const { content } = responses;
    return {
      pageOrientation: 'portrait',
      header: (currentPage, pageCount, pageSize) => {
        gsLog.debug(`In PdfServicesActions: renderContent(): header: current page (${currentPage}), page count (${pageCount}), page size = `);
        gsLog.debug(pageSize);

        let summaryPagecount = 2;

        if (isCachCumstomer) {
          summaryPagecount =1;
        }

        if (currentPage > summaryPagecount) {
          if (currentPage === summaryPagecount+1 && multiPage) {
            return summaryHeader(isCachCumstomer, invoiceSummary);
          }
          return projectHeader(isCachCumstomer, currentPage, pageCount, invoicesWithTwelveMonthImage);
        } else {
          return summaryHeader(isCachCumstomer, invoiceSummary);
        }
      },
      footer: (page, pages) => {
        return footer(page, pages);
      },
      content,
      defaultStyle: {
        font: 'Roboto'
      },
      styles: {
        title: {
          color: PDF_COLORS.title
        },
        summaryTable: {
          width: '53%',
          alignment: 'right',
          margin: [10, 0, 0, 0]
        },
        fillwidthTable: {
          width: '100%'
        },
        financialSummaryLabel: {
          fontSize: 8.5,
          alignment: "right"
        },
        financialSummaryLabel_bold: {
          fontSize: 8.5,
          bold: true,
          alignment: "right"
        },
        tariffName: {
          fontSize: 8
        },
        totalRevenue: {
          fontSize: 8.5,
          bold: true,
          alignment: "left"
        },
        chargeSavings: {
          fontSize: 12,
          bold: true,
          alignment: "left"
        },
        amountDue: {
          fontSize: 8.5,
          color: "#37b9c3",
          bold: true,
          alignment: "left"
        },
        totalLabel: {
          fontSize: 5,
          bold: true,
          alignment: "center",
          fillColor: PDF_COLORS.total
        },
        tableTitle: {
          fontSize: 8,
          color: "#37b9c3"
        },
        financialSummaryColHeader: {
          alignment: "center",
          fontSize: 9,
          bold: true,
          color: "#fff",
          fillColor: PDF_COLORS.headerTableColHeader
        },
        tableHeaderBlue: {
          alignment: "center",
          fontSize: 5,
          bold: true,
          color: "#fff",
          fillColor: "#37b9c3"
        },
        billDateColHeader: {
          alignment: "center",
          fontSize: 9,
          bold: true,
          color: "#fff",
          fillColor: "#549ED9"
        },
        tableColHeader: {
          alignment: "center",
          fontSize: 5,
          bold: true,
          color: "#fff",
          fillColor: PDF_COLORS.headerTableColHeader
        },
        innerTableColHeader: {
          alignment: "center",
          fontSize: 7,
          bold: true,
          color: "black",
          fillColor: PDF_COLORS.total
        },
        tableRowFirstCol: {
          alignment: "left",
          fontSize: 5
        },
        tableRowFirstColLg: {
          alignment: "left",
          fontSize: 6
        },
        tableRow: {
          alignment: "center",
          fontSize: 6
        },
        savingsTable: {
          // margin: [0,5,]
        },
        hline: {
          color: 'green'
        },
        headerMainTitle: {
          fontSize: 11,
          bold: false,
          color: PDF_COLORS.headerTableColHeader
        },
        header: {
          // alignment: 'right',
          fontSize: 11,
          bold: false
        },
        subheader: {
          fontSize: 13,
          color: '#0066cc'
        },
        footer: {
          fontSize: 7
        },
        savingsheader: {
          fontSize: 11
        },
        savingsdata: {
          fontSize: 13
        },
        totalsavings: {
          fontSize: 11,
          bold: true
        },
        tableExample: {
          fontSize: 10,
          margin: [0, 5, 0, 5]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        },
        totalDue: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        currentMonth: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      }
    };
  };

  const renderCashContent = (invoiceSummary, responses) => {
    return {
      pageOrientation: 'portrait',
      header: (currentPage, pageCount, pageSize) => {
        gsLog.debug(`In CreatePdfService: renderContent(): header: current page (${currentPage}), page count (${pageCount}), page size = `);
        gsLog.debug(pageSize);
        return summaryCashHeader(invoiceSummary);
      },
      footer: (page, pages) => {
        return footer(page, pages);
      },
      content: responses.content,
      defaultStyle: {
        font: 'Roboto'
      },
      styles: {
        title: {
          color: PDF_COLORS.title
        },
        summaryTable: {
          width: '53%',
          alignment: 'right',
          margin: [10, 0, 0, 0]
        },
        fillwidthTable: {
          width: '100%'
        },
        financialSummaryLabel: {
          fontSize: 10,
          alignment: "right"
        },
        tariffName: {
          fontSize: 9
        },
        totalRevenue: {
          fontSize: 10,
          alignment: "left"
        },
        chargeSavings: {
          fontSize: 12,
          bold: true,
          alignment: "left"
        },
        amountDue: {
          fontSize: 10,
          color: PDF_COLORS.amountDue,
          alignment: "left"
        },
        totalLabel: {
          fontSize: 9,
          bold: true,
          alignment: "center",
          fillColor: PDF_COLORS.total
        },
        tableTitle: {
          fontSize: 11,
          bold: true,
          color: PDF_COLORS.title
        },
        tableCashsubTitle: {
          fontSize: 13,
          bold: true,
          color: "#36608B"
        },
        tableCashSummTitle: {
          fontSize: 18,
          bold: true,
          color: "#6EAE45"
        },
        tableCashSummTitleTop: {
          fontSize: 14,
          bold: true,
          color: "#6EAE45"
        },
        financialSummaryColHeader: {
          alignment: "center",
          fontSize: 9,
          bold: true,
          color: "#fff",
          fillColor: PDF_COLORS.headerTableColHeader
        },
        tableHeaderBlue: {
          alignment: "center",
          fontSize: 9,
          bold: true,
          color: "#fff",
          fillColor: "#00B1E5"
        },
        billDateColHeader: {
          alignment: "center",
          fontSize: 9,
          bold: true,
          color: "#fff",
          fillColor: "#549ED9"
        },
        tableColHeader: {
          alignment: "center",
          fontSize: 8,
          bold: true,
          color: "#fff",
          fillColor: PDF_COLORS.headerTableColHeader
        },
        innerTableColHeader: {
          alignment: "center",
          fontSize: 7,
          bold: true,
          color: "black",
          fillColor: PDF_COLORS.total
        },
        tableRowFirstCol: {
          alignment: "left",
          fontSize: 9
        },
        tableRow: {
          alignment: "center",
          fontSize: 9
        },
        savingsTable: {
          // margin: [0,5,]
        },
        hline: {
          color: 'green'
        },
        headerMainTitle: {
          fontSize: 11,
          bold: false,
          color: PDF_COLORS.headerTableColHeader
        },
        header: {
          // alignment: 'right',
          fontSize: 11,
          bold: false
        },
        subheader: {
          fontSize: 13,
          color: '#0066cc'
        },
        footer: {
          fontSize: 7
        },
        savingsheader: {
          fontSize: 11
        },
        savingsdata: {
          fontSize: 13
        },
        totalsavings: {
          fontSize: 11,
          bold: true
        },
        tableExample: {
          fontSize: 10,
          margin: [0, 5, 0, 5]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        },
        totalDue: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        currentMonth: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      }
    }
  }

  const doCreateCashInvoice = (customerLifetimeSummaryChartImg, customerBenefitYearChartImg, invoiceSummary, invoiceSummaries, gsInvoiceSummaries, invoicesWithTwelveMonthImage, customerCashMonthPerformanceChartImage, customerCashSitesSavingChartImage) => {
    let contentConfig = _.flow(
      creatMonthPerformance.bind(this, invoiceSummary, invoiceSummaries, gsInvoiceSummaries, invoicesWithTwelveMonthImage, customerCashMonthPerformanceChartImage),
      createSavingBySite.bind(this, invoiceSummary, invoiceSummaries, gsInvoiceSummaries, customerCashSitesSavingChartImage),
      renderCashContent.bind(this, invoiceSummary)
    )({});
    console.log("create invoice cash config:");
    console.log(contentConfig);
    return contentConfig;
  }

  const doCreateInvoice = (isCachCumstomer, cusomterTwelveMonthsChartImage, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries, invoicesWithTwelveMonthImage) => {
    let contentConfig = _.flow(
      createListOfInvoices.bind(this, isCachCumstomer, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries),
      summaryPageContent.bind(this, isCachCumstomer, invoiceSummaries, invoiceSummary, cusomterTwelveMonthsChartImage),
      remittancePageContent.bind(this, isCachCumstomer, invoiceSummaries, invoiceSummary, invoicesWithTwelveMonthImage),
      createDemandChargesByInvoice.bind(this, isCachCumstomer, invoicesWithTwelveMonthImage),
      createEnergyArbitrageByInvoice.bind(this, isCachCumstomer, invoicesWithTwelveMonthImage),
      createContentForEachInvoice.bind(this, isCachCumstomer, invoiceSummaries, invoiceSummariesEnergyArbitrage, invoicesWithTwelveMonthImage),
      renderContent.bind(this, isCachCumstomer, invoiceSummary, invoicesWithTwelveMonthImage)
    )({});
    console.log("create invoice cinfig:");
    console.log(contentConfig);
    return contentConfig;
  };

  return {
    downloadPdf,
    printPdf
  }
};

export default PdfServicesActions;
