import { GET_INVOICES, REFRESH_INVOICE, VIEW_TS_INVOICE, VIEW_INVOICE_REQUEST, REQUESTS } from '../actions/invoiceServices';

const INITIAL_STATE = {

};

export default function invoiceServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_INVOICES:
      return Object.assign({}, state, payload, { refreshInvoice: false });
    case REQUESTS:
      return Object.assign({}, state, payload, { refreshInvoice: false });
    case REFRESH_INVOICE:
      return Object.assign({}, state, payload);
    case VIEW_INVOICE_REQUEST:
      return Object.assign({}, state, payload, { refreshInvoice: false });
    case VIEW_TS_INVOICE:
      return Object.assign({}, state, payload, { refreshInvoice: false });
    default:
      return Object.assign({}, state, { refreshInvoice: false });
  }
}
