import { formatCurrency } from '../utils/utils';
import { default as gsLog } from '../service/loggingService';
import { PDF_COLORS } from "../shared/constants";
import $ from "jquery";

class CustomerLifetimeSummaryChart {
  constructor() {
    if (CustomerLifetimeSummaryChart.instance) {
      return CustomerLifetimeSummaryChart.instance;
    }
    this.gsLog = gsLog;
    this.PDF_COLORS = PDF_COLORS;
    this.graph = {};
    this.init();
    CustomerLifetimeSummaryChart.instance = this;
  }

  update(data) {
    const chart = $("#customerLifetimeSummaryChart").highcharts();
    if (chart) {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      this.gsLog.debug("In CustomerLifetimeSummaryChart: update(): data = ");
      this.gsLog.debug(data);
      let demandCustomerLifeTime = 0,
        gridServicesCustomerLifeTime = 0,
        pieData = [];
      if (data && data.length > 0) {
        const { totalByTransactionTypes } = data[0];
        if (totalByTransactionTypes.DEMAND_BASED) {
          demandCustomerLifeTime = totalByTransactionTypes.DEMAND_BASED.customerLifeTime;

          pieData.push(['Demand Charge Savings', demandCustomerLifeTime]);
        }
        if (totalByTransactionTypes.CONSUMPTION_BASED) {
          demandCustomerLifeTime = totalByTransactionTypes.CONSUMPTION_BASED.customerLifeTime;

          pieData.push(['Energy Arbitrage Savings', demandCustomerLifeTime]);
        }
        if (totalByTransactionTypes.DEMAND_RESPONSE) {
          gridServicesCustomerLifeTime = totalByTransactionTypes.DEMAND_RESPONSE.customerLifeTime;

          pieData.push(['Grid Services Revenue', gridServicesCustomerLifeTime]);
        }
        chart.addSeries({
          type: "pie",
          data: pieData
        });
      }
    }
  }

  init() {
    this.gsLog.debug("In CustomerLifetimeSummaryChart: init(): entering ...");
    this.graph = {
      options: {
        colors: [ this.PDF_COLORS.demandCharges, this.PDF_COLORS.energyArbitrage, this.PDF_COLORS.gridServices ],
        chart: {
            type: "pie",
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: this.PDF_COLORS.altRow
        },
        title: {
            text: null,
            align: 'center',
            verticalAlign: 'middle',
            y: 40
        },
        legend: {
          itemStyle: {
            color: "black"
          }
        },
        plotOptions: {
          pie: {
              dataLabels: {
                  enabled: true,
                  distance: -50,
                  style: {
                      color: 'black',
                      textOutline: "0px 0px black"
                  },
                  formatter: function() {
                    return formatCurrency(this.y);
                  }
              },
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              showInLegend: true
          }
        },
      },
      series: [{
        type: 'pie',
        name: null,
        innerSize: '50%',
        data: []
      }]
    };
  }
}

export default CustomerLifetimeSummaryChart;
