import _ from 'lodash';
import Rx from "rx";
import store from '../service/store';
import PatApi from "../service/patApi";
import PmtApi from "../service/pmtApi";
import State, { stateURLMapping } from "../service/state";
import PortfolioSelectorApi from "../portfolio-selector-footer/portfolioSelectorApi";
import LoggedInUserCheckInService from "../actions/loggedInUserCheckInService";
import CustomerServicesActions from "../actions/customerServices";
import {default as gsLog} from '../service/loggingService';
import { REFRESH_INVOICE } from './invoiceServices';
import { resetChart } from '../utils/utils';
export const PORTFOLIO_SELECTOR_SEARCH = "PORTFOLIO_SELECTOR_SEARCH";
export const PORTFOLIO_SELECTOR_SEARCH_BOOKMARK_TYPE = "PORTFOLIO_SELECTOR_SEARCH_BOOKMARK_TYPE";
export const PORTFOLIO_SELECTOR_PROJECTS = "PORTFOLIO_SELECTOR_PROJECTS";
export const PORTFOLIO_SELECTED_CUSTOMER = "PORTFOLIO_SELECTED_CUSTOMER";
export const PORTFOLIO_SELECTED_PORTFOLIO = "PORTFOLIO_SELECTED_PORTFOLIO";
export const PORTFOLIO_SELECTED_PROJECT = "PORTFOLIO_SELECTED_PROJECT";
export const PORTFOLIO_SELECTED_PROPOSAL= "PORTFOLIO_SELECTED_PROPOSAL";
export const PORTFOLIO_SELECTOR_PROPOSALS = "PORTFOLIO_SELECTOR_PROPOSALS";
export const PORTFOLIO_SELECTED_SERVICE = "PORTFOLIO_SELECTED_SERVICE";

export const PortfolioSelectorServicesActions = () => {
  let patApi = new PatApi();
  let PMTApi = new PmtApi();
  let rx = Rx;
  let state = State;
  let portfolioSelectorApi = new PortfolioSelectorApi();
  let loggedInUserCheckInService = LoggedInUserCheckInService();
  let customerServicesActions = CustomerServicesActions();

  const ERROR_CODE_DESCRIPTION = {
    2000: "Issue with calling the PMT Hist, connection issues, etc...",
    2001: "Issue with getting the ts data for project",
    2002: "Issue with getting the ts for generator",
    2003: "Issue with bad ts input for optimization direct",
    2004: "Issue with calling pipeline to get data",
    2010: "Issue with calling tariff to get tariff information or bad tariff parameters",
    2020: "Any general error exception returned by CPLEX"
  };

  const decodeError = (errorCode, errorTable, reason) => {
    if (!_.isUndefined(errorTable) && !isNaN(errorCode) && (errorCode in errorTable)) {
      return errorTable[errorCode];
    }
    else
    {
      return reason;
    }
  };
  const doBookmarkCustomer = (targetid, isBookmarked, callback) => {
    return async (dispatch) => {
      try {
        const results = await patApi.bookmarkCustomer(targetid,isBookmarked);
        gsLog.debug("In PortfolioSelectorServicesActions: bookmarkCustomer(): results = ");
        gsLog.debug(results);
        callback();
      } catch (error) {
        gsLog.error("In PortfolioSelectorServicesActions: bookmark(): error calling bookmark Customer: error = ");
        gsLog.error(error);
      }
    }
  };

  const doBookmarkService = (targetid, isBookmarked, callback) => {
    return async (dispatch,getState) => {
      try {
        const { selectedCustomer } = getState().portfolioSelectorServices;
        const results = await patApi.bookmarkService(targetid,selectedCustomer.customerUUID, isBookmarked);
        gsLog.debug("In PortfolioSelectorServicesActions: bookmarkService(): results = ");
        gsLog.debug(results);
        callback();
      } catch (error) {
        gsLog.error("In PortfolioSelectorServicesActions: bookmarkService(): error calling bookmark Service: error = ");
        gsLog.error(error);
      }
    }
  };

  const doBookmarkProject = (targetid, isBookmarked, callback) => {
    return async (dispatch,getState) => {
      try {
        const results = await patApi.bookmarkProject(targetid,isBookmarked);
        gsLog.debug("In PortfolioSelectorServicesActions: bookmarkProject(): results = ");
        gsLog.debug(results);
        callback();

      } catch (error) {
        gsLog.error("In PortfolioSelectorServicesActions: bookmarkProject(): error calling bookmark Project: error = ");
        gsLog.error(error);
      }
    }
  };

  const doBookmarkProposal = (targetid, isBookmarked, callback) => {
    return async (dispatch,getState) => {
      try {
        const results = await patApi.bookmarkProposal(targetid,isBookmarked);
        gsLog.debug("In PortfolioSelectorServicesActions: doBookmarkProposal(): results = ");
        gsLog.debug(results);
        callback();
      } catch (error) {
        gsLog.error("In PortfolioSelectorServicesActions: doBookmarkProposal(): error calling bookmark Proposal: error = ");
        gsLog.error(error);
      }
    }
  };

  const deleteCustomer = (customerUUID) => {
    return async (dispatch) => {
      try {
        const deleteResults = await patApi.deleteCustomer(customerUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: deleteCustomer(): delete customer results = ");
        gsLog.debug(deleteResults);
        dispatch(getCustomers(false));
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: deleteCustomer(): error calling deleting customer: error = ");
        gsLog.error(errors);
        throw new Error(errors);
      }
    }
  };

  const deleteService = (serviceUUID, customerUUID) => {
    return async (dispatch, getState) => {
      try {
        const deleteResults = await patApi.deleteService(serviceUUID, customerUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: deleteService(): delete service results = ");
        gsLog.debug(deleteResults);
        const { selectedCustomer } = getState().portfolioSelectorServices;
        dispatch(setSelectedCustomer(selectedCustomer, false));
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: deleteService(): error calling deleting service: error = ");
        gsLog.error(errors);
        throw new Error(errors);
      }
    }
  };

  const deleteProject = (projectUUID) => {
    return async (dispatch, getState) => {
      try {
        const deleteResults = await patApi.deleteProject(projectUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: deleteProject(): delete project results = ");
        gsLog.debug(deleteResults);
        const { selectedService } = getState().portfolioSelectorServices;
        dispatch(setSelectedService(selectedService, false));
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: deleteProject(): error calling deleting project: error = ");
        gsLog.error(errors);
        throw new Error(errors);
      }
    }
  };

  const deleteProposal = (proposalUUID) => {
    return async (dispatch, getState) => {
      try {
        const deleteResults = await patApi.deleteSoftProposal(proposalUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: deleteProposal(): delete proposal results = ");
        gsLog.debug(deleteResults);
        const { selectedProject, selectedCustomer } = getState().portfolioSelectorServices;
        dispatch(setSelectedProject(selectedProject, selectedCustomer.customerUUID, false));
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: deleteProposal(): error calling deleting proposal: error = ");
        gsLog.error(errors);
        throw new Error(errors);
      }
    }
  };

  const setBookmarkFilter= (filter) =>
  {
     return async()=>{
        portfolioSelectorApi.setbookmarkFilter(filter);
    }
  };

  const doSearch = (searchTerm, tenantId) => {
    return async (dispatch, getState) => {

      try {
        const { canViewAnalysisFeatures } = getState().loggedInUserServices.permissions;
        let  userSelectedTenantID;
        if (canViewAnalysisFeatures) {
          userSelectedTenantID = tenantId;
        }
        gsLog.error(`In PortfolioSelectorServicesActions: doSearch(): search term (${searchTerm}), tenantId (${tenantId})`);
        const customerResults = await portfolioSelectorApi.getCustomerByPageSorting({size: 10000, queryString: searchTerm, tenantId});
        
        //Restrict LocalStorage Customer
        customerResults.data.content = loggedInUserCheckInService.setExcludingCustomers(customerResults);

        gsLog.debug("In PortfolioSelectorServicesActions: doSearch(): search term results: customer result = ");
        gsLog.debug(customerResults);
        let preSelectedCustomer = getState().portfolioSelectorServices.selectedCustomer;
        let currentCustomer;
        for (let i = 0; i < customerResults.data.content.length; i++) {
          if (preSelectedCustomer && customerResults.data.content[i].customerUUID === preSelectedCustomer.customerUUID) {
            currentCustomer = customerResults.data.content[i];
            break;
          }
        }
        if(!currentCustomer) {
           currentCustomer =customerResults && customerResults.data ? customerResults.data.content[0] : null;
        }
        const selectedCustomer = currentCustomer,
              portfolioResults = undefined,
              serviceResults = undefined,
              projectResults = undefined;
        gsLog.debug("In PortfolioSelectorServicesActions: selectedCustomer 000 = ");
        gsLog.debug(selectedCustomer);

        dispatch({
          type: PORTFOLIO_SELECTOR_SEARCH,
          payload: {
            searchTerm,
            customers: customerResults && customerResults.data ? customerResults.data.content : [],
            customerParams: {
              pageNum: 0,
              rowsDisplayed: customerResults && customerResults.data ? customerResults.data.content.length : 0,
              totalRows: customerResults && customerResults.data ? customerResults.data.totalElements : 0
            },
            portfolios: portfolioResults && portfolioResults.data ? portfolioResults.data.content : [],
            portfolioParams: {
              pageNum: 0,
              rowsDisplayed: portfolioResults && portfolioResults.data ? portfolioResults.data.content.length : 0,
              totalRows: portfolioResults && portfolioResults.data ? portfolioResults.data.totalElements : 0
            },
            services: serviceResults && serviceResults.data ? serviceResults.data.content : [],
            serviceParams: {
              pageNum: 0,
              rowsDisplayed: serviceResults && serviceResults.data ? serviceResults.data.content.length : 0,
              totalRows: serviceResults && serviceResults.data ? serviceResults.data.totalElements : 0
            },
            projects: projectResults && projectResults.data ? projectResults.data.content : [],
            projectParams: {
              pageNum: 0,
              rowsDisplayed: projectResults && projectResults.data ? projectResults.data.content.length : 0,
              totalRows: projectResults && projectResults.data ? projectResults.data.totalElements : 0
            },
            proposals: [],
            selectedCustomer,
            selectedSelector: {
              customer: true,
              service: false,
              project: false,
              proposal: false
            },
            userSelectedTenantID,
          }
        });

        if (selectedCustomer) {
          dispatch(setSelectedCustomer(selectedCustomer));
        } else {
          dispatch({
            type: PORTFOLIO_SELECTOR_SEARCH,
            payload: {
              selectedPortfolio: null,
              selectedService: null,
              selectedProject: null,
              selectedProposal: null,
              userSelectedTenantID,
            }
          });
        }
      } catch (e) {
        gsLog.error("In PortfolioSelectorServicesActions: doSearch(): (2) error calling search terms API: error = ");
        gsLog.error(e);
      }
    };
  };

  const getCustomers = (keepExistingSelection = false, tenantId=undefined, selectedcustomerUUID=null) => {
    return async (dispatch, getState) => {
      gsLog.debug("In PortfolioSelectorServicesActions: getCustomers(): 000 ");

      try {
        const { canViewAnalysisFeatures } = getState().loggedInUserServices.permissions;
        let userSelectedTenantID;
        if (canViewAnalysisFeatures && _.isUndefined(tenantId)) {
          tenantId = getState().loggedInUserServices.defaultTenantId;
          userSelectedTenantID = tenantId;
        }
        else if (canViewAnalysisFeatures && !_.isUndefined(tenantId))
        {
          userSelectedTenantID = tenantId;
        }

        ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("onlineProjectsOnly"));
        const customerResults = await portfolioSelectorApi.getCustomerByPageSorting({size: 10000, tenantId});
        ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("None"));

        gsLog.debug("In PortfolioSelectorServicesActions: getCustomers(): get customers results = ");
        gsLog.debug(customerResults);

        const customers = customerResults && customerResults.data && customerResults.data.content ? customerResults.data.content : [];
        
        //Restrict LocalStorage Customer
        gsLog.debug("In PortfolioSelectorServicesActions: getCustomers(): 222");
        gsLog.debug(loggedInUserCheckInService);
        customerResults.data.content = loggedInUserCheckInService.setExcludingCustomers(customerResults);
        let selectedCustomer;

        gsLog.debug("In PortfolioSelectorServicesActions: getCustomers(): keepExistingSelection = ");
        gsLog.debug(keepExistingSelection);

        if (keepExistingSelection) {
          let currentCustomer = getState().portfolioSelectorServices.selectedCustomer;

          if (customers && customers.length > 0 && currentCustomer) {
            for (let i = 0; i < customers.length; i++) {
              if (currentCustomer.customerUUID === customers[i].customerUUID) {
                selectedCustomer = customers[i];
                break;
              }
            }
          } else {
            selectedCustomer = getState().portfolioSelectorServices.selectedCustomer;
          }
        } else {
          if (selectedcustomerUUID) {
            selectedCustomer = customers.find((item) => item.customerUUID === selectedcustomerUUID);
            
            if (!selectedCustomer) {
              selectedCustomer  = (customers && customers.length > 0 ? customers[0] : null);
            }
          } else {
            selectedCustomer  = (customers && customers.length > 0 ? customers[0] : null);
          }   
        }

        if (!selectedCustomer) {
          if (customers && customers.length > 0) {
            selectedCustomer= customers[0];
          }
        }

        let selectedService = null,
            services = [];
        if (selectedCustomer) {
          //
          // services
          //
          gsLog.debug("In PortfolioSelectorServicesActions: getCustomers(): getting services ...");
          // setInitialSelectedCustomer(selectedCustomer, keepExistingSelection);
          dispatch(customerServicesActions.setCustomer(selectedCustomer));

          ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("onlineProjectsOnly"));
          const serviceResults = await portfolioSelectorApi.getServicesBycustomerUUID(selectedCustomer.customerUUID).finally(() => {
            portfolioSelectorApi.resetServicesBycustomerUUIDcanceller();
          });
          ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("None"));

          gsLog.debug("In PortfolioSelectorServicesActions: setInitialSelectedCustomer(): service results = ");
          gsLog.debug(serviceResults);

          if (serviceResults && serviceResults.data) {
            services = serviceResults.data.content ? serviceResults.data.content : [];

            selectedService = keepExistingSelection ? getState().portfolioSelectorServices.selectedService : (services.length > 0 ? services[0] : null);
            
            if (selectedService) {
              let foundService = services.find( ele => ele.serviceUUID === selectedService.serviceUUID);
              if (!foundService) {
                selectedService = services.length > 0 ? services[0] : null;
              }
            }

            if (selectedService) {
              setSelectedServiceByCustomer(dispatch, getState, selectedService, keepExistingSelection);
            }
          }
        }

        gsLog.debug("In PortfolioSelectorServicesActions: getCustomers 222 = ");
        gsLog.debug(selectedCustomer);

        if (selectedService) {
          dispatch({
            type: PORTFOLIO_SELECTED_CUSTOMER,
            payload: {
              customers,
              selectedCustomer,
              selectedService,
              services,
              selectedSelector: {
                customer: true,
                service: false,
                project: false,
                proposal: false
              },
              userSelectedTenantID,
            }
          });
        } else {
          dispatch({
            type: PORTFOLIO_SELECTED_CUSTOMER,
            payload: {
              customers,
              selectedCustomer,
              selectedService: null,
              services,
              selectedProject: null,
              projects: [],
              selectedProposal: null,
              proposals: [],
              selectedSelector: {
                customer: true,
                service: false,
                project: false,
                proposal: false
              },
              userSelectedTenantID,
            }
          });
        }
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: getCustomers(): error in processing: error = ");
        gsLog.error(errors);

        dispatch({
          type: PORTFOLIO_SELECTED_CUSTOMER,
          payload: {
            customers: [],
            selectedCustomer: null,
          }
        });
      }
    }
  };


  const setSelectedCustomer = (selectedCustomer, highlightSelectedCustomer=true, selectedserviceUUID=null) => {
    return async (dispatch, getState) => {
      gsLog.debug("In PortfolioSelectorServicesActions: setSelectedCustomer(): 000 ");
		
      dispatch(customerServicesActions.setCustomer(selectedCustomer));
      ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("onlineProjectsOnly"));
      const serviceResults = await portfolioSelectorApi.getServicesBycustomerUUID(selectedCustomer.customerUUID).finally(() => {
        portfolioSelectorApi.resetServicesBycustomerUUIDcanceller();
      });
      ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("None"));
      gsLog.debug("In PortfolioSelectorServicesActions: setSelectedCustomer(): service results = ");
      gsLog.debug(serviceResults);
      //HACK
      // resetChart("vitals-main-graph");
		
      let selectedService = null,
          services = [];
      if (serviceResults && serviceResults.data) {
        services = serviceResults.data.content ? serviceResults.data.content : [];
        selectedService = services.length > 0 ? services[0] : null;
        if (selectedserviceUUID) {
          selectedService = services.find((item) => item.serviceUUID === selectedserviceUUID);
          if(!selectedService) {
            selectedService = services.length > 0 ? services[0] : null;
          }
          gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedCustomer(): select new service ID (${selectedService.serviceUUID}), name (${selectedService.serviceName})`);
        }else {
          let prevSelectedService = getState().portfolioSelectorServices.selectedService;
          if (serviceResults && serviceResults.data && prevSelectedService) {
            let serviceTemplate = services.find((item) => item.serviceUUID === prevSelectedService.serviceUUID);
            if (serviceTemplate)
              selectedService = serviceTemplate;
          }
        }

        if (selectedService) {
          setSelectedServiceByCustomer(dispatch, getState, selectedService, true);
        }
      }

      gsLog.debug("In PortfolioSelectorServicesActions: selectedCustomer 222 = ");
      gsLog.debug(selectedCustomer);

      if (selectedService) {
        dispatch({
          type: PORTFOLIO_SELECTED_CUSTOMER,
          payload: {
            selectedCustomer,
            selectedService,
            services,
            selectedSelector: {
              customer: highlightSelectedCustomer || services.length < 1,
              service: !highlightSelectedCustomer && services.length > 0,
              project: false,
              proposal: false
            }
          }
        });
      } else {
        dispatch({
          type: PORTFOLIO_SELECTED_CUSTOMER,
          payload: {
            selectedCustomer,
            selectedService,
            services,
            selectedProject: null,
            projects: [],
            selectedProposal: null,
            proposals: [],
            selectedSelector: {
              customer: highlightSelectedCustomer || services.length < 1,
              service: !highlightSelectedCustomer && services.length > 0,
              project: false,
              proposal: false
            }
          }
        });
      }

      dispatch({
        type: REFRESH_INVOICE,
        payload: {
          refreshInvoice: true
        }
      });
    };
  };
  const setSelectedProposal = (proposal) => {

    return {
      type: PORTFOLIO_SELECTED_PROPOSAL,
      payload: {
        selectedProposal: proposal,
        selectedSelector: {
          customer: false,
          service: false,
          project: false,
          proposal: true
        }
      }
    };
  };

  const setSelectedPortfolio = (portfolio) => {
    return (dispatch, getState) => {
      const observables = [
        portfolioSelectorApi.getSelectedPortfolio(portfolio.portfolioId, "customers").catch((error) => {
          gsLog.error("In PortfolioSelectorServicesActions: setSelectedPortfolio(): error calling get customers by portfolio ID API: error = ");
          gsLog.error(error);
          return rx.Observable.just({apiError: true});
        }),
        portfolioSelectorApi.getSelectedPortfolio(portfolio.portfolioId, "services").catch((error) => {
          gsLog.error("In PortfolioSelectorServicesActions: setSelectedPortfolio(): error calling get services by portfolio ID API: error = ");
          gsLog.error(error);
          return rx.Observable.just({apiError: true});
        }),
        portfolioSelectorApi.getSelectedPortfolio(portfolio.portfolioId, "projects").catch((error) => {
          gsLog.error("In PortfolioSelectorServicesActions: setSelectedPortfolio(): error calling get projects by portfolio ID API: error = ");
          gsLog.error(error);
          return rx.Observable.just({apiError: true});
        })
      ];

      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([customerResults, serviceResults, projectResults]) => {
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedPortfolio(): search term results: customer result = ");
            gsLog.debug(customerResults);
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedPortfolio(): search term results: service result = ");
            gsLog.debug(serviceResults);
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedPortfolio(): search term results: project result = ");
            gsLog.debug(projectResults);

            return dispatch({
              type: PORTFOLIO_SELECTED_PORTFOLIO,
              payload: {
                selectedPortfolio: portfolio,
                selectedCustomer: null,
                selectedService: null,
                selectedProject: null,
                customers: customerResults ? customerResults.data.content : [],
                customerParams: {
                  pageNum: 0,
                  rowsDisplayed: customerResults ? customerResults.data.content.length : 0,
                  totalRows: customerResults ? customerResults.data.totalElements : 0
                },
                services: serviceResults ? serviceResults.data.content : [],
                serviceParams: {
                  pageNum: 0,
                  rowsDisplayed: serviceResults ? serviceResults.data.content.length : 0,
                  totalRows: serviceResults ? serviceResults.data.totalElements : 0
                },
                projects: projectResults ? projectResults.data.content : [],
                projectParams: {
                  pageNum: 0,
                  rowsDisplayed: projectResults ? projectResults.data.content.length : 0,
                  totalRows: projectResults ? projectResults.data.totalElements : 0
                }
              }
            });
          },
          (error) => {
            gsLog.error("In PortfolioSelectorServicesActions: setSelectedPortfolio(): (2) error processing set selected portfolio: error = ");
            gsLog.error(error);
          },
          () => {
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedPortfolio(): called all APIs: DONE");
          }
        );
    };
  };

  const setSelectedProject = (project, customerUUID, highlightSelectedProject=true, selectedProposalUUID=null) => {

    return async (dispatch, getState) => {

      try {
        gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProject(): project = ");
        gsLog.debug(project);
        const { projectUUID } = project;

        if (state.customRouter.location.pathname !== "app.vitals.newproject" && state.customRouter.location.pathname !== "app.vitals.project" && state.customRouter.location.pathname !== "app.vpp-billing") {
          gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProject(): setSelectedProject 222 ");
          let preselectedProposal = getState().portfolioSelectorServices.selectedProposal;
          if(!selectedProposalUUID && preselectedProposal) {
            selectedProposalUUID = preselectedProposal.proposalUUID;
          }
          getProposalsByProjectUUID(dispatch, getState, projectUUID, false, selectedProposalUUID);
        }
        getGeneratorByProjectUUID(dispatch, getState, projectUUID);

        const projectResults = await portfolioSelectorApi.getServicesByProjectUUID(projectUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProject(): project result = ");
        gsLog.debug(projectResults);

        let serviceUUID;
        if (!_.isUndefined(projectResults) && !_.isUndefined(projectResults.data) &&
            !_.isUndefined(projectResults.data.content) &&
            projectResults.data.content.length !== 0 &&
            !_.isUndefined(projectResults.data.content[0].serviceUUID)) {
            serviceUUID = projectResults.data.content[0].serviceUUID;
        }

        gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): service ID (${serviceUUID})`);
        gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): customerUUID (${customerUUID})`);
        const genTypeResults = await portfolioSelectorApi.getGeneratorTypesByServiceUUID(serviceUUID, customerUUID);
        gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProject(): generator type result = ");
        gsLog.debug(genTypeResults);

        let hasSolar = false;
        let solarInverterACCapacity = 0;
        let solarInverterDCCapacity = 0;

        if (!_.isUndefined(genTypeResults) && !_.isUndefined(genTypeResults.data) &&
            !_.isUndefined(genTypeResults.data.SOLAR) &&
            !_.isUndefined(genTypeResults.data.SOLAR.length !== 0)) {
          hasSolar = true;
          solarInverterDCCapacity = genTypeResults.data.SOLAR[0].inverterDCCapacity;
          genTypeResults.data.SOLAR.forEach(function(item) {
            gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): solarInverterACCapacity traversal: item `);
            gsLog.debug(item);
            gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): solarInverterACCapacity traversal: solarInverterACCapacity (${solarInverterACCapacity})`);
            solarInverterACCapacity = solarInverterACCapacity + item.inverterACCapacity; 
          });
        }
        gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): hasSolar (${hasSolar})`);
        gsLog.debug(`In PortfolioSelectorServicesActions: setSelectedProject(): solarInverterACCapacity (${solarInverterACCapacity})`);

        return dispatch({
          type: PORTFOLIO_SELECTED_PROJECT,
          payload: {
            selectedProject: Object.assign({}, project, {hasSolar, solarInverterACCapacity, solarInverterDCCapacity}),
            selectedSelector: {
              customer: false,
              service: false,
              project: highlightSelectedProject,
              proposal: !highlightSelectedProject
            }
          }
        });
      } catch (errors) {
        gsLog.error("In PortfolioSelectorServicesActions: setSelectedProject(): (2) error processing set selected project: error = ");
        gsLog.error(errors);
      }

    };
  };

  const setSelectedServiceByCustomer = (dispatch, getState, selectedService, keepExistingSelection = false) => {
    gsLog.debug("In PortfolioSelectorServicesActions: setSelectedServiceByCustomer(): 000 ");
    ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("onlineProjectsOnly"));

    const observables = [
      portfolioSelectorApi.getProjectsByServiceUUID(selectedService.serviceUUID).catch((error) => {
        gsLog.error("In PortfolioSelectorServicesActions: setSelectedServiceByCustomer(): error calling get projects API: error = ");
        gsLog.error(error);
        return rx.Observable.just({apiError: true});
      })
    ];

    ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("None"));

    rx.Observable
      .forkJoin(observables)
      .subscribe(
        ([projectResults]) => {
          gsLog.debug("In PortfolioSelectorServicesActions: setSelectedServiceByCustomer(): project results = ");
          gsLog.debug(projectResults);

          let projects = [],
              selectedProject = null;
              selectedProject = keepExistingSelection ? getState().portfolioSelectorServices.selectedProject : null;
          if (projectResults && projectResults.data && projectResults.data.content) {
            
            projects = projectResults.data.content;
            // selectedProject = selectedProject || (projects.length > 0 ? projects[0] : null);
            selectedProject = keepExistingSelection ? selectedProject : (projects.length > 0 ? projects[0] : null);
            if(selectedProject) {
              //Check projects contains selected poroject. If not selected first one
              let matchedproject = projects.find((item) => item.projectUUID === selectedProject.projectUUID);
              if(!matchedproject) {
                selectedProject = projects.length > 0 ? projects[0] : null;
              }
            }else {
              selectedProject = projects.length > 0 ? projects[0] : null;
            }
          }

          if (selectedProject) {
            //HACK
            // resetChart("vitals-main-graph");
            setSelectedProjectByService(dispatch, getState, selectedProject, keepExistingSelection);

            return dispatch({
              type: PORTFOLIO_SELECTED_SERVICE,
              payload: {
                selectedProject,
                projects
              }
            });
          } else {
            //HACK
            // resetChart("vitals-main-graph");
            return dispatch({
              type: PORTFOLIO_SELECTED_SERVICE,
              payload: {
                selectedProject,
                projects,
                proposals: [],
                selectedProposal: null
              }
            });
          }
        },
        (error) => {
          gsLog.error("In PortfolioSelectorServicesActions: setSelectedServiceByCustomer(): error in processing set selected service: error = ");
          gsLog.error(error);

          return dispatch({
            type: PORTFOLIO_SELECTED_SERVICE,
            payload: {
              // selectedProject,
              // projects,
              proposals: [],
              selectedProposal: null
            }
          });
        },
        () => {
          gsLog.debug("In PortfolioSelectorServicesActions: setSelectedServiceByCustomer(): DONE with processing");
        }
      );
  };

  const setSelectedProjectByService = (dispatch, getState, selectedProject, keepExistingSelection = false) => {
    gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProjectByService(): current state");
    gsLog.debug(state.current);

    if (state.customRouter.location.pathname !== "app.vitals.newproject" && state.customRouter.location.pathname !== "app.vitals.project") {
      gsLog.debug("In PortfolioSelectorServicesActions: setSelectedProjectByService(): setSelectedProjectByService 222");
      getProposalsByProjectUUID(dispatch, getState, selectedProject.projectUUID, keepExistingSelection);
    }
    getGeneratorByProjectUUID(dispatch, getState, selectedProject.projectUUID);
  };

  const setSelectedService = (service, highlightSelectedService=true, selectedprojectUUID=null, createdProject=null) => {
    gsLog.debug("In PortfolioSelectorServicesActions: setSelectedService(): 000 ");
    return (dispatch, getState) => {

      const { selectedCustomer } = getState().portfolioSelectorServices; 
      //HACK
      // resetChart("vitals-main-graph");
      gsLog.debug("In PortfolioSelectorServicesActions: selectedCustomer 333 = ");
      gsLog.debug(selectedCustomer);

      ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("onlineProjectsOnly"));
      const observables = [
        portfolioSelectorApi.getProjectsByServiceUUID(service.serviceUUID).catch((error) => {
          gsLog.error("In PortfolioSelectorServicesActions: setSelectedService(): error calling get projects API: error = ");
          gsLog.error(error);
          return rx.Observable.just({apiError: true});
        })
      ];
      ((state.customRouter.location.pathname === stateURLMapping["app.vitals.newproject"] || state.customRouter.location.pathname === stateURLMapping["app.vpp-billing"]) && portfolioSelectorApi.setbookmarkFilter("None"));

      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([projectResults]) => {
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedService(): project results = ");
            gsLog.debug(projectResults);

            let projects = [],
                selectedProject = null;
            if (projectResults && projectResults.data && projectResults.data.content) {
              
              projects = projectResults.data.content;
              selectedProject = projects.length > 0 ? projects[0] : null;
              if (selectedprojectUUID) {
                selectedProject = projects.find((item) => item.projectUUID === selectedprojectUUID);
                if (selectedProject) {
                  gsLog.error(`In PortfolioSelectorServicesActions: setSelectedService(): select new project ID (${selectedProject.projectUUID}), name (${selectedProject.projectName})`);
                }
              }else {
                //get selected project from state, need check selected project state in the list
                let selectedprojectFromState = getState().portfolioSelectorServices.selectedProject;
                if(selectedprojectFromState) {
                  if(projects.find((item) => item.projectUUID === selectedprojectFromState.projectUUID)) {
                    selectedProject = selectedprojectFromState;
                  }
                } 
              }
            }

            if (selectedProject) {
              setSelectedProjectByService(dispatch, getState, selectedProject, true);

              dispatch({
                type: PORTFOLIO_SELECTED_SERVICE,
                payload: {
                  selectedService: service,
                  selectedProject,
                  projects,
                  selectedSelector: {
                    customer: false,
                    service: highlightSelectedService || projects.length < 1,
                    project: !highlightSelectedService && projects.length > 0,
                    proposal: false
                  }
                }
              });
            } else {
              dispatch({
                type: PORTFOLIO_SELECTED_SERVICE,
                payload: {
                  selectedService: service,
                  selectedProject,
                  projects,
                  proposals: [],
                  selectedProposal: null,
                  selectedSelector: {
                    customer: false,
                    service: highlightSelectedService || projects.length < 1,
                    project: !highlightSelectedService && projects.length > 0,
                    proposal: false
                  }
                }
              });
            }
          },
          (error) => {
            gsLog.error("In PortfolioSelectorServicesActions: setSelectedService(): (2) error processing set selected service: error = ");
            gsLog.error(error);
          },
          () => {
            gsLog.debug("In PortfolioSelectorServicesActions: setSelectedService(): called all APIs: DONE");
          }
        );
    };
  };

  const getProposalStatuses = () => {
    gsLog.debug("In PortfolioSelectorServicesActions: getProposalStatuses(): ");

    return (dispatch, getState) => {
      const { selectedOptimizerSpaceId } = getState().cachedObjectServices,
            { proposals, selectedProposal } = getState().portfolioSelectorServices;

      const analysesRequests = proposals.reduce((acc, item) => {
        if (item.analysisId && item.analysisId.length > 0) {
          // TODO test code only
          // item.analysisId = "AV4VPSCuGmAxOrm-3fcu";
          if (item.optimizerStatus !== "completed" && item.optimizerStatus !== "failed" && item.optimizerStatus !== "timeout") {
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalStatuses(): selectedOptimizerSpaceId ");
            gsLog.debug(selectedOptimizerSpaceId);

            acc.push(patApi.getAnalysis(item.analysisId, selectedOptimizerSpaceId).catch((errors) => {
              gsLog.error(`In PortfolioSelectorServicesActions: getProposalStatuses(): error calling get analyses API: id (${item.analysisId}), errors = `);
              gsLog.error(errors);
            }));
          }
        } else {
          gsLog.debug(`In PortfolioSelectorServicesActions: getProposalStatuses(): empty analysis ID for proposal id (${item.proposalUUID}), name (${item.proposalName})`);
        }
        return acc;
      }, []);

      if (analysesRequests && analysesRequests.length > 0) {
        rx.Observable
          .forkJoin(analysesRequests)
          .subscribe(
            (results) => {
              gsLog.debug("In PortfolioSelectorServicesActions: getProposalStatuses(): results in calling get analyses API for each proposal = ");
              gsLog.debug(results);

              // TODO --- don't set selectedProposal or used what's passed in
              dispatch(processAnalysesResults(results, proposals, selectedProposal));
            },
            (errors) => {
              gsLog.error("In PortfolioSelectorServicesActions: getProposalStatuses(): error in processing proposals = ");
              gsLog.error(errors);
            },
            () => {
              gsLog.debug("In PortfolioSelectorServicesActions: getProposalStatuses(): DONE with processing");
            }
          )
      }
    }
  };

  const processProposalResults = (results) => {
    gsLog.debug("In PortfolioSelectorServicesActions: processProposalResults(): results in calling get proposals API = ");
    gsLog.debug(results);

    let analysesRequests = [],
        proposalResults = [];
    if (results.data && results.data.content && results.data.content.length > 0) {
      const { selectedOptimizerSpaceId } = store.getState().cachedObjectServices;
      proposalResults = [...results.data.content];
      analysesRequests = proposalResults.reduce((acc, item) => {
        if (item.analysisId && item.analysisId.length > 0) {
          gsLog.debug("In PortfolioSelectorServicesActions: processProposalResults(): selectedOptimizerSpaceId ");
          gsLog.debug(selectedOptimizerSpaceId);
          acc.push(patApi.getAnalysis(item.analysisId, selectedOptimizerSpaceId).catch((errors) => {
            gsLog.error(`In PortfolioSelectorServicesActions: processProposalResults(): error calling get analyses API: id (${item.analysisId}), errors = `);
            gsLog.error(errors);
          }));
        } else {
          gsLog.debug(`In PortfolioSelectorServicesActions: processProposalResults(): empty analysis ID for proposal id (${item.proposalUUID}), name (${item.proposalName})`);
        }
        return acc;
      }, []);
    }

    if (analysesRequests && analysesRequests.length > 0) {
      // return rx.Observable.forkJoin(analysesRequests);
      return rx.Observable.just({
        noAnalysesRequests: false,
        analysesRequests,
        proposalResults
      });
    } else {
      return rx.Observable.just({noAnalysesRequests: true});
    }
  };

  const processAnalysesResults = (results, proposalResults, selectedProposal = undefined) => {
    let proposals = proposalResults.length > 0 ? proposalResults : [];
    if (_.isUndefined(results.noAnalysesRequests) || (!_.isUndefined(results.noAnalysesRequests) && !results.noAnalysesRequests && results.length > 0)) {
      gsLog.debug("In PortfolioSelectorServicesActions: processAnalysesResults(): results = ");
      gsLog.debug(results);
      if (!_.isUndefined(results[0])) {
        results.map((item) => {
          const { id, request, status, errorCode, reason } = item.data,
            { startDate, endDate } = request;

          let proposal = proposalResults.find((pItem) => pItem.analysisId === id);
          if (proposal) {
            proposals = [...proposalResults];
            gsLog.debug(`In PortfolioSelectorServicesActions: processAnalysesResults(): found proposal ID (${id}), name (${proposal.proposalName}), status (${status}), errorCode (${errorCode}), reason (${reason})`);
            proposal.optimizerStatus = status;
            proposal.optimizerErrorCode = errorCode;
            proposal.optimizerErrorDesc = decodeError(errorCode, ERROR_CODE_DESCRIPTION, reason);
            proposal.optimizerReason = reason;
            proposal.startDate = startDate;
            proposal.endDate = endDate;
            if (!_.isUndefined(item.data.financialModelResults) && !_.isUndefined(item.data.financialModelResults.results) && !_.isNull(item.data.financialModelResults.results)) {
              const { solarStorageIrr, solarStoragePaybackPeriod, solarStorageYear1Savings, storageIrr, storagePaybackPeriod, solarIrr, solarPaybackPeriod,storageYear1Savings } = item.data.financialModelResults.results;
              proposal.financialModelResults = {
                solarStorageIrr, solarStoragePaybackPeriod, solarStorageYear1Savings,
                storageIrr, storagePaybackPeriod,
                solarIrr, solarPaybackPeriod,
                storageYear1Savings
              }
              try{
              proposal.guaranteeOption = item.data.financialModelResults.request.parameters.guaranteeOption;
              proposal.guaranteeDemandThreshold = item.data.financialModelResults.request.parameters.guaranteeDemandThreshold;
              proposal.meterNumber = item.data.financialModelResults.request.parameters.meterNumber;
              proposal.serviceAccountId = item.data.financialModelResults.request.parameters.serviceAccountId;
              proposal.meterAddress = item.data.financialModelResults.request.parameters.meterAddress;
              proposal.billingCalendar = item.data.financialModelResults.request.parameters.billingCalendar;
              }catch(e){}
            } else {
              proposal.financialModelResults = {
                solarStorageIrr: null, solarStoragePaybackPeriod: null, solarStorageYear1Savings: null,
                storageIrr: null, storagePaybackPeriod: null,
                solarIrr: null, solarPaybackPeriod: null,
                storageYear1Savings:null
              }
            }
          }
        });
      }
    }

    return {
      type: PORTFOLIO_SELECTOR_PROPOSALS,
      payload: {
        proposals,
        selectedProposal: selectedProposal ? selectedProposal : (proposals.length > 0 ? proposals[0] : null),
      }
    };
  };

  const getProposalsByServiceUUID = (dispatch, getState, customerUUID, serviceUUID) => {
    // TODO - testing only
    if (serviceUUID) {
      let proposalResults = [];
      gsLog.debug(`In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): for selected customer ID (${customerUUID}), service ID (${serviceUUID})`);

      rx.Observable
        .fromPromise(patApi.getProposalsByCustomerserviceUUID(customerUUID, serviceUUID))
        .catch((errors) => {
          gsLog.error("In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): error in calling get proposals API = ");
          gsLog.error(errors);
          return rx.Observable.just({apiError: true});
        })
        .flatMap(processProposalResults)
        .flatMap((results) => {
          if (results.noAnalysesRequests) {
            return rx.Observable.just({noAnalysesRequests: true});
          } else {
            proposalResults = [...results.proposalResults];
            return rx.Observable.forkJoin(results.analysesRequests);
          }
        })
        .subscribe(
          (results) => {
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): results in calling get analyses API for each proposal = ");
            gsLog.debug(results);

            const { selectedProposal } = getState().portfolioSelectorServices;
            dispatch(processAnalysesResults(results, proposalResults, selectedProposal));
          },
          (errors) => {
            gsLog.error("In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): error in processing proposals = ");
            gsLog.error(errors);

            dispatch({
              type: PORTFOLIO_SELECTOR_PROPOSALS,
              payload: {
                proposals: [],
                selectedProposal: null
              }
            });
          },
          () => {
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): DONE with processing");
          }
        )
    } else {
      gsLog.error("In PortfolioSelectorServicesActions: getProposalsByServiceUUID(): You must select a project first");
    }
  };

  const getGeneratorByProjectUUID = (dispatch, getState, projectUUID) => {
    if (projectUUID) {
      rx.Observable
        .fromPromise(patApi.getGeneratorsByProjectUUID(projectUUID))
        .catch((errors) => {
          gsLog.error("In PortfolioSelectorServicesActions: getGeneratorByProjectUUID(): error from get all systems API = ");
          gsLog.error(errors);
          return rx.Observable.just({apiError: true});
        })
        .subscribe(
          (results) => {
            gsLog.debug("In PortfolioSelectorServicesActions: getGeneratorByProjectUUID(): results from get generators by project ID API = ");
            gsLog.debug(results);

            let generatorUUID = null;
            if (results.data && results.data.content && results.data.content.length > 0) {
              generatorUUID = results.data.content[0].generatorUUID;
            }

            dispatch({
              type: PORTFOLIO_SELECTED_PROJECT,
              payload: {
                selectedGeneratorUUID: generatorUUID
              }
            });
          },
          (errors) => {
            gsLog.error("In PortfolioSelectorServicesActions: getGeneratorByProjectUUID(): error in processing = ");
            gsLog.error(errors);
          },
          () => {
            gsLog.debug("In PortfolioSelectorServicesActions: getGeneratorByProjectUUID(): DONE with processing");
          }
        )
    }
  };

  const getProposalsByProjectUUID = (dispatch, getState, projectUUID, keepExistingSelection = false, selectedProposalUUID = null) => {
    // TODO - testing only
    if (projectUUID) {
      let proposalResults = [];
      gsLog.debug(`In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): for selected project ID (${projectUUID})`);
      gsLog.debug(PMTApi);

      rx.Observable
        .fromPromise(PMTApi.getProposalsByProjectUUID(projectUUID))
        .catch((errors) => {
          gsLog.error("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): error in calling get proposals API = ");
          gsLog.error(errors);
          return rx.Observable.just({apiError: true});
        })
        .flatMap(processProposalResults)
        .flatMap((results) => {
          if (results.noAnalysesRequests) {
            return rx.Observable.just({noAnalysesRequests: true});
          } else {
            proposalResults = [...results.proposalResults];
            return rx.Observable.forkJoin(results.analysesRequests);
          }
        })
        .subscribe(
          (results) => {
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): results in calling get analyses API for each proposal = ");
            gsLog.debug(results);
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): list of proposals = ");
            gsLog.debug(proposalResults);

            let existingProposal = proposalResults.length > 0 ? proposalResults[0] : null;
            let selectedProposal = keepExistingSelection ? getState().portfolioSelectorServices.selectedProposal : existingProposal;
            if (selectedProposalUUID) {
              selectedProposal = proposalResults.find((item) => item.proposalUUID === selectedProposalUUID);
              //gsLog.debug(`In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): select new proposal ID (${selectedProposal.proposalUUID}), name (${selectedProposal.proposalName})`);
            }
            // make sure the selected proposal is in the list
            if(selectedProposal) {
                // If can not find,  select first one. 
                if(! proposalResults.find((item) => item.proposalUUID === selectedProposal.proposalUUID)) {
                  selectedProposal = proposalResults.length > 0 ? proposalResults[0] : null;
                }
            }
            dispatch(processAnalysesResults(results, proposalResults, selectedProposal));
          },
          (errors) => {
            gsLog.error("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): error in processing proposals = ");
            gsLog.error(errors);

            dispatch({
              type: PORTFOLIO_SELECTOR_PROPOSALS,
              payload: {
                selectedProposal: null,
                proposals: []
              }
            });
          },
          () => {
            gsLog.debug("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): DONE with processing");
          }
        )
    } else {
      gsLog.error("In PortfolioSelectorServicesActions: getProposalsByProjectUUID(): You must select a project first");
    }
  };

  return {
    deleteCustomer,
    doBookmarkCustomer,
    doBookmarkService,
    doBookmarkProject,
    doBookmarkProposal,
    deleteProject,
    deleteProposal,
    deleteService,
    doSearch,
    getCustomers,
    getProposalsByProjectUUID,
    getProposalsByServiceUUID,
    getProposalStatuses,
    setSelectedCustomer,
    setSelectedPortfolio,
    setSelectedProject,
    setSelectedProposal,
    setSelectedService,
    setBookmarkFilter
  }
}

export default PortfolioSelectorServicesActions;