import LoggingService from './loggingService';
import localStorageService from './localStorageService';

class StorageServiceFactory {
    constructor() {
        LoggingService.debug("StorageServiceFactory constructor:");
        LoggingService.debug("entering storageService ...");
        if (StorageServiceFactory.instance) {
            return StorageServiceFactory.instance;
        }

        StorageServiceFactory.instance = this;
    }
   
    getVal(key) {
        LoggingService.debug("StorageServiceFactory getVal:");
        LoggingService.debug(key);
        LoggingService.debug("StorageServiceFactory getVal: 222");
        LoggingService.debug(localStorageService.getVal(key));
        return localStorageService.getVal(key);
    }

    setVal(key, val) {
        var lsKeys = this.getKeys();
        LoggingService.debug("StorageServiceFactory setVal: 000 key");
        LoggingService.debug(lsKeys);

        LoggingService.debug("StorageServiceFactory setVal: 111 key");
        LoggingService.debug(key);
        LoggingService.debug("StorageServiceFactory setVal: 222 val");
        LoggingService.debug(val);

        return localStorageService.setVal(key, val);
    }

    getKeys() {
        LoggingService.debug("StorageServiceFactory getKeys:");
        return localStorageService.keys();
    }

    removeKey(key) {
        LoggingService.debug("StorageServiceFactory removeKey:");
        return localStorageService.removeKey(key);
    }

    removeAll() {
        LoggingService.debug("StorageServiceFactory removeAll:");
        return localStorageService.removeAll();
    }
}

const storageService = new StorageServiceFactory();

export default storageService;
