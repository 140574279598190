import '../react-transition/interceptor.js';
import { saveAs } from 'file-saver';
import axios from 'axios';
import {default as gsLog} from '../service/loggingService';
import { BILLING_API, NOC_API } from '../shared/app.constants.all';

class BillingApi {
  constructor() {
    if (BillingApi.instance) {
      return BillingApi.instance;
    }
    this.gsLog = gsLog;
    this.BILLING_API = BILLING_API;
    this.NOC_API = NOC_API;
    BillingApi.instance = this;
  }

  getFileNameFromHeader(header) {
    if (!header) return null;

    let result = header.split(";")[1].trim().split("=")[1];

    return result.replace(/"/g, '');
  }

  downloadInvoice(invoiceIdUrl) {
    let request = axios({
      url: `${invoiceIdUrl}`,
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf'
      },
      responseType: 'arraybuffer'
    });

    return request;
  }

  downloadInvoice_OLD(invoiceIdUrl) {
    return axios({
      url: `${invoiceIdUrl}`,
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf'
      },
      responseType: 'arraybuffer'
    })
    .then((response) => {
      this.gsLog.error("In BillingApi: downloadInvoice(): response = ");
      this.gsLog.debug(response);

      const pdfData = new Blob([response.data], { type: 'application/pdf'});
      saveAs(pdfData, `1.pdf`);

      return {
        response: true
      };
    });
  }

  getBillingGraph(invoiceId) {
    let request = axios({
      url: `${this.BILLING_API.BILLING_GRAPH}/${invoiceId}`,
      method: "GET"
    });

    return request;
  }

  getFinalInvoice(invoiceId) {
    let request = axios({
      url: `${this.BILLING_API.FINAL_INVOICE}/${invoiceId}`,
      method: "GET"
    });

    return request;
  }

  getInvoiceDetails(invoiceId) {
    let request = axios({
      url: `${this.BILLING_API.INVOICE_DETAILS}/${invoiceId}`,
      method: "GET"
    });

    return request;
  }

  getInvoiceTimeSeries(invoiceId, epochTupleFormat = true) {
    let request = axios({
      url: `${this.BILLING_API.INVOICE_TS}/${invoiceId}`,
      method: "GET",
      params: {
        epochTupleFormat
      }
    });

    return request;
  }

  getInvoiceUnmodifiedTimeSeries(invoiceId, epochTupleFormat = true) {
    const request = axios({
      url: `${this.BILLING_API.UNMODIFIED_INVOICE_TS}/${invoiceId}`,
      method: "GET",
      params: {
        epochTupleFormat
      }
    })
  }

  getProjectNamesByCustomer(customerUUID) {
    let request = axios({
      url: `${this.BILLING_API.PROJECT_NAMES_BY_CUSTOMER}/${customerUUID}/onlineProjects`,
      method: "GET"
    });

    return request;
  }

  getTotalSavings(siteId) {
    let request = axios({
      url: `${this.NOC_API.CURRENT_LAST_YEAR_TOTAL_SAVINGS_BY_SITE_WEB_API_URL}/${siteId}`,
      method: "GET"
    });

    return request;
  }

  getInvoicesByCustomerUUID(customerUUID, selectedMonth, selectedYear) {
    let request = axios({
      url: `${this.BILLING_API.INVOICES_BY_CUSTOMER_ID}/${customerUUID}/invoices`,
      method: "GET",
      params: {
        monthName: selectedMonth,
        year: selectedYear
      }
    });

    return request;
  }

  getInvoicesList({customerUUID, selectedMonth = null, selectedYear = null, pageNum = null, order = "desc", sortBy = null, size = 100}) {
    this.gsLog.error("In BillingApi: getInvoicesList(): 000");
    this.gsLog.error(this.BILLING_API.INVOICES_LIST);
    let params = {};
    if (selectedMonth) {
      params.monthName = selectedMonth;
    }
    if (selectedYear) {
      params.year = selectedYear;
    }
    if (size) {
      params.size = size;
    }
    if (pageNum) {
      params.page = pageNum;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (order) {
      params.order = order;
    }

    let request = axios({
      url: `${this.BILLING_API.INVOICES_LIST}/${customerUUID}/summarizedTransactions`,
      method: "GET",
      params
    });

    return request;
  }

  getInvoices() {
    let request = axios({
      url: this.BILLING_API.INVOICES,
      method: "GET"
    });

    return request;
  }

  getInvoiceHistoricalTransactionSummary(customerUUID, month, year, historicalMonths = 12) {
    let path = `${this.BILLING_API.HISTORICAL_TRANSACTION_SUMMARY}/${customerUUID}/historicalTransactionSummary`;
    let params ={};
    params.month = month;
    params.year = year;
    params.historicalMonths = historicalMonths;
    let request = axios({
      url: path,
      method: "GET",
      params
    });
  
    return request;
  }
}

export default BillingApi;
