import _ from 'underscore';
import moment from 'moment';
import { default as gsLog } from '../service/loggingService';
import rx from 'rx';
import ToasterService from "../shared/toasterService.js";
import VitalsHealthCycleApi from "../service/vitalsHealthCycleApi";

export const VIEW_HEALTH_CYCLE = "VIEW_HEALTH_CYCLE";

export const HealthCycleServices = () => {
  let toasterService = ToasterService;
  let vitalsHealthCycleApi = new VitalsHealthCycleApi();

  const parseMainResponse = (timezone, response, responseLatest) => {
    let chartData = null;
    let chartCBCData = null;
    let chartAccDischargedEnergyData = null;
    let chartBatteryDischargedEnergyKWH = null;
    let chartBatteryDischargingCycles = null;
    let ytdData = null;

    if (!_.isUndefined(responseLatest) && !_.isNull(responseLatest)) {
      ytdData = {
        ytdDischarge: parseInt(responseLatest.accummulativeResult.ytdDischargedEnergyKWH).toLocaleString('en-US'),
        ytdBatteryCycle: parseFloat(responseLatest.accummulativeResult.ytdDischargedCycles).toFixed(1)
      };
    
      gsLog.debug(`In HealthCycleServices: parseMainResponse() 000 : ytdData = `);
      gsLog.debug(ytdData);
    }

    if (!_.isUndefined(response) && !_.isNull(response)) {
      gsLog.debug(`In HealthCycleServices: parseMainResponse() 111: response = `);
      gsLog.debug(response);

        if (!_.isUndefined(response.content) && _.isArray(response.content) && response.content.length > 0) {
            chartCBCData = [];
            chartAccDischargedEnergyData = [];
            chartBatteryDischargedEnergyKWH = [];
            chartBatteryDischargingCycles = [];
            chartData = [];

            response.content.forEach(function (item, index) {
                if (!_.isUndefined(item.accummulativeResult) && _.isNumber(item.accummulativeResult.ytdDischargedCycles)) {
                    //Year To Date Cycle Equivalent → accumulativeResult.ytdDischargedCycles
                    chartCBCData.push([moment(item.timestamp).valueOf(),
                        parseFloat((item.accummulativeResult.ytdDischargedCycles).toFixed(2))]);
                }

                if (!_.isUndefined(item.accummulativeResult) && _.isNumber(item.accummulativeResult.ytdDischargedEnergyKWH)) {
                    //Year To Date Discharge Energy → accumulativeResult.ytdDischargedEnergyKWH
                    chartAccDischargedEnergyData.push([moment(item.timestamp).valueOf(),
                        parseFloat((item.accummulativeResult.ytdDischargedEnergyKWH).toFixed(2))]);
                }

                if (!_.isUndefined(item.batteryCycleResult) && _.isNumber(item.batteryCycleResult.batteryDischargedEnergyKWH)) {
                    //Daily Discharge Energy → batteryCycleResult.batteryDischargedEnergyKWH
                    chartBatteryDischargedEnergyKWH.push([moment(item.timestamp).valueOf(),
                        parseFloat((item.batteryCycleResult.batteryDischargedEnergyKWH).toFixed(2))]);
                }

                if (!_.isUndefined(item.batteryCycleResult) && _.isNumber(item.batteryCycleResult.batteryDischargingCycles)) {
                    chartBatteryDischargingCycles.push([moment(item.timestamp).valueOf(),
                        parseFloat((item.batteryCycleResult.batteryDischargingCycles).toFixed(2))]);
                }

                if (!_.isUndefined(item.batteryCycleResult) && _.isNumber(item.batteryCycleResult.batteryDischargedEnergyKWH)) {

                    chartData.push([moment(item.timestamp).valueOf(),
                        parseFloat((item.batteryCycleResult.batteryDischargedEnergyKWH).toFixed(2))]);
                }
            });

            //HACK: put dummy first point to display xAxis tick aligns with the bar
            chartData.unshift([chartData[0][0] + 86400000, 0]);
            chartData.push([chartData[chartData.length-1][0] - 86400000, 0]);
        }

        gsLog.debug(`In HealthCycleServices: parseMainResponse() 555: chartCBCData = `);
        gsLog.debug(chartCBCData);
    }

    gsLog.debug(`In HealthCycleServices: parseMainResponse() 666: chartCBCData = `);
    gsLog.debug(chartCBCData);

    gsLog.debug(`In HealthCycleServices: parseMainResponse() 777: ytdData = `);
    gsLog.debug(ytdData);

    return {chartData:chartData, chartCBCData:chartCBCData, chartAccDischargedEnergyData:chartAccDischargedEnergyData, chartBatteryDischargedEnergyKWH:chartBatteryDischargedEnergyKWH, 
      chartBatteryDischargingCycles:chartBatteryDischargingCycles,  ytdData:ytdData};
  };

  const viewHealthCycle = (projectUUID, startDate, endDate, size) => {

      gsLog.debug(`In HealthCycleServices: viewHealthCycle() 000 :`);

    return (dispatch, getState) => {
      gsLog.debug(`In HealthCycleServices: viewHealthCycle() 111 :`);

      gsLog.debug(`In HealthCycleServices: viewHealthCycle(): call API with vpp ID (${projectUUID}), start date = ${startDate.format('MMM DD, YYYY HH:mm:ss')}, end date = ${endDate.format('MMM DD, YYYY HH:mm:ss')}`);

      gsLog.debug(`In HealthCycleServices: viewHealthCycle() 444 :`);

      let observables = [];
      observables.push(vitalsHealthCycleApi.getDischarge(projectUUID, moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z", 
                     moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z", "ui", size)
        .catch((error) => {
          gsLog.error(`In HealthCycleServices : viewHealthCycle(): error in calling getDischarge API = `);
          gsLog.debug(error);
          toasterService.error("Error in getting details of discharge");
          return rx.Observable.just({ apiError: true });
        })
      );

      observables.push(vitalsHealthCycleApi.getLatestDischarge(projectUUID, size)
        .catch((error) => {
          gsLog.error(`In HealthCycleServices : viewHealthCycle(): error in calling getLatestDischarge API = `);
          gsLog.debug(error);
          
          if (error.data && error.data.statusCode == 404 ) {
            toasterService.error(`No details of latest discharge found for this site id ${projectUUID}`);
          } else 
            toasterService.error("Error in getting details of latest discharge");

          return rx.Observable.just({ apiError: true });
        })
      );

      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([response, responseLatest]) => {
            gsLog.debug("In HealthCycleServices: viewHealthCycle(): response from API = ");
            gsLog.debug(response);
            gsLog.debug("In HealthCycleServices: viewHealthCycle(): responseLatest from API = ");
            gsLog.debug(responseLatest);

            if (!response.apiError && !responseLatest.apiError) {
              let timezone = getState().operationTimezoneServices.timezone;
              let mainChartData = parseMainResponse(
                timezone,
                response.data,
                responseLatest.data
              );
              gsLog.debug(`In HealthCycleServices: viewHealthCycle() 333 :`);
              dispatch({
                type: VIEW_HEALTH_CYCLE,
                payload: {
                  mainChartData: mainChartData,
                },
              });
            } else {
              dispatch({
                type: VIEW_HEALTH_CYCLE,
                payload: {
                  mainChartData: null,
                },
              });
            }

          },
          (error) => {
            gsLog.error("In HealthCycleServices: viewHealthCycle(): (2) error in calling schedules API = ");
            gsLog.debug(error);
            toasterService.error(`error in calling schedules API = ${error.statusText}`);
          },
          () => {
            gsLog.debug("In HealthCycleServices: viewHealthCycle(): DONE with processing");
          }
        );
    };
  };

  return {
    viewHealthCycle
  };
};

export default HealthCycleServices;