import LoggingService from './loggingService';
import CryptoJS  from 'crypto-js';

class CryptoServiceFactory {
    constructor() {
        LoggingService.debug("CryptoServiceFactory constructor:");
        LoggingService.debug("entering cryptoService ...");
        this.code = "ZxlNEnojO5HbQngiYvrqu32Br6V";
    }

    encode64(buff) {
        return btoa(new Uint8Array(buff).reduce((s, b) => s + String.fromCharCode(b), ''));
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    encrypt(text) {
        var returnText = text;
        try {
            returnText = CryptoJS.AES.encrypt(text, this.code).toString();
        } catch (e) {
            LoggingService.error("In cryptoService: error in encrypt() :  " + text);
            LoggingService.error(e);
            return returnText;
        }
        return returnText;
    }

    decrypt(cipherText) {
        var returnText = cipherText;
        // If empty, no need to do decript
        if (!cipherText) {
            return cipherText;
        }
        try {
            returnText = CryptoJS.AES.decrypt(cipherText, this.code).toString(CryptoJS.enc.Utf8);
        } catch (e) {
            LoggingService.error("In cryptoService: error in decrypt() :  " + cipherText);
            LoggingService.error(e);
            return returnText;
        }
        return returnText;
    }
}

const cryptoService = new CryptoServiceFactory();

export default cryptoService;
