import { default as gsLog } from '../service/loggingService';
import _ from 'underscore';

export const stateURLMapping = {
    "app.helios": "/helios",
    "app.support": "/supportcontainer",
    "app.vpp-billing": "/billing",
    "app.settings": "/settings",
    "app.analysis.financial": "/financial",
    "app.analysis.solution": "/solution",
    "app.analysis.optimizer": "/optimizer",
    "app.analysis.savings": "/savings",
    "app.analysis.ders-summary": "/details",
    "app.analysis.audit": "/audit",
    "app.vpp-overview": "/overview-vpp",
    "app.vitals": "/vppMonitor",
    "app.vitals.vpp": "/vppMonitor",
    "app.vitals.itc": "/itc",
    "app.vitals.monthlysavings": "/monthlysavings",
    "tos": "/tos",
    "app.vitals.health": "/health",
    "app.vitals.newproject": "/newprojectMonitor",
    "app.vitals.schedule": "/schedule",
    "savings": "app.vpp-overview.savings",
    "projectMonitor": "app.vitals.project",
    "schedule": "app.vitals.schedule",
    "frequency": "app.vitals.freq",
    "alerts": "app.vitals.alerts",
    "flexForecast": "app.vitals.flexforecast",
    "forecast": "app.vitals.forecast",
    "performance": "app.vitals.performance",
    "details": "app.vitals.details",
    "optimizer": "app.analysis.optimizer",
    "manager": "app.analysis.manager",
    "diagnosticsPanel": "app.IAP",
    "tariffManagement": "app.tariffs",
    "app.impersonate": "/userSim"
};
class State {
    constructor() {
        gsLog.debug("State service constructor: 000");
        if (State.instance) {
            return State.instance;
        }

        State.instance = this;
        this.current = { name: "app.vpp-billing" }
        this.customRouter = null;
    }

    setCustomRouter(router) {
        this.customRouter = router;
    }

    go(gotoState, params) {
        gsLog.debug("State service go: 000");
        gsLog.debug(gotoState);
        if (this.setCustomRouter && this.customRouter) {
            gsLog.debug("State service go: 111");
            gsLog.debug(this.customRouter);
            if(params) {
                this.customRouter.push({pathname: stateURLMapping[gotoState], state : params});
            } else {
            this.customRouter.push(stateURLMapping[gotoState]);
            }
        }
        else {
            //handle old code
            gsLog.debug("State service go: 222");
            window.location.href = `${stateURLMapping[gotoState]}${(gotoState === "tos") && (params) ? `?tid=${params.tid}` : ""}`;
        }
    }
}

const state = new State();

export default state;
