import _ from 'lodash';
import PortfolioSelectorServicesActions from "../actions/portfolioSelectorServices";

export const PORTFOLIO_ANALYSIS_SELECTOR_TOGGLE_BAR = 'PORTFOLIO_ANALYSIS_SELECTOR_TOGGLE_BAR';

export const PortfolioAnalysisSelectorSidebarServices = () => {
  const portfolioSelectorServicesActions = PortfolioSelectorServicesActions();

  const isAnyPanelOpen = (portfolio, customer, service, proposal, project) => {
    let isOpen = false;

    if (portfolio || customer || service || proposal || project) {
      isOpen = true;
    }

    return isOpen;
  };

  const toggleBar = (barName) => {
    return (dispatch, getState) => {
      let { portfolio, customer, project, service, proposal } = getState().portfolioAnalysisSelectorSidebar;
      const isCustomerOpen = !customer.isDisabled && customer.isVisible,
            isServiceOpen = !service.isDisabled && service.isVisible,
            isProposalOpen = !proposal.isDisabled && proposal.isVisible,
            isPortfolioOpen = !portfolio.isDisabled && portfolio.isVisible,
            isProjectOpen = !project.isDisabled && project.isVisible;

      switch (barName) {
        case "portfolio":
          if (portfolio.isVisible && isAnyPanelOpen(false, isCustomerOpen, isServiceOpen, isProposalOpen, isProjectOpen)) {
            portfolio.isVisible = !portfolio.isVisible;
          } else if (!portfolio.isVisible) {
            portfolio.isVisible = !portfolio.isVisible;
          }
          break;
        case "customer":
          if (customer.isVisible && isAnyPanelOpen(isPortfolioOpen, false, isServiceOpen, isProposalOpen, isProjectOpen)) {
            customer.isVisible = !customer.isVisible;
          } else if (!customer.isVisible) {
            customer.isVisible = !customer.isVisible;
          }
          break;
        case "project":
          if (project.isVisible && isAnyPanelOpen(isPortfolioOpen, isCustomerOpen, isServiceOpen, isProposalOpen, false)) {
            // At least one of the available grid “MUST” be open in order to “collapse” project
            project.isVisible = !project.isVisible;
          } else if (!project.isVisible) {
            project.isVisible = !project.isVisible;
          }

          break;
        case "service":
          if (service.isVisible && isAnyPanelOpen(isPortfolioOpen, isCustomerOpen, false, isProposalOpen, isProjectOpen)) {
            service.isVisible = !service.isVisible;
          } else if (!service.isVisible) {
            service.isVisible = !service.isVisible;
          }
          break;
        case "proposal":
          if (proposal.isVisible && isAnyPanelOpen(isPortfolioOpen, isCustomerOpen, isServiceOpen, false, isProjectOpen)) {
            const { projectUUID } = !_.isNull(getState().portfolioSelectorServices.selectedProject) && !_.isUndefined(getState().portfolioSelectorServices.selectedProject) ? getState().portfolioSelectorServices.selectedProject : { projectUUID: null };
            if (!_.isNull(projectUUID)) {
              portfolioSelectorServicesActions.getProposalsByProjectUUID(dispatch, getState, projectUUID);
            }
            proposal.isVisible = !proposal.isVisible;
          } else if (!proposal.isVisible) {
            proposal.isVisible = !proposal.isVisible;
          }
          break;
        default:
          break;
      }

      dispatch({
        type: PORTFOLIO_ANALYSIS_SELECTOR_TOGGLE_BAR,
        payload: {
          portfolio,
          customer,
          project,
          service,
          proposal
        }
      });
    }
  };

  return {
    toggleBar
  }
}

export default PortfolioAnalysisSelectorSidebarServices;