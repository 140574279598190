import Log from 'debug';

class LoggingServiceFactory {
    constructor() {
        this.debug = this.debug.bind(this);

        // Debug logging 
        // To enable everything : "info", "debug", "error"
        // localStorage.debug = 'reactJS:*';

        // To enable "info" only
        localStorage.debug = 'reactJS:info*';

        // To enable "debug" only
        // localStorage.debug = 'reactJS:debug*';

        // To enable "error" only
        // localStorage.debug = 'reactJS:error*';

        localStorage.error = 'reactJS Error:*';

        this.regLog = Log.debug('reactJS:debug logging');
        this.infoLog = Log.debug('reactJS:info logging');
        this.errLog = Log.debug('reactJS:error logging');

        // Log.disable('reactJS:debug logging');
        // Log.enable('reactJS:*');
        // Log.disable('reactJS:info logging');
    }

    error(msg) {
        this.errLog(msg)
    }

    debug(msg) {
        this.regLog(msg)
    }

    info(msg) {
        this.infoLog(msg)
    }
}

const LoggingService = new LoggingServiceFactory();

export default LoggingService;