import { formatCurrency } from '../utils/utils';
import { default as gsLog } from '../service/loggingService';
import $ from "jquery";

class CustomerCashSiteSavingChart {
  constructor() {
    if (CustomerCashSiteSavingChart.instance) {
      return CustomerCashSiteSavingChart.instance;
    }
    this.gsLog = gsLog;
    this.graph = {};
    this.init();
    CustomerCashSiteSavingChart.instance = this;
  }

  update(maxy, title, savingValues) {
    const chart = $("#customerCashSiteSavingChart").highcharts();
    if (chart) {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      chart.setTitle({ text: title });
      chart.yAxis[0].setExtremes(0, maxy);
      chart.addSeries({
        type: "waterfall",
        data: savingValues
      }, true);

    } else {
      gsLog.error("In customerCashSiteSavingChart: update(): chart is NOT initialized!");
    }
  }

  init() {
    this.gsLog.debug("In customerCashMonthPerformanceChart: init(): entering ...");
    this.graph = {
      options: {
        colors: ["#55BF3B", "#5b7a99"],
        chart: {
          type: 'waterfall',
          backgroundColor: "white"
        },
        title: {
          text: "",
          style: {
            color: 'black',
            fontSize: '13'
          }
        },
        legend: {
          enabled: false,
          itemStyle: {
            color: "black"
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              verticalAlign: 'top',
              crop: false,
              overflow: 'none',
              y: -30,
              style: {
                fontSize: 11,
                fontWeight: 'normal',
                color: 'black',
                textOutline: null,
                textShadow: '0 0'
              },
              formatter: function () {
                if (this.key == "Original Bill") {
                  return '<tspan style="color:' + this.point.color + '">' + formatCurrency(Math.abs(this.y)) + '</tspan>';
                } else {
                  return '<tspan style="color:' + this.point.color + '">' + formatCurrency(this.y) + '</tspan>';
                }
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          reversed: true,
          tickColor: "#979ea5",
          labels: {
            style: {
              color: 'black'
            }
          },
          title: {
            style: {
              color: 'black'
            }
          }
        },
        yAxis: [
          {
            gridLineColor: "#fff",
            labels: {
              style: {
                color: 'black'
              },
            },
            title: {
              text: null,
              style: {
                color: '#A0A0A3'
              }
            }
          }
        ]
      },
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Math.abs(this.y);
        },
        style: {
          fontWeight: 'bold'
        }
      },
      series: []
    };
  }
}

export default CustomerCashSiteSavingChart;
