import { UPDATE_WIZARD_CUSTOMER_SERVICES } from '../actions/pmtWizardCustomerServices';

const INITIAL_STATE = {
  customers: [],
  naicsList: []
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_WIZARD_CUSTOMER_SERVICES:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
