import { formatCurrency } from '../utils/utils';
import $ from "jquery";
import { default as gsLog } from '../service/loggingService';

class CustomerCashMonthPerformanceChart {
  constructor() {
    if (CustomerCashMonthPerformanceChart.instance) {
      return CustomerCashMonthPerformanceChart.instance;
    }
    this.gsLog = gsLog;
    this.graph = {};
    this.init();
    CustomerCashMonthPerformanceChart.instance = this;
  }

  update(categories, fmValues, gsValues) {
    const chart = $("#customerCashMonthPerformanceChart").highcharts();
    if (chart) {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      this.gsLog.debug("In customerCashMonthPerformanceChart: update(): categories = ");
      this.gsLog.debug(categories);
      this.gsLog.debug("In customerCashMonthPerformanceChart: update(): dmValues = ");
      this.gsLog.debug(fmValues);
      this.gsLog.debug("In customerCashMonthPerformanceChart: update(): gmValues = ");
      this.gsLog.debug(gsValues);
      let redraw = false;
      this.graph.xAxis.categories = categories;
      if (gsValues) {
        chart.addSeries({
          name: "GCN Saving",
          color: "#6EAE45",
          data: gsValues
        }, true);
        redraw = true;
      }
      if (fmValues) {
          chart.addSeries({
            name: "Final Bill",
            color: "#D0CECF",
            data: fmValues
          }, true);
          redraw = true;
      }
    }
  }

  init() {
    this.gsLog.debug("In customerCashMonthPerformanceChart: init(): entering ...");
    this.graph = {
        options: {
            colors: ["#55BF3B", "#5b7a99"],
            chart: {
                type: 'bar',
                backgroundColor: "white",
                spacingRight: 70
            },
            title: {
              text: "",
              style: {
                 color: '#36608B',
                 fontSize: '15px'
              }
            },
            legend: {
              itemStyle: {
                color: "black",
                fontSize: 11,
                fontWeight: 'normal'
              },
              reversed: true
            },
            plotOptions: {
              series: {
                    stacking: 'normal',
                    pointWidth: 20,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        inside: false,
                        x: 2,
                        style: {
                          fontSize: 10,
                          fontWeight: 'normal',
                          color: 'black',
                          textOutline: null,
                          textShadow: '0 0'
                        },
                        formatter: function() {
                          if(this.series.name == "GCN Saving" )
                            return '<tspan style="color:' + this.point.color + '">' + formatCurrency(this.y) + '</tspan>'; 
                        }
                    }
                }
            },
            xAxis: {
              categories: [],
              tickColor: "#979ea5",
              labels: {
                  style: {
                      color: 'black',
                      fontSize: 9
                  },
                  formatter: function() {
                    if(this.value && this.value.length >10)
                    {
                      return(this.value.substring(0,10) + "...");
                    }else
                    {
                      return this.value;
                    }
                }
              },
              title: {
                style: {
                  color: 'black'
                }
              }
          },
          yAxis: [
            {
              gridLineColor: "#fff",
              labels: {
                  style: {
                      color: 'black',
                      fontSize: 9
                  },
                  format: '${value:,.0f}'
              },
              title: {
                text: null,
                style: {
                  color: '#A0A0A3'
                }
              },
              stackLabels: {
                  enabled: false,
                  style: {
                      fontSize: 8,
                      fontWeight: 'normal',
                      color: 'white'
                  }
              }
            }
          ]
        },
        xAxis: {
            categories: [],
            tickColor: "#979ea5",
            labels: {
                style: {
                    color: 'black',
                    fontSize: 9
                },
                formatter: function() {
                  if(this.value && this.value.length >10)
                  {
                    return(this.value.substring(0,10) + "...");
                  }else
                  {
                    return this.value;
                  }
              }
            },
            title: {
              style: {
                color: 'black'
              }
            }
        },
        yAxis: [
          {
            gridLineColor: "#fff",
            labels: {
                style: {
                    color: 'black',
                    fontSize: 9
                },
                format: '${value:,.0f}'
            },
            title: {
              text: null,
              style: {
                color: '#A0A0A3'
              }
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontSize: 8,
                    fontWeight: 'normal',
                    color: 'white'
                }
            }
          }
        ],
        series: []
      };
  }
}

export default CustomerCashMonthPerformanceChart;
