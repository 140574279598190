import '../react-transition/interceptor.js';
import axios from 'axios';
import { default as gsLog } from '../service/loggingService';
import { VPP_API } from "../shared/app.constants.all";

class HeliosProjectApi {
    constructor() {
        if (HeliosProjectApi.instance) {
            return HeliosProjectApi.instance;
        }

        this.gsLog = gsLog;
        this.VPP_API = VPP_API;
        HeliosProjectApi.instance = this;
    }

    getSolarProjectList(clientId = "UI", sortBy = "vppName") {
        this.gsLog.debug("In HeliosProjectApi: getSolarProjectList(): 000 ");
        const httpRequest = axios({
            url: this.VPP_API.VPP_LIST,
            method: "GET",
            params: {
                clientId,
                sortBy
            }
        });

        return httpRequest;
    }

    getGraphData(jsonFile) {
        let httpRequest = axios({
          url: jsonFile,
          method: "GET",
          headers: {
            "Accept": undefined,
            "Content-Type": undefined
          }
        });
    
        return httpRequest;
      }

      getSiteData(jsonFile) {
        let httpRequest = axios({
            url: jsonFile,
            method: "GET",
            headers: {
              "Accept": undefined,
              "Content-Type": undefined
            }
          });
      
          return httpRequest
      }
}

export default HeliosProjectApi;
