import { VIEW_ITC, VIEW_LATEST_ITC } from '../actions/itcServices';

const INITIAL_STATE = {
  viewEvent: true
};

export default function itcServices(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case VIEW_ITC:
      return Object.assign({}, state, payload);
    case VIEW_LATEST_ITC:
      return Object.assign({}, state, payload);
    default:
      // console.error("In reducer.itcServices: (2) payload = ");
      // console.error(payload);
      // console.error(state);
      return Object.assign({}, state, { viewEvent: true} );
  }
}
