export const AAA_SITES = [
  "staging.api.greencharge.net",
  "api.greencharge.net",
  "10.0.40.26"
];

export const TABS =  {
  SETTINGS: 0,
  OVERVIEW: 1,
  VITALS: 2,
  BILLING: 3,
  SUPPORT: 4,
  PORTFOLIO: 5,
  ANALYSIS: 6,
  TARIFFS:7,
  IAP: 8,
  REACTWIZAID: 9,
  HELIOS: 10
};

export const SUB_TABS = {
  OVERVIEW_DASHBOARD: 1,
  OVERVIEW_SAVINGS: 2,
  OPS_MONITOR: 3,
  OPS_DETAILS: 4,
  OPS_HEALTH: 5,
  OPS_ALERTS: 6,
  PORTFOLIO_PROJECTS: 7,
  OPS_FORECAST: 8,
  OPS_HEATMAP: 9,
  OPS_PERFORMANCE: 10,
  ANALYSIS_MANAGER: 11,
  ANALYSIS_OPTIMIZER: 12,
  OPS_SCHEDULE: 13,
  OPS_FLEX_FORECAST: 14,
  OPS_FREQ: 15,
  OPS_PROJ_MONITOR: 16,
  ANALYSIS_SOLUTION: 17,
  ANALYSIS_TIMESERIES: 18,
  ANALYSIS_SAVINGS: 19,
  ANALYSIS_DETAILS: 20,
  ANALYSIS_AUDIT: 21,
  ANALYSIS_FINANCIAL: 22,
  OPS_ITC: 23
};

export const PDF_COLORS = {
  altRow: "#f0f7f7",
  engieColor: "#f0f7f7",
  altRowGreen: "#f0f7f7",
  amountDue: "#f97f05",
  demandCharges: "#24a070",
  energyArbitrage: "#37B9C3",
  gridServices: "#37B9C3",
  headerTableColHeader: "#37B9C3",
  title: "#37B9C3",
  total: "#cce4ea",
  billDateHeader: "#549ED9"
};

export const LOAD_GRAPH_TITLE = {
  forecast: {
    name: "Forecast Original",
    originalName: "Forecast Original",
    color: "#D33411"
  },
  original: {
    name: "Original",
    originalName: "Original",
    color: "#D33411"
  },
  solar: {
    name: "Net Solar",
    originalName: "Net Solar",
    color: "#ee7600"
  },
  netLoad: {
    name: "Net Storage",
    originalName: "Net Storage",
    color: "#32cc8d"
  },
  soc: {
    name: "Battery SOC",
    originalName: "Battery SOC",
    color: "#939393"
  },
  demandThreshold: {
    name: "Demand Threshold",
    originalName: "Demand Threshold",
    color: "#6a81cd"
  }
};

export const TELSTRA_GRAPH = {
  marginLeft: 75,
  overviewCurtailable: {
    xl: {
      width: 275,
      height: 150
    },
    lg: {
      width: 250,
      height: 105
    },
    md: {
      width: 250,
      height: 115
    },
    sm: {
      width: 250,
      height: 115
    }
  },
  overviewSchedule: {
    xl: {
      height: 90
    },
    lg: {
      height: 70
    },
    md: {
      height: 70
    },
    sm: {
      height: 70
    }
  },
  peakShaving: {
    xl: {
      width: 745
    },
    lg: {
      width: 545
    }
  },
  pie: {
    xl: {
      width: 195,
      height: 145
    },
    lg: {
      width: 150,
      height: 120
    },
    md: {
      width: 140,
      height: 130
    },
    sm: {
      width: 130,
      height: 135
    }
  },
  chart: {
    backgroundColor: "#2f3e4c",
    xl: {
      width: 800,
      height: 200
    },
    lg: {
      extWidth: 1100,
      width: 510,
      height: 185
    },
    md: {
      width: 440,
      height: 210
    },
    sm: {
      width: 450,
      height: 190
    }
  },
  subChart: {
    backgroundColor: "#2f3e4c",
    labelColor: "#fff",
    fitall: {
      height: 470
    },
    xxl: {
      height: 500
    },
    xl: {
      height: 340
    },
    lg: {
      width: 440,
      height: 260
    },
    md: {
      width: 440,
      height: 210
    },
    sm: {
      width: 450,
      height: 190
    }
  }
};

export const BILLING_TS_CHART = {
  width: 770,
  height: 300
};

export const BILLING_MP_CHART = {
  width: 495,
  height: 500
};

export const CUSTOMER_CONTRACT_TYPE = {
  PEA: "PEA",
  CASH: "CASH",
  LEASE: "LEASE",
  STAGING: "STAGING",
  SYSTEM_SALE_AGREEMENT: "SYSTEM_SALE_AGREEMENT"
};

export const DEPRECIATION_SCHEDULE = [
  { id: 0, value: "None" },
  { id: 1, value: "Linear - Project Lifetime" },
  { id: 2, value: "Linear - 10 Year" },
  { id: 3, value: "7-year MACRS" },
  { id: 4, value: "5-year MACRS" },
  { id: 5, value: "5-year Bonus MACRS" },
  { id: 6, value: "1 Year Depreciation" }
];

export const NON_EXPORT_MODES = [
  { id: 1, exportName: "Enabled", val: "allRejected" },
  { id: 2, exportName: "Enabled - Storage Only", val: "storageRejected" },
  { id: 3, exportName: "Disabled", val: "Accepted" }
];

export const EXPORT_MODES = [
  { id: 0, val: "Accepted" },
  { id: 1, val: "allRejected" },
  { id: 2, val: "storageRejected" }
];

export const PERFORMANCE_GUARANTEE = [
  { id: 0, value: "No Guaranty" },
  { id: 1, value: "kW Guaranty" },
  { id: 2, value: "Savings Guaranty" }
];

export const DURATION_IN_MS = {
  FIVE_MIN: 300000,
  FIFTEEN_MIN: 900000
};

export const FREIGHT_COST_FEATURE_GROUP_ID = 7;

export const DOWNLOAD_TYPES = [
  { name: "JPEG", type: "image/jpeg" },
  { name: "PNG", type: "image/png" },
  { name: "PDF", type: "application/pdf" },
  { name: "SVG", type: "image/svg+xml" }
];

export const FIFTEEN_MIN_IN_MS = 900000;

export const SCHEDULE_PROGRAMS = [
  { id: 1, name: "PG&E Manual Control", on: true, color: "#DF5353" },
  { id: 2, name: "PG&E DERMS Commitment", on: true, color: "#55BF3B" },
  { id: 3, name: "GCN Manual Instruction", on: true, color: "#ee7600" }
];

export const SCHEDULE_COMPONENTS = {
   1: { name: "REAL (kW)" },
   2: { name: "REACTIVE (kVAR)" },
   3: { name: "PHI (dg)"}
};

export const CHART_DISPLAY_DELAY_IN_MS =  2000;

export const USA_STATES = [
  {
    name: "Select State",
    abbreviation: "N/A"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
];

export const ERROR_MESSAGE = {
  INTERNAL_SERVER_MESSAGE: 'Internal server error. Please contact us at engiestorage-support@engie.com',
  SAME_PWD_MESSAGE: "Password don't match.",
  INVALID_PWD_MESSAGE: "Invalid password",
  INVALID_CURRENT_PWD_MESSAGE: "Unable to update the password. The value provided as the current password is incorrect"
};

export const PMT_UPLOAD_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed"
};

export const SCHEDULE_COMPONENT = {
  REAL: 1,
  REACTIVE: 2,
  PHI: 3
};

export const SCHEDULE_OBJECT = {
  SYSTEM: 1,
  NET: 2
};

export const SCHEDULE_OBJECTS = [
  { id: 1, name: "SYSTEM" },
  { id: 2, name: "NET" }
];

export const DAYS_IN_WEEK = {
  SUN: 1,
  MON: 2,
  TUE: 4,
  WED: 8,
  THU: 16,
  FRI: 32,
  SAT: 64
};

export const SELECT_VITALS_GRAPH_CHANGE_EVENTS = {
  graphTypeChanged: "selectVitalsGraphChangeEvent"
};

export const TIME_ZONE_US_ONLY = [
  {
    displayName: "US/Pacific",
    zone: "US/Pacific",
    name: "America/Los_Angeles"
  },
  {
    displayName: "US/Mountain",
    zone: "US/Mountain",
    name: "America/Denver"
  },
  {
    displayName: "US/Central",
    zone: "US/Central",
    name: "America/Chicago"
  },
  {
    displayName: "US/Eastern",
    zone: "US/Eastern",
    name: "America/New_York"
  },
  {
    displayName: "US/Hawaii",
    zone: "US/Hawaii",
    name: "Pacific/Honolulu"
  }
];

export const ERROR_MSG = {
  EXPORT_LOAD_GRAPH_NO_DATA: "No data for the selected date range. Please try again.",
  OPS_LOAD_GRAPH: "Error loading chart. Please try again later.",
  PIPELINE_CONFIG: "Error reading VPP configuration. Please try again later.",
  PIPELINE_EP15: "Error loading interval data. Please try again later.",
  PIPELINE_FORECAST: "Error loading forecast data. Please try again later.",
  SESSION_EXPIRED: "Your session has expired. Please login again.",
  TARIFF_TOU: "Error loading tariff information. Please try again later."
};
