import { TIMEZONE_SERVICES } from '../actions/timezoneServices';

const INITIAL_STATE = {};

export default function TimezoneServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TIMEZONE_SERVICES:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
