import {default as gsLog} from '../service/loggingService';

export const UPDATE_SCHEDULE_SIDE_BAR = "UPDATE_SCHEDULE_SIDE_BAR";
export const VITALS_SCHEDULE_SUB_TAB = {
  GRAPH: "graphView",
  GRID: "gridView",
  PERFORMANCE: "performanceView"
};

let ScheduleSidebarActionInstance = null;

const ScheduleSidebarActionConstruction = () => {
  const updateScheduleSidebar = (panelName) => {
    return (dispatch, getState) => {
      let { graphView, gridView, performanceView } = getState().scheduleSidebarServices;

      switch (panelName) {
        case VITALS_SCHEDULE_SUB_TAB.GRAPH:
          graphView = true;
          gridView = false;
          performanceView = false;
          break;
        case VITALS_SCHEDULE_SUB_TAB.GRID:
          gridView = true;
          graphView = false;
          performanceView = false;
          break;
        case VITALS_SCHEDULE_SUB_TAB.PERFORMANCE:
          performanceView = true;
          gridView = false;
          graphView = false;
          break;
        default:
          break;
      }

      dispatch({
        type: UPDATE_SCHEDULE_SIDE_BAR,
        payload: {
          graphView,
          gridView,
          performanceView,
        }
      });
    }
  }

  return {
    updateScheduleSidebar
  }
};

export const ScheduleSidebarAction = ( ) => {
  if (ScheduleSidebarActionInstance) {
    return ScheduleSidebarActionInstance;
  } else {
    ScheduleSidebarActionInstance = ScheduleSidebarActionConstruction();
   return ScheduleSidebarActionInstance;
  }
} 

