import { bindActionCreators } from "redux";
import _ from 'underscore';
import {default as gsLog} from './loggingService';
import userAuthenticatedNotificationService from './userAuthenticatedNotificationService';
import CachedObjectServicesActions from '../actions/cachedObjectServices';
import rootScopeService from '../service/rootScopeService';
import store from '../service/store';

class UserAuthenticatedServiceFactory {
    constructor() {
        this.gsLog = gsLog;
        this.gsLog.debug("UserAuthenticatedService constructor:");
        this.gsLog.debug("entering userAuthenticatedService ...");
    
        if (UserAuthenticatedServiceFactory.instance) {
            return UserAuthenticatedServiceFactory.instance;
        }

        userAuthenticatedNotificationService.myEvent.subscribe((eventData) => {
          this.gsLog.debug("In UserAuthenticatedService: user authenticated event = ");
          this.gsLog.debug(eventData);
          this.doAction(eventData.data);
        });

        UserAuthenticatedServiceFactory.instance = this;

        this.$rootScope = rootScopeService;
        this.cachedObjectServicesActions = CachedObjectServicesActions();
        this.boundActionCreators = bindActionCreators(this.cachedObjectServicesActions, store.dispatch);
        this.unsubscribe = store.subscribe(this.mapStateToThis.bind(this));
        this.gsLog.debug("UserAuthenticatedService constructor: 888");
        this.gsLog.debug(store);
    }

    mapStateToThis() {
        this.gsLog.debug("In UserAuthenticatedService: mapStateToThis(): 333");
        this.gsLog.debug(store.getState());
    }

    doAction(user) {
        this.gsLog.debug("In UserAuthenticatedService: doAction(): user = ");
        this.gsLog.debug(user);

        store.dispatch(this.cachedObjectServicesActions.updateUserDefaults(user));
    }

    updateState(access_token) {
        this.$rootScope.oauth = {};
        this.$rootScope.oauth.access_token = access_token;
        this.$rootScope.oauth.authenticated = true;
        this.gsLog.debug("In UserAuthenticatedService: updateState(): 222 ");
        this.gsLog.debug(this.$rootScope);
      }

    getAaaToken() {
        this.gsLog.debug("In UserAuthenticatedService: getAaaToken(): 000");
        return !_.isUndefined(this.$rootScope.oauth) ? this.$rootScope.oauth.aaa_token : "THER_IS_NO_AAA_TOKEN";
    }

    getRefreshToken() {
        return !_.isUndefined(this.$rootScope.oauth) ? this.$rootScope.oauth.refresh_token : ""; 
    }

    clearState() {
        this.$rootScope.oauth = {};
        localStorage.removeItem('gsAppLocal.defaultUser');
        localStorage.removeItem('gsAppLocal.LoggedinUser');
        localStorage.removeItem('gsAppLocal.Projects');
        localStorage.removeItem('gsAppLocal.excludedCustomerIds');
        localStorage.removeItem('gsAppLocal.excludedMiIds');
        localStorage.removeItem('gsAppLocal.excludedRoles');
        localStorage.removeItem('gsAppLocal.excludedvppIds');
        localStorage.removeItem("gsAppLocal.permission");
        localStorage.removeItem("gsAppLocal.localVpps");
        localStorage.removeItem("gsAppLocal.newCreatedMi");
        localStorage.removeItem("gsAppLocalWarningVar");
        localStorage.removeItem("gsAppLocal.allPermission");
        localStorage.removeItem("gsAppLocal.featureToggleRoles");
    }
}

const userAuthenticatedService = new UserAuthenticatedServiceFactory();
export default userAuthenticatedService;