import LoggingService from './loggingService';
import authenticationService from './authenticationService';

class AppServiceFactory {
    constructor() {
        LoggingService.debug("AppServiceFactory constructor:");
        LoggingService.debug("entering appService ...");

        this._refreshMode = false;
    }
   
    get refreshMode() {
        return this._refreshMode;
    }

    set refreshMode(mode) {
        this._refreshMode = mode;
    }

    refresh() {
        if (!authenticationService.authenticated) {
            LoggingService.debug("In appService: user is not authenticated: refresh user ...");
            if (!authenticationService.refreshUser()) {
                LoggingService.debug("In appService: uh-oh error in refreshing user");
                return false;
            } else {
                this._refreshMode = true;
                return true;
            }
        }
        else 
        {
            LoggingService.debug("In appService: user is authenticated: refresh user ...");
            return true;
        }
    }
}

const appService = new AppServiceFactory();

export default appService;
