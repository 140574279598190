import _ from 'underscore';
import moment from 'moment';
import rx from 'rx';
import ToasterService from "../shared/toasterService.js";
import { default as gsLog } from '../service/loggingService';
import VitalsHealthCycleApi from "../service/vitalsHealthCycleApi";
import SpinnerService from "../shared/react-spinner.js";

export const VIEW_ITC = "VIEW_ITC";
export const VIEW_LATEST_ITC = "VIEW_LATEST_ITC";

export const ItcServices = () => {
  let toasterService = ToasterService;
  let vitalsHealthCycleApi = new VitalsHealthCycleApi();

  const parseMainResponse = (response) => {
    let gridChartData = null;
    let solarChartData = null;
    let chartItcRatioData = null;
    let chartCategory = null;
    let chartCategoryExport = null;
    let totalChargedEnergy = null;
    let accummulativeYtdITCRatio = null;

    if (!_.isUndefined(response) && !_.isNull(response)) {
      if (!_.isUndefined(response.content) && _.isArray(response.content) && response.content.length > 0) {
        chartItcRatioData = [];
        gridChartData = [];
        solarChartData = [];
        totalChargedEnergy = [];
        chartCategory = [];
        chartCategoryExport = [];
        accummulativeYtdITCRatio = [];

        response.content.forEach(function (item, index) {
          if (!_.isUndefined(item.timestamp) && !_.isNull(item.timestamp)) {
            let itcDataActualDate = moment(item.timestamp).local().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            let itcDataActualDateExport = moment(item.timestamp).local().format("MM/DD/YYYY HH:mm");
            chartCategory.push(itcDataActualDate.substring(0, 10));
            chartCategoryExport.push(itcDataActualDateExport);
          }

          if (!_.isUndefined(item.itcResult) && _.isNumber(item.itcResult.totalChargedEnergyKWH) && _.isNumber(item.itcResult.chargedEnergyFromSolarKWH)) {
            solarChartData.push(parseFloat((item.itcResult.chargedEnergyFromSolarKWH).toFixed(2)));
            totalChargedEnergy.push(parseFloat((item.itcResult.totalChargedEnergyKWH).toFixed(2)));
            gridChartData.push(parseFloat((item.itcResult.chargedEnergyFromGridKWH).toFixed(2)));
            chartItcRatioData.push(parseFloat((item.itcResult.chargedEnergyFromSolarKWH / item.itcResult.totalChargedEnergyKWH)) * 100);
          }

          if (!_.isUndefined(item.accummulativeResult) && _.isNumber(item.accummulativeResult.ytdITCRatio) && _.isNumber(item.accummulativeResult.ytdITCRatio)) {
            accummulativeYtdITCRatio.push(parseFloat((item.accummulativeResult.ytdITCRatio).toFixed(2)) * 100);
          }
        });
      }

      gsLog.debug(`In ItcServices: parseMainResponse() 555: chartItcRatioData = `);
      gsLog.debug(chartItcRatioData);
    }

    gsLog.debug(`In ItcServices: parseMainResponse() 666: accummulativeYtdITCRatio = `);
    gsLog.debug(accummulativeYtdITCRatio);

    return {
      gridChartData: gridChartData, chartItcRatioData: chartItcRatioData, chartCategory: chartCategory, chartCategoryExport: chartCategoryExport, solarChartData: solarChartData,
      totalChargedEnergy: totalChargedEnergy, accummulativeYtdITCRatio: accummulativeYtdITCRatio
    };
  };

  const parseLatestResponse = (response) => {
    let latestYTDengergy = null;

    gsLog.debug(`In ItcServices: parseLatestResponse() 000: = `);
    gsLog.debug(response);

    if (!_.isUndefined(response) && !_.isNull(response)) {
      latestYTDengergy = {};

      if (!_.isUndefined(response.accummulativeResult) && _.isNumber(response.accummulativeResult.ytdTotalChargedEnergyKWH)
        && _.isNumber(response.accummulativeResult.ytdChargedEnergyFromSolarKWH)) {

        latestYTDengergy = {
          ytdTotalChargedEnergyKWH: parseFloat(response.accummulativeResult.ytdTotalChargedEnergyKWH.toFixed(2)),
          ytdChargedEnergyFromSolarKWH: parseFloat(response.accummulativeResult.ytdChargedEnergyFromSolarKWH.toFixed(2))
        };
      }

      gsLog.debug(`In ItcServices: parseLatestResponse() 555: latestYTDengergy = `);
      gsLog.debug(latestYTDengergy);
    }

    return { latestYTDengergy: latestYTDengergy };
  };

  const viewItcCycle = (projectUUID, startDate, endDate, size) => {
    gsLog.debug(`In ItcServices: viewItcCycle() 000 :`);

    return (dispatch, getState) => {
      SpinnerService.spin();
      gsLog.debug(`In ItcServices: viewItcCycle() 111 :`);
      gsLog.debug(`In ItcServices: viewItcCycle(): call API with vpp ID (${projectUUID}), start date = ${startDate.format('MMM DD, YYYY HH:mm:ss')}, end date = ${endDate.format('MMM DD, YYYY HH:mm:ss')}`);
      gsLog.debug(`In ItcServices: viewItcCycle() 444 :`);

      rx.Observable
        .fromPromise(vitalsHealthCycleApi.getItc(projectUUID, moment(startDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
          moment(endDate).format("YYYY-MM-DDTHH:mm:ss") + "Z", size))
        .catch((error) => {
          SpinnerService.stop();
          gsLog.error("In ItcServices: viewItcCycle(): error in calling itcServices API = ");
          gsLog.error(error);

          if (error.data && error.data.statusCode == 404) {
            toasterService.error(`No ITC results found for this site id ${projectUUID}`);
          } else
            toasterService.error("Error in getting ITC resutls");

          return rx.Observable.just({ apiError: true });
        })
        .subscribe(
          (response) => {

            gsLog.debug("In ItcServices: viewItcCycle(): response from API = ");
            gsLog.debug(response);
            if (!response.apiError) {
              let mainChartData = parseMainResponse(response.data);
              gsLog.debug(`In ItcServices: viewItcCycle() 333 :`);
              dispatch({
                type: VIEW_ITC,
                payload: {
                  mainChartData: mainChartData
                }
              });
            } else {
              dispatch({
                type: VIEW_ITC,
                payload: {
                  mainChartData: null
                }
              });
            }
          },
          (error) => {
            gsLog.error("In ItcServices: viewItcCycle(): (2) error in calling itcServices API = ");
            gsLog.error(error);
            // backend should return an empty array instead of 404
            // toasterService.error(`error in calling itcServices API = ${error.statusText}`);
          },
          () => {
            SpinnerService.stop();
            gsLog.debug("In ItcServices: viewItcCycle(): DONE with processing");
          }
        );
    };
  };

  const viewItcLatestCycle = (projectUUID) => {
    gsLog.debug(`In ItcServices: viewItcLatestCycle() 000 :`);

    return (dispatch, getState) => {
      gsLog.debug(`In ItcServices: viewItcLatestCycle() 111 :`);
      gsLog.debug(`In ItcServices: viewItcLatestCycle(): call API with vpp ID (${projectUUID})`);
      gsLog.debug(`In ItcServices: viewItcLatestCycle() 444 :`);

      rx.Observable
        .fromPromise(vitalsHealthCycleApi.getLatestItc(projectUUID))
        .catch((error) => {
          gsLog.error("In ItcServices: viewItcLatestCycle(): error in calling itcServices API = ");
          gsLog.error(error.response);

          if (error.response.data && error.response.data.statusCode == 404) {
            toasterService.error(`No ITC Latest Cycle results found for this site id ${projectUUID}`);
          } else
            toasterService.error("Error in getting ITC Latest Cycle results");

          return rx.Observable.just({ apiError: true });
        })
        .subscribe(
          (response) => {

            gsLog.debug("In ItcServices: viewItcLatestCycle(): response from API = ");
            gsLog.debug(response);

            if (!response.apiError) {
              let latestChartData = parseLatestResponse(response.data);
              gsLog.debug(`In ItcServices: viewItcLatestCycle() 333 :`);
              gsLog.debug(latestChartData);
              dispatch({
                type: VIEW_LATEST_ITC,
                payload: {
                  latestChartData: latestChartData
                }
              });
            } else {
              dispatch({
                type: VIEW_LATEST_ITC,
                payload: {
                  latestChartData: null
                }
              });
            }
          },
          (error) => {
            gsLog.error("In ItcServices: viewItcLatestCycle(): (2) error in calling itcServices API = ");
            gsLog.error(error);
            dispatch({
              type: VIEW_LATEST_ITC,
              payload: {
                latestChartData: []
              }
            });

            // toasterService.error(`error in calling itcServices API = ${error.statusText}`);
          },
          () => {
            gsLog.debug("In ItcServices: viewItcLatestCycle(): DONE with processing");
          }
        );
    };
  };

  return {
    viewItcCycle,
    viewItcLatestCycle
  };
};

export default ItcServices;