import { SOLAR_PROJECT_LIST, SOLAR_PROJECT_SEARCH, SOLAR_PROJECT_SET_SELECTED_PROJECT } from '../actions/heliosProjectSelectorActions';

const INITIAL_STATE = {

};

export default function heliosProjectSelectorServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SOLAR_PROJECT_LIST:
      return Object.assign({}, state, payload, { doRefresh: true, doSearch: false, searchResults:[] });
    case SOLAR_PROJECT_SET_SELECTED_PROJECT:
      return Object.assign({}, state, payload, { doRefresh: false });
    case SOLAR_PROJECT_SEARCH:
      return Object.assign({}, state, payload);
    default:
      return Object.assign({}, state, { doRefresh: false, doSearch: false, searchResults:[] });
  }
}
