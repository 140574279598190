import Rx from "rx";
import _ from 'lodash';
import $ from "jquery";
import moment from 'moment';
import { saveAs } from 'file-saver';
import {default as gsLog} from '../service/loggingService';
import { BILLING_API } from '../shared/app.constants.all';
import  BillingApi from "../billing-container/billingApi.js";
import { BILLING_TS_CHART as BILLING_TS_CHART_REACT, BILLING_MP_CHART as BILLING_MP_CHART_REACT, CUSTOMER_CONTRACT_TYPE as CUSTOMER_CONTRACT_TYPE_REACT } from '../shared/constants.js';
import BillingTimeSeriesChart  from "../billing-charts/billingTimeSeriesChart.js";
import CustomerBenefitYearChart from "../billing-charts/customerBenefitYearChart.js";
import CustomerLifetimeSummaryChart from "../billing-charts/customerLifetimeSummaryChart.js";
import CustomerCashMonthPerformanceChart from "../billing-charts/customerCashMonthPerformanceChart.js";
import CustomerCashSiteSavingChart from "../billing-charts/customerCashSiteSavingChart.js";
import PdfServicesActions  from "./pdfServices";
import PmtApi from "../service/pmtApi";
import SpinnerService from "../shared/react-spinner.js";
import ToasterService from "../shared/toasterService.js";
import TwelveMonthsSavingChart from "../billing-charts/twelveMonthsSavingChart.js";

export const GET_INVOICES = "GET_INVOICES";
export const REQUESTS = "REQUESTS";
export const REFRESH_INVOICE = "REFRESH_INVOICE";
export const VIEW_INVOICE = "VIEW_INVOICE";
export const VIEW_TS_INVOICE = "VIEW_TS_INVOICE";
export const VIEW_INVOICE_REQUEST = "VIEW_INVOICE_REQUEST";

//This convert the Angular Factory, using Singolton Factory Patten. Will only one instnace cross system, avoid the muti-instance
let InvoiceServicesActionsInstance = null;

const InvoiceServicesActionsConstruction = () => {
  let rx = Rx;
  let billingApi = new BillingApi();
  let toasterService = ToasterService;
  let spinnerService = SpinnerService;
  let customerLifetimeSummaryChart = new CustomerLifetimeSummaryChart();
  let customerBenefitYearChart = new CustomerBenefitYearChart();
  let billingTimeSeriesChart = new BillingTimeSeriesChart();
  let customerCashMonthPerformanceChart = new CustomerCashMonthPerformanceChart();
  let customerCashSiteSavingChart = new CustomerCashSiteSavingChart();
  let twelveMonthsSavingChart = new TwelveMonthsSavingChart();
  let pdfServicesActions =  PdfServicesActions();
  let pmtApi = new PmtApi();
  let BILLING_TS_CHART = BILLING_TS_CHART_REACT;
  let BILLING_MP_CHART = BILLING_MP_CHART_REACT;
  let CUSTOMER_CONTRACT_TYPE = CUSTOMER_CONTRACT_TYPE_REACT;
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_INVOICES_SORTBY = null;
  const DEFAULT_SORT_DIRECTION = "desc";
  let _tableState = {};

  const downloadInvoice = (downloadMode, invoice) => {
    return (dispatch, getState) => {
      switch (downloadMode) {
        case "DOWNLOAD_INVOICE":
          rx.Observable
            .fromPromise(billingApi.downloadInvoice(`${BILLING_API.INVOICE_ARCHIVE}${invoice.demandSavingRefId}`))
            .catch((errors) => {
              gsLog.error("In InvoiceServicesActions: downloadInvoice(): error in downloading PDF from server = ");
              gsLog.debug(errors);
              return rx.Observable.just({apiError: true});
            })
            .subscribe(
              (results) => {
                gsLog.error("In InvoiceServicesActions: downloadInvoice(): response = ");
                gsLog.debug(results);

                if (results.apiError) {

                } else {
                  const downloadedFileName = `invoice-${invoice.siteName}-${invoice.realMonth}-${invoice.realYear}.pdf`;
                  const pdfData = new Blob([results.data], { type: 'application/pdf'});
                  saveAs(pdfData, downloadedFileName);
                }
              },
              (errors) => {
                gsLog.error("In InvoiceServicesActions: downloadInvoice(): error in processing PDF from server = ");
                gsLog.debug(errors);
              },
              () => {
                gsLog.debug("In InvoiceServicesActions: downloadInvoice(): down with processing PDF from server.");
              }
            );
          break;
        case "PRINT_INVOICE":
          window.open(`${BILLING_API.INVOICE_ARCHIVE}${invoice.demandSavingRefId}`);
          break;
        default:
          break;
      }
    }
  };

  const getInvoicesMgr = (tableState) => {
    return (dispatch, getState) => {
      const { selectedCustomer } = getState().portfolioSelectorServices;
      const { customerUUID } = selectedCustomer ? selectedCustomer : {};
      const {canViewCashBill } = getState().loggedInUserServices.permissions;
      if (_.isNull(tableState)) {
        gsLog.debug("In InvoiceServicesActions: getInvoicesMgr(): _tableState = ");
        gsLog.debug(_tableState);
        _tableState.pagination = {
          number: 0,
          start: 0,
          totalItemCount: 0
        };
        tableState = _tableState;
      }
      _tableState = tableState;
      if (tableState.pagination.start === 0) {
        gsLog.debug("In InvoiceServicesActions: getInvoicesMgr(): get initial list of invoices ...");
        getInvoices(dispatch, tableState, {
          invoices: [],
          customerUUID,
          selectedMonth: null,
          selectedYear: null,
          pageNum: 0,
          size: DEFAULT_PAGE_SIZE,
          sortBy: !_.isEmpty(tableState.sort) ? tableState.sort.predicate : DEFAULT_INVOICES_SORTBY,
          order: !_.isEmpty(tableState.sort) ? (tableState.sort.reverse ? "desc": "asc") : DEFAULT_SORT_DIRECTION,
          canViewCashBill
        });
      } else {
        // we load more
        const { invoiceParams, invoices } = getState().invoiceServices;
        if (tableState.pagination.totalItemCount === 0 || invoiceParams.rowsDisplayed < invoiceParams.totalRows) {
          gsLog.debug(`In InvoiceServicesActions: getInvoicesMgr(): load more invoices: total row count (${invoiceParams.totalRows}), table state = `);
          gsLog.debug(tableState);
          getInvoices(dispatch, tableState, {
            invoices,
            customerUUID,
            selectedMonth: null,
            selectedYear: null,
            pageNum: invoiceParams.pageNum+1,
            size: DEFAULT_PAGE_SIZE,
            sortBy: !_.isEmpty(tableState.sort) ? tableState.sort.predicate : DEFAULT_INVOICES_SORTBY,
            order: !_.isEmpty(tableState.sort) ? (tableState.sort.reverse ? "desc": "asc") : DEFAULT_SORT_DIRECTION,
            canViewCashBill
          });
        } else {
          gsLog.debug(`In InvoiceServicesActions: getInvoicesMgr(): has all invoices, no need to fetch more`);
        }
      }
    };
  };

  const updateSiteNameInInvoice = (projectResults, serviceResults, invoices) => {
    gsLog.debug("In InvoiceServicesActions: updateSiteNameInInvoice(): invoices = ");
    gsLog.debug(invoices);
    gsLog.debug("In InvoiceServicesActions: updateSiteNameInInvoice(): projectResults = ");
    gsLog.debug(projectResults);
    gsLog.debug("In InvoiceServicesActions: updateSiteNameInInvoice(): serviceResults = ");
    gsLog.debug(serviceResults);

    if (projectResults && projectResults.data && serviceResults) {
      const { projects } = projectResults.data;
      const  services  = serviceResults.data ? serviceResults.data : [];
      return invoices.map((item, idx) => {
        if (!item.siteName) {
          const projectInfo = projects.find((project) => project.projectUUID === item.projectUUID);
          const serviceInfo = services.find((service)=> service.serviceUUID == item.serviceUUID );
          item.siteName = projectInfo ? projectInfo.projectName : "";
          item.serviceName = serviceInfo ? serviceInfo.serviceName : "";
          item.expanded = false;
        }
        return item;
      });
    } else {
      return invoices;
    }
  };

  const getInvoices = (dispatch, tableState, searchParams) => {
    gsLog.debug("In InvoiceServicesActions: getInvoices(): search params = ");
    gsLog.debug(searchParams);
    spinnerService.spin("spinner");
    let { pageNum, sortBy, order, size, invoices, customerUUID, selectedMonth, selectedYear } = searchParams;
    let canViewCashBill = searchParams.canViewCashBill;

    if (customerUUID) {
      const observables = [
        billingApi.getInvoicesList({customerUUID, selectedMonth, selectedYear, pageNum, order, sortBy, size}).catch((error) => {
          gsLog.error("In InvoiceServicesActions: getInvoices(): error calling get invoices API: error = ");
          gsLog.debug(error);
          return rx.Observable.just({apiError:true});
        }),
        billingApi.getProjectNamesByCustomer(customerUUID).catch((error) => {
          gsLog.error("In InvoiceServicesActions: getInvoices(): error calling get project names by customer ID API: error = ");
          gsLog.debug(error);
          return rx.Observable.just({apiError:true});
        }),
        pmtApi.getServices(customerUUID).catch((error) => {
          gsLog.error("In InvoiceServicesActions: get services(): error calling get services by customer ID API: error = ");
          gsLog.debug(error);
          return rx.Observable.just({data:[]});
        })
      ];
      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([invoiceResults, projectResults, serviceResults]) => {
            if (_.isUndefined(invoiceResults)) {
              gsLog.error("In InvoiceServicesActions: getInvoices(): there was an error in calling get invoices API: show toaster message ...");
              dispatch({
                type: GET_INVOICES,
                payload: {
                  invoices: [],
                  invoiceParams: {
                    pageNum: 0,
                    rowsDisplayed: 0,
                    totalRows: 0
                  }
                }
              });
            } else {
              gsLog.debug("In InvoiceServicesActions: getInvoices(): response from invoices API = ");
              gsLog.debug(invoiceResults);
              gsLog.debug("In InvoiceServicesActions: getInvoices(): response from projects API = ");
              gsLog.debug(projectResults);
              const { content, totalElements } = invoiceResults.data;
              invoices = invoices.concat(content);
              tableState.pagination.number = invoices.length;
              tableState.pagination.start = invoices.length;
              tableState.pagination.totalItemCount = totalElements;
              invoices = _.flow(
                updateSiteNameInInvoice.bind(this, projectResults, serviceResults)
              )(invoices);
              gsLog.debug("In InvoiceServicesActions: getInvoices(): final list of invoices = ");
              gsLog.debug(invoices);
              let  isCashcustomer = false;

              if (projectResults && projectResults.data && projectResults.data.projects &&  projectResults.data.projects.length>0) {
                isCashcustomer = projectResults.data.projects[0].contractType == CUSTOMER_CONTRACT_TYPE.CASH;
              }
              //CP-2106 Remove the requirement of role 912 for cash customer to view invoices
              canViewCashBill = true;

              if (isCashcustomer && !canViewCashBill) {
                gsLog.debug("In InvoiceServicesActions: getInvoices(): not allow view Cash customer = ");
                dispatch({
                  type: GET_INVOICES,
                  payload: {
                    invoices: [],
                    invoiceParams: {
                      pageNum: 0,
                      rowsDisplayed: 0,
                      totalRows: 0
                    }
                  }
                });
              } else {
                dispatch({
                  type: GET_INVOICES,
                  payload: {
                    invoices,
                    invoiceParams: {
                      pageNum,
                      rowsDisplayed: invoices.length,
                      totalRows: totalElements
                    }
                  }
                });
              }
            }
          },
          (errors) => {
            gsLog.error("In InvoiceServicesActions: getInvoices(): (2) error in calling get invoices API = ");
            gsLog.debug(errors);
            spinnerService.stop("spinner");
            dispatch({
              type: GET_INVOICES,
              payload: {
                invoices: [],
                invoiceParams: {
                  pageNum: 0,
                  rowsDisplayed: 0,
                  totalRows: 0
                }
              }
            });
          },
          () => {
            gsLog.debug("In InvoiceServicesActions: getInvoices(): calling get invoices API is DONE");
            spinnerService.stop("spinner");
          }
        );
    } else {
      gsLog.error("In InvoiceServicesActions: getInvoices(): can't get selected customer");
      spinnerService.stop("spinner");
      dispatch({
        type: GET_INVOICES,
        payload: {
          invoices: [],
          invoiceParams: {
            pageNum: 0,
            rowsDisplayed: 0,
            totalRows: 0
          }
        }
      });
    }
  };

  const pdfDownloadedEvent = () => {
    gsLog.debug("In InvoiceServicesActions: pdfDownloadedEvent(): PDF was downloaded ...");
    spinnerService.stop("spinner");
  };

  const doCreateInvoice = (dispatch, invoiceMode, dynamicData) => {
    gsLog.debug("In InvoiceServicesActions: doCreateInvoice(): dynamic data = ");
    gsLog.debug(dynamicData);
    const { customerName, monthName, year } = dynamicData.invoiceSummary,
          { cusomterTwelveMonthsChartImage, invoiceSummary, invoiceSummaries, invoiceSummariesEnergyArbitrage, gsInvoiceSummaries, invoicesWithTwelveMonthImage } = dynamicData;
    
    if (invoiceMode === "DOWNLOAD_INVOICE") {
      // download PDF
      gsLog.debug("In InvoiceServicesActions: doCreateInvoice(): downloading invoice ...");
      dispatch(pdfServicesActions.downloadPdf(
        cusomterTwelveMonthsChartImage,
        invoiceSummary,
        invoiceSummaries,
        invoiceSummariesEnergyArbitrage,
        gsInvoiceSummaries,
        invoicesWithTwelveMonthImage,
        `invoice-${customerName}-${monthName}-${year}.pdf`,
        pdfDownloadedEvent.bind(this)));
    } else {
      // print PDF
      gsLog.debug("In InvoiceServicesActions: doCreateInvoice(): printing invoice ...");
      dispatch(pdfServicesActions.printPdf(
        cusomterTwelveMonthsChartImage,
        invoiceSummary,
        invoiceSummaries,
        invoiceSummariesEnergyArbitrage,
        gsInvoiceSummaries,
        invoicesWithTwelveMonthImage));
      spinnerService.stop("spinner");
    }
  };

  const createChartImage = (chartId, width, height) => {
    gsLog.debug(`In InvoiceServicesActions: createChartImage(): creating image 000`);

    let chart = twelveMonthsSavingChart.twelveMonthsSavingChartRef.current.chart;
    let chartSvg = chart.getSVG({chart: {width: width, height: height}});
    let chartCanvas = document.createElement('canvas');
    chartCanvas.setAttribute('width', width);
    chartCanvas.setAttribute('height', height);
    let ctx = chartCanvas.getContext('2d');
    ctx.drawSvg(chartSvg, 0, 0, width, height);
    return chartCanvas.toDataURL("image/png");
  };

  const parseEndpoint = (endPointName, endPoints) => {
    return endPoints
      .filter((item) => item.name === endPointName)
      .map((item) => {
        let data = Object.keys(item.valuesWithEpochTime).map((key) => {
          return isNaN(item.valuesWithEpochTime[key]) ? [+key, null] : [+key, Math.floor(item.valuesWithEpochTime[key])];
        });
        return data;
      });
  };

  const parseTimeSeries = (ts) => {
    const { endPoints } = ts;
    gsLog.debug("In InvoiceServicesActions: parseTimeSeries(): ts data = ");
    gsLog.debug(endPoints);
    let netLoad = parseEndpoint("building.value@max", endPoints);
    let original = parseEndpoint("original.value@max", endPoints);
    let netSolar = parseEndpoint("netsolar.value@max", endPoints);
    gsLog.debug("In InvoiceServicesActions: parseTimeSeries(): original data to render = ");
    gsLog.debug(original[0]);
    gsLog.debug("In InvoiceServicesActions: parseTimeSeries(): net load data to render = ");
    gsLog.debug(netLoad[0]);
    gsLog.debug("In InvoiceServicesActions: parseTimeSeries(): net solar data to render = ");
    gsLog.debug(netSolar[0]);
    return {
      netLoad: netLoad[0],
      netSolar: netSolar[0],
      original: original[0]
    };
  };

  const mapsTwelveMonthImagesWithInvoice = (invoicesByProject, projectNamesByCustomer, serviceNamesByCustomer, invoiceHistoricalTransactionSummary, dynamicData) => {
    let invoicesWithTwelveMonthImage = [];
    gsLog.debug("In InvoiceServicesActions: mapsTwelveMonthImagesWithInvoice(): dynamic data = ");
    gsLog.debug(dynamicData);
    if (invoicesByProject.length > 0) {
      invoicesWithTwelveMonthImage = invoicesByProject.map((item) => {
        const projectInfo = projectNamesByCustomer.projects.find((project) => project.projectUUID === item.projectUUID);
        const serviceInfo = serviceNamesByCustomer.find((service) => service.serviceUUID === item.serviceUUID);
        let chartData =[];

         if (projectInfo) {
            let historyData = null;
            //Old version API 
            if (invoiceHistoricalTransactionSummary.breakDownByProjects) {
                historyData = invoiceHistoricalTransactionSummary.breakDownByProjects[projectInfo.projectUUID];
            }
            // new version API 
            if (invoiceHistoricalTransactionSummary.transactionsBreakdown) {
               const breakdownList = invoiceHistoricalTransactionSummary.transactionsBreakdown;

               if (breakdownList && _.isArray(breakdownList)) {
                    let invoiceBreakdown = breakdownList.find(breakdown => item.projectUUID === breakdown.projectUUID && item.serviceUUID === breakdown.serviceUUID);
                    if (invoiceBreakdown) {
                      historyData = invoiceBreakdown.monthlyBreakdown;
                    }
               }
            }

            if (historyData) {
                chartData = Object.keys(historyData).map((key) => {
                const keysStr = key.split("-");
                const currentDate = moment().year(+keysStr[0]).month(+keysStr[1]-1);
                gsLog.debug(`In InvoiceServicesActions: updateCustomerChart(): current date (${currentDate.format("MMM DD, YYYY HH:mm:ss")})`);
      
                let val = historyData[key].DEMAND_BASED;
      
                if (isNaN(val)) {
                  if (!isNaN(historyData[key].CONSUMPTION_BASED)) {
                    val = historyData[key].CONSUMPTION_BASED;
                  }else {
                    val = NaN;
                  }
                } else {
                  if (!isNaN(historyData[key].CONSUMPTION_BASED)) {
                    val = val + historyData[key].CONSUMPTION_BASED;
                  }
                }
      
                return [currentDate.format("MMM YY"), val];
              });
            }
         }
        // update 12 months  Saving chart
        gsLog.debug(`In InvoiceServicesActions: updateCustomerChart(): site 12 months saving `);
        gsLog.debug(chartData);

        twelveMonthsSavingChart.update("",chartData.reverse());
        const { width, height } = BILLING_TS_CHART;
        const chartImg = createChartImage("twelveMonthsSavingChart", width, height);
        return {
          invoice: item,
          chartImg,
          projectInfo,
          serviceInfo
        };
      });
      invoicesWithTwelveMonthImage = _.orderBy(invoicesWithTwelveMonthImage, ["projectInfo.state", "projectInfo.city", "projectInfo.address"], ["asc"]);
      gsLog.debug("In InvoiceServicesActions: invoicesWithTwelveMonthImage(): invoices with time series chart images = ");
      gsLog.debug(invoicesWithTwelveMonthImage);
    }
    return Object.assign({}, dynamicData, { invoicesWithTwelveMonthImage });
  };

  const createInvoiceSummaryTotals = (invoicesByCustomerUUID, projectNamesByCustomer, dynamicData) => {
    let invoiceSummary = {};
    gsLog.debug("In InvoiceServicesActions: createInvoiceSummaryTotals(): dynamic data = ");
    gsLog.debug(dynamicData);
    if (invoicesByCustomerUUID.length > 0) {
      const { monthName, year, total, totalByTransactionTypes, totalBalance, totalCarryOverBalance } = invoicesByCustomerUUID[0];
      const { customerName } = projectNamesByCustomer;
      invoiceSummary = {
        customerName,
        monthName,
        year,
        total,
        totalBalance,
        totalCarryOverBalance,
        totalByTransactionTypes
      };
    }
    gsLog.debug("In InvoiceServicesActions: createInvoiceSummaryTotals(): invoice summary = ");
    gsLog.debug(invoiceSummary);
    return Object.assign({}, dynamicData, { invoiceSummary });
  };

  const createInvoiceSummaries = (invoicesByCustomerUUID, projectNamesByCustomer, serviceNamesByCustomer, dynamicData) => {
    let invoiceSummaries = [],
        invoiceSummariesEnergyArbitrage = [],
        gsInvoiceSummaries = [];
    gsLog.debug("In InvoiceServicesActions: createInvoiceSummaries(): invoices by customer ID = ");
    gsLog.debug(invoicesByCustomerUUID);
    gsLog.debug("In InvoiceServicesActions: createInvoiceSummaries(): project names by customer = ");
    gsLog.debug(projectNamesByCustomer);

    if (invoicesByCustomerUUID.length > 0) {
      const { CONSUMPTION_BASED, DEMAND_BASED, DEMAND_RESPONSE } = invoicesByCustomerUUID[0].breakDownByProjects;

      if (DEMAND_BASED) {
        invoiceSummaries = DEMAND_BASED.map((item) => {
          const projectInfo = projectNamesByCustomer.projects.find((project) => project.projectUUID === item.projectUUID);
          const serviceInfo = serviceNamesByCustomer.find(service => service.serviceUUID == item.serviceUUID);
          return {
            summary: item,
            projectInfo,
            serviceInfo
          };
        });
      }

      if (CONSUMPTION_BASED) {
        invoiceSummariesEnergyArbitrage = CONSUMPTION_BASED.map((item) => {
          const projectInfo = projectNamesByCustomer.projects.find((project) => project.projectUUID === item.projectUUID);
          const serviceInfo = serviceNamesByCustomer.find(service => service.serviceUUID == item.serviceUUID);
          return {
            summary: item,
            projectInfo,
            serviceInfo
          };
        });
      }

      if (DEMAND_RESPONSE) {
        gsInvoiceSummaries = DEMAND_RESPONSE.map((item) => {
          const projectInfo = projectNamesByCustomer.projects.find((project) => project.projectUUID === item.projectUUID);
          const serviceInfo = serviceNamesByCustomer.find(service => service.serviceUUID == item.serviceUUID);
          return {
            summary: item,
            projectInfo,
            serviceInfo
          };
        });
      }
    }

    if (invoiceSummaries.legth > 0) {
      invoiceSummaries = _.orderBy(invoiceSummaries, ["projectInfo.state", "projectInfo.city", "projectInfo.address"], ["asc"]);
    }

    if (invoiceSummariesEnergyArbitrage.length > 0) {
      invoiceSummariesEnergyArbitrage = _.orderBy(invoiceSummariesEnergyArbitrage, ["projectInfo.state", "projectInfo.city", "projectInfo.address"], ["asc"]);
    }

    if (gsInvoiceSummaries.length > 0) {
      gsInvoiceSummaries = _.orderBy(gsInvoiceSummaries, ["projectInfo.state", "projectInfo.city", "projectInfo.address"], ["asc"]);
      gsLog.debug("In InvoiceServicesActions: createInvoiceSummaries(): grid services summaries = ");
      gsLog.debug(gsInvoiceSummaries);
    }

    gsLog.debug("In InvoiceServicesActions: createInvoiceSummaries(): invoice summaries = ");
    gsLog.debug(invoiceSummaries);
    return Object.assign({}, dynamicData, { invoiceSummaries, gsInvoiceSummaries, invoiceSummariesEnergyArbitrage });
  };

  const updateCustomerChart = (invoicesByCustomerUUID, invoiceHistoricalTransactionSummary, dynamicData) => {
    gsLog.debug("In InvoiceServicesActions: updateCustomerChart(): invoicesByCustomerUUID = ");
    gsLog.debug(invoicesByCustomerUUID);
    gsLog.debug("In InvoiceServicesActions: updateCustomerChart(): dynamic data = ");
    gsLog.debug(dynamicData);

    if(invoiceHistoricalTransactionSummary) {
      const { total } = invoiceHistoricalTransactionSummary;
      if (total) {
        let chartData = Object.keys(total).map((key) => {
          const keysStr = key.split("-");
          const currentDate = moment().year(+keysStr[0]).month(+keysStr[1]-1);
          gsLog.debug(`In InvoiceServicesActions: updateCustomerChart(): current date (${currentDate.format("MMM DD, YYYY HH:mm:ss")})`);

          let val = total[key].DEMAND_BASED;

          if (isNaN(val)) {
            if (!isNaN(total[key].CONSUMPTION_BASED)) {
              val = total[key].CONSUMPTION_BASED;
            }else {
              val = NaN;
            }
          } else {
            if (!isNaN(total[key].CONSUMPTION_BASED)) {
              val = val + total[key].CONSUMPTION_BASED;
            }
          }

          return [currentDate.format("MMM YY"), val];
        });

        gsLog.debug(`In InvoiceServicesActions: updateCustomerChart(): all sites 12 months saving `);
        gsLog.debug(chartData);

        twelveMonthsSavingChart.update("",chartData.reverse());
      }  else {
        let categories = [];
        customerBenefitYearChart.update(categories, [], [], []);
        customerCashMonthPerformanceChart.update(categories, [], [], []);
        twelveMonthsSavingChart.update("", []);
      }
    }
  };

  const createCustomerTwelveMonthsChartAsImage = (dynamicData) => {
    const { width, height } = BILLING_TS_CHART;
    const cusomterTwelveMonthsChartImage = createChartImage("twelveMonthsSavingChart", width, height);
    return Object.assign({}, dynamicData, { cusomterTwelveMonthsChartImage });
  };


  const processInvoice = (invoiceMode, invoiceId, invoicesByCustomerUUID, projectNamesByCustomer, serviceNamesByCustomer, invoicesByProjectService, invoiceHistoricalTransactionSummary) => {
    gsLog.debug("In InvoiceServicesActions: processInvoice(): invoicesByProjectService");
    gsLog.debug(invoicesByProjectService);
    return _.flow(
      updateCustomerChart.bind(this, invoicesByCustomerUUID, invoiceHistoricalTransactionSummary),
      createCustomerTwelveMonthsChartAsImage.bind(this, invoiceHistoricalTransactionSummary),
      createInvoiceSummaries.bind(this, invoicesByCustomerUUID, projectNamesByCustomer,serviceNamesByCustomer),
      createInvoiceSummaryTotals.bind(this, invoicesByCustomerUUID, projectNamesByCustomer),
      mapsTwelveMonthImagesWithInvoice.bind(this, invoicesByProjectService, projectNamesByCustomer, serviceNamesByCustomer, invoiceHistoricalTransactionSummary)
    )({});
  }

  const viewInvoice = (invoiceMode, invoiceId, realMonth, realYear, month, year) => {
    return (dispatch, getState) => {
      const { customerUUID } = getState().portfolioSelectorServices.selectedCustomer;
      gsLog.debug(`In InvoiceServicesActions: viewInvoice(): selected customer ID = ${customerUUID}, month (${month}), year (${year})`);
      const observables = [
        billingApi.getInvoicesByCustomerUUID(customerUUID, month, year).catch((error) => {
          gsLog.error("In InvoiceServicesActions: viewInvoice(): error calling get invoices by customer ID API: error = ");
          gsLog.error(error);
        }),
        billingApi.getProjectNamesByCustomer(customerUUID).catch((error) => {
          gsLog.error("In InvoiceServicesActions: viewInvoice(): error calling get project names by customer ID API: error = ");
          gsLog.error(error);
        }),
        billingApi.getInvoiceHistoricalTransactionSummary(customerUUID, realMonth, realYear).catch((error) => {
          gsLog.error("In InvoiceServicesActions: viewInvoice(): error calling get project names by customer ID API: error = ");
          gsLog.error(error);
        }),
        pmtApi.getServices(customerUUID).catch((error) => {
          gsLog.error("In InvoiceServicesActions: get services(): error calling get services by customer ID API: error = ");
          gsLog.error(error);
          return rx.Observable.just({data:[]});
        })

      ];
      let finalInvoiceRequests = [],
        invoiceTimeSeriesRequests = [],
        gsFinalInvoiceRequests = [],
        gsInvoiceTimeSeriesRequests = [];
      spinnerService.spin("spinner");
      let invoiceDataResults = {};
      rx.Observable
        .forkJoin(observables)
        .flatMap(([invoicesByCustomerUUIDResults, projectNamesByCustomerResults, invoiceHistoricalTransactionSummaryResults, serviceNamesByCustomerResults]) => {
          gsLog.debug("In InvoiceServicesActions: viewInvoice(): results from APIs: invoices by customer = ");
          gsLog.debug(invoicesByCustomerUUIDResults);
          gsLog.debug("In InvoiceServicesActions: viewInvoice(): results from APIs: projects by customer = ");
          gsLog.debug(projectNamesByCustomerResults);
          gsLog.debug("In InvoiceServicesActions: viewInvoice(): results from APIs: historicalTransactionSummary by customer = ");
          gsLog.debug(invoiceHistoricalTransactionSummaryResults);

          invoiceDataResults = {
            invoicesByCustomerUUIDResults,
            projectNamesByCustomerResults,
            invoiceHistoricalTransactionSummaryResults,
            serviceNamesByCustomerResults,
            invoices: []
          };

          if (invoicesByCustomerUUIDResults && invoicesByCustomerUUIDResults.data.length > 0) {
            const { DEMAND_BASED, DEMAND_RESPONSE } = invoicesByCustomerUUIDResults.data[0].breakDownByProjects;
            
            if (DEMAND_BASED) {
              finalInvoiceRequests = DEMAND_BASED.map((item) => {
                gsLog.debug(`In InvoiceServicesActions: viewInvoice(): adding invoice id (${item.invoiceServiceId}) ...`);

                return billingApi.getFinalInvoice(item.invoiceServiceId).catch((error) => {
                    gsLog.error(`In InvoiceServicesActions: viewInvoice(): error calling get final invoice API: id (${item.invoiceServiceId}), error = `);
                    gsLog.error(error);
                  });
              });
              
            }
            // TODO
            if (false && DEMAND_RESPONSE) {
              gsFinalInvoiceRequests = DEMAND_RESPONSE.map((item) => {
                gsLog.debug(`In InvoiceServicesActions: viewInvoice(): adding invoice id (${item.invoiceServiceId}) ...`);

                return billingApi.getFinalInvoice(item.invoiceServiceId).catch((error) => {
                    gsLog.error(`In InvoiceServicesActions: viewInvoice(): (grid services) error calling get final invoice API: id (${item.invoiceServiceId}), error = `);
                    gsLog.debug(error);
                  });
              });
              gsInvoiceTimeSeriesRequests = DEMAND_RESPONSE.map((item) => {
                return billingApi.getInvoiceTimeSeries(item.invoiceServiceId).catch((error) => {
                    gsLog.error(`In InvoiceServicesActions: viewInvoice(): (grid services) error calling get invoice ts API: id (${item.invoiceServiceId}), error = `);
                    gsLog.debug(error);
                  });
              });
              if (gsFinalInvoiceRequests && gsFinalInvoiceRequests.length > 0) {
                finalInvoiceRequests.push(...gsFinalInvoiceRequests);
              }
              if (gsInvoiceTimeSeriesRequests && gsInvoiceTimeSeriesRequests.length > 0) {
                finalInvoiceRequests.push(...gsInvoiceTimeSeriesRequests);
              }
            }
          }
          gsLog.debug("In InvoiceServicesActions: viewInvoice(): invoice time series requests = ");
          gsLog.debug(invoiceTimeSeriesRequests);
          gsLog.debug("In InvoiceServicesActions: viewInvoice(): invoice requests = ");
          gsLog.debug(finalInvoiceRequests);

          if (finalInvoiceRequests.length > 0) {
            return rx.Observable.forkJoin(finalInvoiceRequests);
          } else {
            return rx.Observable.just([]);
          }
        })
        .subscribe(
          (results) => {
            gsLog.debug("In InvoiceServicesActions: viewInvoice(): subscribe: invoice time series results = ");
            gsLog.debug(results);
            gsLog.debug("In InvoiceServicesActions: viewInvoice(): subscribe: invoice results = ");
            gsLog.debug(invoiceDataResults);

            if (!_.isUndefined(results) && !_.isNull(results)) {
              results.map((item) => {
                invoiceDataResults.invoices.push(item.data);
              });
            }

            if (invoiceDataResults.invoicesByCustomerUUIDResults) {
              let finalInvoices = invoiceDataResults.invoices.filter((item) => item.invoiceServiceId);
              gsLog.debug("In InvoiceServicesActions: viewInvoice(): final invoices with time series = ");
              gsLog.debug(finalInvoices);

              if (invoiceDataResults.invoices && invoiceDataResults.invoices.length > 0) {
                const dynamicData = processInvoice(invoiceMode, invoiceId, invoiceDataResults.invoicesByCustomerUUIDResults.data, invoiceDataResults.projectNamesByCustomerResults.data, invoiceDataResults.serviceNamesByCustomerResults.data, finalInvoices, invoiceDataResults.invoiceHistoricalTransactionSummaryResults.data);
                gsLog.debug("In InvoiceServicesActions: viewInvoice(): dynamic data = ");
                gsLog.debug(dynamicData);
                doCreateInvoice(dispatch, invoiceMode, dynamicData);
              } else {
                spinnerService.stop("spinner");
                let message = "Cannot find invoice.";
                let customerInvoiceData = invoiceDataResults.invoicesByCustomerUUIDResults.data;

                if (customerInvoiceData && customerInvoiceData.length > 0) {
                  const { msg } = customerInvoiceData[0];

                  if (msg && msg.length > 0) {
                    message = `${message} \n ${msg}`;
                  }
                }

                toasterService.error(message);
              }
            } else {
              // TODO
              toasterService.error("Cannot find invoice.");
              spinnerService.stop("spinner");
            }
          },
          (error) => {
            gsLog.error("In InvoiceServicesActions: viewInvoice(): error calling API: error = ");
            gsLog.error(error);
            spinnerService.stop("spinner");
          },
          () => {
            gsLog.debug("In InvoiceServicesActions: viewInvoice(): DONE getting billing data for invoice");
          }
        );
    };
  };

  const viewTimeSeries = (invoiceId) => {
    return (dispatch, getState) => {
      rx.Observable
        .fromPromise(billingApi.getInvoiceTimeSeries(invoiceId))
        .catch((error) => {
          gsLog.error("In InvoiceServicesActions: viewTimeSeries(): error calling get time series data by invoice ID API: error = ");
          gsLog.debug(error);
          return rx.Observable.just({apiError: true});
        })
        .subscribe(
          (results) => {
            gsLog.debug("In InvoiceServicesActions: viewTimeSeries(): results from time series APIs: results = ");
            gsLog.debug(results);
            if (results.apiError) {
              toasterService.error("Invoice doesn't exist");
              return dispatch({
                type: VIEW_TS_INVOICE,
                payload: {
                  tsByInvoice: {
                    invoiceId,
                    ts: {netLoad: [], original: [], netSolar: []}
                  }
                }
              });
            } else {
              return dispatch({
                type: VIEW_TS_INVOICE,
                payload: {
                  tsByInvoice: {
                    invoiceId,
                    ts: parseTimeSeries(results.data)
                  }
                }
              });
            }
          },
          (error) => {
            gsLog.error("In InvoiceServicesActions: viewTimeSeries(): (2) error processing get time series data by selected invoice: error = ");
            gsLog.debug(error);
          },
          () => {
            gsLog.debug("In InvoiceServicesActions: viewTimeSeries(): DONE");
          }
        );
    }
  };

  return {
    downloadInvoice,
    getInvoicesMgr,
    viewInvoice,
    viewTimeSeries
  };
}

export const InvoiceServicesActions = ( ) => {
   if (InvoiceServicesActionsInstance) {
     return InvoiceServicesActionsInstance;
   } else {
    InvoiceServicesActionsInstance = InvoiceServicesActionsConstruction();
    return InvoiceServicesActionsInstance;
   }
} 

