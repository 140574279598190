import { VPP_SELECTOR_TOGGLE_EXPANDER, VPP_SELECTOR_TOGGLE_FOOTER } from '../actions/vppSelectorToggleServices';

const INITIAL_STATE = {
  expander: false,
  footer: false,
  expandMode: "reset"
};

export default function vppSelectorToggleFooterServices(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case VPP_SELECTOR_TOGGLE_EXPANDER:
      return Object.assign({}, state, payload);
    case VPP_SELECTOR_TOGGLE_FOOTER:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
