import _ from 'lodash';
export const UPDATE_PMT_WIZARD_STORAGE_SERVICES = "UPDATE_PMT_WIZARD_STORAGE_SERVICES";
export const NON_EXPORT_MODES = [
  { id: null, exportName: "-- Select One --" },
  { id: 1, exportName: "Enabled", val: "allRejected" },
  { id: 2, exportName: "Enabled - Storage Only", val: "storageRejected" },
  { id: 3, exportName: "Disabled", val: "Accepted" }
];

export const FREIGHT_COST_FEATURE_GROUP_ID = 7;

export const PmtWizardStorageServices = (rx, gsLog, patApi, SELECT_ONE_ITEM_TARIFF) => {

  const SELECT_ONE_MANUFACTURER = {
    manufacturerId: null,
    manufacturerName: "-- Select One --"
  };

  const SELECT_ONE_FREIGHT_COST = {
    featureId: null,
    featureName: "-- Select One --"
  };

  const initStorageServices = () => {

    return (dispatch, getState) => {

      const { steps } = getState().pmtWizardServices,
        servicesStep = steps.find((item) => item.name === "services"),
        { utilityId } = servicesStep.formData.existingService;
      gsLog.debug(`In PmtWizardStorageServices: initStorageServices(): utility ID (${utilityId})`);

      const observables = [
        patApi.getManufacturers().catch((errors) => {
          gsLog.error(`In PmtWizardStorageServices: initStorageServices(): error in getting manufacturers API = `);
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        }),
        patApi.getFeatureGroups(FREIGHT_COST_FEATURE_GROUP_ID).catch((errors) => {
          gsLog.error(`In PmtWizardStorageServices: initStorageServices(): errors in getting freight cost feature group API = `);
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        }),
        patApi.getTariffListByUtilIdUsingObservable(utilityId).catch((errors) => {
          gsLog.error("In PmtWizardStorageServices: initStorageServices(): error from get list of tariffs by utility ID API = ");
          gsLog.debug(errors);
          return rx.Observable.just({ apiError: true });
        })
      ];

      let isSolar;
      rx.Observable
        .forkJoin(observables)
        .subscribe(
          ([manufacturersResults, featureGroupsResults, tariffResults]) => {
            gsLog.debug("In PmtWizardStorageServices: initStorageServices(): results from manufacturers API = ");
            gsLog.debug(manufacturersResults);
            gsLog.debug("In PmtWizardStorageServices: initStorageServices(): results from feature groups API = ");
            gsLog.debug(featureGroupsResults);
            gsLog.debug("In PmtWizardStorageServices: initStorageServices(): results from tariffs API = ");
            gsLog.debug(tariffResults);

            let manufacturers = [],
              featureGroups = [],
              tariffs = [],
              { selectedStep, steps } = getState().pmtWizardServices,
              generatorsStep = steps.find((item) => item.name === "generators");
            isSolar = generatorsStep.metadata.solarMode !== 1;

            gsLog.debug(`In PmtWizardStorageServices: initStorageServices(): isSolar (${isSolar})`);

            if (manufacturersResults.data) {
              manufacturers = [SELECT_ONE_MANUFACTURER, ...manufacturersResults.data.content];
            }

            if (featureGroupsResults.data) {
              featureGroups = [SELECT_ONE_FREIGHT_COST, ...featureGroupsResults.data];
            }

            if (tariffResults.data) {
              tariffs = [SELECT_ONE_ITEM_TARIFF, ...tariffResults.data];
            }

            if (_.isUndefined(selectedStep.formData.storageTariff)) {
              gsLog.debug("In PmtWizardStorageServices: initStorageServices(): initialize storage form data ...");
              // selectedStep.formData.storageManufacturer = manufacturers.length > 0 ? manufacturers[0] : null;
              selectedStep.formData.freightCost = featureGroups.length > 0 ? featureGroups[0] : null;
              selectedStep.formData.selectedNonExportMode = NON_EXPORT_MODES.length > 0 ? NON_EXPORT_MODES[2] : null;

              const { tariffId } = generatorsStep.formData.generator.solarTariff;
              gsLog.debug(`In PmtWizardStorageServices: initStorageServices(): existing tariff ID (${tariffId})`);
              selectedStep.formData.storageTariff = tariffs.find((item) => item.tariffId === tariffId);
              if (_.isUndefined(selectedStep.formData.storageTariff) && tariffs.length > 0) {
                selectedStep.formData.storageTariff = tariffs[0];
              }

              selectedStep.formData.storageSgipRate = 0.290;
              selectedStep.formData.storageSmartIncentive = false;
              selectedStep.formData.storageOtherUpfrontIncentive = 0;
              selectedStep.formData.storageOtherAnnualIncentive = 0;
              selectedStep.formData.storageOtherIncentiveEscalation = 0;
              selectedStep.formData.storageOtherIncentiveDuration = 0;
              selectedStep.formData.isPeakShaving = true;
              selectedStep.formData.isEnergyArbitrage = true;

              gsLog.debug("In PmtWizardStorageServices: initStorageServices(): storage form data = ");
              gsLog.debug(selectedStep.formData);
            }


            if (!_.isUndefined(selectedStep.formData.freightCost) && !_.isNull(selectedStep.formData.freightCost)) {
              gsLog.debug("In PmtWizardStorageServices: initStorageServices(): re-init freight cost = ");
              selectedStep.formData.freightCost = featureGroups.find((item) => item.featureId === selectedStep.formData.freightCost.featureId);
              gsLog.debug(selectedStep.formData.freightCost);
            }

            if (!_.isUndefined(selectedStep.formData.storageTariff) && !_.isNull(selectedStep.formData.storageTariff)) {
              gsLog.debug("In PmtWizardStorageServices: initStorageServices(): re-init storage tariff = ");
              selectedStep.formData.storageTariff = tariffs.find((item) => item.tariffId === selectedStep.formData.storageTariff.tariffId);
              gsLog.debug(selectedStep.formData.storageTariff);
            }

            dispatch({
              type: UPDATE_PMT_WIZARD_STORAGE_SERVICES,
              payload: {
                enableItcThreshold: isSolar,
                manufacturers,
                featureGroups,
                tariffs,
                NON_EXPORT_MODES: isSolar ? NON_EXPORT_MODES : NON_EXPORT_MODES.filter((item) => _.isNull(item.id) || item.id === 2 || item.id === 3)
              }
            });
          },
          (errors) => {
            gsLog.error(`In PmtWizardStorageServices: initStorageServices(): errors in processing = `);
            gsLog.debug(errors);
            dispatch({
              type: UPDATE_PMT_WIZARD_STORAGE_SERVICES,
              payload: {
                enableItcThreshold: isSolar,
                manufacturers: [],
                featureGroups: [],
                NON_EXPORT_MODES: isSolar ? NON_EXPORT_MODES : NON_EXPORT_MODES.filter((item) => _.isNull(item.id) || item.id === 2 || item.id === 3)
              }
            });
          },
          () => {
            gsLog.debug(`In PmtWizardStorageServices: initStorageServices(): DONE with processing`);
          }
        )
    }
  };

  return {
    initStorageServices
  }
};

PmtWizardStorageServices.$inject = [
  "rx",
  "gs.services.gsLogService",
  "patApi",
  "SELECT_ONE_ITEM_TARIFF"
]
