import Rx from "rx";
import _ from 'underscore';
import PatApi from "../service/patApi";
import { default as gsLog } from '../service/loggingService';
import SpinnerService from "../shared/react-spinner.js";
import ToasterService from "../shared/toasterService.js";
export const VIEW_REFRESH = "VIEW_REFRESH";

//This convert the Angular Factory, using Singolton Factory Patten. Will only one instnace cross system, avoid the muti-instance
let tariffRateInfoServicesActionsInstance = null;

const TariffRateInfoServicesActionsConstruction = () => {
  let rx = Rx;
  let toasterService = ToasterService;
  let spinnerService = SpinnerService;
  let patApi = new PatApi();
  const NONSEASONAL = "nonseasonal";
  const NA = "N/A";

  function chargTypeFormat(chargeType) {
    if (chargeType === 'FIXED_PRICE') {
      return 'Fixed ($)';
    }
    else if (chargeType === 'DEMAND_BASED') {
      return 'Demand ($/kW)';
    }
    else if (chargeType === 'CONSUMPTION_BASED') {
      return 'Energy ($/kWh) ';
    }
    else {
      return chargeType;
    }
  }

  function rateNameFormat(rateName, tier) {
    let demandLimitString = '', consumptionLimitString = '', outputRateName = '';
    demandLimitString = (tier.hasDemandLimit) ? '<' + tier.demandUpperLimit + 'kW' : '';
    consumptionLimitString = (tier.hasConsumptionLimit) ? '<' + tier.consumptionUpperLimit + 'kWh' : '';

    if (demandLimitString !== '' || consumptionLimitString !== '') {
      outputRateName = rateName + ' (' + demandLimitString + (demandLimitString !== '' ? ',' : '') + consumptionLimitString + ')';
    }
    else {
      outputRateName = rateName;
    }

    return outputRateName;
  }

  function findKeyInObjList(key, list) {
    let retCode = -1;
    for (let i = 0; i < list.length; i++) {
      if (key in list[i]) {
        retCode = i;
        break;
      }
    }

    return retCode;
  }

  function parsePeriods(periods) {
    let weekDay = "", weekEnd = "";

    periods.forEach((period, index, array) => {
      let fromTime = "", toTime = "";

      if (period.fromHour > 12) {
        fromTime = `${period.fromHour - 12}:${period.fromMinute !== '0' ? "00" : period.fromMinute}PM`;
      }
      else {
        fromTime = `${period.fromHour !== '0' ? "12" : period.fromHour}:${period.fromMinute !== '0' ? "00" : period.fromMinute}AM`;
      }

      if (period.toHour > 12) {
        toTime = `${period.toHour - 12}:${period.toMinute !== '0' ? "00" : period.toMinute}PM`;
      }
      else {
        toTime = `${period.toHour !== '0' ? "00" : period.toHour}:${period.toMinute !== '0' ? "00" : period.toMinute}AM`;
      }

      if (period.fromDayOfWeek === 1 || period.fromDayOfWeek === 7) {
        weekEnd = `${weekEnd} ${fromTime}-${toTime},`;
      }
      else {
        weekDay = `${weekDay} ${fromTime}-${toTime},`;
      }

    });

    weekEnd = weekEnd.slice(0, -1);
    weekDay = weekDay.slice(0, -1);

    return { weekDay: weekDay, weekEnd: weekEnd };
  }

  function formatSeasonTiming(rate) {
    gsLog.debug('In TariffRateInfoServices: formatSeasonTiming() 111 timeframeList : ');
    gsLog.debug(rate);
    let timefameObj = {};

    if (!_.isUndefined(rate.season) && !_.isNull(rate.season) && !_.isUndefined(rate.season.seasonName)) {
      if (!_.isUndefined(rate.timeframe) && !_.isNull(rate.timeframe) && _.isArray(rate.timeframe.periods)) {

        const { weekDay, weekEnd } = parsePeriods(rate.timeframe.periods);
        timefameObj["charge"] = rate.rateGroupName;
        timefameObj["weekDay"] = weekDay;
        timefameObj["weekEnd"] = weekEnd;
      }
      else {
        timefameObj = { charge: rate.rateGroupName, weekDay: "12:00AM-12:00AM", weekEnd: "12:00AM-12:00AM" };
      }
    }
    else {
      timefameObj = { charge: "Non Coincident", weekDay: "12:00AM-12:00AM", weekEnd: "12:00AM-12:00AM" };
    }

    return timefameObj;
  }

  function formatRateSeasonSummary(rateSeasonSummaryConsoliate) {
    gsLog.debug('In TariffRateInfoServices: formatRateSeasonSummary() 111 rateSeasonSummaryConsoliate : ');
    gsLog.debug(rateSeasonSummaryConsoliate);
    let seasonSummaryList = [];
    let sortedChargeType = Object.keys(rateSeasonSummaryConsoliate);

    sortedChargeType.sort();

    sortedChargeType.forEach((prop) => {
      if (!_.isUndefined(rateSeasonSummaryConsoliate[prop]) && !_.isNull(rateSeasonSummaryConsoliate[prop])) {
        rateSeasonSummaryConsoliate[prop].forEach((seasonObj, index, array) => {
          let seasonName = Object.keys(seasonObj)[0];

          for (let season in seasonObj) {
            if (!_.isUndefined(seasonObj[season]) && !_.isNull(seasonObj[season])) {
              seasonObj[season].forEach((rateNameObj, index, array) => {
                let rateSummary = {};
                rateSummary["chargeType"] = prop;
                prop = "";
                rateSummary["season"] = seasonName;
                seasonName = "";
                rateSummary["rateName"] = Object.keys(rateNameObj)[0];
                rateSummary["week"] = rateNameObj[Object.keys(rateNameObj)[0]].weekDay;
                rateSummary["weekend"] = rateNameObj[Object.keys(rateNameObj)[0]].weekEnd;
                seasonSummaryList.push(rateSummary);
              });
            }
          }
        });
      }
    });

    return seasonSummaryList;
  }

  function formatRateSummary(rateSummaryList) {
    gsLog.debug('In TariffRateInfoServices: formatRateSummary() 111 rateSummaryList : ');
    gsLog.debug(rateSummaryList);
    let summaryList = [];
    let sortedChargeType = Object.keys(rateSummaryList);

    sortedChargeType.sort();

    sortedChargeType.forEach((prop) => {
      gsLog.debug('In TariffRateInfoServices: formatRateSummary() 222 rateSummaryList : ');
      if (!_.isUndefined(rateSummaryList[prop]) && !_.isNull(rateSummaryList[prop])) {
        rateSummaryList[prop].forEach((seasonObj, index, array) => {
          let seasonName = Object.keys(seasonObj)[0];

          for (let season in seasonObj) {
            if (!_.isUndefined(seasonObj[season]) && !_.isNull(seasonObj[season])) {
              seasonObj[season].forEach((rateNameObj, index, array) => {
                let rateSummary = {};
                rateSummary["chargeType"] = prop;
                prop = "";
                rateSummary["season"] = seasonName;
                seasonName = "";
                rateSummary["rateName"] = Object.keys(rateNameObj)[0];
                rateSummary["tierPrice"] = rateNameObj[Object.keys(rateNameObj)[0]];
                summaryList.push(rateSummary);
              });
            }
          }

        });
      }
    });

    return summaryList;
  }

  const viewTariffRateInfo = (tariffId, utilityId) => {
    return (dispatch, getState) => {
      SpinnerService.spin();
      gsLog.debug(`In TariffRateInfoServices: viewTariffRateInfo(): call API with utilityId (${utilityId})`);
      gsLog.debug(`In TariffRateInfoServices: viewTariffRateInfo(): call API with tariffId (${tariffId})`);

      rx.Observable
        .fromPromise(patApi.getTariffRate(tariffId, utilityId))
        .catch((error) => {
          gsLog.error("In TariffRateInfoServices: getLatestTransaction(): error in calling Tariff Rate API = ");
          gsLog.debug(error);
          toasterService.error(`error in calling Tariff Rate API = ${error.statusText}`);
        })
        .subscribe(
          (response) => {
            SpinnerService.stop();

            if (!response.apiError) {
              if (!_.isUndefined(response.data.rates) && !_.isNull(response.data.rates) && _.isArray(response.data.rates)) {
                let rateSummaryList = [], rateSummaryConsoliate = {};
                let rateSeasonSummaryList = [], rateSeasonSummaryConsoliate = {};

                for (let i = 0; i < response.data.rates.length; i++) {
                  let Rate = response.data.rates[i];
                  if (_.isUndefined(Rate.toDateTime) || _.isNull(Rate.toDateTime)) {
                    for (let j = 0; j < Rate.tiers.length; j++) {
                      if (Rate.tiers[j].tierPrice > 0) {
                        let chargeType = chargTypeFormat(Rate.chargeType);
                        let rateName = rateNameFormat(Rate.rateGroupName, Rate.tiers[j]);
                        let tierPrice = Rate.tiers[j].tierPrice;
                        let season = null;

                        if (!_.isUndefined(Rate.season) && !_.isNull(Rate.season) && !_.isUndefined(Rate.season.seasonName)) {
                          season = Rate.season.seasonName + ' (' + Rate.season.seasonStartMonth + '/' + Rate.season.seasonStartDay + ' - ' + Rate.season.seasonEndMonth + '/' + Rate.season.seasonEndDay + ')';
                        }
                        else {
                          season = NONSEASONAL;
                        }

                        if (!(chargeType in rateSummaryConsoliate)) {
                          let seasonObj = {}, rateNameObj = {};
                          let seasonList = [], rateNameList = [];

                          rateNameObj[rateName] = tierPrice;
                          rateNameList.push(rateNameObj);
                          seasonObj[season] = rateNameList;
                          seasonList.push(seasonObj);
                          rateSummaryConsoliate[chargeType] = seasonList;
                        }
                        else {
                          let seasonPos = findKeyInObjList(season, rateSummaryConsoliate[chargeType]);
                          if (seasonPos === -1) {
                            let seasonObj = {}, rateNameObj = {};
                            let rateNameList = [];

                            rateNameObj[rateName] = tierPrice;
                            rateNameList.push(rateNameObj);
                            seasonObj[season] = rateNameList;
                            rateSummaryConsoliate[chargeType].push(seasonObj);

                          } else {
                            let rateNamePos = findKeyInObjList(rateName, rateSummaryConsoliate[chargeType][seasonPos]);

                            if (rateNamePos === -1) {
                              let rateNameObj = {};
                              rateNameObj[rateName] = tierPrice;
                              rateSummaryConsoliate[chargeType][seasonPos][season].push(rateNameObj);

                            }
                          }
                        }
                      }
                    }
                  }
                }

                rateSummaryList = formatRateSummary(rateSummaryConsoliate);

                // timefame construction
                for (let i = 0; i < response.data.rates.length; i++) {
                  let Rate = response.data.rates[i];
                  if (_.isUndefined(Rate.toDateTime) || _.isNull(Rate.toDateTime)) {
                    for (let j = 0; j < Rate.tiers.length; j++) {
                      if (Rate.tiers[j].tierPrice > 0) {

                        let chargeType = chargTypeFormat(Rate.chargeType);
                        let rateName = rateNameFormat(Rate.rateGroupName, Rate.tiers[j]);
                        let tierPrice = Rate.tiers[j].tierPrice;
                        let season = null;

                        if (!_.isUndefined(Rate.season) && !_.isNull(Rate.season) && !_.isUndefined(Rate.season.seasonName)) {
                          season = Rate.season.seasonName + ' (' + Rate.season.seasonStartMonth + '/' + Rate.season.seasonStartDay + ' - ' + Rate.season.seasonEndMonth + '/' + Rate.season.seasonEndDay + ')';
                        }
                        else {
                          season = NONSEASONAL;
                        }

                        const { charge, weekDay, weekEnd } = formatSeasonTiming(Rate);

                        if (weekDay !== NA && weekEnd !== NA) {
                          if (!(season in rateSeasonSummaryConsoliate)) {
                            let chargeList = [];
                            let ratePeriods = [];
                            ratePeriods.push(charge, weekDay, weekEnd, chargeType);
                            chargeList.push(ratePeriods);
                          }
                          else {
                            let ratePeriods = [];
                            ratePeriods.push(charge, weekDay, weekEnd, chargeType);
                          }
                        }

                        if (!(chargeType in rateSeasonSummaryConsoliate)) {
                          let seasonObj = {}, rateNameObj = {};
                          let seasonList = [], rateNameList = [];

                          rateNameObj[rateName] = { weekDay: weekDay, weekEnd: weekEnd };
                          rateNameList.push(rateNameObj);
                          seasonObj[season] = rateNameList;
                          seasonList.push(seasonObj);
                          rateSeasonSummaryConsoliate[chargeType] = seasonList;
                        }
                        else {
                          let seasonPos = findKeyInObjList(season, rateSeasonSummaryConsoliate[chargeType]);

                          if (seasonPos === -1) {
                            let seasonObj = {}, rateNameObj = {};
                            let rateNameList = [];

                            rateNameObj[rateName] = { weekDay: weekDay, weekEnd: weekEnd };
                            rateNameList.push(rateNameObj);
                            seasonObj[season] = rateNameList;
                            rateSeasonSummaryConsoliate[chargeType].push(seasonObj);
                          }
                          else {
                            let rateNamePos = findKeyInObjList(rateName, rateSeasonSummaryConsoliate[chargeType][seasonPos]);

                            if (rateNamePos === -1) {
                              let rateNameObj = {};

                              rateNameObj[rateName] = { weekDay: weekDay, weekEnd: weekEnd };
                              rateSeasonSummaryConsoliate[chargeType][seasonPos][season].push(rateNameObj);

                            }
                          }
                        }
                      }
                    }
                  }
                }

                rateSeasonSummaryList = formatRateSeasonSummary(rateSeasonSummaryConsoliate);

                dispatch({
                  type: VIEW_REFRESH,
                  payload: {
                    rateSummaryList: rateSummaryList,
                    rateSeasonSummaryList: rateSeasonSummaryList,
                    tariffCode: response.data.tariffCode
                  }
                });
              }
            }
          },
          (error) => {
            gsLog.error("In TariffRateInfoServices: getLatestTransaction(): (2) error in calling Tariff Rate API = ");
            gsLog.debug(error);
            toasterService.error(`error in calling Tariff Rate API = ${error.statusText}`);
          },
          () => {
            gsLog.debug("In TariffRateInfoServices: getLatestTransaction(): DONE with processing");
          }
        );
    };
  };

  return {
    viewTariffRateInfo
  };
}

export const TariffRateInfoServicesActions = () => {
  if (tariffRateInfoServicesActionsInstance) {
    return tariffRateInfoServicesActionsInstance;
  } else {
    tariffRateInfoServicesActionsInstance = TariffRateInfoServicesActionsConstruction();
    return tariffRateInfoServicesActionsInstance;
  }
}